import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CanvasRunner } from './lib/CanvasRunner';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasWrapper } from './lib/canvasWrapper';
import { CanvasDemoFn, CanvasDemo } from './lib/CanvasDemo';
import { CodeRunner } from './lib/CodeRunner';
import { Emoji } from './lib/Emoji';
import { Collapse } from './lib/Collapse';
// import { useCleanup } from './lib/hookUtils';

// const MusicRunner: React.FC<CodeRunnerProps> = props => (
// 	<CodeRunner {...props} context={{Music}} extraLibs={musicTypeDef} beforeRun={() => {
// 		Music.stopAll();
// 	}} />
// );
const redsquiggle = require('./images/lessons/redsquiggle.png');
const syntaxErrMsg = require('./images/lessons/syntaxErrMsg.png');
const unexpectedIdentifier = require('./images/lessons/unexpectedIdentifier.png');
const unexpectedTokenElse = require('./images/lessons/unexpectedTokenElse.png');
const consloeNotDefined = require('./images/lessons/consloeNotDefined.png');

const DebuggingLesson = () => {
	// const imgStyles: React.CSSProperties = {float: 'left', marginRight: 10, width: 200, marginBottom: 10};
	const imgStyles: React.CSSProperties = {display: 'block', margin: 'auto', maxWidth: '100%', width: 400, marginBottom: 10};

	return (
		<Layout narrow>
			{/* <> */}
			<h1>Debugging <Emoji e="🐞" /> (fixing errors)</h1>

			<PagedContent pages={[
				<>
					<p className="intro">
						Have you ever written some code that didn't quite work how you wanted it to?
						I hope so! If not, you probably haven't written any code yet <Emoji e="😁" />.
					</p>

					<p className="intro">
						Programming is all about making mistakes and fixing them.
						Most things will not work perfectly on the first try.

						But that means it's very important to know how to fix our errors.
						Today we are going to learn some of the secrets of fixing our code.
					</p>
				{/* </>,<> */}
					<h2 className="hGreen">What is debugging?</h2>
					<p>
						Errors in our code are often referred to as <strong>bugs</strong>.
						And the process of removing bugs, or fixing errors in our programs,
						is known as <strong>debugging</strong>.
					</p>
				</>,<>
					<h2 className="hBlue">Three types of bugs</h2>

					<p>
						Today we'll look at three different categories of bugs.
					</p>

					<p>
						<strong>Syntax errors</strong> are errors that make the program unreadable to the computer. <br/>
						<strong>Runtime errors</strong> are errors that make cause the program to stop running or crash. <br/>
						<strong>Logical errors</strong> are errors where the program doesn't do what the programmer intended for it to do.
					</p>

					<p>Let's take a closer look at each of these.</p>

				</>,<>

					<h2 className="hBlue">Syntax Errors</h2>
					<p>
						<strong>Syntax</strong> means the structure and order of a language.
						For example, here's an example of a sentence in a spoken language (English)
						with bad syntax:
					</p>

					<blockquote>
						Chocolate have do you any!
					</blockquote>

					<p>
						If somebody said that to you, you'd probably be a little bit confused.
						You might guess that they meant to ask, "Do you have any chocolate?"
						Or did they mean something else?
					</p>

					<p>
						It's confusing because we expect the words to appear in a certain order,
						and when a sentence doesn't follow the rules,
						we might not be able to understand it at all.
					</p>
				</>,<>
					<h2 className="hBlue">Syntax Errors</h2>

					<p>
						Programming languages are a lot like spoken languages.
						They have rules so the programmer and the computer can agree
						on what things mean and the computer can run the program exactly how the programmer asked it to.
						{/* Computers can't guess, so they need to be able to  */}
					</p>

					<p>
						Before a Javascript program runs, the computer must first look over the whole
						program and figure out how it's organized.
						If the program doesn't follow the rules,
						the computer can't run the program,
						because it doesn't understand it.
					</p>

					<p>
						That's what we call a syntax error.
						Here's an example of a one-line program with a syntax error:
					</p>

					<CodeRunner code={`
						console.log('this will not run';
					`} />

					<p>
						Can you spot the error? Function calls have to have a
						left parenthesis <code>(</code> and a right parenthesis <code>)</code>.
						If you have a left paren (parenthesis) and no right paren,
						the computer can't understand your program, and it can't run.
					</p>

				</>,<>
					<h2 className="hBlue">Fixing syntax errors</h2>

					<p>Fortunately, syntax errors are pretty easy to fix. And there are tools to help us out.</p>

					<p>These errors can be hard to spot, but luckily our code editor can help us. Here's our program again:</p>

					<CodeRunner code={`
						console.log('this will not run';
					`} />

					<p>
						Do you see the red squiggle in the editor?
					</p>

					<img src={redsquiggle} style={imgStyles} />

					<p>
						That red squiggle shows you where the error is.
						Then it's your job to figure out how to fix it.
					</p>

					<p>In this case, that's pretty easy: add the missing <code>)</code> before the semicolon (<code>;</code>).</p>

				</>,<>
					<h2 className="hBlue">Fixing syntax errors</h2>

					<p>Sometimes you won't see a syntax error until you try to run the program.</p>
					<p>When your try to run a program with a syntax error, you'll get a message like this in the console:</p>

					<img src={syntaxErrMsg} style={imgStyles} />

					<p>
						The message will tell you what seems to be wrong.
						This one is pretty helpful: It can tell you that there's a <code>)</code> missing.
						Then you just need to figure out where the missing <code>)</code> needs to go.
					</p>

					<p>Try running this one, then looking at the error message before you fix it:</p>

					<CodeRunner code={`
						console.log('one of these will not work');
						console.log('one of these will not work';
					`} />

				</>,<>
					<h2 className="hBlue">Fixing syntax errors</h2>

					<p>Some syntax errors can be a little tricker. Here's an example:</p>

					<CodeRunner code={`
						draw();

						funtion draw() {
							console.log('drawing');
						}
					`} />

					<p>Can you spot the problem here? Try running the code. You should see this:</p>

					<img src={unexpectedIdentifier} style={imgStyles} />

					<p>
						What does <code>Unexpected identifier</code> mean?
						Well, an identifier is a name -- usually a variable or a function name.
						so it's saying it's seeing a variable name somewhere it doesn't expect one.
					</p><p>
						Look for the red squiggle again. This time it's under the function name <code>draw</code>.
						So <code>draw</code> is the name that's not expecting to see. Why is it unexpected?
					</p><p>
						Look closely at the line <code>{'funtion draw() {'}</code>.
						I've misspelled <code>function</code> as <code>funtion</code>.
						<code>funtion</code> doesn't mean anything special in javascript, so it looks like a variable name.
						Which means <code>function draw</code> looks like two variable names one after the other, and that's not allowed.
						So the second one, <code>draw</code>, is the one that's the error.
					</p><p>
						This is why syntax errors can be a little extra tricky sometimes. The error message might actually
						refer to whatever comes right after the actual error.
					</p>
				</>,<>

					<h2 className="hBlue">Fixing syntax errors</h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						Let's try another one.
						First, try to spot the problem on your own.
						Can you see what's missing here?
					</p>

					<CodeRunner code={`
						var isGood = true;

						if (isGood) {
							console.log('Oh yes!');
						else {
							console.log('Oh no!');
						}
					`} />

					<p>
						Just like every <code title="left parenthesis">(</code> needs to be closed with a <code title="right parenthesis">)</code>,
						every <code title="left curly bracket">{'{'}</code> needs to be closed with a <code title="right curly bracket">{'}'}</code>.
					</p><p>
						In an if statement, the brackets mark the block of should that should run when the condition is true.
						When does this block end?
					</p><p>
						If you said that it doesn't end, you're right.
						An if statement that doesn't end makes no sense, so the program can't even run.
					</p>

					<p>Without fixing it, try running the code.</p>

					<img src={unexpectedTokenElse} style={imgStyles} />

					<p>If the problem is that the if block doesn't end, why is the error message "Unexpected token 'else'"?</p>

					<p>
						Well, an <strong>else</strong> statement must come right after an <strong>if</strong> statement.
						This else looks like it's inside the other if statement, since that one doesn't have a closing <code>}</code>.
					</p>

					<p>
						This is another example of the error that the message about (unexpected <strong>else</strong>)
						coming right after the real error (missing <code>{'}'}</code>) on the if statement.
					</p>

					<p>
						The thing to remember is that sometimes the problem will be right where the message says it is,
						and sometimes it will be just before that.
					</p>
				</>,<>

					<h2 className="hBlue">Runtime errors <Emoji e="🐛"/></h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						Next up are runtime errors. These are errors that happen while your program is running.
						They can cause the program to "crash", or stop running.
					</p>

					<p>
						Try running this program. What happens?
						{/*  */}
					</p>

					<CodeRunner code={`
						console.log('This runs.');
						consloe.log('This does not!');
					`} />

					<p>
						The first line runs and prints "This runs".
						But the second line doesn't. Instead, you see this message:
					</p>

					<img src={consloeNotDefined} style={imgStyles} />

					<p>
						This message is very helpful.  Not only does it tell us exactly what the problem is
						(<code>consloe is not defined</code>), but it also tells us where it is
						(<code>at line 2</code>).
					</p>

					<p>
						I meant to use the <code>console.log</code> function,
						but I accidentally typed <code>consloe.log</code> instead.
						Nothing called <code>consloe</code> exists in the program, so this is an error.
						Since the program can't find a <code>consloe.log</code> function to run there, it gives up and quits.
					</p>

				</>,<>
					<h2 className="hBlue">Runtime errors <Emoji e="🐛"/></h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						The fix for this should be obvious: change <code>consloe</code> to <code>console</code>.
						Give it a try!
					</p>

					<CodeRunner code={`
						console.log('This runs.');
						consloe.log('This does not!');
					`} />

				</>,<>
					<h2 className="hBlue">Runtime errors <Emoji e="🐛"/></h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						It's not just spelling that matters, but capitalization too. Look at this program:
					</p>

					<CodeRunner code={`
						var name = 'Brian';
						var NAME = 'Tom';
						var Name = 'Jane';
						var nAmE = 'Chuck';

						console.log('name is', name);
						console.log('NAME is', NAME);
						console.log('Name is', Name);
						console.log('nAmE is', nAmE);
					`} />

					<p>
						You can have different variables that are spelled the same, with different capitalization.
						I don't recommend it. It's not a good idea.
					</p>
					<p>
						But you can, and so you have to make sure you use the same capitalization each time you write a variable name.
					</p>

				</>,<>
					<h2 className="hBlue">Runtime errors <Emoji e="🐛"/></h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						Try it out yourself. Can you fix this program?
					</p>

					<CanvasRunner console vertical canvasHeight={200} editorHeight={640} docName="bugs.capitalization" code={`
						var boxX = 0;
						var boxY = 0;
						var boxWidth = 25;

						canvas.animate(draw);

						function draw() {
							boXx += 10;
							boXy += 13;

							if (BoxX > 400) {
								boxx -= 400 - boxwidth;
							}

							if (BoxY > 400) {
								boxy -= 400 - BoxWidth;
							}

							canvas.background('black');
							canvas.fill('white');
							canvas.rect(boxx, boxy, boxwidth, boxwidth);
						}
					`} />

					<p>
						Remember to look at the error messages to see where the next problem is.
					</p>

					<p>
						Make sure you don't create any new variables, just fix the capitalizations inside
						the <code>draw</code> function so they are referring to variables that already exist.
					</p>

					<p>
						When you finish, you should get a box that flies diagonally across the screen.
					</p>

				</>,<>
					<h2 className="hBlue">Runtime errors <Emoji e="🐛"/></h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						Let's look at some other common runtime errors.
					</p>

					<CodeRunner code={`
						console.print('Hello');
					`} />

					<p>
						You should see the error message <code>TypeError: console.print is not a function</code>.
						What does that mean?
					</p>

					<p>
						It's very similar to the last error.  <code>console</code> exists, but <code>console.print</code> does not,
						and so <code>console.print</code> can't be called because it's not a function (because it's undefined).
					</p>

					<p>
						What was meant by <code>console.print</code>? Probably <code>console.log</code>.
						Try changing <code>console.print</code> to <code>console.log</code> above.
					</p>

					<p>
						There are lots of possible ways to get errors in your programs, but we won't go through all of them.
						The important thing is to know how to find where they are and how to fix them.
						Most of the time it will be referring to a variable that doesn't exist.
					</p>

					{/* <CodeRunner code={`
						console.print('Hello');
					`} />

					<p>
						You should see the error message <code>TypeError: console.print is not a function</code>.
						What does that mean?
					</p>

					<p>
						It's very similar to the last error.  <code>console</code> exists, but <code>console.print</code> does not,
						and so <code>console.print</code> can't be called because it's not a function (because it's undefined).
					</p>

					<p>
						What was meant by <code>console.print</code>? Probably <code>console.log</code>.
						Try changing <code>console.print</code> to <code>console.log</code> above.
					</p> */}

				</>,<>
					<h2 className="hBlue">Logical errors <Emoji e="🤔"/></h2>
					{/* <h2 className="hGreen">Your Turn</h2> */}

					<p>
						The last type of error is the trickiest one. These are not errors that keep our program from running.
						They are the errors where the program doesn't do what we wanted or expected it to do.
					</p>

					<p>
						In other words, your program is working -- or a least it's running. But it's not working right.
						So how do you figure out what's wrong?
					</p>

					<h3>Debugging Tips <Emoji e="👾" /></h3>

					<p>
						Here's a list of tips that can help you debug errors in your projects.
						Some will work better than others in different situations.
						Then we'll look at some examples of how to use them with some buggy programs.
					</p>

					<ul>
						<li>Use <code>console.log</code> to inspect the variables in your program.</li>
						<li>Use <code>console.log</code> to see what code is running and when.</li>
						<li>Use <code>canvas.text</code> to watch the values of variables that change.</li>
						<li>Explain what your program is doing line by line to someone else.</li>
						<li>Disable parts of your code using comments to help find the problem.</li>
					</ul>

				</>,<>
					<h2 className="hBlue">Logical errors <Emoji e="🤔"/></h2>

					<p>
						Let's look at an example. This program is supposed to
						draw a white square on a black background, but it doesn't work. Why not?
					</p>

					<CanvasRunner console editorHeight={380} code={`
						var colors = ['red', 'white'];
						canvas.background('black');
						canvas.fill(colors[2]);
						canvas.rect(150, 150, 100, 100);
					`} />

					<p>
						Our first debugging technique is to use <code>console.log</code> to inspect the variables in your program.
						What could you log here to help determine what the problem is?
					</p>
				{/* </>,<> */}
					<h2 className="hBlue">Logical errors <Emoji e="🤔"/></h2>

					<p>
						Since we are seeing the black background, but not the rectangle, I'm suspicious of the two lines that help me draw the rectangle:
					</p>

					<CodeBox code={`
						canvas.fill(colors[2]);
						canvas.rect(150, 150, 100, 100);
					`}/>

					<p>
						Especially the <code>canvas.fill</code> part, because it's using variables, where the <code>canvas.rect</code> part does not.
					</p>

					<p>
						A good idea would be to print the color that I'm try to set before drawing the rectangle, like this:
					</p>

					<CodeBox code={`
						console.log(colors[2]);
						canvas.fill(colors[2]);
					`}/>

					<p>or, even better:</p>


					<CodeBox code={`
						var fillColor = colors[2];
						console.log('setting color:', fillColor);
						canvas.fill(fillColor);
					`}/>

					<p>
						It's a good idea to not just print the value, but something describing what you are trying to print,
						to make the output easy to read. Try replacing line 3 below with the code above:
					</p>

					<CanvasRunner console docName="bugs.logic1" editorHeight={340} code={`
						var colors = ['red', 'white'];
						canvas.background('black');
						canvas.fill(colors[2]);
						canvas.rect(150, 150, 100, 100);
					`} />

					<p>What does it print? Why is my color <code>undefined</code>?</p>

					{/*
					<div className="cont blOrange">
						<div style={{padding: 1, margin: 1}}>

							<Collapse collapsed title={<>What is <code>undefined</code>?</>}>
								<p>
									In Javascript, <code>undefined</code> means a value that has not been set or does not exist.
									For example:
								</p>

								<ul><li>A variable that has been defined but not given a value</li></ul>

								<CodeBox code={`
									var thing;
									// thing is undefined
									thing = 1;
									// thing is now 1
								`} />

								<ul><li>A property that doesn't exist on an object</li></ul>

								<CodeBox code={`
									var guy = {name: 'Tim', job: 'Toolman'};
									console.log(guy.name) // Tim
									console.log(guy.favoriteFood) // undefined
								`} />

								<ul><li>An array item that doesn't exist</li></ul>

								<CodeBox code={`
									var colors = ['blue', 'green'];
									console.log(colors[1]); // green
									console.log(colors[2]); // undefined
								`} />

							</Collapse>
						</div>
					</div>
					*/}

					<p>
						The array has two items in it, <code>'red'</code> and <code>'white'</code>.
						They are at indexes 0 and 1, so <code>colors[2]</code> is empty, or <code>undefined</code>.
					</p>

					<p>
						<code>canvas.fill(undefined)</code> does nothing, so the fill color doesn't get changed, and stays the color it was before, which was black.
					</p>

				</>,<>
					<h2 className="hBlue">Logical errors <Emoji e="🤔"/></h2>

					<p>
						Let's try another one.
					</p>


					<CanvasRunner console editorHeight={460} docName="bugs.logic2" code={`
						var x = 200;
						canvas.animate(draw);

						function draw() {
							x++;

							if (x = 400) {
								x = 0;
							}

							canvas.background('white');
							canvas.ellipse(x, 200, 50);
						}
					`} />

					<p>
						Look over the code. What is it supposed to be doing? And when you run the code, what is it actually doing?
					</p>

					<p>
						This should draw a ball in the center of the screen,
						moving slowly to the right, then jumping over to the left edge when it hits the right edge.
					</p><p>
						Instead, it's jump stuck to the left edge. Why?
					</p><p>
						Let's use our next tip to find out: <strong>Use <code>console.log</code> to see what code is running and when.</strong>
					</p><p>
						Since we don't see anything moving, we might wonder if the draw function is even being called over and over again.
						To find out, we can just add a <code>console.log</code> statement.
						Add <code>console.log('drawing');</code> just inside the draw function.
					</p><p>
						When you run it, you should see that 'drawing' is being printed continuously to the console.
						When you clear it, it fills right back up again.
						So we know our draw function is running. That's not the problem.
					</p><p>
						Next, let's see how often our if statement is <code>true.</code>
						Change the if statement to look like this:
					</p>


					<CodeBox code={`
						if (x = 400) {
							console.log('setting x to 0');
							x = 0;
						}
					`} />

					<p>
						When you run that, you'll see something surprising: Every time the draw function runs,
						x gets set back to 0!
					</p><p>
						Well, that explains why the ball is stuck to the side. But why is x getting set to 0 every time?
						To find out, we need to look at our if condition:
						<code>if (x = 400)</code>
					</p><p>
						Even though x starts out at 200, and keeps getting set to 0, this condition is always <code>true</code>. Why?
					</p><p>
						Let's make another adjustment to our if statement, so we print the value of x before and after it:
					</p>


					<CodeBox code={`
						console.log('x', x);
						if (x = 400) {
							console.log('setting x to 0', x);
							x = 0;
						}
					`} />

					<p>
						Now the problem is even clearer: whatever <code>x</code> was before the if statement,
						it changes to 400 inside. That's not what we wanted.
					</p><p>
						Do you remember the difference between <code>=</code> and <code>==</code>?
						One, <code>==</code> compares – tests if two values are equal.
						The other, <code>=</code>, assigns – it assigns the value on the right into the variable on the left.
						Instead of comparing <code>x</code> to 400, we are <em>setting</em> <code>x</code> to 400.
						Then the if statement runs the block, and sets it back to 0.
					</p><p>
						So, with that in mind, simply change <code>if (x = 400)</code> to <code>if (x == 400)</code> and try running the program again.
					</p><p>
						You should see the ball moving across the screen, just like we wanted.
						And when it gets to the right edge, it jumps back to the left edge and keeps going.
					</p>
				</>,<>
					<h2 className="hBlue">Logical errors <Emoji e="🤔"/></h2>

					<p>
						Sometimes when you are printing a changing value over and over in an animation function,
						it's too hard to read the number because so much is being printed to the console.
					</p>

					<p>
						That's where another tip comes in handy: <strong>Use <code>canvas.text</code> to watch the values of variables that change.</strong>
						Here's your last program. Remove or comment out the <code>console.log</code> lines.
						Then add this to the very end of your draw function:
					</p>

					<CodeBox code={`canvas.text('x: ' + x, 10, 390);`} />

					<p>
						Instead of printing to the console, we're going to draw the number on the screen.
						That makes it easier to watch the number change, and keeps the console clear
						so you can print other helpful things there.
					</p>

					<CanvasRunner console editorHeight={500} docName="bugs.logic2" code={`
						var x = 200;
						canvas.animate(draw);

						function draw() {
							x++;

							console.log('x', x);
							if (x == 400) {
								console.log('setting x to 0', x);
								x = 0;
							}

							canvas.background('white');
							canvas.ellipse(x, 200, 50);
						}
					`} />

					<p>
						This technique can be useful for any values that change in an animation, not just x and y coordinates.
					</p>
				</>,<>
					<h2 className="hBlue">Wrapping Up <Emoji e="🐜"/></h2>

					<p>
						Fixing bugs can be tough, but it's an important skill for any type of programming.
						Every time you fix a bug you are becoming a better problem solver,
						and that doesn't just make you a better programmer, it makes you a better person, so good job!
					</p><p>
						And if you ever are completely stuck, you can always ask someone else for help.
						In this class, remember that there's the Help Chat available in the menu under your name.
					</p>

				</>
			]} />

		</Layout>
	);
};

export { DebuggingLesson }
