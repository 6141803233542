import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CanvasRunner } from './lib/CanvasRunner';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasWrapper } from './lib/canvasWrapper';
import { CanvasDemoFn, CanvasDemo } from './lib/CanvasDemo';
import { CodeRunner } from './lib/CodeRunner';
// import { useCleanup } from './lib/hookUtils';

// const MusicRunner: React.FC<CodeRunnerProps> = props => (
// 	<CodeRunner {...props} context={{Music}} extraLibs={musicTypeDef} beforeRun={() => {
// 		Music.stopAll();
// 	}} />
// );

const GridLesson = () => {
	return (
		<Layout narrow>
			{/* <> */}
			<h1>Grids with 2D arrays</h1>

			<PagedContent pages={[
				<>
					<p className="intro">
						Today we are going to look at how you can represent a two dimensional grid,
						like a map or a maze.
						This will help you both draw and interact with it.
					</p>

					<p className="intro">
						This technique is useful for maps, mazes, puzzles, platformers, and more.
					</p>

					{/* <p className="intro">
						The sounds we'll be making in this class are similar to
						the ones in classic games on consoles such as the NES and the Atari.
					</p> */}

					{/*
					<ul>
						<li className="hBlue"><a onClick={() => goTo(1)}>A Simple Example</a></li>
						<li className="hBlue"><a onClick={() => goTo(2)}>Playing notes in order</a></li>
						<li className="hBlue"><a onClick={() => goTo(3)}>Playing notes in order, take 2</a></li>
						<li className="hBlue"><a onClick={() => goTo(4)}>Cleaning it up</a></li>
						<li className="hBlue"><a onClick={() => goTo(5)}>More cleanup: Using arrays and loops</a></li>
						<li className="hGreen"><a onClick={() => goTo(6)}>Your Turn</a></li>
						<li className="hBlue"><a onClick={() => goTo(7)}>Rhythm</a></li>
						<li className="hPurple"><a onClick={() => goTo(8)}>Bonus round: More ways to get fancy </a></li>
					</ul>
					*/}

				</>,<>

					<h2 className="hBlue">Example: A Maze</h2>

					<p>
						First, let's look at a working example.  This is a small maze.
						Try moving around the maze, and notice that you can't move onto
						the walls, which are black.
					</p>

					<p>
						In a moment you'll learn one way of building a maze like this. But first,
						try to think of how you would go about solving this.  What would you do?
					</p>

					<CanvasDemo codeFn={mazeDemo} />

				</>,<>

					<h2 className="hBlue">Representing a grid with a 2D array</h2>

					<p>
						When we are looking at a grid, we can say where something is on the grid
						by describing what row it's on, and what column it's on.
					</p>

					<p>
						Have you ever played Battleship? The rows are described by numbers, and the columns are described by letters.
						You say a letter and a number to tell the other player which square you want to attack.
					</p>

					<img style={{background: 'white', display: 'block', margin: 'auto', width: 400}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Battleship_game_board.svg/480px-Battleship_game_board.svg.png" alt="Battleship board"/>

					<p>
						In javascript, arrays are perfect for representing a row or a column. They can be any length, and hold anything in them.
						So a row of numbers could be represented as an array, like this:
					</p>

					<CodeBox code={`
						var rowOfNumbers = [0, 1, 0, 2, 5, 0, 3];
					`} />

					<p>
						But what about grid, which has rows <em>and</em> columns?
					</p>

					<p>
						The solution is actually pretty simple.  You need an <strong>array of arrays</strong>,
						or in other words, a <strong>two-dimensional array</strong>.
					</p>

					<p>
						Your grid is an array of rows, and each row is an array of the columns inside that row.
					</p>

					<p>
						Here's an example of how you might represent a tic-tac-toe board:
					</p>

					<CodeRunner code={`
						// Creating the rows first
						var row1 = [0, 0, 0];
						var row2 = [0, 2, 0];
						var row3 = [1, 0, 1];

						// then putting the rows together
						var board = [
							row1,
							row2,
							row3,
						];

						// This is the same thing, in fewer lines of code.
						var board = [
							[0, 0, 0], // first row
							[0, 2, 0], // second row
							[1, 0, 1], // third row
						];
					`} />

					<p>
						Note: I used numbers in this example, but I could have used something else,
						such as the strings <code>'X'</code>, <code>'O'</code>, and <code>' '</code>.
					</p>

				</>,<>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Make a 3x3 or 4x4 grid full of letters or numbers using a two-dimensional array.
						Use <code>console.log()</code> to print out the entire grid at once.
						For a bonus challenge, try printing out each row one at a time, or each cell one at a time.
					</p>

					<CodeRunner docName="grid.printGrid" height={400} code={`
						// var grid = ???

						// print out your grid using console.log()

					`} />

				</>,<>

					<h3 className="hBlue">Drawing your grid</h3>

					<p>So far, we have been creating grids but not drawing them.  Let's draw something, shall we?</p>

					<CanvasRunner vertical code={`
						// player 1 has won this game already
						var board = [
							[1, 1, 1],
							[2, 2, 0],
							[1, 0, 2],
						];

						// use the number of rows and columns to calculate cell width and height
						var rows = board.length;
						var cols = board[0].length;
						var cellHeight = 400 / board.length;
						var cellWidth = 400 / board[0].length;

						canvas.textAlign('center', 'middle');
						canvas.textSize(cellHeight * .8);

						// use two loops to go through the whole grid
						for (var r = 0; r < rows; r++) {
							// r is the row index
							// row will be each row in the grid
							var row = board[r];
							for (var c = 0; c < cols; c++) {
								// c is the column index
								// cell is the item in the grid. 0 for empty, 1 for X, 2 for O
								var cell = row[c];

								// 0 is empty, so don't draw anything unless it's 1 or 2
								if (cell > 0) {
									var x = cellWidth * c + cellWidth / 2;
									var y = cellHeight * r + cellHeight / 2;

									var color = 'red';
									var letter = 'X';
									if (cell == 2) {
										color = 'blue';
										letter = 'O';
									}

									// draw the letter
									canvas.fill(color);
									canvas.text(letter, x, y);
								}
							}
						}

						// draw the grid
						canvas.fill('black');
						canvas.rect(10, cellHeight - 5, 380, 10);
						canvas.rect(10, cellHeight * 2 - 5, 380, 10);
						canvas.rect(cellWidth - 5, 10, 10, 380);
						canvas.rect(cellWidth * 2 - 5, 10, 10, 380);
					`} />

					<p>
						There's a lot going on here, but let's go over the important parts:
					</p>

					<ul>
						<li> We use grid to know what to draw on the screen. </li>
						<li> We use two loops to go through each item in the grid and draw something for that item.</li>
						<li> We use math to decide where to draw each item and how big it should be.
							To keep it flexible, we calculate the size of each space based on how many rows and columns there are
							in the grid.
						</li>
					</ul>

				</>,<>

					<h2 className="hBlue">Reading and writing your grid:</h2>

					<p>
						Let's keep going with the tic-tac-toe grid for a minute.
						Imagine that we have an empty grid, and it's Player 1's turn.
						They click on the center square. We need to update the grid to show that they
						have chosen that square. How do we do it?
					</p>

					<p>
						As a quick refresher, you can access the items in an array like this:
					</p>

					<CodeRunner code={`
						var things = ['pizza', 'bagels', 'orange juice'];
						// this will print 'pizza'.
						console.log(things[0]);

						// this replaces 'orange juice' with 'lemonade'
						things[2] = 'lemonade';

						// this will print [pizza, bagels, lemonade].
						console.log(things);
					`} />

					<p>
						So <code>things[0]</code> refers to the first item in the <code>things</code> array,
						and you can use it (<code>console.log(things[0])</code>),
						and change it (<code>things[0] = 'breadsticks'</code>).
					</p>

					<p>
						In a 2D array, the items in the array, such as <code>grid[0]</code>, are also arrays,
						because they represent rows. <code>grid[0]</code> is the first row of the grid.
						<code>grid[0][0]</code>, therefore, is the first column in the first row, or the top left
						square of the grid.
					</p>

					<CodeRunner code={`
						var board = [
							[0, 0, 0],
							[0, 0, 1],
							[1, 2, 0],
						];

						console.log('the top left square is ', board[0][0]);
						console.log('the middle right square is ', board[1][2]);
						console.log('the bottom center square is ', board[2][1]);

						// change the first square
						board[0][0] = 2;

						console.log('the top left square is now ', board[0][0]);
					`} />

				</>,<>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Let's use what we just learned to make a playable tic-tac-toe game.
						It's mostly done already, but nothing happens when you click.
						To finish making it work, do the following:
					</p>

					<ol>
						<li>Look over the function <code>whenMouseClicked</code> to understand what it's doing.</li>
						<li>
							Find the function <code>doMove</code>, and add code to make it update the board
							with the player's move when someone clicks the board.
						</li>
						<li>
							Use and update the variable <code>playersTurn</code> when a turn is taken
							so the players can take turns.
						</li>
						<li>
							Bonus: Make sure that you can't repeat turns -- if someone clicks a square
							that has already been chosen, nothing should happen.
						</li>
					</ol>

					<CanvasRunner docName="grid.ticTacToePlayable" vertical console editorHeight={600} code={`
						var playersTurn = 1;
						var board = [
							[0, 0, 0],
							[0, 0, 0],
							[0, 0, 0],
						];

						// use the number of rows and columns to calculate cell width and height
						var rows = board.length;
						var cols = board[0].length;
						var cellHeight = 400 / board.length;
						var cellWidth = 400 / board[0].length;

						draw();

						function doMove(row, col) {
							// update the game grid

							// and then update the screen

						}

						function whenMouseClicked(x, y) {
							var row = Math.floor(y / cellHeight);
							var col = Math.floor(x / cellWidth);

							console.log('you clicked row', row, 'col', col);

							doMove(row, col);
						}

						function draw() {
							canvas.background('white');

							canvas.textAlign('center', 'middle');
							canvas.textSize(cellHeight * .8);

							// use two loops to go through the whole grid
							for (var r = 0; r < rows; r++) {
								// r is the row index
								// row will be each row in the grid
								var row = board[r];
								for (var c = 0; c < cols; c++) {
									// c is the column index
									// cell is the item in the grid. 0 for empty, 1 for X, 2 for O
									var cell = row[c];

									// 0 is empty, so don't draw anything unless it's 1 or 2
									if (cell > 0) {
										var x = cellWidth * c + cellWidth / 2;
										var y = cellHeight * r + cellHeight / 2;

										var color = 'red';
										var letter = 'X';
										if (cell == 2) {
											color = 'blue';
											letter = 'O';
										}

										// draw the letter
										canvas.fill(color);
										canvas.text(letter, x, y);
									}
								}
							}

							// draw the grid
							canvas.fill('black');
							canvas.rect(10, cellHeight - 5, 380, 10);
							canvas.rect(10, cellHeight * 2 - 5, 380, 10);
							canvas.rect(cellWidth - 5, 10, 10, 380);
							canvas.rect(cellWidth * 2 - 5, 10, 10, 380);
						}
					`} />

				</>,<>

					<h2 className="hPurple">Bonus: Using a string for each row</h2>

					<p>
						Two lessons back, you learned how strings are a lot like arrays.
						Both have a <code>length</code> property, and you can use square brackets
						(<code>list[3]</code>) and an index with both to look at an individual item or letter.
						For some bigger grids, such as maps, it can be convenient to use a string for each row,
						instead of an array:
					</p>

					<CodeRunner code={`
						// instead of this:
						var arrayMap = [
							['*', '*', '*', '*', '*', '*'],
							['*', 'a', ' ', ' ', ' ', '*'],
							['*', ' ', '*', 'b', ' ', '*'],
							['*', '*', '*', '*', '*', '*'],
						];

						// you can do this. much easier to read and edit!:
						var stringMap = [
							'******',
							'*a   *',
							'* *b *',
							'******',
						];

						console.log(arrayMap[1][1]);
						console.log(stringMap[1][1]);
					`} />

					<p>
						This can make it easier to 'draw' something out right there in your code.
					</p>

				</>,<>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Use this example to create your own map or picture.
						You can change the map, its symbols, colors, sizes, and how the shapes are drawn.
						Get creative! It can be any height and width, as long as all the rows are the same length.
					</p>

					<CanvasRunner docName="grid.mapMaker" vertical console editorHeight={500} code={`
						var map = [
							'ssssssss',
							'sssssSss',
							'ssssssss',
							'mmssssms',
							'oooooooo',
							'oooooooo',
							'oooooobb',
							'ooobbbbb',
						]

						var spaceHeight = 400 / map.length;
						var spaceWidth = 400 / map[0].length;

						var colors = {
							s: 'skyblue', // sky
							S: 'yellow', // sun
							m: 'purple', // mountains
							o: 'blue', // ocean
							b: 'tan',  // beach
						}

						draw();

						function draw() {
							for (var r = 0; r < map.length; r++) {
								for (var c = 0; c < map[r].length; c++) {
									var space = map[r][c];
									var color = colors[space];
									canvas.fill(color);
									canvas.rect(c * spaceWidth, r * spaceHeight, spaceWidth, spaceHeight);
								}
							}
						}
					`} />



					<p>
						Note: For a fancier example, look at how I drew space invaders in this
						example: <a href="https://code.mortensoncreative.com/ahsj/published/mqk1s7sh">https://code.mortensoncreative.com/ahsj/published/mqk1s7sh</a>
					</p>

				</>,<>

					<h2 className="hPurple">More examples</h2>

					<p>
						Here are some other examples projects I have created with 2d grids.
					</p>

					<h3><PrefixedLink to="/published/q9omgk7r" target="_blank">Maze Project</PrefixedLink></h3>
					<p>
						This project demonstrates a simple maze using <del>a 2d array</del> an array of strings to represent the maze tiles.
					</p>
					<h3><PrefixedLink to="/published/xksqjus6" target="_blank">Map Project</PrefixedLink></h3>
					<p>
						This project uses an array of strings to represent and draw a map.
					</p>
					{/* <h3><PrefixedLink to="/published/ywcgx8a6" target="_blank">Happy Birthday</PrefixedLink></h3>
					<p>
						Playing happy birthday, one note at a time.  Not the best way of doing it.
					</p>
					<h3><PrefixedLink to="/published/nqzh81ts" target="_blank">Happy Birthday II</PrefixedLink></h3>
					<p>
						A better way of playing happy birthday, using an array to store the note values and durations.
					</p>
					<h3><PrefixedLink to="/published/c8ss1x1p" target="_blank">Sound Effects</PrefixedLink></h3>
					<p>
						A simple project with a few sound effects in it. A good way to see how to set up sound effects for your own project.
					</p>
					<h3><PrefixedLink to="/published/e2mpymcq" target="_blank">King Arthur</PrefixedLink></h3>
					<p>
						Now with sound effects! the same ones as in the project above.
						Pick up your sword, then start slaying dragons! Use spacebar to swing your sword, and the / key to hold your
						sword in the other hand.
					</p> */}

				</>
			]} />

		</Layout>
	);
};

const mazeDemo: CanvasDemoFn = (canvas) => {
	var player = {
		x: 1,
		y: 1,
	}

	var map = [
		'**********',
		'*     *  *',
		'* *** ** *',
		'*    *   *',
		'* **  ** *',
		'*   * *  *',
		'*** *   **',
		'* * **** *',
		'*        *',
		'**********',
	];

	var spaceHeight = 400 / map.length;
	var spaceWidth = 400 / map[0].length;

	draw();

	function draw() {
		canvas.background('white');
		drawMap();
		drawPlayer();
	}

	function drawMap() {
		canvas.fill('black');
		for (var r = 0; r < map.length; r++) {
			for (var c = 0; c < map[r].length; c++) {
				var space = map[r][c];
				if (space == '*') {
					canvas.rect(c * spaceWidth, r * spaceHeight, spaceWidth, spaceHeight);
				}
			}
		}
	}

	function drawPlayer() {
		canvas.fill('red');
		canvas.rect(player.x * spaceWidth, player.y * spaceWidth, spaceWidth, spaceHeight);
	}

	function whenKeyPressed(key) {
		if (key == 'left') {
			attemptMove(-1, 0);
		}
		else if (key == 'right') {
			attemptMove(1, 0);
		}
		else if (key == 'up') {
			attemptMove(0, -1);
		}
		else if (key == 'down') {
			attemptMove(0, 1);
		}
	}

	function attemptMove(x, y) {
		var nextY = player.y + y;

		if (nextY > 0 && nextY < map.length) {
			var nextX = player.x + x;
			var nextSpace = map[nextY][nextX];

			if (nextSpace != '*') {
				player.x = nextX;
				player.y = nextY;
			}
		}
		// later stage:
		// else {
		//     bumpSound();
		// }

		draw();
	}

	return { whenKeyPressed }
}

export { GridLesson }
