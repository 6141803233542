import { SpringInterpolator } from '@react-spring/shared'
import { createAnimatedInterpolation as to } from '@react-spring/shared/globals'
import { deprecateInterpolate } from '@react-spring/shared/deprecations'

const interpolate: SpringInterpolator = (...args: [any, any]) => {
  deprecateInterpolate()
  return to(...args)
}

export { to, interpolate }
