import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CanvasRunner } from './lib/CanvasRunner';
import { CanvasDemo } from './lib/CanvasDemo';
import { CodeRunner } from './lib/CodeRunner';
import { Emoji } from './lib/Emoji';

import typingGame01 from './projects/typingGame-0.1';
import typingGame10 from './projects/typingGame-1.0';
import { PrefixedLink } from './lib/PrefixedLink';

const TypingGameLesson = () => {
	// const imgStyles: React.CSSProperties = {float: 'left', marginRight: 10, width: 200, marginBottom: 10};
	return (
		<Layout narrow>
			{/* <> */}
			<h1>Typing Game</h1>

			<PagedContent pages={[
				<>
					<p className="intro">
						Today, we will start a new project that we'll work on for a few weeks: A typing game.
					</p>

					<p className="intro">
						Before we get into it, let's look at what we're going to work toward.
						Here's a basic game that helps us practice typing by giving us random letters to type.
					</p>

					<CanvasDemo codeFn={typingGame10}  />

					<p>
						This game is relatively simple, but at the same time, there's a lot to it.
						Instead of trying to build it all at once, we're going to try to build it step by step,
						in a way that after each step we have a working game,
						that's just a little bit better than the version from the step before.
					</p>
					<p>
						But first, we'll discuss as a class what we're going to need to do to build this game.
					</p>
				</>,<>
					<h2 className="hBlue">Game Features</h2>

					<p>
						A <strong>feature</strong> is an individual working part of a program.
						For example, in a paint program, the ability to change tools, or change colors, or undo would all be features.
						Let's try to talk about what features this game has.
						Try to break it down as much as possible, so that each feature is as simple as possible.
						What features does this typing game have?
					</p>

					<p>
						Think about this, and make a list with the class (or on a piece of paper if you're on your own)
						of what features this game has before continuing on to the next step.
					</p>
				</>,<>
					<h2 className="hBlue">Game Features</h2>

					<p>
						Here are some of the features I came up with for this game. This game has or does all of these things:
					</p>

					<ul>
						<li><strong>Keyboard Input</strong> responds to typed input from the keyboard.</li>
						<li><strong>Score</strong> keeps track of the users score.</li>
						<li><strong>High Score</strong> keeps track of the high score.</li>
						{/* <li><strong>Displays letters to type</strong> generates a list of ten random letters at a time.</li> */}
						<li><strong>Randomly Generated Letters</strong> generates a list of ten random letters at a time.</li>
						<li><strong>Timer</strong> has a timer that counts down and ends the round.</li>
						<li><strong>Coloring</strong> uses colors to show which letters have been typed correctly.</li>
						<li><strong>Instructions Screen</strong> shows instructions before the game starts.</li>
						<li><strong>Scores Screen</strong> displays scores after the game is finished.</li>
					</ul>

					<p>
						Can you think of any others? Can you think of a way to break any of those down into smaller pieces?
					</p>
				</>,<>
					<h2 className="hBlue">Basic Game Features</h2>

					<p>Next, to figure out how to start building this game, we need to decide what the most fundamental, important features from that list are.</p>

					<p>
						In other words, what do we need first, to create the simplest possible typing game?
					</p>

					<p>
						Again, talk about this with the class or make a list of your own before you continue.
					</p>
				</>,<>
					<h2 className="hBlue">Basic Game Features</h2>

					<p>Out of all these features, most of them can be removed, and you can still have a working game. But a couple of them are essential.</p>

					{/* <ul>
						<li><strong>Keyboard Input</strong> responds to typed input from the keyboard.</li>
						<li><del><strong>Score</strong> keeps track of the users score.</del></li>
						<li><del><strong>High Score</strong> keeps track of the high score.</del></li>
						<li><strong>Randomly Generated Letters</strong> generates a list of ten random letters at a time.</li>
						<li><del><strong>Timer</strong> has a time that counts down and ends the round.</del></li>
						<li><del><strong>Coloring</strong> uses colors to show which letters have been typed correctly.</del></li>
						<li><del><strong>Instructions Screen</strong> shows instructions before the game starts.</del></li>
						<li><del><strong>Scores Screen</strong> displays scores after the game is finished.</del></li>
					</ul> */}

					<p>
						At its simplest, this game needs to generate something to type,
						accept keyboard input, and check if the user has typed the right letter.
						Then if they have, it needs to give them something new to type.
					</p>

					<p>
						But it doesn't have to display ten letters at once. It could just show one letter at a time.
						That makes the program a lot simpler, because you don't have to keep track of how many of the ten letters they have typed.
					</p>
				{/* </>,<> */}
					<p>
						Let's see what a very basic form of this game could look like.
					</p>

					{/* <div style={{width: 400, height: 400, border: '1px solid #aaa'}}> */}
						<CanvasDemo codeFn={typingGame01}  />
					{/* </div> */}

				</>,<>
					<h2 className="hBlue">Let's get started!</h2>
					<p>
						That looks pretty easy, right? Now it's time to build it. Well, almost.
					</p>

					<p>
						I'd like to discuss one more thing first.
						We have our simple list of features –
						show a random letter, accept keyboard input, check if they typed our random letter, repeat.
					</p>

					<p>
						Now, what do we need in our program to make these features work?
						Discuss this with the class or write it down.
						Here's the example again to refer to:
					</p>

					<CanvasDemo codeFn={typingGame01}  />
				</>,<>
					<h2 className="hBlue">Let's get started!</h2>

					<p>First, we know that since we need to handle keyboard input, we're going to need the special <code>whenKeyPressed</code> function.</p>

					<p>In that function, we'll need to check the typed key and see if it's the letter that we're showing on screen.</p>

					<p>We're going to need a variable to store our letter that we're showing on the screen, so we can compare it to the typed letter.</p>

					<p>
						And we're going to need a way to get a random letter.
						We should make a function that will give us a random letter, so we can reuse that code wherever we want.
					</p>

					<p>
						We're also going to want a function to store all of our drawing code in.
						So each time we make any update that will change what's on screen,
						we just call our drawing function again.
					</p>

				</>,<>
					<h2 className="hBlue">Let's get started!</h2>

					<p>
						It's time to write some code. This time, you're going to use a project for this. That will let you share your code with others if you want to.
					</p>

					<h3>Create a New Project</h3>
					<p>
						If you don't remember how to do this, it's easy:
						Click on your name in the upper right hand corner of the page. <br/>
						Then <strong>right</strong> click 'Your Projects', and select 'Open in New Tab'. This will keep this page open so you can keep it up for help.<br/>
						Then, on the Your Projects page, click the blue 'Create a New Project Button'. You will be taken to a new project page.
					</p>

					<h3>Name Your Project</h3>
					<p>
						At this point, your project will be called 'Untitled Project'.  Click 'Edit title' and rename it to something with 'Typing Game' in it, and hit Save.
					</p>

					<h3>Write Code!</h3>
					<p>
						Now we'll begin coding. At this point, we'll go through what to do in class.
						I want you to work with your classmates and teacher to figure out how to make this project work.
						I'll be adding in some more instructions later to help you out as you work on it at home,
						but for now I want to focus on working together in class.
						Try to use the skills you have learned already in this class to figure out what is needed.
						If you're stuck, ask a classmate, or ask your teacher <Emoji e="👨🏼‍💻"/>. That's what I'm there for!
					</p>

					<p>If you finish this first version early, first, try to help someone else who is still working on it.</p>
					<p>Then, you can move on to the next page and we'll talk about adding in more features.</p>
				</>,<>
					<h2 className="hBlue">Adding in Features</h2>

					<p>
						Once you have your project working to that point, it's time to start adding features.
						Here's a suggested order add the features in:
					</p>

					<ul>
						<li><strong>Score</strong>: Keep track of the users score and show it in the corner.</li>
						<li><strong>Instructions Screen</strong>: Show instructions before the game starts.</li>
						<li><strong>High Score</strong>: Keep track of the high score.</li>
						<li><strong>Scores Screen</strong>: Really just the instructions screen, but you'll show scores on it.</li>
						<li><strong>Timer</strong>: Add a time limit, and keep track of time. Display the timer on the screen</li>
						<li><strong>10 letters</strong>: Show ten letters at a time. This is a bigger change.</li>
						<li><strong>Coloring</strong>: Use colors to show which letters have been typed correctly.</li>
					</ul>

					<p>On the next pages I'll have tips for making some of these changes. We'll also talk about them in class.</p>

				</>,<>
					<h2 className="hBlue">Adding a score</h2>

					<p>
						One of the easiest and best additions we can make is to have a score.
						All we need is a variable that we can update.
						You have seen this pattern in other places,
						such as in the <PrefixedLink to="/lesson/game-loop">canvas.animate() lesson</PrefixedLink>.
					</p>

					<p>
						Here's a complete example that gives you points when you type a letter.
						Focus on the lines that deal with the <code>score</code> variable.
					</p>

					<CanvasRunner console vertical canvasHeight={200} editorHeight={400} docName="typingGame.score" code={`
						// Important: define your variable at the top of the program,
						// outside of any function.
						var score = 0;

						// start the animation
						canvas.animate(draw)

						// define functions
						function draw() {
							canvas.background('#bbffbb');
							canvas.textSize(32)
							// Important: display the score on screen
							canvas.text('your score is ' + score, 25, 150);
							canvas.text('press a key, but not x.', 25, 250);
						}

						function whenKeyPressed(key) {
							// Important: change the score variable whenever you need to.
							if (key == 'x') {
								score -= 100;
							}
							else {
								score += 5;
							}
						}
					`} />

					<p>Try adding a score to your own typing game.</p>
				</>,<>
					<h2 className="hBlue">Adding a high score</h2>

					<p>
						This is almost the same as the score, but you should only change the variable
						(`highScore` would be a great name for it) when the score is greater than the high score.
						How do you put this into code?
					</p>

					<p>Try to do it on your own. There's a hint below if you need it.</p>

					<CodeBox clickToShow code={`
						//define your highScore variable at the top and set it to 0.
						var highScore = 0;

						// whenever you update the score, do this:
						if (score > highScore) {
							highScore = score;
						}
					`} />

					<p>Try adding a high score to your own typing game.</p>
				</>,<>
					<h2 className="hBlue">Adding a timer</h2>

					<p>
						This one is a bit complicated. I plan on tackling it in class,
						so if you are reading this in class, I suggest following along
						when I show it in class.
					</p>

					<p>
						First, I need to introduce a new function that we
						haven't talked about: <code>Date.now()</code>.
					</p>

					<p>
						<code>Date.now()</code> is a function that will tell you the current time – sort of.
						It gives you a big huge number, like 1583225898164.
						That numbers is actually the number of <strong>milliseconds</strong> since midnight on Jan 1, 1970.
						None of us are that old, so why would we care about that number?
					</p>

					<p>
						We don't care about that number specifically, but it is a handy reference point.
						If I know the time that something happened, and I know what time it is now, then I know how long ago it happened.
					</p>

					<p>
						So I can use <code>Date.now()</code> to know when something happened, or when something needs to happen.
					</p>

					<CanvasRunner console vertical canvasHeight={200} editorHeight={640} docName="typingGame.dateNow" code={`
						// note the time when the program started
						var startTime = Date.now()
						// this will be the time ten seconds after the program starts
						var endTime = Date.now() + 10 * 1000;

						canvas.animate(draw)

						function draw() {
							canvas.background('white');
							canvas.textSize(50);

							// get the current time
							var now = Date.now();

							// has more than 10 seconds passed?
							if (now > endTime) {
								canvas.text("It's over!!", 80, 200);
							}
							else {
								// calculate how long the timer has been running
								var milliseconds = now - startTime
								// convert to seconds
								var seconds = milliseconds / 1000;

								canvas.background('white');
								canvas.text(seconds, 120, 200);
							}
						}
					`} />

					<p>
						This program uses <code>Date.now</code> to calculate the time since the program started
						during each frame, then does two things with:
						it displays the time on the screen,
						and after ten seconds, it changes to "It's over!!".
					</p><p>
						You will want to do both of these things to implement your timer.
						But you might also want to be able to restart the timer to play multiple times.
						This is easy: Just reset <code>startTime</code> every time you start a round.
					</p>

					<p>For an example that counts down and can be reset, see this <PrefixedLink to="/published/bxemp960">countdown example</PrefixedLink>.</p>
				</>,<>
					<h2 className="hBlue">Instructions and Scores Screens</h2>
					<p>
						Before the game starts, it's useful to give some instructions on how to play.
						This will be common for many games.
					</p><p>
						To do this, you need to keep track of whether the game has started.
						This is either a yes or a no.  Remember that in
						programming, <code>true</code> and <code>false</code> are the same
						as yes and no.
					</p><p>
						I recommend making a variable called <code>started</code>, and
						using it like this:
					</p>

					<CodeBox code={`
						// at the start.
						var started = false;

						// when you start the game:
						started = true;

						// when you draw the screen
						if (started == false) {
							// draw the instructions
						}
						else {
							// draw the started game
						}
					`}/>

					<h2 className="hBlue">Showing Scores After</h2>
					<p>
						When the timer is complete, you'll also want to show their score, and maybe the high score too.
						You might want to changed the <code>start</code> variable back to <code>false</code> when the timer is up,
						or to set another variable to say that the game is finished.
					</p>

					<p>
						You can decide yourself how you want to determine whether the game has been run yet or not.
						You could do it based on whether the score is greater than 0,
						since it will be 0 before the game starts.
						Or, you could have another variable that you set when the game begins, to know that it has run at least once.
						Or set a variable when the game ends to know that it has ended at least once.
					</p>

					<p>It's up to you! That's part of the beauty of programming.</p>

				</>,<>
					<h2 className="hBlue">Showing multiple letters on screen</h2>

					<p>
						Right now, your game shows one letter at a time on the screen.
						But it's hard to type very fast at all when you can only see one letter at a time.
						So it's helpful to show more than one. In my example, I made the game show ten letters
						at a time.
					</p>

					<p>
						In order to show multiple letters, you'll need to store a list of your random letters.
						How do you store a list? In an <strong>array</strong>, of course!
					</p>

					<p>This array can replace the single letter variable in your program. You might want something like this:</p>

					<CodeBox code={`
						var letters = [];
						var letterCount = 10;

						var newLetters = [];
						for (var i = 0; i < letterCount; i++) {
							// you should already have a getRandomLetter function set up
							newLetters.push(getRandomLetter());
						}
					`} />

					<p>
						That will give you ten letters to work with.
						I'll leave it to you to decide how to display all ten letters on the screen.
					</p>

					<h3>Checking the letter</h3>
					<p>
						With multiple letters, you'll need to know which letter is the next one that needs to be typed.
						You'll need a new variable for that.
						That will need to change each time they get the right letter so they can move on to the next one.
						Your code will need to look something like this:
					</p>

					<CodeBox code={`
						// add this at the beginning
						var letterIndex = 0;

						// check the current letter
						if (key == letters[letterIndex]) {
							// move the index to the next one
							letterIndex += 1;
						}
					`} />

					<p>
						This will get you some basic checking.  But you'll still need to handle what happens
						when you get to the end of the sequence of letters.
						I'll leave that to you to figure out, but remember to reach out if you need help!
					</p>

				</>,<>
					<h2 className="hBlue">Color the completed letters</h2>

					<p>
						When you type a letter correctly, you move on to the next letter.
						But your player needs an easy way to know what letter should be typed next.
						One way to accomplish this is by changing the color of a letter when it has been
						typed correctly.
					</p>

					<p>
						In other words, you'll want to color the letters one color if they have been typed correctly already,
						and another if they haven't. How do you know which ones have been typed correctly?
					</p>

					<p>
						Since they all have to be in order, every letter to the left of the next letter has already been typed.
						And every letter at or to the right of the next letter hasn't.
					</p>

					<p>
						What can you use to determine this? Try to figure it out on your own. But here's a hint below if you need it.
					</p>

					<CodeBox clickToShow code={`
						// in your drawing function, loop through all the current letters.
						for (var i = 0; i < letters.length; i++) {
							// compare the letter's index to next
							if (i < letterIndex) {
								// this letter has been typed.
							}
							else {
								// this letter has not been typed.
							}
						}
					`} />
				</>,<>
					<h2 className="hBlue">Add polish and personality</h2>

					<p>
						If you have gone through all these steps, you now have a pretty cool typing game working!
					</p>

					<p>
						From here, you can tweak and customize your game however you like.
						There's a lot that you could do, but it's all up to you.
						I will give you some ideas and suggestions, though:
					</p>

					<h3>More ways to enhance your typing game</h3>
					<ul>
						<li>Add sound effects: You could have a sound for typing correct letters, wrong letters,
							starting and ending the game, and getting a high score.
						</li>
						<li>Calculate and display the player's words per minute while the play.</li>
						<li>Show a count of how many mistakes have been made.</li>
						<li>Instead of random letters, have the player type random regular words, or a whole paragraph you chose instead.</li>
						<li>Add animation: The sky's the limit here.
							<ul>
								<li>Add a cool animated background of any kind.</li>
								<li>Make each letter fall off the screen when typed.</li>
								<li>Add a fun animation when the game ends. Make it better when they get a high score.</li>
								<li>Add a pointer or a character that moves along and points at the next letter to type.</li>
							</ul>
						</li>
					</ul>

					<p>
						Have fun with it!
						I suggest that you keep working on and polishing your typing game,
						and share it with the class if you like.
						You can even share the link with someone who isn't in the class, and they'll be able to see it too.
					</p>
				</>
			]} />

		</Layout>
	);
};

export { TypingGameLesson }
