import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { CodeBox } from './CodeBox';
import { Button } from 'sancho/esm/Button';
import { PrefixedLink } from './lib/PrefixedLink';

const madLibsCode1 =`/*
 * Interactive Mad Libs - Part 2
 *
 * Writing your own!
 */


// First, define all the variables you need.
// var exampleVariable = prompt("Ask your question here?");

// Now, use them to display your story.
// console.log("Your variable value is", exampleVariable);
// console.log("The value of your variable is value is " + exampleVariable);
// console.log(\`One more time, \${exampleVariable} is your variable's value\`);
`;

const InteractiveMadLibsProject2: React.FC = () => {
	return <ProjectPage
		pageContent={<PageContent />}
		// docName="asdf-test"
		monacoProps={{
			initialCode: madLibsCode1,
			docName: 'imadlibs2',
		}}
	/>
}

const PageContent: React.FC = () => <>
	<h2>Interactive Mad Libs, part 2</h2>

	<p>Now, it's time to write your first program all of your own.  But don't worry, you are ready.</p>

	<p>
		Your job is to write your own mad libs, using <code>prompt()</code> to assign values to your variables,
		and <code>console.log</code> to display them.
	</p>

	<p>
		In other words, it's just like part 1 that you just finished, only this time you get to start from scratch,
		and write your own story.
	</p>

	<h3>Step 1</h3>
	<p>To start, you might want to just write out your story. If you want, you can write it right in the editor.</p>
	<p>
		Use <code>{'//'}</code> to "comment out" your story.  When you put <code>{'//'}</code> at the beginning of a line,
		it tells the program to ignore the rest of the line, so you can type notes about your program to help yourself remember what it's doing.
	</p>

	<h3>Step 2</h3>
	<p>Once you have your story, print it out using <code>console.log</code> and strings, just like before.</p>

	<h3>Step 3</h3>
	<p>Then, replace the parts of the story you want to change with variables.</p>
	<p>Remember, you can use <code>+</code> to connect two strings together, like this:</p>

	<CodeBox code={`
		console.log("Hi, my name is " + myName + ", and I am " + age + " years old.");
	`} />

	{/* <p>You can also just log things on separate lines too, like this: </p>

	<CodeBox code={`
		console.log("Hi, my name is");
		console.log(myName);
		console.log("and I am");
		console.log(age);
		console.log("years old.");
	`} /> */}

	<h3>Step 4</h3>
	<p>Finally, use <code>prompt()</code> to ask questions and get values for your variables.</p>

	<p>Once your program is working, show it to your classmates, and have them give it a try.  You are really coding now!</p>

	<h2>Tips</h2>
	<ul>
		<li>Don't be afraid of errors! The error messages will help you get your program running correctly.</li>
		<li>Take things one step at a time.  It's a good idea to break every problem down into little pieces that are easy to fix.</li>
		<li>You can look to the code from part one for examples.  Using code that works as a reference is a great way to get help when you're creating something new.</li>
		<li>If you get stuck, don't be afraid to ask your neighbor or the teacher for help.</li>
	</ul>

	{/* <p>&nbsp;</p> */}
	<p>&nbsp;</p>

	{/* <h3>Bonus</h3>
	<p>You may have noticed that you can connect two strings into a bigger string using <code>+</code>, like this:</p>

	<CodeBox code={`
		var favoriteFood = "tacos";
		var message = "my favorite food is " + favoriteFood;
		console.log(message);
	`} />

	<p>
		Try to connect all the strings in the whole mad lib using one or more variables and <code>+</code>.
		Then use <code>alert</code> to display the entire story at once.  If you get stuck, don't worry – we'll learn more ways to do this next time!
	</p> */}

	<p>
		<PrefixedLink to="/project/interactive-mad-libs">
			<Button intent="none">Back to Part 1</Button>
		</PrefixedLink>
		{} &nbsp; {}
		<PrefixedLink to="">
			<Button /* disabled={!props.canContinue} */ intent="primary" /* onPress={() => props.frameApi.updateCode(madLibsCode2)} */>Finished!</Button>
		</PrefixedLink>
	</p>
</>;


export { InteractiveMadLibsProject2 }
