import * as React from 'react';

import { Layout } from './Layout';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { LinkButton } from './lib/LinkButton';

const MathOperatorsQuiz: React.FC = () => (
	<Layout narrow>
		<h1>Quiz: Math Operators</h1>

		<p>
			Now it's time to test your knowledge about math operators.
			This quiz is not scored – it's just for you to help review and remember what you learned.
			It's ok if you don't remember something.
			Just write down as best as you can remember, then compare your answer with the given answer.
		</p>

		<p><strong>Make sure to write down your answer first, before you hit Show Answer!</strong></p>

		<ol>
			<li>
				<Question>
					How would you add two numbers together and store the answer in a variable?
					Make sure you also know where to find it on the keyboard.
				</Question>
				<Answer id="qmath.1">
					<CodeBox code={`
						// you can add numbers from variables (like currentVal)
						// or literals (like 42)
						var sum = currentVal + 42;
					`} />
				</Answer>
			</li>

			<li>
				<Question>
					How would you subtract one number from another, and store the answer in a variable?
					Make sure you also know where to find it on the keyboard.
				</Question>
				<Answer id="qmath.2">
				<CodeBox code="var newBalance = oldBalance - pricePaid;" />
				</Answer>
			</li>

			<li>
				<Question>
					How would you multiply two numbers together and store the answer in a variable?
					Make sure you also know where to find it on the keyboard.
				</Question>
				<Answer id="qmath.3">
					<CodeBox code={`
						var pixels = 400 * 400;
						var cookies = plates * cookiesPerPlate;
					`} />
				</Answer>
			</li>

			<li>
				<Question>
					How would you divide one number by another, and store the answer in a variable?
					Make sure you also know where to find it on the keyboard.
				</Question>
				<Answer id="qmath.4">
					<CodeBox code="var teachersNeeded = students / maxClassSize;" />
				</Answer>
			</li>

			<li>
				<Question>
					What is <code className="snippet">+=</code>?
					How would you use it?
				</Question>
				<Answer id="qmath.5">
					It's a shorthand for updating a number in a variable by adding to it.
					<code>var skill = 76; skill += 10;</code> initializes a variable to 76, then adds 10 to it.
					It is the same as <code>skill = skill + 10</code>, and is useful anywhere you would want to
					update a number.
				</Answer>
			</li>

			<li>
				<Question>
					What is <code className="snippet">++</code>?
					How would you use it?
				</Question>
				<Answer id="qmath.6">
					It's a shorthand specifically for adding 1 to a number.
					It's especially useful for counting, when things always increase by 1.
				</Answer>
			</li>
		</ol>

		<LinkButton to="/canvas/review">Onward to the Canvas review</LinkButton>
		&nbsp;
		<LinkButton to="">Finished</LinkButton>
	</Layout>
);

export { MathOperatorsQuiz }
