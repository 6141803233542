import * as React from 'react';
import { firestore } from 'firebase';
import { RouteComponentProps } from 'react-router';
import { Button } from 'sancho/esm/Button';
import { Alert } from 'sancho/esm/Alert';

import { Layout } from './Layout';
import { useQuery } from './lib/dbHooks';
import { useAppContext } from './AppContext';
import { UserDoc, getUserDocRef } from './model/userDoc';
import { CanvasPage } from './CanvasPage';
import { NotFound } from './NotFound';
import { useIsAdmin } from './firebase';
import { GearSpinner } from './lib/GearSpinner';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { PrefixedLink } from './lib/PrefixedLink';

interface SharedProjectPageProps extends RouteComponentProps<{docName: string, preview: string, uid: string}> {}

const COURSE_ID = 'js-intro';

const SharedProjectPage = /* withRouter( */(props: SharedProjectPageProps) => {
	let prefix = useCoursePrefix();
	let context = useAppContext();
	let isAdmin = useIsAdmin();

	// let uid = context.auth.currentUser ? context.auth.currentUser.uid : '';
	let docName = props.match.params.docName;
	let uid = props.match.params.uid;

	// console.log('docName', docName)
	// console.log('preview
	// ')

	let whereClause: (ref: firestore.CollectionReference) => firestore.Query;

	// /preview/:userId/:docName
	if (isAdmin && uid) {
		whereClause = q => q.where('doc_name', '==', docName).where('user_id', '==', uid).where('group', '==', 'user');
	}
	else {
		whereClause = q => q.where('doc_name', '==', docName).where('sharing', '==', true).where('group', '==', 'user');
	}
	let docQuery = useQuery(context.db, 'user_docs', whereClause, {bypass: !docName});

	let doc = docQuery.value ? docQuery.value[0] as UserDoc : null;

	let remixButton;

	if (context.auth.currentUser) {
		const remix = async () => {
			let currentUid = context.auth.currentUser.uid;
			let newDocName = randomString(8);
			let docRef = getUserDocRef(COURSE_ID, currentUid, newDocName);
			let newTitle = (doc.title || 'Untitled Project');

			if (newTitle.indexOf(' Remix') == -1) {
				newTitle += ' Remix';
			}

			let newDoc: UserDoc = {
				content: doc.content,
				course_id: COURSE_ID,
				user_id: currentUid,
				doc_name: newDocName,
				group: 'user',
				title: newTitle,
				// for now, they are all canvas projects.
				type: 'canvas',
				parent: {title: doc.title, doc_name: docName, uid: doc.user_id},
				updated: Date.now(),
			};

			try {
				await docRef.set(newDoc);
				let parentRef = getUserDocRef(COURSE_ID, doc.user_id, docName);
				let remix: typeof doc.remixes[0] = {
					doc_name: newDocName,
					title: newTitle,
					uid: currentUid,
				};

				parentRef.update({
					remixes: firestore.FieldValue.arrayUnion(remix),
				}).then(success => {
					console.info('parent updated succeeded', success);
				}, error => {
					console.error('parent updated failed', error);
				});

				// console.log('doc created');
				// navigate
				props.history.push(`${prefix}/user/project/${newDocName}`);
			}

			catch (e) {
				console.error('doc creation failed', e);
			}
		};

		remixButton = <Button disabled={!(doc && doc.content)} onPress={remix} intent="primary">Remix It</Button>;
	}

	let description = doc && doc.description || 'Enjoy this project created by a student!';

	let yourProject = !!(context.auth.currentUser && doc && doc.user_id == context.auth.currentUser.uid);

	if (docQuery.loading) {
		return <Layout>
			{/* <Spinner label="Loading..." center delay={300} /> */}
			<GearSpinner center />
		</Layout>
	}
	else if (doc) {
		let projectTitle = doc.title || 'Untitled Project';

		// return <Layout narrow>
		// 	<h1>{projectTitle}</h1>
		// 	<CanvasRunner height={400} vertical code={doc.content}/>
		// </Layout>

		return <CanvasPage
			className="SharedProjectPage"
			pageContent={
				<div>
					<h1>{projectTitle}</h1>

					{doc.parent && <p>
						Remixed from {' '}
						<PrefixedLink to={`/published/${doc.parent.doc_name}`}>{doc.parent.title}</PrefixedLink>
					</p>}

					{docQuery.error && <Alert intent="danger" title="This project could not be loaded">
						Try refreshing the page.
						<small>{docQuery.error.message}</small>
					</Alert>}

					<p style={{whiteSpace: 'pre-line'}}>{description}</p>
					<p>Click the Run button on the left to see this project.</p>

					{yourProject && <p>
						This is your project.  <PrefixedLink to={`/user/project/${doc.doc_name}`}>Edit this project.</PrefixedLink>
					</p>}

					{remixButton && <>
					<hr/>

					<p>{remixButton} Create your own version of this project</p>
					</>}

					{!!prefix && <>
						<hr/>
						<p><PrefixedLink to="/shared-projects">Back to all projects</PrefixedLink></p>
					</>}
				</div>
			}
			code={doc && doc.content}
			// docName={docName}
			// docGroup="user"
			// docType="canvas"
		/>
	}
	else if (docQuery.error) {
		return <Layout>
			<p>Something went wrong here!</p>
			<p>{docQuery.error.message}</p>
		</Layout>
	}
	else {
		return <NotFound />
	}
};

function randomString(len: number) {
	let str = ''
	while (str.length < len) {
	 str += (Math.random() * 36 | 0).toString(36)
	}
	return str
}

export { SharedProjectPage, SharedProjectPageProps }
