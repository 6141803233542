import * as React from 'react'
// import { PagedContent } from './PagedContent'
import { withRouter, RouteComponentProps, Route, Link } from 'react-router-dom';
import { Button } from 'sancho/esm/Button';
import { LinkButton } from './LinkButton';

type Renderable = JSX.Element | ((props: RouteComponentProps/*page: number , goTo: ((nextPage: number) => void) */) => JSX.Element)

interface PagedContentProps extends RouteComponentProps<{}> {
	pages: Renderable[],
	// common: ()
	// beforeChange?(oldPage: number, nextPage: number): void
	// afterChange?(nextPage: number): void
	// withIndex?: boolean
	noHome?: boolean
}

const PagedContent = withRouter((props: PagedContentProps) => {
	let url = props.match.url;
	// props.match;
	// props.location;
	// props.history;
	// props.staticContext;
	// debugger;
	console.log(props);

	let parts = props.location.pathname.split('/')
	// note that this is 1-based because it's displayed in the url
	let page = +parts[parts.length - 1] || 1;

	// let [page, setPage] = React.useState(0);
	let isLast = page >= props.pages.length;
	let ref = React.useRef<HTMLDivElement>(null);

	// let goTo = (nextPage: number) => {
	// 	if (props.beforeChange) props.beforeChange(page, nextPage);

	// 	setPage(nextPage);

	// 	setTimeout(() => {
	// 		ref.current && ref.current.scrollIntoView();
	// 		if (props.afterChange) props.afterChange(nextPage);
	// 	}, 16);
	// }

	// let pageContent = props.pages[page];
	// if (typeof pageContent == 'function') {
	// 	pageContent = pageContent(page, goTo);
	// }


	return (
		<div ref={ref}>
			{/* <Route exact path={url} render={routeProps => {
				let page = props.pages[0];
				return typeof page == 'function' ? page(routeProps) : page;
			}} /> */}

			{props.pages.map((renderable, idx) =>
				<Route exact path={idx ? `${url}/${idx + 1}` : url} render={routeProps => (
					typeof renderable == 'function' ? renderable(routeProps) : renderable
				)} />
			)}

			<p style={{textAlign: 'center'}}>
				{page > 1 && (
					// <><Button intent="none" size="lg" onPress={() => goTo(page - 1)}>Back</Button> &nbsp;</>
					// <PrefixedLink {...linkProps}><Button intent="primary" {...buttonProps}>{children}</Button></PrefixedLink>
					<><Link to={page > 2 ? `${url}/${page - 1}` : url}><Button intent="none" size="lg">Back</Button></Link> &nbsp;</>
				)}
				{/* {props.withIndex && []} */}
				{!isLast && (
					// <><Button intent="primary" size="lg" onPress={() => goTo(page + 1)}>Next</Button> &nbsp;</>
					<><Link to={`${url}/${page + 1}`}><Button intent="primary" size="lg">Next</Button></Link> &nbsp;</>
				)}
				{!props.noHome && isLast && (
					<LinkButton buttonProps={{size: 'lg'}} to="">Home</LinkButton>
				)}
			</p>
		</div>
	);
});

export { PagedContent, PagedContentProps };
