import * as React from 'react';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { Layout } from './Layout';
import { WrappedCanvas, CanvasInterface, CanvasWrapper } from './lib/WrappedCanvas';
import { canvasBirdDemo } from './projects/canvasBirdDemo';
import { Button } from 'sancho/esm/Button';

const CanvasLesson = () => {
	// let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	// React.useEffect(() => {
	// 	if (canvasInterface) {
	// 		setupDemo(canvasInterface);

	// 	}
	// }, [canvasInterface]);
	// const onInit = (canvasInterface) => setupDemo(canvasInterface);
	// const onInit = setupDemo

	return (
		<Layout narrow>
			<h1>Drawing on the Canvas</h1>

			<div style={{margin: 'auto', width: 400}}>
				<WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={setupDemo} />
			</div>

			<p className="intro">
				This is a <strong>canvas</strong>.
				Like a real physical canvas, you can draw just about anything you want on it.
				If you can imagine it, there's a way to draw it on a canvas.
			</p>

			<p>
				Unlike a physical canvas, you can write code to draw on this canvas for you.
				That's pretty awesome, because as you are starting to find out,
				you can do some powerful things with code.
			</p>

			<p>
				As you can see in the demo above, the drawing on a canvas can also change.
				Later on I'll show you how this is done. This will allow us to make games and animations!
				But first we need to learn how to draw on it.
			</p>

			<h2 className="hOrange">Getting Started</h2>

			<p>
				In this lesson you will learn how to draw rectanges, ellipses, lines, and text,
				as well as change their colors.
			</p>

			{/* <p>
				As you have already learned with the <code>prompt</code> function,
				your program can also get input while it's running.  This allows your
				program to be interactive, like a game or an app.
			</p>

			<p>
				Drawing on the canvas happens really fast. So fast, that you can redraw everything
				you have drawn when you want to change it
				Your program can also drw
				<code>canvas</code> has lots of functions that allow you to draw on it.
			</p> */}

			<p>
				Locations on the canvas are defined by a grid that is 400 pixels by 400 pixels.

				That means any point on the canvas can be described by two numbers.  The first
				0, 0 The grid starts at the top left.
			</p>

			<div style={{margin: 'auto', width: 400}}>
				<WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={setupCoordsDemo} />
			</div>

			<hr className="spacer"/>

			<h2 style={{clear: 'both', paddingTop: 20}}>
				Canvas functions
			</h2>

			<h3 className="hGreen">
				<code>canvas.background()</code>
			</h3>

			<p>
				<code>canvas.background(someColor)</code> draws a background {/* <PrefixedLink to="/colors"> */}color{/* </PrefixedLink> */}.
				It draws over everything, so it should always be the first thing you draw.
			</p>

			<CanvasRunner autorun code={`canvas.background('skyblue'); `}/>

			<p>
				Try putting some other color names in there.
				For a deeper dive into how to draw different colors,
				visit the <PrefixedLink to="/colors">colors</PrefixedLink> page.
			</p>

			<hr className="spacer"/>

			<h3 className="hGreen">
				<code>canvas.rect()</code>
			</h3>

			<p>
				<code>canvas.rect(x, y, width, height)</code> will draw a rectangle on the canvas.
				It needs four numbers to know where to draw the canvas.
				{/* Let's talk about what those four numbers mean. */}
			</p>

			<p>
				The first one is <code>x</code>, which means the position on the x-axis.
				That just means how far left or right it is.
				<code>0</code> is all the way to the left, and <code>400</code> is all
				the way to the right.
			</p>

			<p>
				The next one is <code>y</code>, which means the position on the y-axis.
				That just means how far up or down it is.
				<code>0</code> is all the way at the top, and <code>400</code> is at the bottom.
			</p>

			<p>
				The other two are width, then height.
				The top left corner of the rectangle will be at <code>x, y</code>.
				The bottom right corner will be at <code>x + width, y + height</code>.
			</p>

			<CanvasRunner autorun code={`
				// top
				canvas.rect(180, 10, 40, 40)
				// left
				canvas.rect(10, 180, 40, 40)
				// right
				canvas.rect(350, 180, 40, 40)
				// bottom
				canvas.rect(180, 350, 40, 40)
			`} />

			{/* <CanvasRunner autorun code={`
				canvas.text('move your mouse', 50, 180);
				canvas.text('over the canvas', 50, 210);

				function whenMouseMoved(x, y) {
					var text = x + ', ' + y;
					canvas.background('white');
					canvas.text(text, 100, 200);
				}
			`} /> */}

			<hr className="spacer"/>

			<h3 className="hGreen">
				<code>canvas.fill()</code>
			</h3>

			<p>
				<code>canvas.fill(someColor)</code> sets the color to fill in shapes with.
				You won't see it do anything by itself.
				When you draw shapes after it, those shapes will be the color you set.
			</p>

			<CanvasRunner autorun code={`
				canvas.fill('blue');
				canvas.rect(125, 175, 50, 50);
				canvas.fill('red');
				canvas.rect(225, 175, 50, 50);
			`} />

			<hr className="spacer"/>


			<h3 className="hGreen">
				<code>canvas.ellipse()</code>
			</h3>

			<p>
				<code>canvas.ellipse(centerX, centerY, width, height)</code> draws an ellipse or a circle.
				Unlike a rectangle, an ellipse (stretched circle) or a circle is specified by its center point,
				and its width and height.
			</p>

			<p>
				Its first parameters are also <code>x</code> and <code>y</code>,
				just remember that they specify where the center will be.
			</p>

			<p>
				The other two are width and height (diameter).
				You can leave out height, and it will be the same as the width,
				making it a perfect circle.
			</p>

			<CanvasRunner autorun code={`
				// draw an ellipse that is 100 wide and 75 high
				canvas.fill('orange');
				canvas.ellipse(200, 100, 100, 75);

				// draw a circle that is 100 in diameter
				canvas.fill('green');
				canvas.ellipse(200, 300, 100);
			`}/>

			<hr className="spacer"/>


			<h3 className="hGreen">
				<code>canvas.line()</code>
			</h3>

			<p>
				<code>canvas.line(x1, y1, x2, y2)</code> draws a straight line from one point to another.
				{/* <dl className="paramList">
					<dt>centerX</dt>
					<dd>horizontal position of the center of the circle/ellipse</dd>

					<dt>centerY</dt>
					<dd>vertical position of the center of the circle/ellipse</dd>

					<dt>width</dt>
					<dd>width (diameter) of the circle/ellipse</dd>

					<dt>height (optional)</dt>
					<dd>height (diameter) of the circle/ellipse.  If left out, the height will be the same as the width, so you'll get a circle.</dd>
				</dl> */}
			</p>

			<p>
				The first two numbers describe the starting x and y,
				and the last two are the ending x and y,
				with a straight line drawn between them.
			</p>

			<CanvasRunner autorun code={`
				// diagonal
				canvas.line(150, 150, 250, 250);
				// horizontal
				canvas.line(150, 200, 250, 200);
				// vertical
				canvas.line(200, 150, 200, 250);
				// opposite diagonal
				canvas.line(250, 150, 150, 250);
			`}/>

			<hr className="spacer"/>

			<h3 className="hGreen">
				<code>canvas.stroke(){/* </code> */} and {/* <code> */}canvas.strokeWeight()</code>
			</h3>

			<p>
				<code>canvas.stroke(color)</code> changes the color of lines. <br/>
				<code>canvas.strokeWeight(width)</code> changes the thickness of lines.
			</p>

			<CanvasRunner autorun code={`
				canvas.stroke('red');
				canvas.strokeWeight(4);
				canvas.line(200, 230, 200, 270);
				canvas.line(180, 240, 200, 230);
				canvas.line(220, 240, 200, 230);
				canvas.line(180, 300, 200, 270);
				canvas.line(220, 300, 200, 270);

				canvas.fill('red')
				canvas.ellipse(200, 210, 30, 30)
			`}/>



			<hr className="spacer"/>

			<h3 className="hGreen">
				<code>canvas.text(){/* </code> */}, {/* <code> */}canvas.textAlign(){/* </code> */}, and {/* <code> */}canvas.textSize()</code>
			</h3>

			<p>
				<code>canvas.textSize(number)</code>sets the text size. <br/>
				<code>canvas.textAlign()</code> sets how the text will be anchored. There are three possible values:
				<code>"left"</code> attaches the text at its left side <br/>
				<code>"center"</code> attaches the text at its center point <br/>
				<code>"right"</code> attaches the text at its right side <br/>
			</p>

			<CanvasRunner autorun code={`
				canvas.textSize(32);
				canvas.textAlign('left');
				canvas.text('left aligned', 10, 160);

				canvas.textAlign('center');
				canvas.text('center aligned', 200, 200);

				canvas.textAlign('right');
				canvas.text('right aligned', 390, 240);
			`}/>


			<hr className="spacer"/>


			<h2 id="colors" className="hRed">Colors</h2>
			<p>
				There are a few different ways to specify colors. The first is by names, such as 'red' or 'blue'.
				See the <PrefixedLink to="/colors">Colors</PrefixedLink> page for all of the possible names you can use.
			</p>
			<p>
				You can also use hexadecimal (or hex) colors. For more details about hex colors,
				or to pick a color, see the <PrefixedLink to="/colors">Colors</PrefixedLink> page.
			</p>

			<h2 className="hGreen">Practice</h2>
			<p>That covers the basics.  Next, you'll practice by drawing something all of your own.</p>

			<p>
				<PrefixedLink to="/project/draw"><Button intent="primary">Go to project</Button></PrefixedLink>
				&nbsp;
				<PrefixedLink to=""><Button intent="primary">Back</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

function setupDemo(canvasInterface: CanvasInterface) {
	let exported = canvasBirdDemo(canvasInterface.wrapper);
	canvasInterface.handlers.onKeyPressed = exported.whenKeyPressed;
}

function setupCoordsDemo(canvasInterface: CanvasInterface) {
	let exported = runCoordsDemoCode(canvasInterface.wrapper);
	canvasInterface.handlers.onMouseMoved = exported.whenMouseMoved;
}

function runCoordsDemoCode(canvas: CanvasWrapper) {
	const bg = '#fafafa';
	canvas.reset();
	canvas.background(bg);
	canvas.textAlign('center');
	canvas.text('move your mouse', 200, 180);
	canvas.text('over the canvas', 200, 210);
	drawCorners();

	function drawCorners() {
		canvas.textAlign('left');
		canvas.text('0, 0', 10, 25);
		canvas.text('0, 400', 10, 390);
		canvas.textAlign('right');
		canvas.text('400, 0', 390, 25);
		canvas.text('400, 400', 390, 390);
	}

	function whenMouseMoved(x, y) {
		var text = x.toFixed() + ', ' + y.toFixed();
		canvas.background(bg);
		canvas.textAlign('center');
		canvas.text(text, 200, 200);
		drawCorners();
	}

	return {
		whenMouseMoved,
	}
}

export { CanvasLesson }
