import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CanvasRunner } from './lib/CanvasRunner';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasDemoFn, CanvasDemo } from './lib/CanvasDemo';
import { CodeRunner } from './lib/CodeRunner';

const dinoShape = [0, 7, 1, 7, 1, 9, 2, 9, 2, 10, 3, 10, 3, 11, 5, 11, 5, 10, 6, 10, 6, 9, 8, 9, 8, 8, 9, 8, 9, 7, 10, 7, 10, 1, 11, 1, 11.8, 1.4, 11.8, 2.4, 13.1, 2.4, 13.1, 1.4, 11.8, 1.4, 11, 1, 11, 0, 19, 0, 19, 1, 20, 1, 20, 5, 15, 5, 15, 6, 18, 6, 18, 7, 14, 7, 14, 9, 16, 9, 16, 11, 15, 11, 15, 10, 14, 10, 14, 13, 13, 13, 13, 14, 12, 14, 12, 15, 11, 15, 11, 19, 12, 19, 12, 20, 10, 20, 10, 17, 9, 17, 9, 16, 8, 16, 8, 17, 7, 17, 7, 18, 6, 18, 6, 19, 7, 19, 7, 20, 5, 20, 5, 16, 4, 16, 4, 15, 3, 15, 3, 14, 2, 14, 2, 13, 1, 13, 1, 12, 0, 12];
const declareDinoShape = `var dinoShape = [${dinoShape.join(', ')}];`

const TransformationsLesson = () => {
	return (
		<Layout narrow>
			{/* <> */}
			<h1>Transformations (Moving and Resizing shapes)</h1>

			<PagedContent pages={[
				<>
					<p className="intro">
						Today we'll look at some ways to move, resize, and rotate shapes on the screen.
						These kinds of changes are called <strong>transformations</strong>.
					</p>

					<CanvasDemo codeFn={canvas => {
						// var dinoShape = [0, 7, 1, 7, 1, 9, 2, 9, 2, 10, 3, 10, 3, 11, 5, 11, 5, 10, 6, 10, 6, 9, 8, 9, 8, 8, 9, 8, 9, 7, 10, 7, 10, 1, 11, 1, 11.8, 1.4, 11.8, 2.4, 13.1, 2.4, 13.1, 1.4, 11.8, 1.4, 11, 1, 11, 0, 19, 0, 19, 1, 20, 1, 20, 5, 15, 5, 15, 6, 18, 6, 18, 7, 14, 7, 14, 9, 16, 9, 16, 11, 15, 11, 15, 10, 14, 10, 14, 13, 13, 13, 13, 14, 12, 14, 12, 15, 11, 15, 11, 19, 12, 19, 12, 20, 10, 20, 10, 17, 9, 17, 9, 16, 8, 16, 8, 17, 7, 17, 7, 18, 6, 18, 6, 19, 7, 19, 7, 20, 5, 20, 5, 16, 4, 16, 4, 15, 3, 15, 3, 14, 2, 14, 2, 13, 1, 13, 1, 12, 0, 12];

						var seed = Math.random() * 100000;

						canvas.animate(function(f) {
							f += seed;
							var scale = (1 + Math.abs(((f % 180) - 90) / 90)) * 3;

							var scale = (1 + (1 + Math.sin(f / 41)) / 2) * 3
							var xOffset = Math.cos(f / 70) * 120;
							var yOffset = Math.sin(f / 93) * 120;
							var hue = f % 360;

							canvas.background('black');
							canvas.changePosition(200 + xOffset, 200 + yOffset);

							canvas.scale(scale);

							canvas.rotateDeg(f)
							canvas.changePosition(-10, -10);
							canvas.fill(`hsl(${hue}, 100%, 50%)`)
							canvas.shape(dinoShape);
							canvas.textSize(4)
							canvas.text("I'm being transformed!", -10, -2);

							canvas.resetPosition(true);
						})
					}} />
				</>,<>

					<h2 className="hBlue">Review <code>canvas.shape()</code></h2>

					<p>Before we begin, let's review how <code>canvas.shape()</code> works.</p>

					<p>
						<code>canvas.shape()</code> is useful for drawing any kind of straight-lined shape. You pass in several numbers,
						and each pair of numbers represents a point in  the shape. So this produces a triangle:
					</p>

					<CanvasRunner code={`
						canvas.shape(100, 100, 300, 300, 100, 300);
					`} />

					<p>
						You can also pass an array to <code>canvas.shape()</code>.
						This is handy if you want to use build your array of points first.
					</p>

					<CanvasRunner code={`
						var left = 50;
						var right = 400 - left;
						var top = 20;
						var bottom = 400 - top;
						var myShape = [
							left, top,
							right, bottom,
							left, bottom,
						];
						canvas.shape(myShape);

						//
					`} />

					<p>
						That's pretty much all there is to <code>canvas.shape()</code>.
					</p>
				</>,<>

					<h2 className="hBlue">Review <code>canvas.shape()</code></h2>

					<p>
						Since you can use an array with canvas.shape(),
						the possibilities on how you create your shape are endless.
						Here's one way to build it. (Click around on the canvas.)
					</p>


					<CanvasRunner editorHeight={460} code={`
						var clickShape = [];

						draw();

						function whenMouseClicked(x, y) {
							clickShape.push(x, y);

							draw();
						}

						function draw() {
							canvas.background('white');

							if (clickShape.length < 6) {
								canvas.text('Click around!', 100, 200);
							}
							else {
								canvas.shape(clickShape);
							}
						}
					`} />
				</>,<>
					<h2 className="hBlue">A tiny shape</h2>

					<p>Here's a cooler shape:</p>

					<CanvasRunner code={`
						${declareDinoShape}

						canvas.shape(dinoShape);
					`} />

					<p>
						It's a cute little dino! But it's so tiny!
						What if I want a bigger one?
						Do I have to make a whole new one that's bigger?
						And what if I want it somewhere else on the screen?
					</p>

				</>,<>
					<h2 className="hBlue">Resizing with <code>canvas.scale()</code></h2>

					<p>Our little dino is only 20 x 20 pixels. Fortunately, there's a way to scale him up.</p>

					<CanvasRunner code={`
						${declareDinoShape}

						// draw everything 4x bigger!
						canvas.scale(4)

						canvas.shape(dinoShape);
					`} />

					<p>
						<code>canvas.scale()</code> can help us out. It changes the size of everything drawn after it,
						so it can help us resize anything. Kind of like how <code>canvas.fill('red')</code> will make everything
						after it red.
					</p>

				</>,<>
					<h2 className="hBlue">Resizing with <code>canvas.scale()</code></h2>

					<p>
						<code>canvas.scale()</code> When you scale the canvas, everything is bigger. Including starting positions.
					</p>

					<CanvasRunner code={`
						${declareDinoShape}

						canvas.text('This is not so big!', 50, 80);

						// draw everything 4x bigger!
						canvas.scale(4)

						canvas.shape(dinoShape);
						canvas.text('This is big!', 50, 80);

					`} />

					<p>
						Notice how the bigger text isn't just bigger, it's farther down and farther to the right.
						That's because all those numbers have been multiplied by 4.
					</p>

				</>,<>
					<h2 className="hBlue">Resetting after resizing</h2>

					<p>
						You can undo the last transformation by calling <code>canvas.resetPosition()</code>.
					</p>

					<CanvasRunner code={`
						${declareDinoShape}

						// draw everything 4x bigger!
						canvas.scale(4)

						canvas.shape(dinoShape);
						canvas.text('This is big!', 50, 80);

						canvas.resetPosition();

						canvas.text('This is not so big!', 50, 80);
					`} />

					<p>
						This time the small text is being drawn after the big text. It's back to normal size,
						thanks to <code>canvas.resetPosition()</code>.
					</p>

				</>,<>
					<h2 className="hBlue">Moving with canvas.changePosition()</h2>

					<p>
						Let's say we want to put our dino somewhere else, like the middle, or the bottom corner.
						We can do that using <code>canvas.changePosition()</code>.
					</p>

					<CanvasRunner code={`
						${declareDinoShape}

						// move to the center
						canvas.changePosition(200, 200);

						// draw everything 4x bigger!
						canvas.scale(4)

						canvas.shape(dinoShape);
					`} />

					<p>
						Now the top right of the dino is right in the center of the screen.
					</p>

				</>,<>
					<h2 className="hBlue">Combining moving and scaling</h2>

					<p>
						Let's say we want to put our dino somewhere else, like the middle, or the bottom corner.
						We can do that using <code>canvas.changePosition()</code>.
					</p>

					<CanvasRunner code={`
						${declareDinoShape}

						// move to the center
						canvas.changePosition(200, 200);

						// draw everything 4x bigger!
						canvas.scale(4)

						canvas.shape(dinoShape);
					`} />

					<p>
						Now the top right of the dino is right in the center of the screen.
						But what if I want the middle of the dinosaur in the middle of the screen?
					</p>

					<p>
						Since our dino is 20x20, if we move the dino up and left 10, the middle of the dino
						will be where the corner was.  If do this after <code>canvas.scale()</code>,
						it will center the dino no matter what the scale is.
					</p>

					<CanvasRunner code={`
						${declareDinoShape}

						// move to the center
						canvas.changePosition(200, 200);

						// draw everything 8x bigger!
						canvas.scale(8)

						// center the dino (left 10, up 10)
						canvas.changePosition(-10, -10);

						canvas.shape(dinoShape);
					`} />

					<p>
						Try changing the scale. Even if the dino is bigger than the canvas, he will be centered on it.
						That's because when that second <code>changePosition</code> happens,
						it's moving on the new scale -- so if the scale is 8, then moving by 10px really moves everything up 80px.
					</p>
				</>,<>
					<h2 className="hGreen">Your Turn</h2>

					<p>
						Now it's time to practice! Try to copy the image you see.
					</p>

					<CanvasDemo size={250} codeFn={canvas => {
						canvas.changePosition(300, 0);
						canvas.scale(5);

						canvas.shape(dinoShape);
					}} />

					<CanvasRunner vertical console docName="transforms.dinoTopRight" code={`
						${declareDinoShape}
					`} />

					<p>This dino is 1/2 the height of the canvas.</p>


					<CanvasDemo size={250} codeFn={canvas => {
						canvas.background('blue');
						canvas.fill('white');

						canvas.changePosition(200, 200);
						canvas.scale(10);

						canvas.shape(dinoShape);
					}} />

					<CanvasRunner vertical console docName="transforms.dinoBottomRight" code={`
						${declareDinoShape}
					`} />

					<p>
						hint: there are 20 pixels of border on each side of this dino.
					</p>


					<CanvasDemo size={250} codeFn={canvas => {
						canvas.background('red');
						canvas.fill('yellow');

						canvas.changePosition(20, 20);
						canvas.scale(18);

						canvas.shape(dinoShape);
					}} />

					<CanvasRunner vertical console docName="transforms.dinoCentered" code={`
						${declareDinoShape}
					`} />

					<p>
						I call it Quad Dino™️!
					</p>

					<p>
						Remember, all your transformations add up.
						You can use <code>canvas.resetPosition()</code> to undo
						a transformation, or  <code>canvas.resetPosition(true)</code> to
						undo them all.
					</p>

					<CanvasDemo size={250} codeFn={canvas => {
						canvas.background('black');

						canvas.fill('greenyellow');
						canvas.scale(10);
						canvas.shape(dinoShape);

						canvas.fill('cyan');
						canvas.changePosition(0, 20)
						canvas.shape(dinoShape);
						canvas.resetPosition();

						canvas.fill('magenta');
						canvas.changePosition(20, 20)
						canvas.shape(dinoShape);
						canvas.resetPosition();

						canvas.fill('yellow');
						canvas.changePosition(20, 0)
						canvas.shape(dinoShape);

					}} />

					<CanvasRunner vertical console docName="transforms.dinoQuad" code={`
						${declareDinoShape}
						// colors are greenyellow, yellow, cyan, magenta
					`} />


				</>,<>
					<h2 className="hBlue">Rotation</h2>

					<p>
						How about rotation? Yep, we can do that too! But it's kind of tricky,
						because you have be careful about where you're rotating from.
					</p>

					<p>Let's put our dino in the middle of the screen, then rotate him on his side:</p>


					<CanvasRunner code={`
						${declareDinoShape}

						canvas.changePosition(200, 200);
						canvas.scale(4);
						canvas.rotateDeg(180);

						canvas.shape(dinoShape);

					`} />

					<p>
						First of all, notice that the function we used is called <code>canvas.rotateDeg()</code>.
						There's one called <code>canvas.rotate()</code>, but is doesn't use degrees, and if you use it you'll get
						a different angle than you expected.
					</p>

					<p>
						Next, notice that our dino rotated around his top left corner.
						<code>canvas.rotate()</code> always rotates everything around 0, 0, or the current top left corner.
						So to change where something is rotating, you need to <code>canvas.changePosition</code>,
						then change back after.
					</p>

					<p>
						Here's a version that correctly rotates our dino around his middle, by a random angle:
					</p>


					<CanvasRunner code={`
						${declareDinoShape}

						canvas.changePosition(200, 200);
						canvas.scale(5);
						canvas.rotateDeg(Math.random() * 360);
						canvas.changePosition(-10, -10);

						canvas.shape(dinoShape);

					`} />

					<p>
						There's more to discuss, but we'll have to talk about it next week. To be continued!!!
					</p>
				{/* </>,<>
					<h2 className="hBlue">Resetting everything</h2>

					<p>
						Each <code>canvas.resetPosition()</code> undoes one transformation.
						Sometimes you need to reset all of your <code>canvas.changePosition</code>s and <code>canvas.scale</code>say
						at once. There's a shortcut for that: <code>canvas.resetPosition(true)</code>.
					</p>

					<p>Let's see how it works:</p>


					<CanvasRunner code={`
						${declareDinoShape}

						// draw one dino

						// draw a second dino

						// move to the center
						canvas.changePosition(200, 200);

						// draw everything 8x bigger!
						canvas.scale(8)

						// center the dino (left 10, up 10)
						canvas.changePosition(-10, -10);

						canvas.shape(dinoShape);
					`} />
 */}
				</>
			]} />

		</Layout>
	);
};


export { TransformationsLesson }
