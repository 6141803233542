import { useState } from "react";

// interface ConsoleLike {
// 	log(...args: any[])
// 	error(...args: any[])
// 	warn(...args: any[])
// 	info(...args: any[])
// 	debug(...args: any[])
// }

// const useRunner = () => useState(createRunner)[0];

// const createRunner = () => { }

function run(source: string, console: Console, context: any, exports?: string[]) {
	let wrapperFn = 'f' + Math.random().toString().slice(2);
	let f;

	try {
		context = context || {};
		context.console = console;

		// if there's a syntax error here, catch it before we wrap
		// it in our own code, so the error message isn't confusing
		new Function(source);

		f = createFunction(wrapperFn, source, context, exports);

		// try {
		console.info('\nRunning your code...')
		let ret = f();
		/* if (ret) console.info(ret);
		else  */console.info('done.')

		return ret;
	}
	catch(e) {
		window.console.error(e);
		let stack = processStack(e);

		console.error(stack)
		// sendMessage('afterRun', {success: false, error: e, stack});
	}
}

// use for executing callbacks whose errors should be handled in the fake console.
function execute<T extends (...args: any[]) => any>(fn: T, console: Console/* , source: string */, ...args: Parameters<T>): ReturnType<T> {
	try {
		return fn(...args);
	}
	catch(e) {
		window.console.error(e);
		try {
			let stack = processStack(e);
			console.error(stack)
		}
		catch (e) {
			window.console.error('failed to process stack', e);
		}
	}
}

function processStack(error: any) {
	let message: string = error.message, stack: string = error.stack, name: string = error.name;

	// Firefox doesn't inclue the error name and message in the stack like Chrome does
	// this will normalize them
	if (stack.slice(0, name.length) !== name) {
		stack = `${name}: ${message}\n${stack}`;
	}

	// now process the stack lines to match the editor
	let lines = stack.split('\n');

	let processedLines = [lines.shift()];

	for (let l = 0; l < lines.length; l++) {
		let processedLine = processStackLine(lines[l]);
		if (processedLine) {
			processedLines.push(processedLine);
		}
		else break;
	}

	return processedLines.join('\n');
}

function processStackLine(line: string) {
	// the amount of wrapper lines we add around the user code.

	const lineOffset = -3; ///Gecko\/\d/.test(navigator.userAgent) ? -3 : -1;
	const replacer = (m0, fName, line, col) => {
		if (fName == 'eval' || fName == 'anonymous') return '';

		let isWrapper = /f\d{8,}/.test(fName);
		let at = (isWrapper) ? '\tat' : `\tat ${fName}(),`;
		return `${at} line ${+line + lineOffset}`
		// return `${at} line ${+line + lineOffset}, column ${col}`
	};

	let chromeStackPattern = /\s*at ([^\(]+) \(eval.*, <anonymous>:(\d+):(\d+)\)?/
	if (chromeStackPattern.test(line)) {
		return line.replace(chromeStackPattern, replacer);
	}

	let firefoxStackPattern = /\s*([^@]+)@.*> Function:(\d+):(\d+)/;
	if (firefoxStackPattern.test(line)) {
		return line.replace(firefoxStackPattern, replacer);
	}

	return null;
}

function handleInConsole<T extends (...args: any[]) => any>(fn: T, console: Console) {
	return (...args: Parameters<T>) => {
		return execute(fn, console, ...args);
	};
}

function createFunction(fName: string, source: string, context: any, exports?: string[]) {
    let paramNames = [], params = [];
	for (let key in context) {
		paramNames.push(key);
		params.push(context[key]);
	}

	// console.log('paramNames', paramNames, 'params', params);
	let exportsStr = '';
	if (exports) {
		exportsStr = `
		return {${
			exports.map(e => `
			${e}: typeof ${e} !== 'undefined' ? ${e} : undefined,`
			).join('')}
		}`;
	}

	let wrappedSource = `return (function ${fName}(){'use strict';
		${source}
		${exportsStr}
	})()`

	// console.log("wrappedSource", wrappedSource);

	let fn = new Function(...paramNames, wrappedSource)
    return () => fn(...params)
}

const createConsole = (consoleRef: React.MutableRefObject<HTMLDivElement>, __realConsole: Console) => {
	let pseudoConsole = {
		log: function(...args) {
			return logLevel('log', ...args);
		},
		error: function(...args) {
			return logLevel('error', '🚨', ...args);
		},
		warn: function(...args) {
			return logLevel('warn', ...args);
		},
		info: function(...args) {
			return logLevel('info', ...args);
		},
		debug: function(...args) {
			return logLevel('debug', ...args);
		},

		clear: function() {
			consoleRef.current.innerHTML = '';
		},
		// __proto__: window.console,
	}

	return pseudoConsole as any as Console;

	function logLevel(level, ...args) {
		let logText = args.map(serialize).join('\ ');
		appendToLog(logText, level);
		// let logFn = window.console.
		__realConsole[level](...args);
	}

	function serialize(arg: any) {
		if (Array.isArray(arg)) {
			// let sep = arg.length > 5 ? '\n\t' : ', ';
			let sep = ', ';
			return `[${arg.map(serialize).join(sep)}]`
		}
		else if (arg && typeof arg == 'function') {
			return arg.name ? `Function ${arg.name}` : 'Function';
		}
		else if (arg && typeof arg == 'object') {
			return JSON.stringify(arg);
		}
		else {
			return String(arg)
		}
	}

	function appendToLog(logText, level = 'log') {
		let consoleEl = consoleRef && consoleRef.current;
		if (!consoleEl) return;
		// not exactly the react way of doing it.
		let container = document.createElement('div');
		container.innerHTML = `<div class="log-entry"><span class="text ${level}">${logText}</span></div>`;
		let entry = container.children[0];
		consoleEl.appendChild(entry);
		if (consoleEl.children.length > 200) {
			for (let i = 0; i < 10; i++) {
				consoleEl.removeChild(consoleEl.firstChild);
			}
		}
		// consoleEl.innerHTML += `<div class="log-entry"><span class="text ${level}">${logText}</span></div>`;
		// consoleEl.innerHTML += `<div class="log-entry"><span class="text ${level}">${logText}</span><span class="ts">${new Date().toLocaleTimeString()}</span></div>`;
		consoleEl.scrollTop = consoleEl.scrollHeight;
	}
}

const useConsole = (consoleRef: React.MutableRefObject<HTMLDivElement>) => useState(createConsole(consoleRef, window.console))[0];

export {
	// createRunner,
	// useRunner,
	createConsole,
	useConsole,
	run,
	processStack,
	handleInConsole,
	execute,
}
