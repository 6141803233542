import * as React from 'react';
import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { PagedContent } from './lib/PagedContent';

const AnimationLesson = () => {
	const firstExample = `
		var size = 15;
		var currentColor = 'black';
		var prevColor = 'white';

		draw();

		canvas.fill('black');
		canvas.textAlign('center');
		canvas.text('Press or hold down any key', 200, 240);

		function whenKeyPressed(key) {
			draw();

			size += 5;
			if (size > 565) {
				size = 15;
				if (currentColor == 'black') {
					currentColor = 'white';
					prevColor = 'black';
				}
				else {
					currentColor = 'black';
					prevColor = 'white';
				}
			}
		}

		function draw() {
			canvas.background(prevColor);
			canvas.fill(currentColor);
			canvas.ellipse(200, 200, size);
			canvas.fill('black');
		}
	`;

	return (
		<Layout narrow>
			<h1>Animating</h1>

			<p className="intro">
				In this lesson we'll learn how to put variables, functions, and if statements to
				work to help us animate our drawings to make a flipbook or a slideshow.
			</p>

			{/* ## Things to review:

			- how functions work
			- how if statements work */}


			<PagedContent pages={[
				<>
					<h2 className="hBlue">What is an animation?</h2>

					<p>
						Have you ever made or seen a flipbook? Where you draw a series of pictures on each page,
						then flip through the pages to see the pictures turn into an animation?
					</p>

					<p>
						We can create animations on the canvas in the same way.
						A video is really just a series of pictures played quickly one after the other.
						When you pause the video, you are seeing on of those pictures.
					</p>

					<p>
						The "flip" can be triggered
						in lots of different ways, but for today we will stick with one way:
						Pressing a key to change to the next image.
					</p>

					<p>
						Many of the example programs you have already seen use this pattern.
						Here's another example:
					</p>

					<CanvasRunner vertical editorHeight={500} code={firstExample} />

					<p>
						There's a slightly cooler version at <a href="https://code.mortensoncreative.com/published/x5luo8hf" target="_blank">https://code.mortensoncreative.com/published/x5luo8hf</a>.
					</p>

					<p>
						Let's talk about how <strong>variables</strong>, <strong>functions</strong>, and <strong>if statements</strong> make this animation possible.
					</p>
				</>,
				<>
					<CanvasRunner vertical editorHeight={500} code={firstExample} />

					<h3 className="hBlue">Variables</h3>

					<p>
						This animation uses only three variables: <code>size</code>, <code>currentColor</code>,
						and <code>prevColor</code> (previous color).
					</p>

					<p>These are the parts of our animation that change, and so we store the changing values as variables.</p>

					<p>
						When you define a variable at the top of a program, it is accessible anywhere in the program.
						That means you can both access and change it inside functions.
						If you change it inside one function, and use it inside another function,
						you will be using the changed value.
					</p>

					<p>
						When you define a variable that way, it's called a <strong>global variable</strong>.
						It's <em>global</em>, because it can be used anywhere and everywhere.
					</p>

					<p className="container blRed">
						{/* <p> */}
							It's important when you're changing a variable inside a function <strong>not</strong> to
							use <code>var</code> where you're changing it.  Only use <code>var</code> once with
							a variable when you define it.
						{/* </p> */}
					</p>

					<p>
						Because we made <code>size</code>, <code>currentColor</code>,
						and <code>prevColor</code> <strong>global</strong> variables, we can change them
						in <code>whenKeyPressed</code>, and use them to draw in the <code>draw</code> function.
					</p>

					<p>
						This technique is key to making our animation work. It allows us to update our canvas
						in response to some kind of human input.
					</p>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Here's a program that just displays the word 'hello' on the page.
						It has a <code>whenKeyPressed</code> handler that does nothing.
					</p>

					<p>
						Your job is to add some code to change one or more variables
						when a key is pressed.
					</p>

					<p>
						You can change the message, or its position, or both. It's up to you.
					</p>

					<CanvasRunner console vertical canvasHeight={250} editorHeight={680} docName="animating.changeGlobals" code={`
						var greeting = 'hello';
						var left = 100;
						var top = 100;

						draw();

						function whenKeyPressed(key) {
							console.log('key pressed', key);

							// ----------------------
							// change something here!
							// ----------------------

							draw();
						}

						function draw() {
							canvas.background('white');
							// draw anything here!

							canvas.textSize(32);
							canvas.text(greeting, left, top);
						}
					`} />

					<p>
						For a bonus challenge, add some code to change the background color and text color.
						You can also change those colors when a key is pressed.
					</p>
				</>,
				<>
					<h3 className="hBlue">Functions</h3>

					<p>
						Functions allow us to to create reusable pieces of code that we can run over and over again inside or program.
						You can think of each on as a mini-program that your program can use.
					</p>

					{/* <p>
						Let's spice up the previous example: Now you can move the message by pressing a key,
						or with a mouse click:
					</p>

					<CanvasRunner vertical editorHeight={500} code={`
						var greeting = 'hello';
						var left = 100;
						var top = 100;

						draw();

						function whenKeyPressed(key) {
							left += 10;
							draw();
						}

						function whenMouseClicked(x, y) {
							left = x;
							top = y;
							draw();
						}

						function draw() {
							canvas.background('white');

							canvas.textSize(32);
							canvas.text(greeting, left, top);
						}
					`} /> */}

					<p>
						Let's look at another example.  This one draws some trees:
					</p>

					<CanvasRunner vertical editorHeight={500} code={`
						// this would take a lot of repetition!
						drawTree(100, 200);
						drawTree(300, 350);
						drawTree(175, 100);
						drawTree(250, 250);

						canvas.fill('black');
						canvas.text('click to draw more trees', 100, 200);

						function whenMouseClicked(x, y) {
							drawTree(x, y);
						}

						function drawTree(x, y) {
							canvas.changePosition(x, y - 20);

							canvas.fill('saddlebrown');
							canvas.rect(-8, 0, 16, 30)
							canvas.fill('forestgreen');
							canvas.shape(-20, 0, 0, -80, 20, 0);
							canvas.shape(-20, -40, 0, -120, 20, -40);

							canvas.resetPosition();
						}
					`} />

					<p>
						In this example, we use the <code>drawTree</code> function to help us draw trees.
						Without it, we would have to write the same tree drawing code over, and over, and over again.
						But by creating the <code>drawTree</code> function, we made it reusable.
					</p>

					<p>
						Creating a function made it easy to draw lots of trees, as you see at the beginning of the program,
						but it also made it possible to add more trees later in different positions.
					</p>

					<p>
						So, functions can help us avoid writing repetitive code, but they can also make it
						possible to do things at a specific time! Functions are one of the most useful things you can
						learn in programming.
					</p>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Create a new function to draw something new on the screen.
						Make sure it can be passed a parameter to change where it can be drawn.
					</p>

					<p>
						Call your function multiple times to draw your creation in multiple places.
					</p>

					<CanvasRunner console vertical canvasHeight={250} editorHeight={680} docName="animating.useFunction" code={`
						// this would take a lot of repetition!
						drawTree(100, 200);
						drawTree(300, 350);
						drawTree(175, 100);
						drawTree(250, 250);
						// call your own drawing function here

						canvas.fill('black');
						canvas.text('click to draw more trees', 100, 200);

						function whenMouseClicked(x, y) {
							// draw your own thing instead?
							drawTree(x, y);
						}

						function drawTree(x, y) {
							canvas.changePosition(x, y - 20);

							canvas.fill('saddlebrown');
							canvas.rect(-8, 0, 16, 30)
							canvas.fill('forestgreen');
							canvas.shape(-20, 0, 0, -80, 20, 0);
							canvas.shape(-20, -40, 0, -120, 20, -40);

							canvas.resetPosition();
						}

						// create your own function here!
						// function drawSomethingOfMyOwn() {
						// 	???
						// }
					`} />

					<p>
						For a bonus challenge, add parameters to your function to change the way it is drawn.
						You can make it use different colors, for example, or height, width, or anything else you can think of.
					</p>
				</>,
				<>
					<h3 className="hBlue">Functions, continued</h3>

					<p>
						It's also really helpful to put all of our drawing code into a function.
						That way we can draw everything over again easily in response to any change,
						whether it was a mouse click, a key press, or something different.
					</p>

					<CanvasRunner vertical editorHeight={500} code={`
						var greeting = 'hello';
						var left = 100;
						var top = 100;

						draw();

						function whenKeyPressed(key) {
							left += 10;
							draw();
						}

						function whenMouseClicked(x, y) {
							left = x;
							top = y;
							draw();
						}

						function draw() {
							canvas.background('white');

							canvas.textSize(32);
							canvas.text(greeting, left, top);
						}
					`} />

					<p>
						Notice how in this example, we call <code>draw()</code> in three different places:
						At the beginning of the program,
						when a key is pressed,
						and when the mouse is clicked.
					</p>

					<p>
						If we didn't have that code in a function, we would have to write it over and over again!
					</p>

					<p>
						Every program that draws on the screen should have a function that does all of the drawing.
						You can call it anything, but <code>draw</code> or <code>drawScene</code> make a lot of sense.
					</p>

				</>,
				<>
					<h3 className="hBlue">If Statements</h3>

					<p>
						<PrefixedLink to="/lesson/if-statements" target="_blank">If statements</PrefixedLink> {' '}
						are key to letting us make our games and animations smart.
						For example, if you want to have different keys do different things,
						you'll need to use an if statement inside the <code>whenKeyPressed</code> function.
					</p>

					<p>
						Let's look at how an if statement can help you make a flipbook-style animation:
					</p>

					<CanvasRunner vertical editorHeight={500} code={`
						var currentPage = 1;
						var lastPage = 3;

						drawScene();

						function whenKeyPressed(key) {
							currentPage = currentPage + 1;
							if (currentPage > lastPage) {
								currentPage = 1;
							}

							drawScene();
						}

						function drawScene() {
							canvas.background('white');

							if (currentPage == 1) {
								drawPage1();
							}
							else if (currentPage == 2) {
								drawPage2();
							}
							else if (currentPage == 3) {
								drawPage3(0);
							}
						}

						function drawPage1() {
							drawPeople();
							canvas.line(40, 110, 100, 180);
							canvas.text('How do you wake up Lady Gaga?', 10, 100);
						}

						function drawPage2() {
							drawPeople();
							canvas.line(340, 150, 300, 180);
							canvas.text('I dunno, how?', 200, 140);
						}

						function drawPage3() {
							drawPeople();
							canvas.line(40, 110, 100, 180);
							canvas.text('You poke her face!', 10, 100);
						}

						function drawPeople() {
							drawPerson(100, 300, 'blue');
							drawPerson(300, 300, 'red');
						}

						function drawPerson(x, y, color) {
							canvas.changePosition(x, y);

							canvas.stroke(color);
							canvas.strokeWeight(4);
							canvas.line(0, -70, 0, -30);
							canvas.line(-20, -60, 0, -70);
							canvas.line(20, -60, 0, -70);
							canvas.line(-20, 0, 0, -30);
							canvas.line(20, 0, 0, -30);

							canvas.fill(color);
							canvas.ellipse(0, -90, 30, 30);
							canvas.noStroke();

							canvas.resetPosition();
						}
					`} />

					<p>
						This example is a little bit long, but don't worry, we don't need to go over all of it.
					</p>

					<p>
						Look at the very top of the program.  There's a variable called <code>currentPage</code>.
						That's important, because it determines what we draw.
					</p>

					<p>
						Next, look in <code>whenKeyPressed</code>.  When any key is pressed, <code>currentPage</code> changes
						by one, then we redraw everything.
					</p>

					<p>
						Now look at <code>drawScene</code> where the drawing happens.
						Depending on what <code>currentPage</code> is, we call a different function that calls
						a different page.
					</p>

					<p>
						Each of these page functions uses the <code>drawPeople</code> function to draw the people, which don't
						change.  Then they have some code of their own to draw the rest, or tell the joke.
					</p>

					<p>
						You wouldn't always have a separate function to draw every frame, (or "page" of a flipbook),
						but this helps us see how we can have parts of our drawing that are different every time, and parts that stay the same.
					</p>

					<p>
						For another example of an animation that tells a short story,
						see <a href="https://code.mortensoncreative.com/published/5zzj42ch" target="_blank">https://code.mortensoncreative.com/published/5zzj42ch</a>.
					</p>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Now it's your turn to put it all together.  Using the techniques we have talked about today,
						create an animation with at least three frames.
						Your program should include:
					</p>

					<ul>
						<li>At least one global variable that keeps track of something that is used in your drawing function</li>
						<li>A <code>whenKeyPressed</code> handler that changes the drawing when a key is pressed</li>
						<li>A function that does all of the drawing</li>
						<li>A function that can draw a person, or anything else, that is used by your main drawing function</li>
					</ul>

					<p>
						I have put some code in the editor to help you get started.
					</p>


					<CanvasRunner console vertical canvasHeight={250} editorHeight={680} docName="animating.flipbook" code={`
						var currentPage = 1;
						var left = 100;
						var top = 200;

						drawScene();

						function whenKeyPressed(key) {
							console.log("key pressed", key);
							// change something here!

							drawScene();
						}

						function drawScene() {
							// do whatever you like here!
							canvas.background('white');

							canvas.text('Make this animated!', left, top);
						}
					`} />

					<p>
						Have questions? Ask for help! The whole point of this class is to help you learn.
					</p>
				</>,
			]} />

			<p>
				&nbsp;
			</p>

			{/* <p>
				<PrefixedLink to="/"><Button intent="none">Home</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/lesson/and-or/2"><Button intent="primary">Continue</Button></PrefixedLink>
			</p> */}
		</Layout>
	);
};

export { AnimationLesson }
