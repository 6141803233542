import * as React from 'react';
import { Layout } from './Layout';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { Button } from 'sancho/esm/Button';
import { outdent, CodeBox } from './CodeBox';
import { Link } from 'react-router-dom';
import { Question, Answer } from './lib/Quiz';

const VariablesQuiz: React.FC = () => {
	const prefix = useCoursePrefix();

	return <Layout narrow>
		<h1>Quiz: Variables</h1>

		<p>
			Now it's time to test your knowledge about variables.
			This quiz is not scored – it's just for you to help review and remember what you learned.
			It's ok if you don't remember something.
			Just write down as best as you can remember, then compare your answer with the given answer.
		</p>

		<p><strong>Make sure to write down your answer first, before you hit Show Answer!</strong></p>

		<ol>
			<li>
				<Question>
					What is a <strong>variable</strong>?
				</Question>
				<Answer id="q1.1">
					A variable is a place to store a piece of information with a descriptive name. Once you store information in a variable,
					you can use it and reuse it in your program.
				</Answer>
			</li>

			<li>
				<Question>What is a <strong>string</strong>?</Question>
				<Answer id="q1.2">
				A string is a piece of text in a program.
				You surround it with "double quotes" or 'single quotes' to tell the computer that it's a string.
				</Answer>
			</li>

			<li>
				<Question>
					What does this code do?
					<CodeBox code={`var myAddress = "123 Main Street"`} />
				</Question>
				<Answer id="q1.3">
				It puts the string "123 Main Street, M" into the variable called `myAddress`.
				Then anywhere the program uses `myAddress`, its value will be "123 Main Street".
				</Answer>
			</li>

			<li>
				<Question>What is <code className="snippet">console.log</code>?</Question>
				<Answer id="q1.4">
				`console.log` is a function (a command) built into javascript that prints something to the script.
				</Answer>
			</li>

			{/* <Question>How do you use a function like console.log?</Question> */}
			<li>
				<Question>
					What does this code do?
					<CodeBox code={`
						var greeting = 'Hello!'
						console.log(greeting);`
					}/>
				</Question>
				<Answer id="q1.5">
					It prints 'Hello!' to the screen.
				</Answer>
			</li>
		</ol>

		<Link to={prefix}><Button intent="primary">Finished</Button></Link>
		{/* <br/> */}
		{/* <br/> */}
	</Layout>
}

export { VariablesQuiz }
