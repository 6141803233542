import * as React from 'react';
import { CanvasPage } from './CanvasPage';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const CanvasHangmanExample = () => (
	<CanvasPage

		pageContent={
			<div>
				<h1>Example Project: <br/>Guessing Game</h1>

				<p className="intro">
					Here's an example program made using canvas.
					It's a guessing game.
					You can play with it and change it around if you like.
				</p>

				<p>
					It might contain concepts you haven't learned yet.
					That's okay! You can just focus on what you know,
					or you can learn from the code.
				</p>

				<p>
					<PrefixedLink to=""><Button intent="primary">Back</Button></PrefixedLink>
				</p>

			</div>
		}
		code={`
			var word;
			var hint;
			var displayWord;
			var guesses;
			var wrongGuesses;
			var error;
			var won;
			var lost;

			restart();

			function restart() {
				word = '';
				canvas.background('white');
				do {
					var wordTmp = prompt('Enter a word or phrase for the other player to guess') || '';
					if (wordTmp.length > 25) {
						alert("That's too long! 25 characters max.");
					}
					else if (wordTmp.match(/[^a-zA-Z ]/)) {
						alert('Letters and spaces only, please!')
					}
					else {
						word = wordTmp;
					}

				} while (!word);

				word = word.replace(/ /g, '  ');

				hint = prompt('Enter a category or hint');

				// word = 'javascript';
				displayWord = '';
				guesses = [];
				wrongGuesses = [];
				error = ''
				won = false;
				lost = false;

				for (var i = 0; i < word.length; i++) {
					if (word[i] == ' ') {
						displayWord += ' ';
					}
					else {
						displayWord += '_';
					}
				}

				drawGame();
			}

			function whenKeyPressed(key) {
				if ((won || lost) && key == 'enter') {
					restart();
				}
				if (key.length == 1 && key >= 'a' && key <= 'z') {
					guessLetter(key);
				}
			}

			function guessLetter(letter) {
				letter = letter.toLowerCase();

				if (guesses.includes(letter)) {
					error = 'you already guessed that letter';
				}
				else {
					error = '';
					guesses.push(letter);
					var oldDisplayWord = displayWord;
					var match = false;
					displayWord = '';
					for (var i = 0; i < word.length; i++) {
						if (word[i].toLowerCase() == letter) {
							match = true;
							displayWord += word[i];
						}
						else {
							displayWord += oldDisplayWord[i];
						}
					}

					if (match == false) {
						wrongGuesses.push(letter);

						if (wrongGuesses.length >= 6) {
							lost = true;
						}
					}
					else if (displayWord == word) {
						won = true;
					}
				}
				drawGame();
			}

			function drawGame() {
				canvas.background('white');
				canvas.fill('black');
				canvas.textAlign('center')
				canvas.text(hint, 200, 235)

				if (won || lost) {
					if (won) {
						canvas.text('You win!!!', 200, 100);
						canvas.text(word, 200, 200)
					}
					else {
						canvas.text('You lose!', 200, 100);
						canvas.fill('red');
						canvas.text(word, 200, 200)
						canvas.fill('black');
						canvas.text(wrongGuesses.join(' '), 200, 300);
					}
					canvas.text('Press Enter to play again.', 200, 340)
				}
				else {
					canvas.text('type a letter to guess', 200, 100);
					canvas.text(displayWord, 200, 200)
					canvas.fill('red')
					canvas.text(error, 200, 340)
					canvas.text(wrongGuesses.join(' '), 200, 300);
				}

				drawHearts();
			}

			function drawHeart(x, y, size, color = 'red') {
				var unit = size / 2;
				var diag = Math.sqrt(2);
				canvas.fill(color);
				canvas.shape(x, y - unit, x - unit, y, x, y + unit, x + unit, y);
				canvas.ellipse(x - unit / 2, y - unit / 2, unit * diag);
				canvas.ellipse(x + unit / 2, y - unit / 2, unit * diag);
			}

			function drawHearts() {
				for (var i = 0; i < 6 - wrongGuesses.length; i++) {
					drawHeart(380 - i * 24, 20, 16);
				}
			}
		`}
		docName="draw-project"
	/>
);
export { CanvasHangmanExample }
