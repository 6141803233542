import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import { Header } from './Header';
import { c } from './lib/classNames';

interface LayoutProps {
	narrow?: boolean
	solid?: boolean
	full?: boolean
	className?: string
}

const Layout: React.FC<LayoutProps> = (props) => (
	<div>
		<Header />
		<div className={c('container', props.narrow && 'narrow', props.solid && 'solid', props.full && 'full', props.className)}>
			{props.children}
		</div>
	</div>
);

interface FlexLayoutProps {
	className?: string
}

const FlexLayout: React.FC<FlexLayoutProps> = (props) => (
	<div className={props.className}
	style={{
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		overflow: 'hidden',
	}}>
		<div style={{
			flex: 0,
		}}>
			<Header />
		</div>
		<div style={{
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		}}>
			{props.children}
		</div>
	</div>
);

// Scroll restoration based on https://reacttraining.com/react-router/web/guides/scroll-restoration.
const ScrollToTop = withRouter(
	class ScrollToTopWithoutRouter extends React.Component<RouteComponentProps<any>> {
		componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>) {
			if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
			}
		}

		render() {
			return null;
		}
	}
);

export { Layout, FlexLayout, LayoutProps, ScrollToTop }
