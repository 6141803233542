import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { CanvasRunner } from './lib/CanvasRunner';
import { WrappedCanvas } from './lib/WrappedCanvas';

const Canvas2Lesson = () => {
	// let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	// React.useEffect(() => {
	// 	if (canvasInterface) {
	// 		setupDemo(canvasInterface);

	// 	}
	// }, [canvasInterface]);
	// const onInit = (canvasInterface) => setupDemo(canvasInterface);
	// const onInit = setupDemo

	const wrapperStyles = {
		width: 252,
		height: 252,
		border: '1px solid #888',
		margin: '10px auto',
	};

	return (
		<Layout narrow>
			<h1>More with Canvas</h1>

			<p className="intro">
				Today we will learn some new canvas commands to make it easier to draw more things.
			</p>

			<h3 className="hBlue"><code>canvas.shape(x1, y1, x2, y2, x3, y3, ...)</code></h3>

			<p>So far we have drawn squares, rectangles, circles, lines, and ellipses, as well as drawn text.</p>

			<p>
				But what if I wanted to draw, say, a triangle?  Or a star?
			</p>

			<p>
				We have just the tool for that, and it's <code>canvas.shape()</code>.
				Before we talk about it, how about an example?
			</p>

			<CanvasRunner code={`
				canvas.fill('green');
				canvas.shape(
					100, 300, // bottom left
					200, 100, // top center
					300, 300, // bottom right
				);
			`} />

			<p>
				As you can see, that code draws a triangle.
			</p>

			<p>
				<code>canvas.shape()</code> is different than other functions that you have used so far,
				because it can take any number of parameters. (Well, any even number.)
			</p>

			<p>
				Each one is an x or a y coordinate for a corner of the shape.
				Another way to write the example above would be like this:
			</p>

			<CanvasRunner code={`
				canvas.fill('green');

				// bottom left
				var x1 = 100;
				var y1 = 300;

				// top center
				var x2 = 200;
				var y2 = 100;

				// bottom right
				var x3 = 300;
				var y3 = 300;

				canvas.shape(x1, y1, x2, y2, x3, y3);

				//
			`} />

			<p>
				So each pair of parameters is the x and y coordinates of one point.
				Or, in other words, it's a big list of dots to connect. When they are connected,
				you will get a shape!
			</p>

			<p>
				It doesn't matter whether you put the points on the same line or different lines,
				but I like to put them on different lines to make it easier to read,
				and to keep the pairs together.
			</p>

			<h3 className="hGreen">Your Turn</h3>
			<p>
				Ok, now you try! See if you recreate these images:
			</p>

			<div style={wrapperStyles}>
				<WrappedCanvas scaledWidth={250} scaledHeight={250} canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={(canvasInterfaceRef) => {
					let canvas = canvasInterfaceRef.wrapper;

					canvas.fill('blue');

					canvas.shape(100, 100, 300, 300, 100, 300);
				}} />
			</div>

			<CanvasRunner vertical console docName="canvas2.blueTri" code={`
				canvas.fill('blue');
				// canvas.shape( ?? );
			`} />

			<div style={wrapperStyles}>
				<WrappedCanvas scaledWidth={250} scaledHeight={250} canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={(canvasInterfaceRef) => {
					let canvas = canvasInterfaceRef.wrapper;

					canvas.fill('red');

					canvas.shape(0, 200, 400, 400, 200, 0);
				}} />
			</div>

			<CanvasRunner vertical console docName="canvas2.redTri" code={`
				canvas.fill('red');
				// canvas.shape( ?? );
			`} />


			<div style={wrapperStyles}>
				<WrappedCanvas scaledWidth={250} scaledHeight={250} canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={(canvasInterfaceRef) => {
					let canvas = canvasInterfaceRef.wrapper;

					canvas.fill('limegreen');

					canvas.shape(100, 350, 300, 350, 300, 150, 200, 50, 100, 150);
				}} />
			</div>

			<CanvasRunner vertical console docName="canvas2.greenHouse" code={`
				canvas.fill('limegreen');
				// canvas.shape( ?? );
			`} />

			<h3 className="hYellow">Bonus!</h3>

			<p>
				<strong>Hint:</strong> On this one, you're going to want to make use of a function or a loop.
				Remember, D.R.Y.! (Don't repeat yourself).
			</p>

			<p>
				If you get stuck, you might find
				the <a href="https://code.mortensoncreative.com/ahsj/lesson/loops-review" target="_blank">Loops Review</a> helpful.
			</p>

			<div style={wrapperStyles}>
				<WrappedCanvas scaledWidth={250} scaledHeight={250} canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={(canvasInterfaceRef) => {
					let canvas = canvasInterfaceRef.wrapper;

					canvas.fill('deeppink');
					for (var i = 0; i < 4; i++) {
						canvas.changePosition(i * 100, i * 100);

						canvas.shape(
							50, 0,
							0, 50,
							50, 100,
							100, 50,
						);

						canvas.resetPosition();

					}
				}} />
			</div>

			<CanvasRunner vertical console docName="canvas2.pinkDiamonds" code={`
				canvas.fill('deeppink');
				// D.R.Y
				// canvas.shape( ?? );
			`} />



			<h3 className="hRed">A Helpful Tool</h3>

			<p>
				I made this program that lets you draw shapes by clicking.
				It also prints out the code that could be used to draw that shape! Pretty neat, eh?
				Take a look:
			</p>

			<p>
				<a href="https://code.mortensoncreative.com/published/h3mckpx2" target="_blank">https://code.mortensoncreative.com/published/h3mckpx2</a>
			</p>

			{/* <CodeRunner code={`
				canvas.size = 200;
				canvas.changePosition(100, 100);
				canvas.shape(
					0, 0,
					size * .4, 0,
					size * .5, size * .4,
					size * .6, 0,

					size, 0,
					size, size * .4,
					size * .6, size * .5,
					size, size * .6,

					size, size,
					size * .6, size,
					size * .5, size * .6,
					size * .4, size,

					0, size,
					0, size * .6,
					size * .4, size * .5,
					0, size * .4,

					0, 0
				);
				canvas.resetPosition();
			`} /> */}

			<h3 className="hPurple">Quiz Yourself</h3>

				{/* <Question>
					What does this code print?
					<CodeBox code={`
						var colors = ['red', 'blue', 'yellow', 'green'];

						console.log(colors[1]);
						console.log(colors[3]);
						console.log(colors[0]);
					`} />
				</Question>
				<Answer id="arrays-lesson.q1">
					blue<br/>
					green<br/>
					red
				</Answer>

				<Question>
					<strong>Bonus:</strong> How about this?
					<CodeBox code={`
						var colors = ['red', 'blue', 'yellow', 'green'];

						var position = colors.length - 1;

						console.log(colors[position]);
					`} />
				</Question>
				<Answer id="arrays-lesson.q2">
					It prints "green".

					<p>
						Since the first thing in the array is at 0,
						the last thing in the array is at <code>colors.length - 1</code>.
						In this example, <code>colors.length</code> is 4, but
						<code>colors[3]</code> is the last color.
					</p>
				</Answer> */}

			<h3>Nice work!</h3>

			<p>
				Now you can draw just about any shape in the universe.
			</p>

			<p>
				...as long as it's only made of straight lines.
			</p>

			<p>
				{/* <PrefixedLink to="/lesson/canvas-2/2"><Button intent="primary">Continue</Button></PrefixedLink> */}
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p>

		</Layout>
	);
};

export { Canvas2Lesson }
