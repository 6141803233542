type TimeoutFn = typeof window.setTimeout | typeof window.setInterval;

function createTimeouts() {
	// let canceled = false;
	let running = new Set<number>();

	function run(timeoutFn: TimeoutFn, fn: Function, ms: number, ...args: any[]) {
		// if (!canceled) {
		var to = timeoutFn((...args) => {
			// if (!canceled) {
			if (timeoutFn === window.setTimeout) {
				console.log('timeout finished', to);
				running.delete(to);
			}

			fn(...args);
			// }
		}, ms, ...args);

		console.log('setting timeout', to);
		running.add(to);

		return to;
		// }
		// else {
		// 	return -1;
		// }
	}
	function setTimeout(fn: Function, ms: number, ...args: any[]) {
		return run(window.setTimeout, fn, ms, args);
	}

	function setInterval(fn: Function, ms: number, ...args: any[]) {
		return run(window.setInterval, fn, ms, args);
	}

	function cancelAll() {
		console.log('cancelAll');
		for (let to of running) {
			console.log('canceling timeout', to);
			window.clearTimeout(to);
		}

		running.clear();
		// canceled = true;
	}


	return {
		setTimeout,
		setInterval,
		cancelAll,
	}

}

export { createTimeouts }
