import * as React from 'react';

import { Button } from 'sancho/esm/Button';
import { Popover } from 'sancho/esm/Popover';
import { IconButton } from 'sancho/esm/IconButton';
import { IconHelpCircle } from 'sancho/esm/Icons/icons/IconHelpCircle';

import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { CodeBox } from './CodeBox';
// import { CodeRunner } from './lib/CodeRunner';
import { ProjectPage } from './ProjectPage';
import { PagedContent } from './lib/PagedContent';
import { Vocab } from './lib/Vocab';
// import { Vocab } from './lib/Vocab';

// const HelpPopout: React.FC<{}> = (props) => (
// 	<Popover content={<div className="helpContent">{props.children}</div>}>
// 		<IconButton tabIndex={-1} variant="ghost" label="Need Help?" icon={<IconHelpCircle />} size="sm" />
// 	</Popover>
// );

const ScratchPad: React.FC = (props) => {
	return (
		<ProjectPage
			monacoProps={{
				docName: "scratch-pad",
				initialCode: "// write anything here",
			}}
			pageContent={<PagedContent pages={[
				/****
				 * page 1
				 */
				<>
					<h1>Scratch pad</h1>

					<p>This is your own personal scratch pad.</p>

				</>,
			]} />}
		/>
	)
}

export { ScratchPad }
