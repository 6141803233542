import * as React from 'react';
import { QuizPage } from './lib/Quiz';
import { CodeBox } from './CodeBox';

const FunctionsQuiz: React.FC = () => {
	return <QuizPage title="Functions" id="q.func" questions={[
		[
			<>How would you describe a function?</>,
			<>It's a reusable block of code that does a task in a program.</>
		],
		[
			<>What's an example of how you might use a function in a program of your own?</>,
			<>To repeat something that takes multiple steps -- for example, to print out the chorus of a song.</>
		],
		[
			<>
				In this program, how many times total will the world "hello" be printed?
				<CodeBox code={`
					function sayHello() {
						console.log('hello');
						console.log('hello again');
					}

					sayHello();
					sayHello();
				`} />
			</>,
			<>
				Four times – twice each time the function is called.
			</>
		],
	]} />
}

export { FunctionsQuiz }
