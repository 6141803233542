import * as React from 'react';

import { Layout } from './Layout';
import { Button } from 'sancho/esm/Button';
import { PrefixedLink } from './lib/PrefixedLink';
import { CodeRunner } from './lib/CodeRunner';

const FunctionCallsLesson: React.FC = () => {
	const you = 'you';

	return (
		<Layout narrow>
			<h1>Lesson: Using functions</h1>

			{/* <h2>New function: <code>alert</code></h2> */}

			<p>
				So far, we have been using a function, <code>console.log</code>, to display text on the screen.
				But we don't know very much about functions yet.  Let's learn some more about functions today.
			</p>

			<h2>Functions are the building blocks of programs</h2>
			<p>
				Remember how we talked about programs having inputs, outputs, and computations?<br/>
				Well, a function is like a mini program.  A function also has inputs, outputs, and computations. <br/>
				A function can be used over and over again inside a program, and can <strong>call</strong> (or use)
				other functions.  {/* Let's see how this works: */}
			</p>

			<p>
				You have already learned how a function gets inputs.  When you call <code>console.log</code>,
				the message that you print is the input.
			</p>

			<h3>What are the inputs and outputs, of <code>console.log</code>?</h3>

			{/*
				Insert toggleable answer here!
				<Toggle>
			*/}
			<p>
				The input for console.log is what you put in between the parenthesis <code>()</code>. <br/>
				The output is that it prints the input values to the string.
			</p>
			{/*
				</Toggle>
			*/}

			{/* <p>Let's try a new function.  This one does </p> */}

			{/* <p>
				Look at this function.  Can you tell what this code does?
			</p>

			<CodeRunner code={`
				console.log("5 + 8 = ", add(5, 8));
				console.log("3622571 + 5052738 = ", add(3622571, 5052738));

				function add(firstNumber, secondNumber) {
					var sum = firstNumber + secondNumber;
					return sum;
				}`}
			/> */}

			{/* hello */}

			{/* <p> */}
			{/* <table className="lessonTable">
				<tr>
					<th>Function</th>
					<th>Inputs</th>
					<th>Computations</th>
					<th>Outputs</th>
				</tr>
				<tr>
					<td><code>console.log</code></td>
					<td>one or more strings</td>
					<td>??</td>
					<td>prints strings to the console</td>
				</tr>
			</table> */}
			{/* </p> */}

			<p>
			The function <code>alert</code> is a lot like <code>console.log</code>: You can pass it a string, and alert will
			display it.  But instead of displaying it to the console, it will display in a very different way.
			</p>

			<p>

			To find out what it does, run the code below:
			</p>

			<CodeRunner code={`
				var greeting = 'Hello!'
				// console.log(greeting);
				alert(greeting)`}
			/>

			<p>
				The alert functions displays a popup with your message.  Neat!  But it's just a different way of doing
				what <code>console.log</code> already does.  Let's learn another one.
			</p>


			<h2>Functions can return values</h2>

			{/* <p>
				A functions can <strong>return</strong>, or output, a value, and the program can store that value to use.
				That might sound a little confusing, so let's see a useful example.  Run this code:
			</p>

			<p>Woah!  Ok, what is that doing?  <code>prompt</code> is a function that </p>

			<p>
				So far, you have used one function: <code>console.log</code>.  Let's learn a couple more functions.
			</p> */}

			<p>
				<code>console.log</code> and <code>alert</code> send information to {you}.
				How about a function that gets information from {you}? Would that be useful?
				The <code>prompt</code> function does just that -- it gets a <strong>string</strong> from {you}.
			</p>

			<p>
				Before we look at the code, let's see an example of what it does.  Click the button below.
			</p>

			<p>
				<Button intent="danger" onPress={() => {
					var yourName = prompt("What is your name?") || '';
					alert("Nice to meet you, " + yourName);
				}}>Click here!</Button>
			</p>

			<p>A function can <strong>return</strong>, or output, a value, and the program can store that value to use.</p>

			<p> It works like this: </p>

			<CodeRunner code={`
				prompt("What is your name?")
			`} />

			<p>Ok, but where did the name go?  How do you do something with it?</p>

			<p>
				That's a good question.  The answer is something we learned about earlier.
				Can you remember a way to store pieces of information, so they can be reused later in the program?
			</p>

			<p>
				That's right, we're going to need to use a <strong>variable</strong>.
				But how does the name get into the variable?
			</p>

			<CodeRunner code={`
				var yourName = prompt("What is your name?");
				console.log("Nice to meet you, " + yourName);`}
			/>

			<p>
				It's a lot like how you assign a string literal (one with quotes around it) to a variable.
				The <code>prompt</code> function <strong>return</strong>s your input as a string.
				{/* So you put the function that returns a string on the right side, and the variable you want to assign it to on the left side. */}
				In other words, if {you} type "Baymax", then the code <code>var yourName = prompt("What is your name?");</code>
				is going to do the same thing that this code does: <code>var yourName = "Baymax";</code>.
			</p>

			<p>
				Remember, you always put the variable you're assigning to on the left side of the equals sign, and the value you're assigning on the right side.
				In this case, the value of <code>prompt('a question?')</code> is what {you} typed into the box.
			</p>


			{/* <p>
				There's a big difference, though.  You can run this first one over and over, and each time it runs,
				you can put in something different, and so the program will have to do something a little different each time it runs.
			</p> */}

			{/*
			<p>
				This is how a program is designed to work, right?  Each time you play a game, the game is different because you and the
				other players are giving the program different inputs.  What are those inputs?
			</p> */}



			{/* <p>
				Let's look at another example.  This time
			</p> */}




			<PrefixedLink to=""><Button intent="primary">Finished</Button></PrefixedLink>
		</Layout>
	)
}

export { FunctionCallsLesson }
