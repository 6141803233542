import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { CanvasRunner } from './lib/CanvasRunner';

const AndOrNotLesson = () => {
	return (
		<Layout narrow>
			<h1>Booleans, AND, and OR</h1>

			<p className="intro">
				In this lesson we'll learn more about how to work with booleans (<code>true</code> and <code>false</code>)
				by making use of <code>&&</code> (and) and <code>||</code> (or){/* , and <code>!</code> (not) */}.
			</p>

			<h2 className="hBlue code">What is a Boolean?</h2>

			<p>
				First, we should talk about what a boolean is.  We've talked about it in class,
				but haven't had a specific lesson about it yet.
				But we have actually used them quite a bit already,
				especially in the <PrefixedLink to="/lesson/if-statements">If statements</PrefixedLink> lesson.
			</p>

			<p>Take a look at this <strong>if statement</strong>:</p>

			<CodeBox code={`
				var score = 4;
				if (score > 0) {
					console.log('You have some points.');
				}
				else {
					console.log('You have no points.');
				}
			`} />

			<p>
				I assign the number 4 to a variable called <code>score</code>, and then
				print either "You have some points" or "You have no points", depending on
				whether score is greater than zero (<code>score > 0</code>).
				Since <code>score</code> is 4 and 4 is greather than zero,
				it prints "You have some points".
			</p>

			<p>
				In this example, the <strong>condition</strong> that we're testing is <code>score > 0</code>,
				which is either <code>true</code> or <code>false</code>.
				That value can even be stored in a variable, like this:
			</p>

			<CodeRunner code={`
				var score = 4;
				var hasPoints = score > 0;
				console.log(hasPoints);
				if (hasPoints) {
					console.log('You have some points.');
				}
				else {
					console.log('You have no points.');
				}
			`} />

			<p>
				This program works almost exactly like the last one, with one small difference:
				I stored the result of <code>score > 0</code> in a variable called <code>hasPoints</code>,
				which allowed me to use it twice: Once to print the value to the console,
				and once in the if statement. The if statement works exactly the same
				with <code>hasPoints</code> as it did with <code>score > 0</code>.
			</p>

			<p>
				<code>true</code> and <code>false</code> are a special type value called a <strong>boolean</strong>.
				It's a fancy name – named after a guy named Boole – but it really just means <code>true</code> or <code>false</code>.
			</p>

			<p>
				There are other ways to get true and false.  You have learned a little about them already.
				There are different operators (symbols) that compare a value on the left and on the right,
				in different ways.
			</p>

			<p>
				A computer scientist would call them <strong>comparison operators</strong>.
				"Operator" just means a symbol that <em>operates</em> (performs some kind of calculation)
				on the values it is written by.
			</p>

			<h3>Comparison Operators</h3>

			<table className="codeTable">
				<tr>
					<th>Operator</th>
					<th>What it means</th>
					{/* <th>Data type</th> */}
				</tr>
				<tr>
					<td className="code">==</td>
					<td>is equal to</td>
				</tr>
				<tr>
					<td className="code">&gt;</td>
					<td>is greater than</td>
				</tr>
				<tr>
					<td className="code">&lt;</td>
					<td>is less than</td>
				</tr>
				<tr>
					<td className="code">&gt;=</td>
					<td>is greater than or equal to</td>
				</tr>
				<tr>
					<td className="code">&lt;=</td>
					<td>is less than or equal to</td>
				</tr>
			</table>

			<p>Here are some examples of these operators in action:</p>

			<CodeRunner height={500} code={`
				// -----------------
				// comparing strings
				// -----------------

				var direction = 'left';
				console.log('direction is right:', direction == 'right');

				var dirIsLeft = direction == 'left';
				console.log('direction is left:', dirIsLeft);

				var nextDirection = 'up';
				console.log('direction is the same as nextDirection:', direction == nextDirection);

				// -----------------
				// comparing numbers
				// -----------------

				var age = 17;
				var votingAge = 18;
				var drivingAge = 16;
				console.log('can drive:', age >= drivingAge);

				var canVote = age >= votingAge;
				console.log('can vote:', canVote);

				var cantVote = age < votingAge;
				console.log("can't vote:", cantVote);

				var randomNumber = 5;
				var guess = 1;

				console.log('guess is correct:', guess == randomNumber);

				guess = 5;
				console.log('new guess is correct:', guess == randomNumber);

			`} />

			<p>
				Notice how each comparison is either comparing two strings,
				but they can be variables,
				or <strong>literals</strong> (values that are written out
				directly in the code).
			</p>

			<h2 className="hGreen">Your Turn</h2>

			<p>
				Can you fix this program to correctly decide if you can ride the ride?
				Make sure it works for all different numbers.
			</p>

			<CodeRunner docName="andOrNot.canRide" code={`
				var myHeight = prompt('How many inches tall are you?');
				var minHeight = 48;

				// fix this if statement to print the right message by comparing the two heights
				if (true) {
					alert('You must be ' + minHeight + ' inches tall to ride this ride');
				}
				else {
					alert('You may ride this ride');
				}
			`} />

			<p>
				Next, can you fix this program so it prints whether you clicked the top or the bottom?
			</p>

			<CanvasRunner console vertical canvasHeight={200} editorHeight={680} docName="andOrNot.clickedTop" code={`
				var middle = 200;

				draw();

				function draw() {
					canvas.background('sienna');
					canvas.fill('skyblue');
					canvas.rect(0, 0, 400, middle);
				}

				function whenMouseClicked(x, y) {
					draw();
					canvas.fill('black');
					canvas.textAlign('center');
					canvas.textSize(33);

					// fix this if statement so it prints the right message
					// depending on where you clicked.
					if (false) {
						canvas.text('You clicked the top', 200, 110);
					}
					else {
						canvas.text('You clicked the bottom', 200, 110);
					}
				}
			`} />

			<p>
				Click on Continue on you have solved these problems.
				Don't be afraid to ask for help!
			</p>

			<p>
				<PrefixedLink to="/"><Button intent="none">Home</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/lesson/and-or/2"><Button intent="primary">Continue</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

export { AndOrNotLesson }
