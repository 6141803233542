import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { Layout, FlexLayout } from './Layout';
import { SplitPane } from './components/SplitPane';
import { useDoc } from './lib/dbHooks';
import { useDb } from './firebase';
import { CodeBox, Snippet } from './CodeBox';
import { Button } from 'sancho/esm/Button';
import { Link } from 'react-router-dom';
// import { useAppAuthState } from './firebase';
// import { useDocumentData } from 'react-firebase-hooks/firestore';
// import { useAuthState } from 'react-firebase-hooks/auth';

/*
const madLibsCode =
`var person = 'Jedi'; // change 'Jedi' to 'boy', 'girl', 'rock star', or anything you like.
var name = ''; // Type your name in between the ''
// you'll need to create some more variables:
// var adjective = 'exquisite';

var story = \`Once there was a \${person} named \${name}.
\${name} was a very \${adjective} \${person}.
One day \${name} went to see the king, King \${sillyName}.
"Oh great King \${sillyName}, what is the secret to your success?"
Listen, \${name}, you must always remember this:
Never put bad \${foodIngredient} on a good \${foodType}.\`;

console.log(story)`;
*/

const madLibsCode1 =
`/*
* Mad Libs - part 1
*/

// change 'Jedi' to 'boy', 'girl', 'rock star', or anything you like.
var person = 'Jedi';

// Type your name in between the ''
var name = '';

// you'll need to create some more variables:
// var adjective = 'exquisite';


// Don't change this part!

console.log('Once there was a ' + person + ' named ' + name + '.');
console.log(name + ' was a very ' + adjective + ' ' + person + '.');`;

const MadLibsProject: React.FC = () => {
	const [frameApi, setFrameApi] = React.useState<any>(null);
	// const [canContinue, setCanContinue] = React.useState(false);

	return <ProjectPage
	pageContent={<PageContent frameApi={frameApi} /* canContinue={canContinue} */ />}
	// docName="asdf-test"
	monacoProps={{
		initialCode: madLibsCode1,
		storageKey: 'madLibs',
		docName: 'madlibs1',
		// frameSrc: 'http://localhost:5000/monaco-frame',
		// frameOrigin: 'http://localhost:5000',
		// frameOrigin: 'https://monaco-frame.mortensoncreative.com',
		// runRemote: true,
		afterInit: (api) => {
			(window as any).frameApi = api;
			setFrameApi(api);
		},
		// beforeRun: (source) => {
		// 	console.log('beforeRun', source);
		// 	return false;
		// },
		// afterRun: (details) => {
		// 	setCanContinue(details.success);
		// 	// console.log('afterRun', details)
		// }
		// defaultValue: `// write some code here`,
		// context: null,
		// editorDidMount: n
	}}
	 />
}

interface PageContentProps {
	frameApi: {
		updateCode(code: string, run?: boolean): void
	}
	// canContinue: boolean
}

const PageContent: React.FC<PageContentProps> = (props) => <>
	<h2>Mad Libs</h2>

	<p>In this project, you will be creating a Mad Libs Story.</p>
	<p>In order to complete the mad lib, you'll need to define some variables.</p>

	<p>Try running the code for the first time.  It doesn't run! 🤦‍♂️.  But that's ok, you can fix it. Click the Run Code button, and let's see what happens.</p>

	<CodeBox code={`
		ReferenceError: adjective is not defined
		    at line 18, column 37`} />

	<p>
		Do you see something like this? That's ok.
		That just means you are trying to use a variable called "adjective", that you haven't created yet.
		For our program to run, you'll need to create that variable before it gets used.  On line 12, just type this code:
	</p>

	<p><Snippet>var adjective = 'lovely';</Snippet></p>

	<p>
		You might feel like copying and pasting it instead, but you should really type it&hellip;
		Writing it out helps you remember what you did to make it work.
	</p>

	<p>
		After you create the <code className="snippet">adjective</code> variable and assign a string to it, try running it again.
		If it worked, you'll see your story printed out! If not, you'll have another error message telling you what went wrong.
	</p>

	<p>
		If you see this: <Snippet>SyntaxError: Unexpected string</Snippet>, look for a red squiggle in the editor.
		A SyntaxError means the program is seeing something it doesn't expect or understand.  The red squiggle shows where it is.
	</p>

	<p>
		Once you get your code running, you'll also notice that the person is named 'YOUR NAME HERE'.
		Find 'YOUR NAME HERE' in the code, and change it to your name (or any other name).
	</p>

	{/* <CodeBox code={madLibsCode2} updateCode={props.frameApi && props.frameApi.updateCode} /> */}

	<p>
		Once you are satisfied that your code is working, let's expand on it.
	</p>

	<p>
		<Link to="./mad-libs/2">
			<Button /* disabled={!props.canContinue} */ intent="primary" /* onPress={() => props.frameApi.updateCode(madLibsCode2)} */>It worked! Let's go.</Button>
		</Link>
	</p>



	{/* <CodeBox updateCode={props.frameApi && props.frameApi.updateCode} code={`
		// Type some code ->
		var number = 2;
		var doubled = number * 2;

		console.log(doubled);`}
	/> */}
</>;


export { MadLibsProject }
