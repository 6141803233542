import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { WrappedCanvas } from './lib/WrappedCanvas';
import { canvasTypedef, CanvasInterface } from './lib/canvasWrapper';
import { handleInConsole } from './lib/runner';
import { c } from './lib/classNames';
import { Music, musicTypeDef } from './lib/sound/Music';
import { useCleanup } from './lib/hookUtils';
import { useTimeouts } from './lib/useTimeouts';

interface CanvasPageProps {
	pageContent: JSX.Element | ((canvas: JSX.Element) => JSX.Element)
	className?: string
	context?(...args: any[]): void
	docName?: string
	docGroup?: string
	docType?: string
	code: string
	noReset?: boolean
	exports?: string[]
	buttons?: boolean
	afterRun?(canvas: CanvasInterface, exports: any, {console: Console}): any
}

const CanvasPage: React.FC<CanvasPageProps> = (props) => {
	// let canvasWrapperRef = React.useRef<CanvasWrapper>(null);
	let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	let pageContent;
	if (typeof props.pageContent == 'function') {
		pageContent = props.pageContent(<WrappedCanvas canvasInterfaceRef={canvasInterfaceRef} fullScreen />);
	}
	else {
		pageContent = <div className="container">
			<div className="canvasWrapper">
				<WrappedCanvas canvasInterfaceRef={canvasInterfaceRef} fullScreen />
			</div>
			{props.pageContent}
		</div>;
	}

	let timeouts = useTimeouts(); // createTimeouts();

	useCleanup(() => {
		Music.stopAll();
		timeouts.cancelAll();
		let canvas = canvasInterfaceRef.current && canvasInterfaceRef.current.wrapper;
		if (canvas) canvas.reset();
	});

	return <ProjectPage
		className={c("CanvasPage", props.className)}
		useCodeRunner
		pageContent={pageContent}
		codeRunnerProps={{
			docName: props.docName,
			docGroup: props.docGroup,
			docType: props.docType,
			code: props.code,
			context: (console) => {
				let context: any = {};
				if (props.context && typeof props.context == 'function') {
					context = props.context(console, canvasInterfaceRef);
				}

				let canvas = canvasInterfaceRef.current.wrapper;

				return Object.assign({ canvas, Music, setTimeout: timeouts.setTimeout, setInterval: timeouts.setInterval }, context);
			},
			exports: ['whenKeyPressed', 'whenKeyReleased', 'whenMouseMoved', 'whenMouseClicked', ...(props.exports || [])],
			beforeRun(source, console, context, exports) {
				timeouts.cancelAll();
				context.canvas.reset();
				context.Music.stopAll();
				let cInterface = canvasInterfaceRef.current;
				cInterface.handlers.onKeyPressed = null
				cInterface.handlers.onKeyReleased = null
				cInterface.handlers.onMouseClicked = null
				cInterface.handlers.onMouseMoved = null

				cInterface.console = console;
				cInterface.hasRun = true;
			},
			afterInit: api => {
				canvasInterfaceRef.current.handlers.onRun = api.runCode;
			},
			// canvasInterface: canvasInterfaceRef.current,
			afterRun: (exported, {console}) => {
				if (typeof props.afterRun == 'function') {
					let newExports = props.afterRun(canvasInterfaceRef.current, exported, {console});
					if (newExports && typeof newExports == 'object') exported = newExports;
				}

				if (exported.whenKeyPressed) {
					canvasInterfaceRef.current.handlers.onKeyPressed = handleInConsole(exported.whenKeyPressed, console)
				}
				if (exported.whenKeyReleased) {
					canvasInterfaceRef.current.handlers.onKeyReleased = handleInConsole(exported.whenKeyReleased, console)
				}
				if (exported.whenMouseMoved) {
					canvasInterfaceRef.current.handlers.onMouseMoved = handleInConsole(exported.whenMouseMoved, console)
				}
				if (exported.whenMouseClicked) {
					canvasInterfaceRef.current.handlers.onMouseClicked = handleInConsole(exported.whenMouseClicked, console)
				}
			},
			extraLibs: canvasTypedef + musicTypeDef,
			unsavedChangesWarning: true,
			noReset: props.noReset,
		}}
	/>
}

export { CanvasPage, CanvasPageProps }
