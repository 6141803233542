// let loaded = false;
let tawkApi = null;

async function loadTawkApi(visitorName?: string/* , visitorEmail?: string/* timeout = 0 */) {
	if (tawkApi) {
		return tawkApi;
	}

	return new Promise((res, rej) => {
		var w = window as any;
		var Tawk_API = w.Tawk_API = w.Tawk_API || {};

		if (visitorName) {
			Tawk_API.visitor = {
				name: visitorName,
				email: 'nobody@mortensoncreative.com',
				hash: '720f4bf80f1887296e934eef60aa14be2e70689615b22025f18ffad7581f5914',
				// email: 'brian@mortensoncreative.com',
				// hash: '834f9f4060a304edc3aab3ca6686d2ccbd2d7a8aa49b2bc8110a14198c8138cd',
				// email: 'test@example.com',
				// hash: 'cf7be5f88467c3b2a78471c58593dcbd0f866c22d0a7edb7403e98a58101a3ce',
				// email: '',
				// hash: 'ee4d03dd4107cca199ba792402e1951545e18417f1fd36c9c208cdfe17320ca9',
			};
		}

		w.Tawk_LoadStart = new Date();
		Tawk_API.onLoad = () => {
			tawkApi = Tawk_API;
			// Tawk_API.hideWidget();
			res(tawkApi);
		};

		var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
		s1.async = true;
		s1.src = 'https://embed.tawk.to/5e29cefc8e78b86ed8aac396/default';
		s1.charset = 'UTF-8';
		s1.setAttribute('crossorigin', '*');
		s0.parentNode.insertBefore(s1, s0);
		// </script>
		// <!--End of Tawk.to Script-->
	});
}

export { loadTawkApi };
