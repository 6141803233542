import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { Note } from './lib/Note';
import { Link } from 'react-router-dom';
import { Button } from 'sancho/esm/Button';
import { useCoursePrefix } from './lib/useCoursePrefix';

const IntroLesson: React.FC = () => {
	const prefix = useCoursePrefix();

	return <Layout narrow>
		<h1>Lesson: Hello, World!</h1>

		<Note>{`
			Before we start programming, there are a few things we should talk about:

			The code editor.  You can write code here and run it with the run button.

			Don't be afraid to make mistakes!  Most things will not work perfectly on the first try.

			Below the editor is the console.  Your program sends its output here.
			It will also print error messages when something goes wrong.
		`}</Note>

		<p>
			Traditionally, the first program a student learns in a programming class is the Hello, World program.
			This is where a program introduces itself to the world (usually by saying "Hello, world!");
		</p>

		<p>
			Let's see what Hello, World looks like in Javascript:
		</p>

		<CodeRunner code='console.log("Hello, world!");' />

		<p>Try running the code by clicking the 'Run Code' button.  What do you see happening?</p>

		<p>
			Now try changing <code className="snippet">"Hello, World"</code> to something else, but keep the quotes there.
			Maybe something like <code className="snippet">"Hi, my name is Brian. Nice to meet you!"</code>.
			Except use your name instead.
		</p>

		<h3 className="hYellow">Did you try it?</h3>
		<p>Congratulations, you are now writing Javascript! But we have a lot more to learn.</p>

		<p>
			There are a few things to look at here:
			{} <code className="snippet">console.log</code> is a <strong>function</strong>.  We'll learn more about functions soon.
			For now, just know that console.log(something)
			will print whatever is between the parenthesis to the console.
		</p>

		<p>
			<code className="snippet">"Hello, world!"</code> is a <strong>string</strong>.  We'll learn a lot more about strings too.
			Strings are what we call text inside a computer program. They are always surrounded by quotes.
			They can be single quotes, like this: <code className="snippet">'string'</code>, or double quotes, like this: <code className="snippet">"string"</code>.
		</p>

		<p>
			The semicolon (<code className="snippet">;</code>) at the end of the line is the end of the statement.
			It's like the period at the end of the sentence.
			It's telling the computer where one command ends and the next one begins.
		</p>

		{/* <h3>Bonus</h3>
		<p>What happens if you get rid of the quotes around your string? (You can always hit the reset button to start over)</p>
		<p>What happens if you get rid of the quotes around your string?</p> */}

		<p>
			You can use console.log over and over again to print as much as you like.
			Try writing a few lines to introduce yourself.
			I have included an example that you can start from, but be sure to add more lines of your own!
		</p>

		<CodeRunner docName="helloworld-selfintro" code={`
			// Change these how you like,
			console.log("Hi, my name is Brian.");
			console.log("I am 34 years old");

			// Add some more!
			console.log("I like to rock climb and write code.")

			// Keep going!  add whatever you like.
		`} />

		<p>
			<strong>Pro Tip:</strong> One key to coding is practice.
			There's a lot to learn, but if you use what you're learning, you will start to remember it!
			You'll want to write code often to help you remember and build on what you learn.
		</p>

		<Link to={prefix}>
			<Button intent="primary">Finished</Button>
		</Link>


		{/* <Links /> */}
	</Layout>
}


export { IntroLesson }
