import * as React from 'react';
import { CodeBox } from './CodeBox';
import { createRunner, pongTypeDef, PongRunner } from './Pong';
import { ProjectPage } from './ProjectPage';
import { CanvasInterface } from './lib/canvasWrapper';
import { WrappedCanvas } from './lib/WrappedCanvas';
import { useCleanup } from './lib/hookUtils';
import { Music } from './lib/sound/Music';
import { CodeRunnerProps } from './lib/CodeRunner';
import { Emoji } from './lib/Emoji';

const PongProject: React.FC = () => {
	// let canvasWrapperRef = React.useRef<CanvasWrapper>(null);
	let canvasInterfaceRef = React.useRef<CanvasInterface>(null);
	// const [pong] = React.useState(() => Pong.createInstance(canvasInterfaceRef));
	let runner: PongRunner;

	const tableData: {[prop: string]: string} = {
		'info.playerY': 'The player\'s y position.',
		'info.playerX': 'The player\'s x position. Also where the ball will bounce.',
		'info.playerSpeed': 'The player\'s maximum up/down speed, or the maximum distance the player can travel in one frame.',
		'info.playerWidth': 'The total width of the player\'s paddle',
		'info.playerSign': 'The direction the ball moves toward the player. 1 if the player is on the right, and -1 if the player is on the left.',
		'info.playerVy': 'The player\'s vertical velocity. Negative when the player is moving up, and positive when the player is moving down.',
		'info.playerScore': 'The player\'s current score',

		'info.ballY': 'The ball\'s y position.',
		'info.ballX': 'The ball\'s x position.',
		'info.ballVx': 'The ball\'s x velocity.',
		'info.ballVy': 'The ball\'s y velocity.',
		'info.ballMinY': 'The ball\'s minimum y, or where the ball will bounce at the top of the screen.',
		'info.ballMaxY': 'The ball\'s maximum y, or where the ball will bounce at the bottom of the screen.',
		// 'info.ballMinX': 'The ball\'s min x.',
		// 'info.ballMaxX': 'The ball\'s max x.',

		'info.opponentY': 'The opponent\'s y position.',
		'info.opponentX': 'The opponent\'s x position. Also where the ball will bounce.',
		'info.opponentSpeed': 'The opponent\'s maximum up/down speed, or the maximum distance the opponent can travel in one frame.',
		'info.opponentWidth': 'The total width of the opponent\'s paddle.',
		'info.opponentSign': 'The direction the ball moves toward the opponent. 1 if the opponent is on the right, and -1 if the opponent is on the left.',
		'info.opponentVy': 'The opponent\'s vertical velocity. Negative when the opponent is moving up, and positive when the opponent is moving down.',
		'info.opponentScore': 'The opponent\'s current score.',
	}

	useCleanup(() => {
		Music.stopAll();
	});

	const pageContent = <div className="container">
		<div className="canvasWrapper">
			<WrappedCanvas canvasInterfaceRef={canvasInterfaceRef} fullScreen />
		</div>

		<h1 style={{fontSize: '3em'}}> <Emoji e="🤖" /> PONG BOTS <Emoji e="🤖" style={{color: 'red'}} /></h1>

		<p className="intro">
			In this project you will create your own AI to play pong! It can play against you (or other humans), other bots, or itself.
		</p>

		<p>
			Your bot is really just a function. You can register your function as a bot like this:
		</p>

		<CodeBox code={`
			Pong.registerAI(function myBot(player, ball, opponent) {
				// you will write some code here.
			}, 'Cool Bot Name');

		`} />

		<p>
			Your bot will show up in the menu, and you can choose it for either player.
		</p><p>
			During every frame of the game, the game engine will call your function to find out where the bot should go next.
		</p><p>
			The game engine will pass an <code>info</code> your function.
			This object contains information about your player, the ball, and the opposing player.
			<code>player</code>, <code>ball</code>, and <code>opponent</code>.
			For example, <code>info.playerY</code> is your player's vertical position,
			and <code>info.ballY</code> is the ball's vertical position.
		</p><p>
			Your bot should use these and other properties to decide where to move.
		</p><p>
			<strong>Your function must return a number</strong>, and that number is how the bot should move during the current frame.
			Return a positive number to move down (remember, the Y axis goes from top to bottom), and a negative number to move up.
			The distance your player will move is limited by their max speed, or <code>player.speed</code>.
		</p>
		<h3>Bot Strategies</h3>
		<p>
			The simplest bot would only need to worry about two
			things: <code>ball.y</code>, and <code>player.y</code>.
			When the ball is above the player, move up, and when the ball is below the player, move down.
			I'll leave it to you to figure out how to write that.
		{/*
			The simplest bot would look like this:
		</p>
		<CodeBox code={`
			function basicBot(player, ball) {
				return ball.y - player.y;
			}
		`} />
		<p>
			That bot just tries to follow
			It's very simple to build a bot that just tries to keep up with the ball wherever it currently is.
			You can write that bot in one line of code! I was going to put that code here as an example, but I think
			it will be more instructive for you to figure that out on your own.
		*/}
		</p><p>
			But a more advanced bot is going to want to consider both the ball's position and
			velocity, to figure out where the ball is going to make sure it gets there on time.
		</p><p>
			The most advanced bots will try to position themselves to speed up or slow down the ball,
			depending on where they want it to go next.
		</p>

		<h3>Bot Function Parameters</h3>
		<p>
			Here's a list of all the information your bot will receive as function parameters when
			the bot function is called.  Your bot can use these to help decide where to move.
		</p>

		<table className="lessonTable">
			<tbody>
				{Object.entries(tableData).map(([prop, desc]) => (
					<tr key={prop}>
						<td>{prop}</td>
						<td>{desc}</td>
					</tr>
				))}
			</tbody>
		</table>

		<h3>Suggested Steps to Build Your Bot</h3>
		<p>
			Here's a suggested process for writing your bot.
		</p>

		<h4>Step 1</h4>
		<p>
			Understand how the bot function works. Starting with the example code provided,
			use <code>console.log</code> to print out values on the <code>info</code> object.
			You can also use <code>Pong.debugDraw(x, y, color)</code> to draw dots anywhere on the canvas.
		</p>

		<h4>Step 2</h4>
		<p>
			Make some "dumb" bots. For example, try returning a value
			like <code>0</code>, <code>1</code>, or <code>-3</code> from your function.
			What does this cause your bot to do?
		</p>

		<p>
			Next, try making a bot that moves to a certain position (like 100), then stays there.
			This should help you get a feel for how to make the bot "think".
		</p>

		<h4>Step 3</h4>
		<p>
			Time to make your first bot that actually plays the game.
			Make a simple bot that follows the ball around -
			if your bot is below the ball, go up, and if your bot is above the ball, go down.
			You'll need to use <code>info.ballY</code> and <code>info.playerY</code> to do this.
		</p>

		<h4>Step 4</h4>
		<p>
			Use the ball's speed and direction to anticipate where the ball is going and move in that direction.
			The variables <code>info.ballY</code>, <code>info.ballX</code>, <code>info.ballVx</code>, <code>info.ballVy</code> will
			all be useful here.
		</p><p>
			This will require some math!
			As always, try to figure it out yourself first, but don't be afraid to reach out for help.
		</p>

		<h4>Step 5</h4>
		<p>
			The next step is to predict where the ball is going,
			even when the ball is going to bounce off a wall before it reaches your player.
			The variables <code>info.ballMinY</code> and <code>info.ballMaxY</code> indicate
			where the ball will bounce, and that can help you with these calculations.
		</p>

		<h4>Step 6</h4>
		<p>
			Once your bot can account for bounces off the top and bottom walls,
			you can use a similar calculation to anticipate where it's going before before the ball
			bounces of the opponent's paddle.
			The guess won't be perfect since the opponent's bounce can change where the ball is going,
			but don't worry about that.  Just assume it's going to bounce straight, and your guess will be close.
		</p>

		<h4>Step 7</h4>
		<p>
			Now it's time to add some "personality" to your bot.
			Do you want it to be aggressive? Defensive?
			Change how your bot positions itself to the ball when it hits it to change its trajectory.
			If your player hits the ball in the very center of the paddle, the ball's direction will not change.
			Otherwise, it will change the ball's y velocity. The change is equal to the distance from the center
			to where the ball hits, times <code>info.directionChangeVal</code>.
			You can use that to calculate where to position the paddle in order to hit the ball a certain way.
		</p>

		<p>
			For example, if <code>ball.vy == 1</code>, and the ball hits the paddle
			when <code>ball.y == 120</code> and <code>player.y == 135</code>,
			after the bounce, <code>ball.vy</code> will be <code>2.5</code>,
			because <code>ball.y - player.y == 15</code> and <code>info.directionChangeVal == 10</code>.


		</p>
	</div>;

	const codeRunnerProps: CodeRunnerProps = {
		docName: 'project.pongAI',
		code: `
			Pong.registerAI(function(info) {
				// return your move here.
				return 0;

			}, 'Name your bot');

		`,
		extraLibs: pongTypeDef,
		// docGroup: props.docGroup,
		// docType: props.docType,
		afterInit: api => {
			canvasInterfaceRef.current.handlers.onRun = api.runCode;
		},
		beforeRun(source, console, context, exports) {
			// debugger;
			runner = createRunner(),
			context.Pong = runner.interface;
			// the code will call runner.interface.registerAI(),
			// then we call runner.initGame() in afterRun
			canvasInterfaceRef.current.handlers.onKeyPressed = null
			canvasInterfaceRef.current.handlers.onKeyReleased = null
			canvasInterfaceRef.current.handlers.onMouseClicked = null
			canvasInterfaceRef.current.handlers.onMouseMoved = null

		},
		afterRun: (exported, stuff) => {
			// this happens after the AI(s) have been registered.
			runner.initGame(canvasInterfaceRef.current);
		},
		unsavedChangesWarning: true,
	};

	return <ProjectPage useCodeRunner
		className="CanvasPage"
		pageContent={pageContent}
		codeRunnerProps={codeRunnerProps}
	/>;
}

export { PongProject }
