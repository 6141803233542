import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Vocab } from './lib/Vocab';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const FunctionsLesson: React.FC = () => (
	<Layout>
		<h1>Lesson: Functions</h1>

		<p>
			A function is one of the building blocks of a program.
			Like a program, it has inputs, outputs, and calculations.
			When you use a function, it is said that you <strong>call</strong> the function.
			When you call a function, you are asking the function to do some work for you, and to let you know the result of that work.
		</p>

		<p>
			A function allows you to store code to be reused multiple times, or in multiple places in your program.
			You can think of if as a named, reusable block of code.
		</p>

		<p>A function definition looks like this:</p>

		<CodeBox code={`
			function printSomethingProfound() {
				console.log('What goes up must come down.');
			}
		`} />

		<p>
			A function doesn't run when it's defined.
			You have to call it to run the function,
			just like you call the other functions you already know.
		</p>

		<CodeRunner code={`
			function printSomethingProfound() {
				console.log('What goes up must come down.');
			}

			console.log('Here goes:');
			printSomethingProfound();
			console.log('How profound!');
		`} />

		<p>You can call a function over and over again too.  That's part of what makes them useful.</p>

		<CodeRunner code={`
			function printSomethingProfound() {
				console.log('What goes up must come down.');
			}

			console.log('Here goes:');
			printSomethingProfound();
			console.log('How profound!');
			printSomethingProfound();
			console.log('Still very profound.');
		`} />

		<h3 className="hPurple">Quiz Yourself</h3>

		<ul>
			<li>What order are these colors going to print out?  Try to decide before you run it, and see if you're right.</li>
		</ul>

		<CodeRunner code={`
			function blue() {
				console.log('blue');
			}

			console.log('red');

			// now I can call that function anywhere in the program
			blue();

			console.log('yellow');

			blue();

			// what is this going to print out?
		`}/>

		<h2 className="hBlue">Parameters and Return Values</h2>
		<p>
			Remember how I said that programs can have inputs and outputs? {/* inputs, outputs, and calculations? */}
			And that functions are like mini programs, that also have inputs and outputs?
		</p>

		<p>Let's see an example of a function with both inputs and outputs:</p>
		<CodeRunner code={`
			var message = 'Today is Tuesday';
			var excitingMessage = makeMoreExciting(message);
			console.log(excitingMessage);

			function makeMoreExciting(phrase) {
				var moreExcitingPhrase = '¡¡' + phrase + '!!!!'
				return moreExcitingPhrase;
			}`
		} />


		<Vocab term="parameter">
			A parameter is an input to a function. It makes a function reusable
			by allowing it to do different things.
			<br/>
			For example, console.log can log a different message every time,
			because you give the message to log as a parameter.
		</Vocab>

		<p>
			When you define a function, you can say what inputs it needs in between the parenthesis.
			Technically, these inputs are called <strong>parameters</strong>.  I'll call them that from now on.
			Here, the parameter is called <code>phrase</code>.
		</p>

		<p>
			Then when you call the function, you give it the parameters (input) it needs.
			Your parameter, <code>"Today is Tuesday"</code> (which was stored in the <code>message</code> variable)
			was passed into the function like this: <br/>
			<code>makeMoreExciting(message)</code>
		</p>
		<p>
			Inside the function, now you have a variable called <code>phrase</code>,
			and you can use it in your function.  We used it to make a more exciting version called <code>moreExcitingPhrase</code>.
		</p>

		<p>
			Then, we used <strong>return</strong> to output the new improved phrase,
			and captured it where we called the function by assigning it to a variable.
		</p>

		<Vocab term="keyword">
			A keyword is like a special command in Javascript.
			You already know another keyword, <code>var</code>, which declares a variable.
		</Vocab>

		<p>
			<strong>return</strong> is a special <em>keyword</em> that returns the value after it back outside the function, where it was called.
		</p>

		<h3 className="hGreen" style={{clear: 'both'}}>Try it yourself</h3>

		<p>
			It does sound complicated at first, but it's easier to understand if you play with the code,
			and try it out yourself.
		</p>

		<p>
			Here's a mini challenge:  Can you write a function called <code>addSmiley</code>?
			The function should take one parameter called <code>sentence</code>, which should be a string.
			Then it should return a string that is <code>sentence</code> with a smiley added to the end,
			like this: <code>sentence + ' :)'</code>
		</p>

		<CodeRunner docName="addSmiley">
			// write your function addSmiley here.
		</CodeRunner>

		<p>If you need help, ask a friend, or the teacher first!</p>

		<p>After you write your function, try it out by calling it, and using it.</p>

		After you're done, compare it to this answer:

		<CodeBox clickToShow code={`
			function addSmiley(sentence) {
				var sentenceWithSmiley = sentence + ' :D';
				return sentenceWithSmiley;
			}

			var notSmileySentence = "Programming is fun";
			var smileySentence = addSmiley(notSmileySentence);
			console.log(smileySentence);

			// or, here's a shorter version that does the same thing!
			console.log(addSmiley("Programming is fun"));
		`} />


		<h3 className="hPurple">Quiz Yourself</h3>

		<ul>
			<li>What is a function? Why would you want to use one?</li>
			<li>How do you write a function?</li>
			<li>How do you pass parameters (inputs) into a function?</li>
			<li>How do you get output from a function?</li>
		</ul>

		<PrefixedLink to="/project/quiz-game/2"><Button intent="primary">On to the project!</Button></PrefixedLink>
		&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
	</Layout>
)


export { FunctionsLesson }
