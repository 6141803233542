import * as React from 'react';
import { Layout } from './Layout';
import { useAppContext } from './AppContext';
import { UserDoc } from './model/userDoc';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Alert } from 'sancho/esm/Alert';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { GearSpinner } from './lib/GearSpinner';
import { Collapse } from './lib/Collapse';

interface SharedProjectsPageProps extends RouteComponentProps {}

const COURSE_ID = 'js-intro';
const teacherIds = {
	rKI3m2EJ9oYOu1EHu4U3ysuIS912: true,
};

const SharedProjectsPage = withRouter((props: SharedProjectsPageProps) => {
	let context = useAppContext();
	let prefix = useCoursePrefix();
	let [docNameFilter, setDocNameFilter] = React.useState('');

	let query = context.db.collection('user_docs').where('group', '==', 'user').where('sharing', '==', true);

	let [projects, projectsLoading, projectsError] = useCollectionData<UserDoc>(query, {idField: '_id'/* , snapshotListenOptions: {includeMetadataChanges: true} */});

	// React.useEffect(() => {
	if (projects && projects.length) {
		projects.sort((a, b) => (b.updated || 0) - (a.updated || 0));
		// projects = projects.filter(p => p.u);

		if (docNameFilter) {
			let filter = docNameFilter.toLowerCase();
			projects = projects.filter(project => {
				return (project.title && project.title.toLowerCase().indexOf(filter) > -1)
					|| (project.description && project.description.toLowerCase().indexOf(filter) > -1);
			});
		}
	}

	let studentProjects: UserDoc[] = [];
	let teacherProjects: UserDoc[] = [];

	if (projects) {
		for (let proj of projects) {
			if (proj.hidden) {
				continue;
			}
			if (teacherIds[proj.user_id]) {
				teacherProjects.push(proj);
			}
			else {
				studentProjects.push(proj);
			}
		}
	}

	let href = '';

	const lists = [
		{ heading: 'Student Projects', projects: studentProjects, showAuthor: true },
		{ heading: 'Teacher Projects/Examples', projects: teacherProjects, showAuthor: false },
	]

	return (
		<Layout className="SharedProjectsPage">
			<h1>Shared Projects</h1>

			<p>These projects have been shared by other students, or by Brian. Check them out!</p>

			<label style={{float: 'right'}}>
				{/* Search &nbsp; */}
				<input className="input" placeholder="Search" type="text" value={docNameFilter} onChange={e => setDocNameFilter(e.target.value)} />
			</label>


			{lists.map(list => <>
				{/* <h2>{list.heading}</h2> */}
				<Collapse tag="h2" className="" title={list.heading}>
					<table className="lessonTable codeTable">
						<tbody>
							<tr>
								<th>Title</th>
								{/* <th>ID</th> */}
								{list.showAuthor && <th>Author</th>}
								<th>Description</th>
								<th>Last Updated</th>
								{/* <th>Sharing</th> */}
								{/* Type */}
							</tr>

							{projectsLoading && <tr><GearSpinner center /*  center delay={300} */ /></tr>}
							{projectsError && <tr><Alert intent="danger" title={projectsError.message} /></tr>}

							{list.projects.map(doc => (
								href = `${prefix}/published/${doc.doc_name}`,
								<tr key={doc.doc_name}>
									<td><Link to={href}>{doc.title || 'Untitled Project'}</Link></td>
									{/* <td><Link to={href}>{doc.doc_name}</Link></td> */}
									{list.showAuthor && <td>{doc.author}</td>}
									<td style={{textAlign: 'left'}}>{doc.description}</td>
									<td>{new Date(doc.updated).toLocaleDateString()}</td>
									{/* <td>{doc.type}</td> */}
								</tr>
							))}
						</tbody>
					</table>
				</Collapse>

			</>)}

			{/* <p>
				<label><input type="checkbox" checked={hideBrians} onChange={() => setHideBrians(!hideBrians)}/> Hide Brian's</label>
			</p> */}


			{/* {result.loading && <Spinner label="Loading..." center delay={300} />}

			{result.value && result.value.map(doc => (
				doc.do
			))} */}
		</Layout>
	)
});

function randomString(len: number) {
	let str = ''
	while (str.length < len) {
	 str += (Math.random() * 36 | 0).toString(36)
	}
	return str
}

export { SharedProjectsPage, SharedProjectsPageProps }
