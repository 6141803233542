import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import { useAppContext, AppContext } from './AppContext';
import { useAuthState } from 'react-firebase-hooks/auth';
// import { useContext } from 'react';

function initFirebase(context: AppContext) {
	// firebase
	const app = firebase.initializeApp({
		apiKey: "AIzaSyBlwkGeVjYthdwcLHOtSUFpiywYom8cXmo",
		authDomain: "bmortenson-js-course.firebaseapp.com",
		databaseURL: "https://bmortenson-js-course.firebaseio.com",
		projectId: "bmortenson-js-course",
		// storageBucket: "bmortenson-js-course.appspot.com",
		messagingSenderId: "661456276350",
		appId: "1:661456276350:web:9dc7506152e9079e",
		measurementId: "G-0FPVZVVQWV",
	});

	firebase.analytics();

	context.firebase = app;
	// alternatives to hooks?
	context.auth = app.auth();
	context.db = app.firestore();

	// let authStateStr = sessionStorage.getItem('authState');
	// let authState: AuthState = authStateStr ? JSON.parse(authStateStr) : { authed: false };
	// return store(authState);

	// let authState = store({
	// 	user: null,

	// })


	// app.auth().onAuthStateChanged(user => {

	// },
	// error => {

	// })

	return context;

	// auth.onAuthStateChanged(setValue, setError);
}

const useDb = () => useAppContext().db;
const useAuth = () => useAppContext().auth;
const useAppAuthState = () => useAuthState(useAuth());
const useUser = () => useAppAuthState()[0];

const useIsAdmin = () => {
	let user = useUser();
	return user && user.uid == 'rKI3m2EJ9oYOu1EHu4U3ysuIS912';
}

export {
	initFirebase,
	useDb,
	useAppAuthState,
	useAuth,
	useUser,
	useIsAdmin,
}
