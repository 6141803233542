import * as React from 'react';
import { Layout } from './Layout';
import { MatchingQuestionProps, MatchingQuestion, QuestionStateManager, MatchingQuestionData } from './lib/MatchingQuestion';
import { PagedContent, Renderable } from './lib/PagedContent';
import { Button } from 'sancho/esm/Button';
import { useAssignment } from './model/assignment';
import { c } from './lib/classNames';
import { IconCheck } from 'sancho/esm/Icons/icons/IconCheck';
import { IconX } from 'sancho/esm/Icons/icons/IconX';
import { Collapse } from './lib/Collapse';
import { LinkButton } from './lib/LinkButton';
import { GearSpinner } from './lib/GearSpinner';

const COURSE_ID = 'js-intro';
const ASSN_ID = 'quiz.final';

const operatorQuestions: MatchingQuestionProps = {
	id: 'final.operators',
	name: 'operators',
	title: 'Operators',
	intro: 'Click on an operator on the left, then click on the matching description on the right.',
	asCode: true,
	questions: [
		{ text: '=', answer: <>{/* <strong>equals</strong>:  */}assigns the value on the right to the variable on the left</> },
		{ text: '+', answer: <>{/* <strong>plus</strong>:  */}adds the value on the right to the value on the left</> },
		{ text: '-', answer: <>{/* <strong>minus</strong>:  */}subtracts the value on the right from the value on the left</> },
		{ text: '*', answer: <>{/* <strong>times</strong>:  */}multiplies the value on the left by the value on the right</> },
		{ text: '/', answer: <>{/* <strong>divided by</strong>:  */}divides the value on the left by the value on the right</> },
		{ text: '+=', answer: <>{/* <strong>plus equals</strong>:  */}adds the value on the right to the value on the left, and assigns the new value to the variable on the left</> },
		{ text: '-=', answer: <>{/* <strong>minus equals</strong>:  */}subtracts the value on the right from the value on the left, and assigns the new value to the variable on the left</> },
		{ text: '==', answer: <><code>true</code> if the value on the left <strong>is equal to</strong> the value on the right, otherwise <code>false</code></> },
		{ text: '>', answer: <><code>true</code> if the value on the left <strong>is greater than</strong> the value on the right, otherwise <code>false</code></> },
		{ text: '<', answer: <><code>true</code> if the value on the left <strong>is less than</strong> the value on the right, otherwise <code>false</code></> },
		{ text: '>=', answer: <><code>true</code> if the value on the left <strong>is greater than or equal to</strong> the value on the right, otherwise <code>false</code></> },
		{ text: '<=', answer: <><code>true</code> if the value on the left <strong>is less than or equal to</strong> the value on the right, otherwise <code>false</code></> },
	],
}

const vocabQuestions: MatchingQuestionProps = {
	id: 'final.vocab',
	name: 'vocab',
	title: 'Vocabulary',
	intro: 'Click on an term on the left, then click on its definition on the right.',
	asCode: false,
	questions: [
		{ text: 'variable', answer: 'A named placeholder that can store a changeable value for your program to use' },
		{ text: 'function', answer: 'A reusable block of code in a program. A sort of mini-program that you can use to run the same code more than once' },
		{ text: 'string', answer: 'A sequence of text (letters, numbers, or other characters) that can be stored and used in your program' },
		{ text: 'array', answer: 'A list of values stored together in order. It can hold any number of items, and they can be added, removed, and changed.' },
		{ text: 'call', answer: 'To run the code in a function. This requires using parenthesis.' },
		{ text: 'if statement', answer: 'A statement that allows a block of code to run under some conditions, depending on whether something is true or false' },
		{ text: 'for loop', answer: 'A three-part statement that allows a block of code to run any number of times' },
	],
}

const vocab2Questions: MatchingQuestionProps = {
	id: 'final.vocab2',
	name: 'vocab2',
	title: 'More Vocabulary',
	intro: 'Click on an term on the left, then click on its definition on the right.',
	asCode: false,
	questions: [
		{ text: 'boolean', answer: <>A variable or value that is either <code>true</code> or <code>false</code></> /* , or an expression that evaluates to true or false */ },
		{ text: 'return', answer: 'To send a value out of a function back to the code that called it' },
		{ text: 'parameter', answer: 'A value that is provided to a function to change what the function does' },
		{ text: 'object', answer: 'A special data type that can store a group of related names and values together' },
		{ text: 'expression', answer: 'A snippet of code that evaluates to a single value.  It can include one or more variables, operators, literals, and functions.' },
		{ text: 'operator', answer: 'A symbol used to carry out a basic task like adding, assigning, or comparing' },
		{ text: 'literal', answer: <>A value specified directly in code, rather than through a variable. <code>"hello"</code>, <code>true</code>, and <code>42</code> are all examples.</>},
		// { question: 'animation', answer: '' },
		// { question: 'frame', answer: '' },
	],
}


const functionQuestions: MatchingQuestionProps = {
	id: 'final.functions',
	name: 'functions',
	title: 'Functions',
	intro: 'Click to match the name of a function on the left with a description of what it does on the right.',
	asCode: true,
	questions: [
		{ text: 'alert()', answer: 'Displays a pop-up message to the user' },
		{ text: 'prompt()', answer: 'Displays a pop-up message to the user asking them to type a value, and returns the value they typed' },
		{ text: 'console.log()', answer: 'Prints one or more values to the console' },
		{ text: 'array.push()', answer: 'Adds an item to the end of an array' },
		{ text: 'array.pop()', answer: 'Removes and returns one item from the end of an array' },
		{ text: 'array.shift()', answer: 'Removes and returns one item from the beginning of an array' },
		{ text: 'array.unshift()', answer: 'Adds an item to the beginning of an array' },
		{ text: 'Math.random()', answer: 'Returns a random number between 0 and 1' },
		{ text: 'Math.floor()', answer: 'Rounds down to the nearest whole number' },
	],
}


const canvasQuestions: MatchingQuestionProps = {
	id: 'final.canvas',
	name: 'canvas',
	title: 'Functions: Canvas',
	intro: 'Click to match the name of a function on the left with a description of what it does on the right.',
	asCode: true,
	questions: [
		{ text: 'canvas.stroke()', answer: 'Sets the color of lines and borders' },
		{ text: 'canvas.strokeWeight()', answer: 'Sets the line width of lines and borders' },
		{ text: 'canvas.noStroke()', answer: 'Causes shapes to be drawn without a border' },
		{ text: 'canvas.fill()', answer: 'Sets the color of shapes and text' },
		{ text: 'canvas.noFill()', answer: 'Causes shapes to be drawn without a fill color' },
		{ text: 'canvas.background()', answer: 'Fills the entire canvas with one solid color' },
		{ text: 'canvas.ellipse()', answer: 'Draws a circle or an ellipse on the canvas' },
		{ text: 'canvas.rect()', answer: 'Draws a square or a rectangle on the canvas' },
		{ text: 'canvas.text()', answer: 'Draws a line of text on the canvas' },
		{ text: 'canvas.textSize()', answer: 'Sets the size of the text to be drawn on the canvas' },
		{ text: 'canvas.textAlign()', answer: 'Sets how text should be aligned on the canvas' },
		{ text: 'canvas.line()', answer: 'Draws a single straight line on the canvas' },
		{ text: 'canvas.shape()', answer: 'Draws a polygon on the canvas' },
		{ text: 'canvas.changePosition()', answer: 'Causes everything to be drawn on the canvas relative to the coordinates given' },
		{ text: 'canvas.resetPosition()', answer: 'Causes everything on to be drawn on the canvas relative to the top corner (0, 0)' },
		{ text: 'canvas.animate()', answer: 'Runs a function over and over again in order to produce smooth animations' },
	],
}

let questionsMap: OMap<MatchingQuestionProps> = {};

const questionSets = [
	vocabQuestions,
	vocab2Questions,
	operatorQuestions,
	functionQuestions,
	canvasQuestions,
];

for (let questions of questionSets) {
	questionsMap[questions.name] = questions;
}

// const updateAnswers = (stateManager: QuestionStateManager) => {

// }

interface QuestionManagers {
	vocab?: QuestionStateManager
	vocab2?: QuestionStateManager
	operators?: QuestionStateManager
	functions?: QuestionStateManager
	canvas?: QuestionStateManager
	[key: string]: QuestionStateManager
}

interface FinalQuizData extends QuizDataInstance {
	versions: QuizDataInstance[],
}

interface QuizDataInstance {
	matches: {
		[group: string]: {
			[question: string]: string
		}
	}
	score: number
	possible: number
}

interface QuizResult {
	question: string
	correct: boolean
	yourAnswer: React.ReactNode
	answer: React.ReactNode
}

const FinalQuiz: React.FC = () => {
	let [showingAnswers, setShowingAnswers] = React.useState(false);
	// let [answersLoaded, setAnswersLoaded] = React.useState(false);
	let [questionManagers] = React.useState<QuestionManagers>(() => ({}));

	let [resultDraft, updateResultDraft] = React.useState<FinalQuizData>({matches: {}} as FinalQuizData);

	let [assignment, updateAssignment, assnLoading, assnError] = useAssignment(COURSE_ID, ASSN_ID, true);

	const applyAllAnswers = (data: Pick<FinalQuizData, 'matches'>) => {
		Object.entries(questionManagers).forEach(([name, manager]) => {
			let matches = data.matches[manager.questionName];
			if (matches) {
				manager.applyAnswers(matches);
			}
		});
	};

	React.useEffect(() => {
		console.log("FinalQuiz: useEffect", assignment, assnLoading, assnError);
		// debugger
		if (assnLoading || assnError) {
			console.log("loading or error", assnLoading, assnError);
			return;
		}
		if (assignment && assignment.data) {
			updateResultDraft(assignment.data);
			applyAllAnswers(assignment.data);
		}
		else {
			updateResultDraft({
				score: 0,
				possible: 0,
				matches: {
					vocab: {},
					vocab2: {},
					operators: {},
					functions: {},
					canvas: {},
				},
				versions: [],
			});
		}
			// setting new assignment
			// assignment = {
			// 	courseId: COURSE_ID,
			// 	data: null,
			// 	id: ASSN_ID,
			// 	updated: 0,
			// 	userDisplayName: '',
			// 	userId: '',
			// };
		// }
		// if (assignment) {
		// if (!assignment.data) {
		// 	console.log("initializing assignment data");
		// 	assignment.data =  as QuizDataInstance;
		// }
		// else {
		// 	console.log("loading assignment answers");

		// 	let quizData = assignment.data as QuizDataInstance;
		// 	Object.entries<QuestionStateManager>(questionManagers).forEach(([name, manager]) => {
		// 		manager.applyAnswers(quizData.matches[name]);
		// 	});
		// }
		// }
	}, [/* assignment,  */assnLoading, assnError]);

	// let [userDoc, updateUserDoc, loading, error] = useUserDoc(COURSE_ID, ASSN_ID, true);

	// useDocumentData(fire)

	// React.useEffect(() => {
	// 	if (userDoc) {
	// 		// happens once after init.
	// 		// if (!answersLoaded) {
	// 		// 	answersLoaded = true;
	// 		// }
	// 		if (questionManagers.canvas) {
	// 			questionManagers.canvas.applySaved(userDoc.
	// 		}

	// 	}
	// }, [userDoc ? userDoc.doc_name : null]);

	let submitQuiz;
	if (!showingAnswers) {
		submitQuiz = () => {
			saveProgress(true);
			// getUserDocRef(COURSE_ID, )
			// let docRef = firebase.firestore().doc(`user_docs/${courseId}_${userId}_${docName}`)
			setShowingAnswers(true);
		}
	}

	const saveProgress = (newVersion = false) => {
		// debugger
		// let assignmentData: FinalQuizData = assignment ? assignment.data : null;

		let matches: OMap<OMap> = {...resultDraft.matches};
		let score = 0;
		let possible = 0;

		Object.entries(questionManagers).forEach(([name, manager]) => {
			let answers = manager.serializeAnswers();
			matches[name] = answers;
			for (let key in answers) {
				if (typeof key == 'string' && answers[key] === key) {
					score++;
				}
			}
			possible += manager.questions.length;
		});

		resultDraft.matches = matches;
		resultDraft.score = score;
		resultDraft.possible = possible;

		if (newVersion) {
			resultDraft.versions.push({
				matches,
				score,
				possible,
			});
		}

		updateAssignment(resultDraft);
		updateResultDraft(resultDraft);
		// return assignmentData;
	}

	let pages: Renderable[] = questionSets.map(questions =>
		<>
			<MatchingQuestion key={questions.id} {...questions}
				onInit={questionManager => {
					let qName = questionManager.questionName;
					questionManagers[qName] = questionManager;

					let matches = resultDraft.matches[qName];

					// debugger
					if (matches) {
						questionManager.applyAnswers(matches);
					}
				}
			} />
			<p>Press 'Next' when you have selected all of your answers.</p>
		</>
	);

	pages.unshift(() => {
		let lastScore = '';
		if (resultDraft && resultDraft.versions && resultDraft.versions.length) {
			let lastVersion = resultDraft.versions[resultDraft.versions.length - 1];
			lastScore = `${lastVersion.score} out of ${lastVersion.possible}`;
		}
		return <>
			<p>
				It's time for one last quiz!
				This one is designed to cover everything we have learned.
				If you don't remember something, just do your best – you can retake it again if you want.
			</p>

			{lastScore && <p>
				Your last score: {lastScore}
			</p>}
		</>;
	});

	pages.push((page, goTo) => <>
		<h2>Finished!</h2>


		{!showingAnswers && <>
			<p>
				You're at the end! Press "Back" to review your answers, or press "Submit" to submit and see how you did.
			</p>

			<Button size="lg" intent="primary" onPress={submitQuiz}>Submit</Button>
		</>}

		{showingAnswers && <React.Fragment key="submit">
			<p>
				Great job! You scored <strong>{resultDraft.score}</strong> out of <strong>{resultDraft.possible}</strong>.
				You can review the answers below.
			</p>
			{/* {questionSets.map(questions => (

			))} */}
			{questionSets.map((questionSet/* , qi */) => {
				let questionAnswerTextMap: OMap<React.ReactNode> = {};

				questionSet.questions.forEach(question => {
					questionAnswerTextMap[question.text] = question.answer;
				})

				let total = 0;
				let possible = 0;

				// let matches: FinalQuizData = assignment && assignment.data.matches[questionSet.name] || {};
				let matches: OMap = resultDraft.matches[questionSet.name] || {};

				let results: QuizResult[] = [];

				for (let question of questionSet.questions) {
					let correct = matches[question.text] == question.text;
					let yourAnswer = correct ? null : questionAnswerTextMap[matches[question.text]] || '(No answer)';

					possible++;
					correct && total++;

					results.push({
						question: question.text,
						answer: question.answer,
						correct: correct,
						yourAnswer: yourAnswer,
					});
				}

				// let questionStateManager = questionManagers[name];
				return (<>
					<Collapse /* collapsed={qi > 0} */ title={`${questionSet.title} – ${total}/${possible}`}
						// header={<><h2>{questionSet.title}</h2><h3>You got {total} out of {possible} correct!</h3></>}
					>
						<div className="MatchingQuestion results">
							{results.map(result => (
								<div key={result.question} className={c('matched', result.correct ? 'correct' : 'incorrect')}>
									<strong className={c('question', questionSet.asCode && 'code')}>
										{result.question}
									</strong>
									{!result.correct && <>
										<div className="ans-incorrect">
											<IconX color="red" size="lg" />

											{/* <span className="check-mark" /> */}
											<strong>You said: </strong>
											{result.yourAnswer}
										</div>
									</>}
									<div className={result.correct && "ans-correct"}>
										<IconCheck color="green" size="lg" />

										{/* <span className="check-mark" /> */}
										<strong>Correct answer: </strong>
										{result.answer}
									</div>
									{/* <span className="ans">
										{questionState.selectedMatch.result.answer}
									</span> */}
								</div>
							))}
						</div>
					</Collapse>
				</>)
			})}

			<p>
				Great job! Your results have been saved.
				If you would like to start over and improve your score,
				click the button below to take the quiz again.
			</p>

			<Button size="lg" intent="primary" onPress={() => {
				let newDraft: FinalQuizData = {
					score: 0,
					possible: 0,
					matches: {
						vocab: {},
						vocab2: {},
						operators: {},
						functions: {},
						canvas: {},
					},
					versions: resultDraft.versions,
				};

				setShowingAnswers(false);
				updateResultDraft(newDraft);
				applyAllAnswers(newDraft);

				goTo(0);
			}}>Start Over</Button>

			{' '} <LinkButton buttonProps={{size: 'lg'}} to="">Home</LinkButton>
		</React.Fragment>}
	</>)

	return <Layout narrow>
		<h1>Final Quiz</h1>

		{assnLoading && <GearSpinner />}

		{!assnLoading && <>

			{/* <MatchingQuestion {...vocabQuestions} onInit={stateManager => {
				questionManagers.vocab = stateManager
				// if (!init) {

				// }
			}} /> */}
			<PagedContent noHome pages={pages} beforeChange={() => saveProgress()} />
		</>}
	</Layout>
};

export { FinalQuiz }
