import * as React from 'react';
import { CanvasPage } from './CanvasPage';
import { PrefixedLink } from './lib/PrefixedLink';

const BlankCanvas = () => (
	<CanvasPage
		buttons
		pageContent={
			<div>
				<h2>Just a canvas.</h2>
				<p>
					Here's a canvas to work with.
					You can build anything you like.
					This is for you to make your own projects.
				</p>
				<p>
					Use canvas functions like <code>canvas.rect()</code>, <code>canvas.ellipse()</code>,
					and <code>canvas.line()</code> to draw on it.
				</p>

				<p>For help, see the <PrefixedLink to="/lesson/canvas">canvas lesson</PrefixedLink>.</p>
			</div>
		}
		code={`
			// write your canvas code here
			// canvas.background('skyblue');
		`}
		docName="blank-canvas"
	/>
);

export { BlankCanvas };
