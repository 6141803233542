import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom'
import { PrefixedLink } from './lib/PrefixedLink';
import { Collapse } from './lib/Collapse';
import { Emoji } from './lib/Emoji';

interface CourseMaterialsListProps {
	classStr: string
}

const CourseMaterialsList: React.FC<CourseMaterialsListProps> = ({classStr}) => {
	let svla = classStr == 'svla';
	let ahsj = classStr == 'ahsj';

	return (<>
		<Collapse tag="h2" className="hBlue bigger" title="Spring 2020">
			<Collapse title="Week 14: Practice Time">
				<p>
					There is no lesson for today!
					Instead, you'll have time to work on your final projects,
					and I'll be helping you guys find bugs and solve problems
					in them.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 13: Arrays/Bouncing Dots">
				<BigLink to={`/${classStr}/lesson/bouncing-dots`}>Bouncing Dots</BigLink>
				<p>
					Today, we'll review arrays, then we'll use them to make a program
					that can have any number of bouncing dots on the screen.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 12: More about Functions">
				<BigLink to={`/${classStr}/lesson/functions2`}>More about Functions</BigLink>
				<p>
					Today, we'll learn and practice some new things about functions.
					Functions are one of the key building blocks of any program, and it's important
					to know how to use them correctly!
				</p>
			</Collapse>

			<Collapse collapsed title="Week 11: Final Project">
				<BigLink to={`/${classStr}/lesson/final-project`}>Final Project Info</BigLink>
				<p>
					Today, my main goal is to talk about a final project.
					Depending on how much time we have left after that, I can show you something new,
					or just help your guys with your own projects and questions.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 10: Colors">
				<BigLink to={`/${classStr}/lesson/colors`}>All About Colors</BigLink>
				<p>
					It's quarantine week <Emoji e="🦠" />. Stay safe everyone!
				</p>
				<p>
					This week, we will learn some of the science of how colors are made,
					and how to use RGB hex codes in our programs.
				</p>
				<p>
					Coming later or next week, I'll be adding information about how to use HSL colors.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 9: Transformations">
				<BigLink to={`/${classStr}/lesson/transformations`}>Transformations</BigLink>
				<p>
					This week, we will learn some ways to move, resize, and rotate shapes on the screen.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 8: Typing game continued">
				<BigLink to={`/${classStr}/lesson/typing-game/9`}>Typing Game</BigLink>
				<p>
					This week, we will add some new features, such as a timer, to our typing game.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 7: Typing game">
				<BigLink to={`/${classStr}/lesson/typing-game`}>Typing Game</BigLink>
				<p>
					This week, we will start a new project that we'll work on for a few weeks: A typing game.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 6: Debugging">
				<BigLink to={`/${classStr}/lesson/debugging`}>Debugging</BigLink>
				<p>
					This week, we will learn more about errors in our programs, and how to fix them.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 5: Challenges Continued">
				<BigLink to={`/${classStr}/lesson/practice-challenges`}>Practice Challenges</BigLink>
				<p>
					This week, we will continue to hone our skills with the code challenges.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 4: Challenges">
				{/* <h3>News:</h3>
				<p>
					Shared projects now have a remix button: Just click the Remix button
					to create your own copy of a project that you can modify as you want!
					Try it out with a <PrefixedLink to="/shared-projects">shared project</PrefixedLink>.
				</p> */}
					{/* Try it out with this <PrefixedLink to="/published/pr3nc9o8">game starter</PrefixedLink>. */}
				<BigLink to={`/${classStr}/lesson/practice-challenges`}>Practice Challenges</BigLink>
				<p>
					This week, we will hone our skills with some fun code challenges.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 3: Grids with 2D arrays">
				{/* <h3>News:</h3>
				<p>
					Shared projects now have a remix button: Just click the Remix button
					to create your own copy of a project that you can modify as you want!
					Try it out with a <PrefixedLink to="/shared-projects">shared project</PrefixedLink>.
				</p> */}
					{/* Try it out with this <PrefixedLink to="/published/pr3nc9o8">game starter</PrefixedLink>. */}
				<BigLink to={`/${classStr}/lesson/grids`}>Lesson: Grids</BigLink>
				<p>
					In this lesson, we learn how to represent two-dimensional grids
					for maps, mazes, and more.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 2: Sounds">
				{/* <h3>News:</h3>
				<p>
					Shared projects now have a remix button: Just click the Remix button
					to create your own copy of a project that you can modify as you want!
					Try it out with a <PrefixedLink to="/shared-projects">shared project</PrefixedLink>.
				</p> */}
					{/* Try it out with this <PrefixedLink to="/published/pr3nc9o8">game starter</PrefixedLink>. */}
				<BigLink to={`/${classStr}/lesson/sounds`}>Lesson: Sounds</BigLink>
				<p>
					In this lesson, we learn how to create sounds for music and sound effects.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 1: Strings">
				{/* <h3>News:</h3>
				<p>
					Shared projects now have a remix button: Just click the Remix button
					to create your own copy of a project that you can modify as you want!
					Try it out with a <PrefixedLink to="/shared-projects">shared project</PrefixedLink>.
				</p> */}
					{/* Try it out with this <PrefixedLink to="/published/pr3nc9o8">game starter</PrefixedLink>. */}
				<BigLink to={`/${classStr}/lesson/strings`}>Lesson: String functions</BigLink>
				<p>
					In this lesson we learn about some essential string functions:
					<code>string.toUpperCase()</code>,
					<code>string.toLowerCase()</code>,
					<code>string.indexOf()</code>,
					<code>string.length</code>, and
					<code>string.replace()</code>.
				</p>
			</Collapse>
		</Collapse>

		<Collapse tag="h2" className="hBlue bigger" collapsed title="Fall 2019">
			<Collapse collapsed title="Week 1: Hello & Variables">
				<BigLink to={`/${classStr}/lesson/hello-world`}>Lesson: Hello, World</BigLink>
				<BigLink to={`/${classStr}/lesson/variables`}>Lesson: Variables</BigLink>
				<BigLink to={`/${classStr}/project/mad-libs`}>Project: Mad Libs (Variables)</BigLink>
				<BigLink to={`/${classStr}/quiz/variables`}>Quiz: Variables</BigLink>
			</Collapse>

			<Collapse collapsed title="Week 2: Functions">
				<BigLink to={`/${classStr}/lesson/function-calls`}>Lesson: Function Calls</BigLink>
				<BigLink to={`/${classStr}/project/interactive-mad-libs`}>Project: Interactive Mad Libs</BigLink>
				<BigLink to={`/${classStr}/introduce-game-studio`}>Extra time: Game studio</BigLink>
			</Collapse>

			<Collapse collapsed title="Week 3: If Statements">
				{/* <BigLink to={`/${classStr}/introduce-game-studio`}>Extra time: Game studio</BigLink> */}
				{/* <BigLink to={`/${classStr}/lesson-game-studio`}>Extra time: Game studio</BigLink> */}
				<BigLink to={`/${classStr}/lesson/if-statements`}>Lesson: If Statements</BigLink>
				{/* <BigLink to={`/${classStr}/lesson/if-statements/2`}>Lesson: If Statements</BigLink> */}
				<BigLink to={`/${classStr}/project/quiz-game`}>Project: Quiz Game part 1</BigLink>
				<BigLink to={`/${classStr}/quiz/if-statements`}>Quiz: If Statements</BigLink>

				{svla ? <h3>Bonus</h3> : <br style={{lineHeight: 0}}/>}
				<BigLink to={`/${classStr}/lesson/functions`}>Lesson: Writing Functions</BigLink>
				<BigLink to={`/${classStr}/project/quiz-game/2`}>Project: Quiz Game part 2</BigLink>
				<BigLink to={`/${classStr}/quiz/functions`}>Quiz: Functions</BigLink>
			</Collapse>

			<Collapse collapsed title="Week 4: Math/Canvas">
				<BigLink to={`/${classStr}/lesson/math-operators`}>Lesson: Math Operators</BigLink>
				<BigLink to={`/${classStr}/project/simple-calc`}>Project: Calculator</BigLink>
				<BigLink to={`/${classStr}/quiz/math-operators`}>Quiz: Math Operators</BigLink>

				<br style={{lineHeight: 0}}/>

				<BigLink to={`/${classStr}/lesson/canvas`}>Lesson: Canvas</BigLink>
				{/* <BigLink to={`/${classStr}/project/draw`}>Project: Draw</BigLink> */}
				<BigLink to={`/${classStr}/canvas/review`}>Review: Canvas</BigLink>

				<PrefixedLink to="/colors">Colors page</PrefixedLink>
			</Collapse>

			<Collapse collapsed title="Week 5: Events">
				<BigLink to={`/${classStr}/lesson/events`}>Lesson: Events</BigLink>
			</Collapse>
			{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
			{/* <BigLink to={`/${classStr}/quiz/math-operators`}>Quiz: Math Operators</BigLink> */}

			<Collapse collapsed title="Week 6: Loops">
				<BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink>
				<BigLink to={`/${classStr}/lesson/loops-review`}>Review: Loops</BigLink>
				{/* <BigLink to={`/${classStr}/lesson/arrays`}>Lesson: Arrays</BigLink> */}

				<BigLink to={`/${classStr}/user/projects`}>Extra time: Your projects</BigLink>

			</Collapse>

			<Collapse collapsed title="Week 7: Arrays">
				{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
				<BigLink to={`/${classStr}/lesson/arrays`}>Lesson: Arrays</BigLink>
				<BigLink to={`/${classStr}/project/sokoban`}>Project: Sokoban</BigLink>
			</Collapse>

			<Collapse collapsed title="Week 8: Drawing Shapes">

				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
				<BigLink to={`/${classStr}/lesson/canvas-2`}>Lesson: More Shapes with Canvas</BigLink>

				<p>
					In this lesson, you'll learn to draw triangles and other shapes with <code>canvas.shape()</code>.
				</p>

				<h3>Practice Time</h3>
				<p>Individual time to work on Sokoban or other unfinished projects, or a project of your own</p>


				<h3>Project Brainstorm</h3>
				<p>Brainstorm and discuss with the class ideas for a project you'd like to work on over the course of a few weeks.</p>
				{/* <BigLink to={`/${classStr}/lesson/math-random`}>Lesson: Math.random() and Friends</BigLink>
				<p>
					In this lesson, you'll learn how to get a random number, and how that can be useful
					in a game or any other program.
				</p> */}
			</Collapse>

			<Collapse collapsed title="Week 9: Arrays II">
				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
				<BigLink to={`/${classStr}/lesson/arrays-2`}>Lesson: Arrays: Adding and removing items</BigLink>

				{/* <p>
					This week we'll learn a few more tools for managing arrays.
					If there's time we'll talk about some useful building blocks for making games and putting it all together.
				</p> */}

				{/* <p>This week we'll talk about some useful building blocks for making games and putting it all together.</p> */}





				{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
				{/* <BigLink to={`/${classStr}/lesson/variable-scope`}>Lesson: Long-lived variables</BigLink> */}

				<h3>Paint/Practice Time</h3>
				<p>I will walk through starting a paint project, or you can work on a project of your own.</p>

				<BigLink to={`/${classStr}/project/shopping-list`}>Homework: Shopping List</BigLink>
				<p>Use the functions you learned this week to complete a shopping list you can edit.</p>
			</Collapse>

			<Collapse collapsed title="Week 10: Booleans">
				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
				<BigLink to={`/${classStr}/lesson/and-or`}>Lesson: Booleans, AND, and OR</BigLink>
				{/* <p>In this lesson we'll learn how to combine true and false statements.</p> */}

				<p>
					For homework, finish up <PrefixedLink to="/lesson/and-or/2">Part 2</PrefixedLink> of the lesson.
					The Bonus Challenges are optional, but I would at least give them a try.
				</p>

				<h3>Project Practice Time</h3>
				<p>More time to work on a paint project, or another project of your own.</p>
			</Collapse>

			<Collapse collapsed title="Week 11: Animation I">
				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				{/* <BigLink to={`/${classStr}/lesson/loops`}>Lesson: Loops</BigLink> */}
				<BigLink to={`/${classStr}/lesson/animation`}>Lesson: Animation, part 1</BigLink>

				{/* <p>
					For homework, finish up <PrefixedLink to="/lesson/and-or/2">Part 2</PrefixedLink> of the lesson.
					The Bonus Challenges are optional, but I would at least give them a try.
				</p> */}

				<h3>Practice Time</h3>
				<p>This lesson will take up the whole class, I promise!</p>

				<h3>Homework</h3>
				<p>
					Once you get to the end of the lesson, keep working on your animation!
					You can also copy your code into <PrefixedLink to="/user/projects">your own project</PrefixedLink>
					{' '} and work on it there.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 12: Free Project">
				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				{svla && <>
					<BigLink to={`/${classStr}/lesson/animation`}>Lesson: Animation, part 1</BigLink>
					<p>We'll keep working on last week's animation for part of the day.</p>
				</>}

				<BigLink to={`/${classStr}/lesson/free-project`}>Free Project</BigLink>
				<p>
					Today you are going to work on a project of your choice!
					Click the lesson link to learn more.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 13: Objects">
				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				<BigLink to={`/${classStr}/lesson/objects`}>Objects</BigLink>
				<p>
					Today we will learn about objects. What is an object? Click the link to find out.
				</p>

				<BigLink to={`/${classStr}/lesson/free-project`}>Free Project</BigLink>
				<p>
					After you finish the objects lesson, you may keep working on your free project.
				</p>
			</Collapse>

			<Collapse collapsed title="Week 14: Random Numbers">
				<h3>Show and Tell</h3>
				<p>Sharing projects with the class</p>

				<BigLink to={`/${classStr}/lesson/random`}>Random Numbers</BigLink>
				<p>
					Today we will learn why and how to make use of random numbers,
					and do some fun things with them.
				</p>

				<BigLink to={`/${classStr}/lesson/free-project`}>Free Project</BigLink>
				<p>
					If you finish the random numbers lesson, you may keep working on your free project{ahsj && <>,
					or you may do the Week 15/Bonus lesson below.
					</>}.
				</p>
			</Collapse>

			<Collapse title="Week 15: Animation II">
				{ahsj && <>
					<p>
						Since we only had 14 weeks of class instead of 15, this is a bonus lesson that you can
						do on your own time.
					</p>
				</>}

				{svla && <>
					<h3>Show and Tell</h3>
					<p>Sharing projects with the class</p>
				</>}

				<BigLink to={`/${classStr}/lesson/game-loop`}>Animating with canvas.animate()</BigLink>
				<p>
					In this lesson you'll learn how to make fast, smooth animations.
				</p>

				{/* <BigLink to={`/${classStr}/lesson/free-project`}>Free Project</BigLink>
				<p>
					If you finish the random numbers lesson, you may keep working on your free project.
				</p> */}
			</Collapse>

			<h2>Final Quiz</h2>
			<p>
				It's a quiz! It's a final! It's a final quiz!
			</p>

			<p>
				Please <PrefixedLink to="/quiz/final">take the quiz</PrefixedLink>,
				and may your Christmas be merry and bright.
			</p>
		</Collapse>

		<h2>Other Stuff</h2>
		<p><PrefixedLink to="/colors">Colors page</PrefixedLink> A reference with all the named colors, as well as how to use RGB colors, and a color picker.</p>
		<p>
			<PrefixedLink to="/keyboard-shortcuts">Keyboard shortcuts</PrefixedLink>
			{' '}A handy reference to keyboard shortcuts you can use in the code editor.
		</p>

		{ahsj && <>
			<p><PrefixedLink to="/ai-pong">Pong Bots</PrefixedLink> Write an AI to play pong.</p>
			<p><a target="_blank" href="https://codesandbox.io/s/little-lake-ef5dv">Tic Tac Toe bots</a> Write an AI to play tic-tac-toe.  Soon to be a project here.</p>
		</>}

		{/* <h2>Upcoming</h2>
		<BigLink disabled to={`/${classStr}/lesson/strings`}>Lesson: More about Strings</BigLink>
		<BigLink disabled to={`/${classStr}/project/paint`}>Project: Guessing Game</BigLink>
		<BigLink disabled to={`/${classStr}/project/paint`}>Project: Paint Program</BigLink>
		<BigLink disabled to={`/${classStr}/project/tictactoe`}>Project: Tic Tac Toe</BigLink> */}

		{/*
			ideas:
			hangman style game
			dice game
			canvas drawing

			topics:
			canvas.shape()
			&&, ||
			Math.random()
			String.toLowerCase
			String.toUpperCase
			String.indexOf
			String.slice
			`` strings
			Objects
			null
			animation loop
			scope
			continue/break
			callbacks/anonymous functions?
			ternary
		*/}
	</>);
}

interface BigLinkProps extends LinkProps {
	disabled?: boolean
}

// const Span: React.FC<React.HTMLProps<HTMLSpanElement>> = (props) => <span {...props} />;

const BigLink: React.FC<BigLinkProps>  = (props) => {
	let {disabled, children, ...linkProps} = props;

	// let Container = disabled ? Span : Link;
	if (!disabled) {
		return (
			<Link {...linkProps}>
				<h3>{children}</h3>
			</Link>
		);
	}
	else {
		return <InlineHeader children={children} afterContent={<span> 🔒</span>}/>
	}
}

// const doThing:

interface ElementProps<K extends keyof HTMLElementTagNameMap, T extends HTMLElementTagNameMap[K]> extends React.HTMLProps<T> {
	tagName: K
}
// interface ElementProps<K extends keyof HTMLElementTagNameMap, T extends HTMLElementTagNameMap[K],> extends JSX.IntrinsicElements[K] {
// 	tagName: K
// }

// type Element<K extends keyof HTMLElementTagNameMap/* , T extends HTMLElementTagNameMap[K] */> = React.FC<ElementProps<K, HTMLElementTagNameMap[K]>>;

/*const Element : Element<K extends keyof HTMLElementTagNameMap> */
function Element<K extends keyof HTMLElementTagNameMap>(props: ElementProps<K, HTMLElementTagNameMap[K]>) {
	let {tagName, ...rest} = props;
	return React.createElement(tagName, props)
}

interface InlineHeaderProps/* <K extends keyof React.ReactHTML> */ {
	tagName?: keyof React.ReactHTML
	beforeContent?: React.ReactNode
	afterContent?: React.ReactNode
}
const InlineHeader: React.FC<InlineHeaderProps/* <keyof React.ReactHTML> */> = (props) => {
	let children = props.children
	let Component = props.tagName || 'h3';
	return <div style={{margin: '1.25em 0'}}>
		{props.beforeContent}
		<Component style={{display: 'inline-block', margin: 0}}>{children}</Component>
		{props.afterContent}
	</div>
}

export { CourseMaterialsList }
