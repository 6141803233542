import * as React from 'react'

interface SplitPaneProps {
	leftContent: JSX.Element,
	rightContent: JSX.Element,
}

const SplitPane: React.FC<SplitPaneProps> = (props) => (
	<div className="SplitPane">
		<div className="pane left">{props.leftContent}</div>
		<div className="pane right">{props.rightContent}</div>
	</div>
);

export { SplitPane, SplitPaneProps }
