/**
 * @typedef {import("../ahs-js-course/lib/CanvasDemo").CanvasDemoFn} CanvasDemoFn
 * @type CanvasDemoFn
 */
export default function(canvas) {
	var nextLetter = getRandomLetter();
	// var score = 0;

	// canvas.animate(draw);
	draw();

	function draw() {
		canvas.background('white');

		canvas.textAlign('center', 'middle');
		canvas.textSize(128);
		canvas.text(nextLetter, 200, 200);

		canvas.textSize(40);
		canvas.textAlign('right', 'top');

		// draw score
		// canvas.text(score, 390, 10);
	}

	function whenKeyPressed(key) {
		if (key == nextLetter) {
			// score++;
			nextLetter = getRandomLetter();
		}
		else {
			// score--;
		}
		draw();
	}

	function getRandomLetter() {
		// return String.fromCharCode(97 + Math.floor(Math.random() * 26));
		var letters = 'aaaaabbcccdddeeeeeeeffgghhiiijkklllmmnnnnoooppqrrrrssssttttuuvwwxyyz';
		// var letters = 'abcdefghijklmnopqrstuvwxyz';
		var n = Math.floor(Math.random() * letters.length);
		return letters[n];
	}

	return { whenKeyPressed }
}
