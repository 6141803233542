import * as React from 'react'
import { useCoursePrefix } from './useCoursePrefix';
import { LinkProps, Link } from 'react-router-dom';

export const PrefixedLink: React.FC<LinkProps> = (props) => {
	let {to, ...rest} = props;
	let prefix = useCoursePrefix();
	to = prefix + (to as string);

	return <Link to={to} {...rest} />
};
