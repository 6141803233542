import * as React from 'react';
import { useAppContext } from './AppContext';
import { UserDoc, getUserDocRef } from './model/userDoc';
import { Button } from 'sancho/esm/Button';
import { Alert } from 'sancho/esm/Alert';
import { withRouter, RouteComponentProps } from 'react-router';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { useUserDoc } from './model/userDoc';
import { CanvasPage } from './CanvasPage';
import { LinkButton } from './lib/LinkButton';
import { outdent } from './CodeBox';
import { PrefixedLink } from './lib/PrefixedLink';

interface UserProjectPageProps extends RouteComponentProps<{docName: string}> {}

const COURSE_ID = 'js-intro';

const UserProjectPage = withRouter((props: UserProjectPageProps) => {
	let prefix = useCoursePrefix();
	let context = useAppContext();
	// let uid = context.auth.currentUser ? context.auth.currentUser.uid : '';
	let docName = props.match.params.docName;

	let [doc, _updateDoc, docLoading, docError] = useUserDoc(COURSE_ID, docName);
	let [editingTitle, setEditingTitle] = React.useState(false);
	let [savingTitle, setSavingTitle] = React.useState(false);
	let [updatedTitle, setUpdatedTitle] = React.useState('');
	let [titleTextDraft, setTitleTextDraft] = React.useState(doc ? doc.title : '');

	let [editingDescription, setEditingDescription] = React.useState(false);
	let [savingDescription, setSavingDescription] = React.useState(false);
	let [updatedDescription, setUpdatedDescription] = React.useState('');
	let [descriptionTextDraft, setDescriptionTextDraft] = React.useState(doc ? doc.description : '');

	let [updatedSharing, setUpdatedSharing] = React.useState<boolean>(undefined);
	let [savingSharing, setSavingSharing] = React.useState(false);
	// let prefix = useCoursePrefix();

	let projectTitle = updatedTitle || ((docLoading) ?  '' : (doc && doc.title || 'Untitled Project'));
	let projectDescription = updatedDescription || ((docLoading) ?  '' : (doc && doc.description || 'Add a project description'));

	let sharing = typeof updatedSharing == 'boolean' ? updatedSharing : (doc && doc.group == 'user' && doc.sharing == true);
	let shareUrl = '';
	if (sharing == true) {
		shareUrl = `${location.origin}/published/${doc ? doc.doc_name : ''}`;
	}



	const editTitle = () => {
		setEditingTitle(true);
		setTitleTextDraft(projectTitle);
	};

	const saveTitle = async (e: React.FormEvent) => {
		e.preventDefault();
		setSavingTitle(true);
		let updateObj = appendAuthor({title: titleTextDraft});
		await getUserDocRef(COURSE_ID, context.auth.currentUser.uid, docName).update(updateObj);
		setUpdatedTitle(titleTextDraft);
		setSavingTitle(false);
		setEditingTitle(false);
	}

	const editDescription = () => {
		setEditingDescription(true);
		setDescriptionTextDraft(projectDescription);
	};

	const saveDescription = async (e: React.FormEvent) => {
		e.preventDefault();
		setSavingDescription(true);
		let updateObj = appendAuthor({description: descriptionTextDraft});
		await getUserDocRef(COURSE_ID, context.auth.currentUser.uid, docName).update(updateObj);
		setUpdatedDescription(descriptionTextDraft);
		setSavingDescription(false);
		setEditingDescription(false);
	}

	const toggleSharing = async () => {
		let msg: string;
		if (!sharing) {
			msg = `
			Do you want to share this project?
			This will add the project to the class shared projects page.

			By clicking OK, you are saying that this project is appropriate to share with the class.
			Anything rude or inapproriate will NOT be tolerated.`;
		}
		else {
			msg = `
			Do you want to unshare this project?
			Anybody who has had a link to it will longer be able to see it.`
		}

		if (!confirm(outdent(msg))) {
			return;
		}

		sharing = !sharing;
		setSavingSharing(true);
		let updateObj = appendAuthor({sharing});
		await getUserDocRef(COURSE_ID, context.auth.currentUser.uid, docName).update(updateObj);
		setSavingSharing(false);
		setUpdatedSharing(sharing);
	}

	const appendAuthor = (updateObj: Partial<UserDoc>) => {
		if (doc && !doc.author && context.auth.currentUser.displayName) {
			let name = context.auth.currentUser.displayName;
			let space = name.indexOf(' ');
			if (space > -1) name = name.slice(0, space);
			updateObj.author = name;
		}

		return updateObj;
	}

	const deleteProject = async () => {
		if (!confirm("Are you sure you want to delete this project? This is can't be undone.")) return;

		try {
			await getUserDocRef(COURSE_ID, context.auth.currentUser.uid, docName).delete();

			alert('Your project has been deleted');

			props.history.replace(prefix + '/user/projects');
		}
		catch (e) {
			alert("Your project couldn't be deleted: " + e.message);
		}
	}

	return <CanvasPage
		pageContent={
			<div>
				{editingTitle? (
					<form onSubmit={saveTitle}>
						<h1>
							<input autoFocus value={titleTextDraft} onChange={e => setTitleTextDraft(e.target.value)}/>
						</h1>
						<Button type="submit" disabled={savingTitle}>Save</Button>
					</form>
				) : (
					<>
						<h1>{projectTitle}</h1>
						<a onClick={editTitle}>Edit title</a>
					</>
				)}

				{editingDescription? (
					<form onSubmit={saveDescription}>
						<textarea rows={6} autoFocus value={descriptionTextDraft} onChange={e => setDescriptionTextDraft(e.target.value)} />

						<Button type="submit" disabled={savingDescription}>Save</Button>
					</form>
				) : (
					<>
						<p style={{whiteSpace: 'pre-line'}}>{projectDescription}</p>
						<a onClick={editDescription}>Edit description</a>
					</>
				)}

				{(doc && doc.parent) && <p>
					Remixed from {' '}
					<PrefixedLink to={`/published/${doc.parent.doc_name}`}>{doc.parent.title}</PrefixedLink>
				</p>}

				{docError && <Alert intent="danger" title="This project could not be loaded">
					Try refreshing the page.
					<small>{docError.message}</small>
				</Alert>}

				<p>This is your own custom project. You can do whatever you like with it! </p>

				{/* {typeof sharing === 'boolean' && <> */}
					<h3>Sharing</h3>
					{sharing
						? <>You are sharing this project.  Anyone can view it at <a target="_blank" href={shareUrl}>{shareUrl}</a>.</>
						: <>You are not sharing this project.</>
					}
					<br/>
					<br/>
					<Button disabled={savingSharing} intent="primary" onPress={toggleSharing}>{sharing ? 'Stop Sharing' : 'Share'}</Button>
					<br/>
					<br/>
					<Button disabled={savingSharing} intent="danger" onPress={deleteProject}>Delete Project</Button>
				{/* </>} */}

				<p>
					<LinkButton to="/user/projects">Back</LinkButton>
				</p>
			</div>
		}
		code=" // write your code here"
		docName={docName}
		docGroup="user"
		docType="canvas"
		noReset
	/>
});

export { UserProjectPage, UserProjectPageProps }
