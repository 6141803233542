// this file was made by generating typings:
// tsc --emitDeclarationOnly -d --skipLibCheck lib/sound/Drums.ts
// and manually tweaking the definitions

const musicTypeDef = `
interface Envelope {
	/** the time in seconds it takes the note to reach full volume */
	attack: number;
	/** the time in seconds it takes to drop from the peak level to the sustain level */
	decay: number;
	/** the volume level to sustain the note at, as a fraction of the peak level */
	sustain: number;
	/** the time in seconds it takes to drop from the sustain level to zero */
	release: number;
}
interface Instrument {
	envelope: Envelope;
	playNote(noteOrFreq: string | number, duration: number, timeOffset?: number, volume?: number): void;
	playChord(noteNames: string, duration: number, timeOffset?: number, volume?: number, transpose?: number): void;
}

type InstrumentType = 'triangle' | 'sine' | 'sawtooth' | 'square';

interface Drums {
	playNote(note: 'bass' | 'snare' | 'hihat' | 'tom', timeOffset?: number, duration?: number, volume?: number, freq?: number): void;
	bass(timeOffset?: number, duration?: number, volume?: number, freq?: number): void;
	snare(timeOffset?: number, duration?: number, volume?: number, noiseFreq?: number, drumFreq?: number): void;
	hihat(timeOffset?: number, duration?: number, volume?: number, freq?: number, pass?: 'highpass' | 'lowpass' | 'bandpass', q?: number): void;
	tom(whichTom?: number, timeOffset?: number, duration?: number, volume?: number): void;
	cymbal(timeOffset?: number, duration?: number, volume?: number, freq?: number, pass?: 'highpass' | 'lowpass' | 'bandpass', q?: number): void;
}

declare var Music: {
	/** Converts a note name like A4, Bb3, or C#5 to a note number (C3 is 60) */
	noteNumber(noteName: string): number;
	/** Converts a note name, like A4, Bb3, or C#5 or a note number, to a frequency in Hz (A4 or 69 will be 440)  */
	noteFrequency(note: string | number): number;

	/** Creates an instrument using a triangle, sine, sawtooth, or square value.  Volume is a number between 0 and 1 */
	createInstrument(wave?: InstrumentType, volume: number): Instrument;
	// getContext(): AudioContext;

	/** Creates an instrument that produces drum sounds.  Volume is a number between 0 and 1 */
	createDrums(volume?: number): Drums
}
`;

export { musicTypeDef };
