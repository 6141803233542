import * as React from 'react';
import { useState } from 'react';
import { Layout } from './Layout';
import { CourseMaterialsList } from './CourseMaterialsList';

const AHSJHome = () => {
	// const [showCal, setShowCal] = useState(false);
	// const { location } = useReactRouter();

	const classStr = 'ahsj';

	return <Layout solid>
		<h1>Let's Code with JS</h1>

		<p>Welcome to Let's Code! This is the class page.  I'll add class materials here as we move along.</p>

		<CourseMaterialsList classStr={classStr} />

		{/*
		<h2>Calendar</h2>

		{!showCal && <p><a onClick={() => setShowCal(true)}>Show calendar</a></p>}

		{showCal && <>
			<p><a onClick={() => setShowCal(false)}>Hide calendar</a></p>
			<iframe style={{borderWidth:0}} width="600" height="500" frameBorder="0" scrolling="no"
				src="https://calendar.google.com/calendar/embed?height=500&amp;wkst=1&amp;ctz=America%2FDenver&amp;src=bHZoYzlpY28zbm5yZ3ZyOHF2OTMxbmxhM3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;mode=AGENDA"
			/>
			{/* SVLA: src=amduMTlwaW8zZWR0dHRndDh2NTZuMTloMWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ
		</>} */}

	</Layout>;
};


export { AHSJHome }
