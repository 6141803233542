import * as React from 'react';
import { useAppContext } from './AppContext';
import { Avatar } from 'sancho/esm/Avatar'
import { Navbar } from 'sancho/esm/Navbar'
import { Toolbar } from 'sancho/esm/Toolbar'
import { Button } from 'sancho/esm/Button'
import { IconChevronDown } from "sancho/esm/Icons/icons/IconChevronDown";
import { useAppAuthState, useAuth } from './firebase';
import { Link } from 'react-router-dom';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { Popover } from 'sancho/esm/Popover';
import { MenuList, MenuItem, MenuLabel, MenuDivider  } from 'sancho/esm/Menu';
import { HelpDialog, openChat } from './HelpDialog';
// import { jsx } from "@emotion/core";
// import "@emotion/core";


interface HeaderProps {}


const Header: React.FC = () => {
	let [user/* , authed, error */] = useAppAuthState();
	let auth = useAuth();
	const [showingHelpDialog, setShowingHelpDialog] = React.useState(false);

	const logOut = () => { auth.signOut() };

	// const { authState, firebase } = useAppContext();
	// const { username } = authState;
	// const logOut = () => {
	// 	authState.authed = false;
	// 	authState.email = null;
	// 	authState.username = null;
	// 	auth.signOut();
	// }

	const prefix = useCoursePrefix();

	return (<>
		{showingHelpDialog && <HelpDialog onRequestClose={() => setShowingHelpDialog(false)} />}

		<Navbar position="static" style={{borderBottom: '.5px solid #a8a8a8'}}>
			<Toolbar compressed style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'start',

			}}>
				<div className="left" style={{display: 'flex', alignItems: 'center'}}>
					<Link to={prefix}>
						<h1>{prefix == '/svla' ? "Code Padawans" : "Let's Code!"}</h1>
					</Link>
					{user && prefix && <>
						<Link className="navlink" to={prefix}>
							Home
						</Link>
						<Link className="navlink" to={prefix + '/user/projects'}>
							Your Projects
						</Link>
						<Link className="navlink" to={prefix + '/shared-projects'}>
							Shared Projects
						</Link>
					</>}
				</div>
				<div className="right" style={{flex: '0 0 auto', marginTop: 5, display: 'flex', alignItems: 'center'}}>
					{user && (
						<Popover content={
							<MenuList>
								<MenuItem component={Link} to={`${prefix}/user/projects`}>
								{/* <MenuItem component="a" href={`${prefix}/user/projects`}> */}
									Your projects
								</MenuItem>
								<MenuItem component={Link} to={`${prefix}/shared-projects`}>
									Shared projects
								</MenuItem>
								<MenuDivider />
								<MenuItem onPress={() => setShowingHelpDialog(!showingHelpDialog)}>Help</MenuItem>
								<MenuItem onPress={() => openChat(user)}>Help Chat</MenuItem>
								<MenuDivider />
								<MenuItem onPress={logOut}>Log Out</MenuItem>
							</MenuList>

						// <>
						// 	<Button variant="ghost" intent="primary" onPress={logOut}>Log out</Button><br/>
						// 	<Button variant="ghost" intent="primary" onPress={logOut}>Your projects</Button>
						// </>
						}>
							<Button /* size="sm" */ variant="ghost" iconAfter={<IconChevronDown />}>
								<Avatar src={photoURL(user)} name={user.displayName || ''} size="sm" style={{display: 'inline-flex'}} />
								<span style={{paddingLeft: 10}}>{user.displayName || user.email}</span>
							</Button>
						</Popover>
					)}
				</div>
			</Toolbar>
		</Navbar>
	</>);
}

const photoURL = (user: firebase.User) => {
	return user.photoURL || 'https://robohash.org/' +  user.email + '.png?set=set1&size=100x100';
}

export { Header, HeaderProps }
