import { useDocumentData, useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import firebase, { firestore } from 'firebase';
import { useState } from 'react';
import { useUser } from '../firebase';

interface Assignment {
	data: any
	id: string
	courseId: string
	userId: string
	updated: number
	userDisplayName: string
}

const getAssignmentDocRef = (courseId: string, userId: string, docName: string) => (
	firebase.firestore().doc(`assignments/${courseId}_${userId}_${docName}`)
);

const useAssignment = (courseId: string, id: string, once = true) => {
	const user = useUser();
	const [assnRef] = useState(() => getAssignmentDocRef(courseId, user && user.uid, id));

	const useHook = once ? useDocumentDataOnce : useDocumentData as <T>(docRef?: firestore.DocumentReference, options?: {
		getOptions?: firestore.GetOptions;
		snapshotListenOptions?: firestore.SnapshotListenOptions | undefined;
		idField?: string;
	}) => [void | T, boolean, void | Error];

	const [assignment, assnLoading, assnError] = useHook<Assignment>(assnRef);

	const updateAssignment = (data: any) => {

		let updateObj: Partial<Assignment> = {
			data,
			courseId,
			id,
			userId: user && user.uid,
			userDisplayName: user && user.displayName,
			// if we needed more security, firebase.firestore.FieldValue.serverTimestamp() would work,
			// but firebase.firestore.Timestamp is an object w/ seconds and nanoseconds.
			// a single number representing millis is much more convenient
			updated: Date.now(),
		}
		assnRef.set(updateObj);
	}

	return [assignment, updateAssignment, assnLoading, assnError] as const;
}

export {
	useAssignment,
	getAssignmentDocRef,
	Assignment,
}
