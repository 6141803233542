import * as React from 'react'

interface VocabProps {
	term: string
}
const Vocab: React.FC<VocabProps> = (props) => (
	<div className="Vocab">
		<h3>{props.term}</h3>
		<div className="definition">{props.children}</div>
	</div>
);

export { Vocab }
