// FY shuffle
const shuffle = (array: any[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		let rand = Math.floor(Math.random() * (i + 1));
		if (rand != i) {
			let tmp = array[i];
			array[i] = array[rand];
			array[rand] = tmp;
		}
	}

	// make sure you know it's an in-place shuffle.  call slice() on the array you're shuffling.
	return;
}

export { shuffle }
