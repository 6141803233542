import * as React from 'react';
import { CanvasPage } from './CanvasPage';
import { PrefixedLink } from './lib/PrefixedLink';
import { LinkButton } from './lib/LinkButton';

const DrawProject = () => (
	<CanvasPage
		pageContent={
			<div>
				<h1>It's a blank canvas!</h1>

				<p className="intro">
					In this project, you will simply draw a picture, using your imagination,
					and all the functions from the <PrefixedLink to="/lesson/canvas">canvas lesson</PrefixedLink>.
				</p>

				<p>
					You can draw anything you like.
					I just need you to practice what you've learned.
					Go ahead and make it fancy!
				</p>

				<p>
					If you need ideas, maybe start with a landscape.
					Start simple with the ground and sky
					Then you can add trees, birds, flowers, and whatever else you like.
					Skyscrapers? Guy on a buffalo?  Go for it!
				</p>

				<h2 className="hOrange" style={{clear: 'both'}}>But wait, there's more!</h2>

				<p>There is one catch, though: You must use each of the canvas functions you have learned at least once.</p>

				<p>Here's a handy list to help you make sure you get them all.</p>

				<ul className="basicList">
					<li><code>canvas.background()</code></li>
					<li><code>canvas.fill()</code></li>
					<li><code>canvas.ellipse()</code></li>
					<li><code>canvas.rect()</code></li>
					<li><code>canvas.textSize()</code></li>
					<li><code>canvas.textAlign()</code></li>
					<li><code>canvas.text()</code></li>
					<li><code>canvas.stroke()</code></li>
					<li><code>canvas.strokeWeight()</code></li>
					<li><code>canvas.line()</code></li>
				</ul>


				<h2 className="hBlue">Share</h2>
				<p>
					Show your classmates what you are working on, and have them show you what they are working on.
					Is there something you can help each other with?  Did they have an idea that you liked?
				</p>


				<p>
					<LinkButton to="">Done</LinkButton>
				</p>
			</div>
		}
		code={`
			// It's all yours.
			// Here's some sample code you can try:

			// // sky
			// canvas.background("blue");

			// // clouds
			// canvas.fill("white");
			// canvas.ellipse(50, 40, 50, 20);
			// canvas.ellipse(80, 50, 50, 20);

			// // sun
			// canvas.fill("yellow");
			// canvas.ellipse(300, 40, 60, 60);

			// // grass
			// canvas.fill("green");
			// canvas.rect(0, 300, 400, 100);

			// // text
			// canvas.fill('white');
			// canvas.textSize(48);
			// canvas.text(message, 130, 130);

			// // can you turn this into a stick figure?
			// canvas.stroke('red');
			// canvas.strokeWeight(4);
			// canvas.line(200, 230, 200, 270);
		`}
		docName="draw-project"
	/>
);
export { DrawProject }
