import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeRunner, CodeRunnerProps } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
// import { Question, Answer } from './lib/Quiz';
// import { PrefixedLink } from './lib/PrefixedLink';
// import { Button } from 'sancho/esm/Button';
// import { Popover } from 'sancho/esm/Popover';
// import { Tooltip } from 'sancho/esm/Tooltip';
import { Music, musicTypeDef } from './lib/sound/Music';
import { CanvasRunner } from './lib/CanvasRunner';
import { PrefixedLink } from './lib/PrefixedLink';
import { useCleanup } from './lib/hookUtils';

const MusicRunner: React.FC<CodeRunnerProps> = props => (
	<CodeRunner {...props} context={{Music}} extraLibs={musicTypeDef} beforeRun={() => {
		Music.stopAll();
	}} />
);

const SoundLesson = () => {
	useCleanup(() => {
		Music.stopAll();
	});

	return (
		<Layout narrow>
			{/* <> */}
			<h1>Making Sounds</h1>

			<PagedContent pages={[
				/* (page, goTo) => */ <>
					<p className="intro">
						In this lesson, we'll introduce some new functions
						that can be used to make sounds -
						Including playing music, and creating sound effects.
					</p>

					<p className="intro">
						Music and sound can do a lot for a game. Not only can they make it more fun and exciting,
						but they can help the player understand what's happening and react.
					</p>

					<p className="intro">
						In our projects, we can make music too! Let me show you how.
					</p>

					{/* <p className="intro">
						The sounds we'll be making in this class are similar to
						the ones in classic games on consoles such as the NES and the Atari.
					</p> */}

					{/*
					<ul>
						<li className="hBlue"><a onClick={() => goTo(1)}>A Simple Example</a></li>
						<li className="hBlue"><a onClick={() => goTo(2)}>Playing notes in order</a></li>
						<li className="hBlue"><a onClick={() => goTo(3)}>Playing notes in order, take 2</a></li>
						<li className="hBlue"><a onClick={() => goTo(4)}>Cleaning it up</a></li>
						<li className="hBlue"><a onClick={() => goTo(5)}>More cleanup: Using arrays and loops</a></li>
						<li className="hGreen"><a onClick={() => goTo(6)}>Your Turn</a></li>
						<li className="hBlue"><a onClick={() => goTo(7)}>Rhythm</a></li>
						<li className="hPurple"><a onClick={() => goTo(8)}>Bonus round: More ways to get fancy </a></li>
					</ul>
					*/}

				</>,<>

					<h2 className="hBlue">A Simple Example</h2>

					<p>
						First, let's look at the most basic example possible.  Run the code, and you will hear a note.
						If you don't hear anything, check that the speakers are not muted and the volume is up.
					</p>

					<MusicRunner code={`
						var inst = Music.createInstrument();
						inst.playNote('C3', .5);
					`} />

					<p>
						There's a new object available in your projects that you haven't seen before:
						The <code>Music</code> object.  <code>Music</code> is what we sometimes call a <em>library</em> &mdash;
						a code tool that helps us accomplish a complex task.
						&#32;<code>Music</code> has one very important function: <code>Music.createInstrument()</code>.
					</p>

					<p>
						<code>Music.createInstrument()</code> gives you an instrument that can play notes.
						In order to use it, you'll need to keep your instrument around by storing it in a variable:
					</p>

					<CodeBox code={`
						var inst = Music.createInstrument();
					`} />

					<p>
						Then, you can use the <code>playNote</code> function on your instrument to play a note.
					</p>

					<CodeBox code={`
						inst.playNote('C3', .5);
					`} />

					<p>
						<code>Instrument.playNote()</code> has two required <strong>parameters</strong>: note, and duration.
						In the example above, <code>'C3'</code> is the note, and <code>.5</code> is the duration.
						Each note name is a string that consists of the note name (such as Ab, C#, or G), and the octave, which is a number from 2 - 6,
						and determines how high the note is.
						The <strong>duration</strong> is the number of seconds you want the note to last.
						Since most notes are shorter than a second,
						you'll probably use a decimal number, like .4, for example.
					</p>

					<p>
						<strong>Note:</strong> If you aren't a musician, that's okay. You'll still be able to use these examples to create music!
					</p>
				</>,<>

					<h2 className="hBlue">Playing notes in order</h2>

					<p>
						Next, let's learn how to use the playNote() function
						to play the first few notes of of "Mary Had a Little Lamb".
					</p>

					<p>
						The notes we want to play are E - D - C - D - E - E - E, in that order.
						So let's try putting them in one after the other:
					</p>

					<MusicRunner code={`
						var inst = Music.createInstrument();
						inst.playNote('E3', .5);
						inst.playNote('D3', .5);
						inst.playNote('C3', .5);
						inst.playNote('D3', .5);
						inst.playNote('E3', .5);
						inst.playNote('E3', .5);
						inst.playNote('E3', .5);
					`} />

					<p>
						What happened?!?  All the notes played at the same time. How do we fix that? Let's find out.
					</p>

				</>,<>
					<h2 className="hBlue">Playing notes in order, take 2</h2>
					<p>
						It turns out that <code>playNote()</code> is <em>scheduling</em> a note to be played,
						and when you use it with only two parameters, it will schedule it to play immediately.
						So our program just plays all those notes at once.
					</p>

					<p>
						<code>playNote()</code> has a third parameter: <code>timeOffset</code>,
						or in other words, how far in the future you want this note to be played.
					</p>

					<p>Let's use that third parameter to play all of our notes in sequence, one after the other.</p>

					<MusicRunner code={`
						var inst = Music.createInstrument();
						inst.playNote('E3', .5, 0);
						inst.playNote('D3', .5, 0.5);
						inst.playNote('C3', .5, 1);
						inst.playNote('D3', .5, 1.5);
						inst.playNote('E3', .5, 2);
						inst.playNote('E3', .5, 2.5);
						inst.playNote('E3', .5, 3);
					`} />

					<p>
						Pretty cool, right? Well, maybe Mary Had a Little Lamb isn't your favorite song,
						but you can use this to play any song you want.
					</p>

				</>,<>

					<h2 className="hBlue">Cleaning it up</h2>

					<p>
						But, imagine that
						{/* instead of Mary Had a Little Lamb, you're creating a tune of your own. */}
						you want to speed up or slow down the song. To do that,
						you'll need to change all the note durations, and all the time offsets.
						That's a big pain! Programmers don't like doing things that are tedious.
					</p>

					<p>
						So, how do we fix that?  By using our programming skills, of course!
						Here's Mary Had a Little Lamb, with some programming-friendly improvements:
					</p>

					<MusicRunner code={`
						var inst = Music.createInstrument();
						// use beats per minute to calculate note duration.
						var bpm = 140;
						var duration = 60 / bpm;

						// use a time variable to schedule all the notes.
						var t = 0;
						inst.playNote('E3', duration, t); t += duration;
						inst.playNote('D3', duration, t); t += duration;
						inst.playNote('C3', duration, t); t += duration;
						inst.playNote('D3', duration, t); t += duration;
						inst.playNote('E3', duration, t); t += duration;
						inst.playNote('E3', duration, t); t += duration;
						inst.playNote('E3', duration, t); t += duration;
					`} />

					<p>
						Much better! I can add or remove notes, change speed, and make other changes,
						without having to rewrite the whole song.
						Programming is all about making things easy for ourselves.
					</p>

					<p>
						Let's look at the changes we made:  First, we calculate the note duration,
						based on the number of beats per minute we want.
						The we store the note duration in a variable <code>v</code>,
						so we can reuse it for each note.
					</p>

					<p>
						Then, instead of hard-coding (typing by hand) when each note should be played,
						we create a variable <code>t</code> for time, and update it after every note
						to keep track of when the next note should be played. <code>t += duration</code> adds
						the note duration to <code>t</code> so that the next note will start right
						when the last one ended.
					</p>

					<p>
						These all make my program more <em>maintainable</em>.
						In other words, it's easier and more fun to write and make changes to it.
					</p>

				</>,<>

					<h2 className="hBlue">More cleanup: Using arrays and loops</h2>

					<p>
						But it still takes two lines to play one note. I think there's still some room for improvement there.
						Let's see if we can make it even better.
					</p>

					<MusicRunner key="loop" code={`
						var inst = Music.createInstrument();
						var littleLambNotes = [
							'E3',
							'D3',
							'C3',
							'D3',
							'E3',
							'E3',
							'E3',
						];

						playSong(littleLambNotes, 140);

						function playSong(notes, bpm) {
							// use beats per minute to calculate note duration.
							var duration = 60 / bpm;

							// use a time variable to schedule all the notes.
							var t = 0;

							for (var i = 0; i < notes.length; i++) {
								inst.playNote(notes[i], duration, t);
								t += duration;
							}
						}
					`} />

				</>,<>

					<h2 className="hGreen">Your Turn</h2>

					<p>
						Write your own simple tune. You can make up the notes yourself,
						or you can use notes from a song you know.
					</p>

					<p>
						Feel free to use loops and arrays like the last example, or any other
						way you want to use to put your song together.
					</p>



					<MusicRunner docName="sounds.firstTune" height={500} code={`
						var inst = Music.createInstrument();

						// create your song here
						// use inst.playNote(note, duration, timeOffset) to play a note.
					`} />

				</>,<>

					<h2 className="hBlue">Rhythm</h2>

					<p>
						Almost all songs have notes of different lengths, and rests (spaces in between notes).
					</p>

					<p>
						Our function in the last example could play a few notes of Mary Had a Little Lamb,
						but it would not work for something with more rhythm, because we are only storing notes in our array.
						To fix it, we need to store note durations as well. Here's one way to do that. What's this song?
					</p>

					<MusicRunner key="rhythm" height={600} code={`
						var inst = Music.createInstrument();
						var mysterySongNotes = [
							['G3', 4],
							['A4', 4],
							['C4', 2],
							['C4', 2],
							['C4', 2],
							['C4', 1],
							['C4', 2],
							['C4', 1],
							['C4', 2],
						];

						playSong(mysterySongNotes, 140);

						function playSong(notes, bpm) {
							// use beats per minute to calculate note duration.
							var singleNoteLength = 60 / bpm / 4;

							// use a time variable to schedule all the notes.
							var t = 0;

							for (var i = 0; i < notes.length; i++) {
								var noteInfo = notes[i];
								var note = noteInfo[0];
								var length = noteInfo[1];
								var noteLength = length * singleNoteLength;
								inst.playNote(note, noteLength, t);
								t += noteLength;
							}
						}
					`} />

				</>,<>
					<h3 className="hBlue">Using other sounds</h3>

					<p>The <code>Music.createInstrument()</code> function lets you specify what type of sound you want to use:</p>

					<MusicRunner code={`
						// try changing 'sawtooth' to 'sine', 'triangle', and 'square'
						var saw = Music.createInstrument('sawtooth');
						var duration = .25;
						var t = 0;
						var volume = .3;

						saw.playNote('C4', duration, t, volume); t += duration;
						saw.playNote('E4', duration, t, volume); t += duration;
						saw.playNote('G4', duration, t, volume); t += duration;
					`} />

					<p>
						There are four types of sounds you can use: sine, triangle, square, and sawtooth.
						If those don't sound like regular instrument names to you, it's because they're not.
						They are referring to the shape of the sound waves used to create the sounds.
						We're not going to dig deep into that, so if you want to learn more about these types of waves,
						check out this <a target="_blank" href="https://pudding.cool/2018/02/waveforms/">Introduction to waveforms</a>.
					</p>

					<p>
						The important thing to know is that each one sounds different. Try changing <code>sawtooth</code> in the example
						above to <code>sine</code>, <code>triangle</code>, and <code>square</code> to hear how different they are.
					</p>

					<p>
						Another thing to notice is that there's a third parameter to playNote: volume.
						You can use this to make your sounds quieter or balance them with other sounds.
						Volume can be a number between 0 and 1, and if it's not set, it will be 1 (full volume).
					</p>
				</>,<>

					<h3 className="hBlue">Creating sound effects</h3>

					<p>
						Next, we're going to create a sound effect.
						There's really nothing about special about a sound effect – it's just a short, repeatable piece of music.
					</p>

					<p>
						But we're going to look at how we might go about playing a sound when something happens in your game or program.
					</p>

					<CanvasRunner vertical editorHeight={500} code={`
						var inst = Music.createInstrument();
						var coins = 0;

						draw();

						// hopefully this sounds coin-y
						function coinSound() {
							inst.playNote('D5', .08, 0, .3);
							inst.playNote('D6', .5, .07, .4);
						}

						function whenMouseClicked() {
							collectCoin();
						}

						function whenKeyPressed(key) {
							if (key == 'c') {
								collectCoin();
							}
						}

						function collectCoin() {
							coins++;
							coinSound()
							draw();
						}

						function draw() {
							canvas.background('white');
							canvas.textSize(36);
							canvas.textAlign('center', 'middle');
							canvas.text('Click or press C', 200, 150);
							canvas.text('You have ' + coins + ' coins.', 200, 250);
						}

					`} />

					<p>
						Let's go over what happened there:
						We made a function called <code>coinSound</code>. Whenever you play it, it plays our sound effect,
						which is just two notes.
					</p>

					<p>
						There's also a <code>collectCoin()</code> function, which we call when you click or press C to collect a coin,
						and that function calls <code>coinSound()</code> to play the sound effect.
					</p>
				</>,<>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Create your own sound effect that happens when someone presses a key.
						Choose a sound (sine, triangle, square, sawtooth),
						and a few notes to play. You don't need to be a composer.
						Pick some random notes, space them out, and you might be pleasantly surprised with how they sound.
					</p>

					<p>
						A couple tips to remember:
					</p>

					<ol>
						<li>
							<code>inst.playNote</code> has four parameters:
							the note name, note duration, time offset, and volume.
						</li>
						<li>
							Note names start with a letter (A-G), followed by a number (2-6).
							They are strings, so don't forget to use quotes.
						</li>
						<li>
							Remember what we talked about earlier:
							Use functions and variables to help make your code easier to edit.
							You might even want to use an array and a loop if you are using a lot of notes.
						</li>
					</ol>


					<CanvasRunner console vertical docName="sounds.soundEffect" editorHeight={600} code={`
						var inst = Music.createInstrument('triangle');
						var score = 0;

						draw();

						function soundEffect() {
							// create some sound here!!
							// inst.playNote('C3', .4, 0, .5);
						}

						function whenMouseClicked(x, y) {
							score++;
							soundEffect();
							draw();
						}

						function draw() {
							canvas.background('white');
							canvas.textSize(36);
							canvas.textAlign('center', 'middle');
							canvas.text('Click me.', 200, 150);
							canvas.text('Your score is ' + score, 200, 250);
						}
					`} />

				</>,<>

					<h2 className="hPurple">Bonus round: More ways to get fancy </h2>

					<p>
						This very simple library opens up all kinds of possibilities.
						You could program a whole symphony if you wanted to, though only using the four sounds we've used here.
						Here are some other examples I have created to help you see what you can do with it.
					</p>

					<h3><PrefixedLink to="/published/blsd4wri" target="_blank">Basic Notes</PrefixedLink></h3>
					<p>
						This project allows you to press keys A - G to play notes, and change octaves using the number keys.
					</p>
					<h3><PrefixedLink to="/published/3v9fqc16" target="_blank">Piano</PrefixedLink></h3>
					<p>
						Similar to the Basic Notes project, but this one draws some keys that you can click to play notes.
					</p>
					<h3><PrefixedLink to="/published/ywcgx8a6" target="_blank">Happy Birthday</PrefixedLink></h3>
					<p>
						Playing happy birthday, one note at a time.  Not the best way of doing it.
					</p>
					<h3><PrefixedLink to="/published/nqzh81ts" target="_blank">Happy Birthday II</PrefixedLink></h3>
					<p>
						A better way of playing happy birthday, using an array to store the note values and durations.
					</p>
					<h3><PrefixedLink to="/published/c8ss1x1p" target="_blank">Sound Effects</PrefixedLink></h3>
					<p>
						A simple project with a few sound effects in it. A good way to see how to set up sound effects for your own project.
					</p>
					<h3><PrefixedLink to="/published/e2mpymcq" target="_blank">King Arthur</PrefixedLink></h3>
					<p>
						Now with sound effects! the same ones as in the project above.
						Pick up your sword, then start slaying dragons! Use spacebar to swing your sword, and the / key to hold your
						sword in the other hand.
					</p>

				</>
			]} />

		</Layout>
	);
};

export { SoundLesson }
