import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { CodeBox } from './CodeBox';
import { Button } from 'sancho/esm/Button';
import { PrefixedLink } from './lib/PrefixedLink';

const madLibsCode1 =`
/*
 * Interactive Mad Libs
 *
 * In this project you will create a mad lib that can be played by anyone,
 * and is different each time!
 */

// use this line as is
var person = prompt("What is a type of person (boy, girl, knight, princess, etc)?");

// change ???? to be a question to get someone to type a name.
// For example, it could be "What is your name?",
// or "What is a name for a character in a book?"
var name = prompt("????");

// define the rest of these variables, and use prompt() to ask questions
// and get input for them:
// adjective
// sillyName
// goodThing
// badThing

// Don't change this part (yet)!

console.log('Once there was a ' + person + ' named ' + name + '.');
console.log(name + ' was a very ' + adjective + ' ' + person + '.');
console.log('One day ' + name + ' went to see the king, King ' + sillyName + '.');
console.log('"Oh great King ' + sillyName + ', what is the secret to your success?" said ' + name + '.');
console.log(' ');
console.log('Listen, ' + name + ', you must always remember this:');
console.log('A ' + goodThing + ' a day keeps the ' + badThing + ' away.');
`;

// console.log(\`Once there was a \${person} named \${name}.\`);
// console.log(\`\${name} was a very \${adjective} \${person}.\`);
// console.log(\`One day \${name} went to see the king, King \${sillyName}.\`);
// console.log(\`"Oh great King \${sillyName}, what is the secret to your success?" said \${name}.\`);
// console.log(' ');
// console.log(\`Listen, \${name}, you must always remember this:\`);
// console.log(\`A \${goodThing} a day keeps the \${badThing} away.\`);`;

// const COURSE_ID = 'js-intro';
// const DOC_ID = 'imadlibs';

const InteractiveMadLibsProject: React.FC = () => {
	return <ProjectPage
		pageContent={<PageContent />}
		monacoProps={{
			initialCode: madLibsCode1,
			docName: 'imadlibs',
		}}
	/>
}

const PageContent: React.FC = () => <>
	<h2>Interactive Mad Libs</h2>

	<p>In this project, you will be creating another Mad Libs Story.</p>
	<p>In order to complete the mad lib, you'll need to define some variables.</p>
	<p>But this time, instead of "hard coding" the variables, you will get them at <strong>runtime</strong>, meaning when the program runs, using the <code>prompt</code> function.</p>

	<p>
		Look at the code that's in the editor right now.  It's similar to what you had in the last project.
		But instead of assigning values to variables to fill in the blanks, this time you're going to use prompt()
		to get interactive input!
	</p>

	<p>
		Follow the directions in the code editor to get the program working.
	</p>

	<p>Just like before, if you see an error like this:</p>

	<CodeBox code={`
		ReferenceError: adjective is not defined
		    at line 27, column 38`} />
	<p>
		That just means you are trying to use a variable that hasn't been defined yet.
		Your job in this project is to define those variables, and to make sure they assigned using <code>prompt()</code>.
	</p>

	<p>&nbsp;</p>

	<p>
		Once your code is working, you should be able to complete the mad libs interactively!
		Try it out, then have your neighbor try it as well.
	</p>

	<h3>Bonus</h3>
	<p>You may have noticed that you can connect two strings into a bigger string using <code>+</code>, like this:</p>

	<CodeBox code={`
		var favoriteFood = "tacos";
		var message = "my favorite food is " + favoriteFood;
		console.log(message);
	`} />

	<p>
		Try to connect all the strings in the whole mad lib using one or more variables and <code>+</code>.
		Then use <code>alert</code> to display the entire story at once.  If you get stuck, don't worry – we'll learn more ways to do this next time!
	</p>

	<p>
		<PrefixedLink to="/project/interactive-mad-libs/2">
			<Button /* disabled={!props.canContinue} */ intent="primary" /* onPress={() => props.frameApi.updateCode(madLibsCode2)} */>Done! Let's go on.</Button>
		</PrefixedLink>
	</p>
</>;

export { InteractiveMadLibsProject }
