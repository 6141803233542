import * as React from 'react';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { sokobanDemo, sokobanRunner, SokobanProjectExports } from './sokobanRunner';
import { WrappedCanvas } from './lib/WrappedCanvas';
import { CanvasPage } from './CanvasPage';
import { CodeBox } from './CodeBox';
import { PagedContent } from './lib/PagedContent';
import { handleInConsole } from './lib/runner';
const sokoStats = require('./images/example/soko-stats.png');

const SokobanProject: React.FC = () => {
	return <CanvasPage
		pageContent={<>
			<h1>Sokoban Project</h1>

			<p className="intro">
				Sokoban is a Japanese game where you move boxes around a warehouse to get them into the right places.
				You can only push, not pull boxes, and you can't push more than one box.
			</p>

			<p className="intro">
				In this project you will do the graphics for the game by implementing a function that knows how to draw each kind of space.
			</p>

			<hr className="spacer" style={{clear: 'both'}} />

			<PagedContent pages={[
				<>
					<h2 className="hGreen">A Preview</h2>
						First, here's an example of the game.  Your finished version will work something like this.

					<div style={{margin: 'auto', width: 400}}>
						<WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={sokobanDemo} />
					</div>

					<p>
						The game is almost finished.  It works, but it has no graphics.  You can't see anything.
						You'll need to write the functions that draw the game on the screen.
					</p>

					<p>
						You will write seven functions &mdash; one each to draw the background,
						walls, boxes, goal spaces, the player,
						empty spaces, and the stats.
					</p>
				</>,
				<>
					<h2 className="hGreen">Let's build it - Step 1</h2>
					<p>
						To get started, let's draw the background.
						To do this, you'll need to define a function called <code>drawBackground</code>.
						You can keep it really simple if you want and just draw a white background.
					</p>

					<p>
						Or, you can get creative and draw something else.
						Just make sure to fill the whole screen,
						or you will have "leftovers" each time you redraw the board!
					</p>

					<CodeBox clickToShow code={`
						function drawBackground() {
							canvas.background('white');
						}
					`}/>
				</>,
				<>
					<h2 className="hGreen">Step 2 - Draw Walls</h2>
					<p>
						Now let's draw the walls.  Define another function called <code>drawWall</code>.
						This one will be called for every wall that needs to be drawn.
					</p>

					<p>
						<code>drawWall</code>, and most of the other drawing functions, takes three parameters:
						<code>x</code>, <code>y</code>, and <code>boxSize</code>.
						<code>x</code> <code>y</code> tell you where the wall should be drawn,
						and <code>boxSize</code> tells you how big it should be.
					</p>

					<p>
						Before you draw it, try just logging when the function is
						called to make sure you have defined it correctly.
						It sholud look something like this:
					</p>

					<CodeBox code={`
						function drawWall(x, y, boxSize) {
							console.log('drawing wall', x, y, boxSize);
						}
					`}/>

					<p>If you run your code, you should see a bunch of lines in the console like this:
						<code className="box">
							drawing wall 80 140 40
							drawing wall 120 140 40
						</code>
					</p>

					<p>
						Each one represents a single wall tile on the board.
						You'll need to draw something at that position to be the wall.
					</p>

					<p>
						Just for fun, try this:
					</p>

					<CodeBox code={`
						function drawWall(x, y, boxSize) {
							canvas.text('W', x, y);
						}
					`}/>

					<p>Now run the code. What is that doing?</p>

					<p>
						You should see a bunch of W's on screen. Each one is part of the board's walls!
					</p>

					<p>
						The point is that you can draw the wall however you want.
						But it does need to be right size, and in the right position.
						<em>You</em> are in charge of <em>how</em> you want the wall to be drawn.
					</p>

					<p>
						The simplest way to draw a wall that looks like a wall is to just
						fill the space with a solid square.  I suggest you do that for now.

						There's a hint below if you need help.
					</p>

					<CodeBox clickToShow code={`
						function drawWall(x, y, boxSize) {
							var wallColor = 'black';
							canvas.fill(wallColor);
							canvas.rect(x, y, boxSize, boxSize);
						}
					`}/>
				</>,
				<>
					<h2 className="hGreen">Step 3 - Draw the Player</h2>

					<p>
						Now let's draw the player.  It's pretty hard to play the game if you can't see the player you're controlling!
					</p>

					<p>
						Define another function called <code>drawPlayer</code>.
						This one will be called once each time the board is drawn, with the current position of the player, along with the box size.
						So the three parameters again are <code>x</code>, <code>y</code>, and <code>boxSize</code>.
					</p>

					<p>
						I'd suggest drawing a circle for the player.
						If you do, remember that <code>canvas.ellipse</code> uses <code>x</code> and <code>y</code>
						for the center of the circle, not the top left corner, like <code>canvas.rect</code>.
						That means you're going to have to do some adjusting if you want the circle in the middle of the square.
					</p>

					<CodeBox clickToShow code={`
						function drawPlayer(x, y, boxSize) {
							var playerColor = 'magenta';
							var centerX = x + boxSize / 2;
							var centerY = y + boxSize / 2;
							canvas.fill(playerColor);
							canvas.ellipse(centerX, centerY, boxSize);
						}
					`}/>

					<p>
						Now run it.  If it worked, you should be able to see your player inside the walls!
						Try moving around with the arrows. Does it work?
						Is the playing positioned correctly, or overlapping with walls?
					</p>
				</>,
				<>
					<h2 className="hGreen">Step 4 - Draw the Goal Spaces</h2>

					<p>
						Each level has goal spaces where the boxes need to get moved to.  You'll need to give them a color
					</p>

					<p>
						Define another function called <code>drawGoal</code>.
						This one will be called once for each goal on the board each time the board is drawn,
						with its <code>x</code> and <code>y</code> position, along with the box size.


						So like before, the three parameters are <code>x</code>, <code>y</code>, and <code>boxSize</code>.
					</p>

					{/* <p>
						Define that function, and log its parameters when it is called.
					</p>

					<CodeBox clickToShow code={`
						function drawGoal(x, y, boxSize) {
							console.log('drawing goal', x, y, boxSize);
						}
					`}/>

					<p>
						Try running that.  You should see something like this in the console when you first run it:
					</p>

					<code className="box">
						drawing box 200 180 40 false
					</code>
					*/}

					<p>This function works exactly like <code>drawWall</code>, so if you can, try to figure it out on your own.</p>

					<p>
						Make it a square, a circle, or anything else, just make sure it's in the right position,
						and that it is a unique color, so you can differentiate it from the other types of spaces.
					</p>

					<p>
						Here's a hint if you get stuck.  Try not to use it!
					</p>

					<CodeBox clickToShow code={`
						function drawGoal(x, y, boxSize) {
							var centerX = x + boxSize / 2;
							var centerY = y + boxSize / 2;
							var circleSize = boxSize / 2;
							canvas.fill('gold');
							canvas.ellipse(centerX, centerY, circleSize);
						}
					`}/>

					<p>
						At this point, you should be able to see the player,
						the walls, and the boxes as they move around.
						You're in it to win it!
					</p>
				</>,
				<>
					<h2 className="hGreen">Step 5 - Draw the Boxes</h2>

					<p>
						Each level has boxes that you have to move around by pushing them. Next let's draw these boxes.
					</p>

					<p>
						Define another function called, not suprisingly, <code>drawBox</code>.
						This one will be called once for each box on the board each time the board is drawn,
						with the current position of the player, along with the box size.
						<strong>But</strong> this one will get another parameter called isOnGoal.
						Because you are trying to get the boxes onto the goal squares, you'll need to draw the box differently
						if it's on a goal square so the person playing the game can tell.
					</p>

					<p>
						If the box is on a goal, <code>isOnGoal</code> will be <code>true</code>, otherwise it will be <code>false</code>.

						So the four parameters for <code>drawBox</code> are <code>x</code>, <code>y</code>, <code>boxSize</code>, and <code>isOnGoal</code>.
					</p>

					<p>
						Define that function, and log its parameters when it is called.
					</p>

					<CodeBox clickToShow code={`
						function drawBox(x, y, boxSize, isOnGoal) {
							console.log('drawing box', x, y, boxSize, isOnGoal);
						}
					`}/>

					<p>
						Try running that.  You should see something like this in the console when you first run it:
					</p>

					<code className="box">
						drawing box 200 180 40 false
					</code>

					<p>
						Now it's time to draw again!
						This is a box, so again, drawing a rectangle is a good choice.  I'd make it one color if it's on goal, and a different color if not.
						So you'll need an <strong>if statement.</strong>
					</p>

					<p>Remember, an <strong>if statement</strong> looks like this:</p>

					<CodeBox code={`
					if (condition == true) {
						// code if true
					}
					else {
						// code if false
					}`} />

					<p>
						Here's a hint if you get stuck:
					</p>

					<CodeBox clickToShow code={`
						function drawBox(x, y, boxSize, isOnGoal) {
							var boxColor = 'blue';
							if (isOnGoal) {
								boxColor = 'green';
							}
							canvas.fill(boxColor);
							canvas.rect(x, y, boxSize, boxSize);
						}
					`}/>

					<p>
						Once you have that, you should be able to see the goal spaces,
						your player moving around, pushing the boxes,
						and the boxes changing color when they hit the goal!
					</p>

					<p>In other words you have a playable game. Congratulations!  But there are still improvements we can make.</p>
				</>,
				<>
					<h2 className="hGreen">Step 6 - Add stats</h2>

					<img style={{float: 'left', marginRight: 10, width: 300, maxWidth: '50%'}} src={sokoStats} />

					<p>Now let's add some stats.</p>

					<p>
						Define a function called <code>drawStats</code>.
						This one will be called after the board has been drawn,
						and let you print information on the canvas such as the number of moves,
						pushes, and the current level.
					</p>

					<p>
						It will be given four parameters: <code>moveCount</code>, <code>pushCount</code> <code>isWinner</code>, and <code>currentLevel</code>.
						Each of those is a number, except for <code>isWinner</code>, which is <code>true</code> if you have won the level.
					</p>
					<p>
						You can print those on the screen somewhere to let the player how they're doing.
						If they have won, do something to let them know it.
					</p>

					{/* <p>
						For example, if you look at the demo, it prints "Moves: 9" in the top level corner,
						"L: 1" at the top center, "Pushes: 3" at the top right,
					</p>

					<p>
						If <code>isWinner</code> is <code>true</code>, it prints "You win! Press Enter to continue" at the bottom,
						otherwise it prints  "Press Enter to reset" at the bottom.
					</p> */}

					<p>
						You are in charge of the visual design of this game, so you can print it how you like,
						but you should include those sames pieces of information to help the player along.
					</p>

					<p>
						You'll want to use <code>canvas.textAlign()</code>, <code>canvas.fill()</code>, and <code>canvas.text()</code> here to print
						the stats where you want them to go.
					</p>

					<p>
						Try to do it on your own if you can! Need help? Here's an example that's partly done.
						But it doesn't print the text in the best places...
					</p>

					<CodeBox clickToShow code={`
						function drawStats(moveCount, pushCount, isWinner, currentLevel) {
							canvas.fill('deeppink');
							canvas.textAlign('center');
							canvas.text('L:' + currentLevel, 200, 100);

							if (isWinner) {
								canvas.text('WINNER. Press Enter', 200, 150);
							}
							else {
								canvas.text('Press Enter to reset', 200, 150);
							}
							canvas.text('Moves: ' + moveCount, 200, 200);
							canvas.text('Pushes: ' + pushCount, 200, 250);
						}
					`}/>
				</>,
				<>
					<h2 className="hGreen">Making improvements</h2>

					<p>Your game is working! You can play it, and it will be fun.</p>
					<p>But you can keeping going if you want. Get creative! Here are some fun ideas for you:</p>

					<ul>
						<li>Make the walls look like bricks</li>
						<li>Make the floor look like tiles or checkerboard</li>
						<li>
							Make the player into a character.  How about a stick figure, or a dog, or a robot? Use your imagination.
							The <a target="_blank" href="https://code.mortensoncreative.com/ahsj/lesson/canvas">canvas lesson</a> might be helpful here.
						</li>
						<li>
							Make the goal space look like a jewel or a coin.
						</li>
					</ul>
				</>,
				<>
					<p>
						If you want to learn more about how I built this game, you can see a complete version with all of the code (and the original hard levels) here:
					</p>

					<p className="intro">
						<a target="_blank" href="https://code.mortensoncreative.com/published/4rpgkcd9">https://code.mortensoncreative.com/published/4rpgkcd9</a>
					</p>

					<p>It's pretty long, and it covers concepts that we haven't covered yet, but you can still learn a lot from it if you're curious!</p>
				</>,
		]}/>


			<p>
				<PrefixedLink to=""><Button intent="primary">Done</Button></PrefixedLink>
			</p>
		</>}
		docName="project.sokoban"
		code={`
			// function drawBackground() {}
			// function drawWall(x, y, boxSize) {}
			// function drawPlayer(x, y, boxSize) {}
			// function drawGoal(x, y, boxSize) {}
			// function drawBox(x, y, boxSize, isOnGoal) {}
			// function drawEmpty(x, y, boxSize, isOnGoal) {}
			// function drawStats(moveCount, pushCount, isWinner, currentLevel) {}
		`}
		// Use the arrow keys to move the boxes to the goal spaces.
		// If you get stuck, click to reset.
		// Press J to jump to any level.
		// Click to begin.
		afterRun={(canvasInterface, exported: SokobanProjectExports, {console}) => {
			let runner = handleInConsole(sokobanRunner, console);
			return runner(canvasInterface, exported, console);
		}}
		exports={[
			'drawBackground',
			'drawPlayer',
			'drawWall',
			'drawGoal',
			'drawBox',
			'drawStats',
			'drawInstructions',
			'drawEmpty',
			'getCustomLevels',
		]}
	/>
}

export { SokobanProject }
