import * as React from 'react';

import { namedColors } from './lib/namedColors';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { Layout } from './Layout';
import { CanvasRunner } from './lib/CanvasRunner';
import { ColorPicker2 } from './lib/ColorPicker';

const ColorDocPage = () => {
	return (<Layout narrow>
		<h1>Colors</h1>

		<h2 className="hBlue">Named Colors</h2>
		<p>
			There are a few different ways to specify colors. The first is by names.
			There are {namedColors.length} different color names you can use.
			Here they are:
		</p>

		{/* <ul className="basicList"> */}
		{/* <table>
			<tbody> */}
		<div className="colorList">
			{namedColors.map(color => (
				<div key={color.name} className="color">
					<span className="colorName">{color.name}</span>
					<span className="colorHex">{color.hex}</span>
					{/* <span className="colorName"> */}<div className="colorBox" style={{background: color.hex}} />{/* </span> */}
				</div>
			))}
			{/*  */}
			<div className="color"></div>
		</div>
				{/* {colorPairs.map(([color1, color2]) => <div style={{display: 'flex'}}>
					<span>{color1.name}</span>
					<span>{color1.hex}</span>
					<span><div style={{width: 60, height: 24, background: color1.hex, border: '1px solid black'}} /></span>
					{color2 && <>
						<span style={{flexGrow: 2}}>{' '}</span>
						<span>{color2.name}</span>
						<span>{color2.hex}</span>
						<span><div style={{width: 60, height: 24, background: color2.hex, border: '1px solid black'}} /></span>
					</>}
				</div>)} */}
			{/* </tbody>
		</table> */}
		{/* </ul> */}


		<p>Try choosing some named colors and using them here.  Remember that these names are strings, so always wrap them in <code>"quotes"</code>.</p>

		<CanvasRunner autorun code={`
			canvas.fill("red");
			canvas.ellipse(200, 200, 360);

			canvas.fill("white");
			canvas.ellipse(200, 200, 240);

			canvas.fill("blue");
			canvas.ellipse(200, 200, 120);
		`} />

		<h2 className="hBlue">Hexadecimal Color Codes</h2>
		<p>
			You can also use hexadecimal color codes.
			 A hexadecimal (or hex) code is a six digit format that precisely represents a specific color.
			We'll dig more into these later.  But you can get a hex color code from a color picker like this (click it):
		</p>

		<ColorPicker2 initialColor="#00ffff" />
		{/* <ColorPicker /> */}

		<p>
			Try picking some colors and using them here.  These codes are still strings,
			so they also need to be wrapped in <code>"quotes"</code>.
		</p>

		<CanvasRunner autorun code={`
			canvas.background("#663399");

			canvas.fill("#00ffff");
			canvas.rect(75, 75, 250, 250);

			canvas.fill("#44cc00");
			canvas.rect(150, 150, 100, 100);
		`} />


		<hr className="spacer"/>

		<p>
			<PrefixedLink to="">
				<Button intent="primary">Back to home</Button>
			</PrefixedLink>
		</p>
	</Layout>
)};


export { ColorDocPage }
