import { CanvasWrapper } from '../lib/WrappedCanvas';

function canvasBirdDemo(canvas: CanvasWrapper) {
	let message = '';
	var line = 0;
	var lyrics = [
		'Hello!',
		'This is a canvas.',
		"You can write a program\nto draw anything\nyou want on it.",
		'',
		'Try pressing the arrow keys',
		'',
		'',
	];

	var loop = () => {
		cycleLyrics();
		drawScene();
		setTimeout(loop, 2000);
	}

	var birdX = 100, birdY = 100, flapping = false;
	var poopX =-1, poopY = -1, poopVel = 0;
	var poops = {};

	// drawScene()
	loop();

	// *********
	// *********
	// *********

	function cycleLyrics() {
		message = lyrics[(line++ % lyrics.length)];
	}

	function drawTree(x = 0, y = 0) {
		canvas.changePosition(x, y - 20);

		canvas.fill('saddlebrown');
		canvas.rect(-8, 0, 16, 30)
		canvas.fill('forestgreen');
		// canvas.line(-200, 0, 200, 0)
		canvas.shape(-20, 0, 0, -80, 20, 0);
		canvas.shape(-20, -40, 0, -120, 20, -40);

		canvas.resetPosition();
	}

	function drawTrees() {
		for (var i = 0; i <=400; i += 50) {
			drawTree(i, 400);
		}
	}

	function drawMountains() {
		var colors = [
			'mediumslateblue',
			'slateblue',
			'darkslateblue',
			'indigo',
		];

		for (var i = 0; i < colors.length; i++) {
			canvas.changePosition(-57.5 + (3 - i) * 75, 60 + (3 - i) * 12);

			canvas.fill(colors[i]);
			canvas.shape(-200, 400, 100, 100, 400, 400);

			canvas.resetPosition();
		}
	}

	function drawBird() {
		var y = flapping ? -2 : -5;
		canvas.changePosition(birdX, birdY);
		canvas.stroke('black')
		canvas.line(-11, y, 0, 0);
		canvas.line(0, 0, 11, y);
		canvas.resetPosition();
	}

	function drawPoop() {
		canvas.fill('white');
		canvas.noStroke();

		if (poopY > 0 && poopY < 400) {
			canvas.ellipse(poopX, poopY, 2);

			poopY += poopVel;
			poopVel += .3;

			setTimeout(drawScene, 16);
		}
		else if (poopY >= 400) {
			if (poops[poopX]) {
				// let tip = poops[poopX];
				let left = poops[+poopX - 1] | 0, right = poops[+poopX + 1] | 0;
				if (left < poops[poopX]) {
					do {
						poopX--;
						left = poops[+poopX - 1] | 0;

					} while (left < poops[poopX]);

					poops[poopX] = poops[poopX] ? poops[poopX] + 1 : 1;
				}
				else if (right < poops[poopX]) {
					do {
						poopX++;
						right = poops[+poopX + 1] | 0;

					} while (right < poops[poopX]);

					poops[poopX] = poops[poopX] ? poops[poopX] + 1 : 1;
				}
				else {
					poops[poopX] = poops[poopX] + 1;
				}

			}
			else {
				poops[poopX] = 1;
			}
			poopY = -1;
		}

		Object.keys(poops).forEach(poopX => {
			canvas.rect(+poopX - 1, 400 - poops[poopX], 2, poops[poopX]);
		});
	}

	function drawScene() {
		canvas.background('skyblue')
		canvas.noStroke();

		// sun
		canvas.fill('yellow')
		canvas.ellipse(320, 50, 50);

		drawMountains();

		drawTrees();

		drawBird();

		drawPoop();

		drawText();
	}

	function drawText() {
		canvas.fill('white');
		var textSize = 28;
		canvas.textSize(textSize);
		canvas.textAlign('center');
		message.split('\n').forEach((message, i) =>
			canvas.text(message, 200, 130 + i * textSize + 3)
		);
	}
	function whenKeyPressed(key) {
		// console.log('keyPressed', key)
		if (key == 'left' || key == 'right' || key == 'up' || key == 'down' || key == 'space' || key == 'enter') {
			if (key == 'space' || key == 'enter') {
				poopX = birdX;
				poopY = birdY;
				poopVel = 1;
			}
			else {
				flapping = !flapping;
				if (key == 'left') {
					birdX -= 10;
				}
				if (key == 'right') {
					birdX += 10;
				}
				if (key == 'up') {
					birdY -= 7;
				}
				if (key == 'down') {
					birdY += 7;
				}
			}

			drawScene();
			return false;
		}
	}

	return {
		whenKeyPressed,
		// whenMouseClicked,
	}
}

export { canvasBirdDemo }
