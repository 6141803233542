import React, { useEffect } from 'react';
import { firestore } from 'firebase';

function useDoc<T extends firebase.firestore.DocumentSnapshot>(db: firebase.firestore.Firestore, collection: string, id: string) {
	// initialize our default state
	const [error, setError] = React.useState(null)
	const [loading, setLoading] = React.useState(true)
	const [value, setValue] = React.useState<T>(null)
	// when the id attribute changes (including mount)
	// subscribe to the value document and update
	// our state when it changes.
	useEffect(() => {
		const unsubscribe = db.collection(collection).doc(id).onSnapshot(doc => {
			setLoading(false);
			setValue(doc as T);
		}, err => {
			setError(err)
			setLoading(false)
		})
		// returning the unsubscribe function will ensure that
		// we unsubscribe from document changes when our id
		// changes to a different value.
		return () => unsubscribe()
	}, [id]);

	return {
		error,
		loading,
		value,
	}
}

function useQuery<T extends any>(
	db: firebase.firestore.Firestore,
	collection: string,
	whereClause?: (ref: firestore.CollectionReference) => firestore.Query,
	options?: {
		withChanges?: boolean
		includeMetadataChanges?: boolean
		// this should change when whereClause changes
		// to force useEffect to rerun
		queryHash?: string | number
		bypass?: boolean
	}
) {
	// initialize our default state
	const [error, setError] = React.useState(null)
	const [loading, setLoading] = React.useState(true)
	const [value, setValue] = React.useState<DocWithRef<T>[]>(null)
	const [changes, setChanges] = React.useState<firestore.DocumentChange[] | null>(null)

	options = options || {};
	// when the id attribute changes (including mount)
	// subscribe to the value document and update
	// our state when it changes.
	useEffect(() => {
		// use can't use a hook conditionally, so this allows useQuery to be conditional.
		if (options.bypass) {
			return;
		}

		const collectionRef = db.collection(collection);
		const filteredRef = whereClause ? whereClause(collectionRef) : collectionRef;
		let snapshotOptions = {includeMetadataChanges: options.includeMetadataChanges};
		const unsubscribe = filteredRef.onSnapshot(snapshotOptions, query => {
			if (options.withChanges) {
				setChanges(query.docChanges(snapshotOptions))
			}
			setValue(query.docs.map(invertDocRef));
			setLoading(false);

		}, err => {
			setError(err);
			setLoading(false);
		})
		// returning the unsubscribe function will ensure that
		// we unsubscribe from document changes when our id
		// changes to a different value.
		return () => {
			console.log('useQuery cleanup');
			unsubscribe();
		};
	}, [options.queryHash, options.bypass]);

	return {
		error,
		loading,
		value,
		changes,
		// _ref:
	}
}


type DocWithRef<T> = T & {
	_ref: firestore.DocumentSnapshot
	_updateTime: Date
	_createTime: Date
	_readTime: Date
}

function invertDocRef<T extends any>(docRef: firestore.DocumentSnapshot): DocWithRef<T> {
	let object: any = {};
	let data = docRef.data();
	for (let [k, v] of Object.entries(data)) {
		object[k] = v;
	}
	object._ref = docRef;

	// add timestamps
	if ((docRef as any)._createTime) {
		object._updateTime = new Date((docRef as any)._updateTime._seconds * 1000);
		object._createTime = new Date((docRef as any)._createTime._seconds * 1000);
		object._readTime = new Date((docRef as any)._readTime._seconds * 1000);
	}

	return object;
}

//   useDoc(null, 'asdf', '').
export { useDoc, useQuery, DocWithRef }
