import * as React from 'react';

import { Layout } from './Layout';
import { Button } from 'sancho/esm/Button';
import { PrefixedLink } from './lib/PrefixedLink';
import { CodeBox } from './CodeBox';
import { CodeRunner } from './lib/CodeRunner';

const IfStatementsLesson2: React.FC = () => {
	return (
		<Layout narrow>
			<h1>If Statements, continued</h1>

			<p>
				{/* So now you can write an <strong>if statement</strong>. */}
				Imagine that if today is Friday, you want to wear blue shoes.
				But on any other day, you want to wear red shoes.
				You could write an if statement to wear blue shoes on Friday,
				but what about the other days?  Fortunately, Javascript has you covered.
			</p>

			<p>
				<code>if</code> has a counterpart called <code>else</code>.
				It looks like this:
			</p>

			<CodeBox code={`
				if (today == 'Friday') {
					shoeColor = 'red';
				}
				else {
					shoeColor = 'blue';
				}
			`} />

			<p>
				This code does exactly what you would think it does.
				If the condition (in this case, <code>today == 'Friday'</code>) is true,
				it will execute the code in the first block (<code>shoeColor = 'red';</code>).
				Otherwise, it will execute the code in the second block.
			</p>

			<p>
				The else block works much like the if block.
				It is surrounded by <code>{'{'}</code> and <code>{'}'}</code>,
				and you can put whatever kind of code you want inside it.
			</p>

			<h2 className="hGreen">Try it out</h2>

			<p>
				Now it's your turn.
				Can you add an <code>else</code> block to the <code>if</code> statement?
				It should print something out.  (Maybe "That sounds good, but not as good as pizza...");
			</p>

			<CodeRunner docName="ifpizza" code={`
				var lunch = 'tuna sandwich';

				if (lunch == 'pizza') {
					console.log('Yay, pizza!')
				}
				// add an else block and print something about lunch here.

				// Then try changing the lunch variable
				// to 'pizza' to make sure it worked.
			`} />

			<p>Did you get it?  If not, check out the example above for help. And don't forget you can always ask a neighbor, or your favorite teacher!</p>

			<h2 className="hOrange">Tips</h2>
			{/* <ul> <li> */}
			<p>
				The code inside an <code>if</code> or <code>else</code> block should be indented.
				This makes the code easier to read because it helps you keep track of where
				the block starts and ends.
			</p>

			<p>
				You can use the tab key (left of the Q) to <strong>indent</strong> (shift to the right by four spaces) your code.
				To do it, put your cursor at the start of the line, or select one or more lines.
				Pressing shift and tab together will move it back out.
				The editor will sometimes be able to do it for you, but you can always change it yourself.
				Programmers use indentation to help keep their code organized.
				Indentation does not affect how the program runs.
			</p>

			<p></p>
			{/* </li> </ul> */}


			<h2 className="hPurple">Quiz Yourself</h2>
			<ul>{/*  className="basicList" */}
				<li>What is an else block?</li>
				{/* <li>How do you use an if statement?</li> */}
				{/* <li>What does an if statement do?</li> */}
				<li>What's an example of how you might use an if/else statement in a program of your own?</li>
				{/* <li>What is the difference between <code>=</code> and <code>==</code> in Javascript?</li> */}
				<li>How do you indent and outdent your code? Why should you do it?</li>
			</ul>

			{/* <BonusTask>
				If you're getting ahead, try changing this program to use <code>prompt()</code> to ask you for the weather,
				then alert instead of
			</BonusTask> */}

			<p>
				<PrefixedLink to="/lesson/if-statements"><Button>Back</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/project/quiz-game"><Button intent="primary">Next</Button></PrefixedLink>
			</p>
		</Layout>
	)
}

export { IfStatementsLesson2 }
