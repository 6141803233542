import * as React from 'react';

interface ErrorBoundaryState {
	hasError: boolean
	error: any
}
class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: error };
	}

	componentDidCatch(error: any, info: any) {
		console.error(error);
		console.error(info);
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
			<div>
				<h2>Something went wrong.</h2>
				<h3>{this.state.error.message}</h3>

			</div>
			);
		}

		return this.props.children;
	}
}

export { ErrorBoundary }
