import { useDocumentData, useDocumentDataOnce, useDocument } from 'react-firebase-hooks/firestore'
import firebase, { firestore } from 'firebase';
import { useUser } from '../firebase';
import { useState } from 'react';
import { useQuery } from '../lib/dbHooks';

interface UserDoc {
	content: string
	doc_name: string
	course_id: string
	user_id: string
	id?: string
	group?: string
	title?: string
	description?: string
	author?: string
	type?: 'console' | 'canvas' | 'custom' | 'quiz'
	/** milliseconds. firebase.firestore.Timestamp would also work but this is simpler. */
	updated?: number
	sharing?: boolean
	hidden?: boolean // can be shared by link only (sharing must be true)
	parent?: DocLink // identify where a remix came from
	remixes?: DocLink[] // remixed versions of this
}

interface DocLink {
	title: string
	doc_name: string
	uid: string
}

const getUserDocRef = (courseId: string, userId: string, docName: string) => (
	firebase.firestore().doc(`user_docs/${courseId}_${userId}_${docName}`)
);

const useUserDoc = (courseId: string, docName: string, once = true) => {
	const user = useUser();
	const [userDocRef] = useState(() => getUserDocRef(courseId, user && user.uid, docName));

	const useHook = once ? useDocumentDataOnce : useDocumentData as <T>(docRef?: firestore.DocumentReference, options?: {
		getOptions?: firestore.GetOptions;
		snapshotListenOptions?: firestore.SnapshotListenOptions | undefined;
		idField?: string;
	}) => [void | T, boolean, void | Error];

	const [userDoc, docLoading, docError] = useHook<UserDoc>(userDocRef);

	const updateDoc = (content: string) => {

		let updateObj: Partial<UserDoc> = {
			content: content,
			course_id: courseId,
			user_id: user && user.uid,
			doc_name: docName,
			// if we needed more security, firebase.firestore.FieldValue.serverTimestamp() would work,
			// but firebase.firestore.Timestamp is an object w/ seconds and nanoseconds.
			// a single number representing millis is much more convenient
			updated: Date.now(),
			type: 'quiz',
		}
		userDocRef.set(updateObj);
	}

	return [userDoc, updateDoc, docLoading, docError] as const;
}

export {
	useUserDoc,
	getUserDocRef,
	UserDoc,
}
