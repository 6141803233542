import * as React from 'react';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { Layout } from './Layout';

const KeyboardShortcutsPage = () => {
	return (<Layout solid narrow>
		<h1>Keyboard Shortcuts</h1>

		<p className="intro">
			Programming is a lot of work typing, so there are shortcuts to help get things done faster.
			Here's a list of shortcuts that you can use in the editor.
			I have tried to put the most useful ones first.
		</p>


		<table className="table"><tbody>
			<tr>
				<th>Action</th>
				<th>Windows/Linux</th>
				<th>Mac</th>
			</tr>
			<tr>
				<td>Undo</td>
				<td><code>ctrl + z</code></td>
				<td><code>command + z</code></td>
			</tr>
			<tr>
				<td>Redo</td>
				<td><code>ctrl + y</code></td>
				<td><code>command + shift + z</code></td>
			</tr>
			<tr>
				<td>Run code</td>
				<td><code>ctrl + enter</code></td>
				<td><code>command + enter</code></td>
			</tr>
			<tr>
				<td>Cut</td>
				<td><code>ctrl + x</code></td>
				<td><code>command + x</code></td>
			</tr>
			<tr>
				<td>Copy</td>
				<td><code>ctrl + c</code></td>
				<td><code>command + c</code></td>
			</tr>
			<tr>
				<td>Paste</td>
				<td><code>ctrl + v</code></td>
				<td><code>command + v</code></td>
			</tr>
			<tr>
				<td>Move line up/down</td>
				<td><code>alt + up/down</code></td>
				<td><code>option + up/down</code></td>
			</tr>
			<tr>
				<td>Select text</td>
				<td><code>shift + arrows</code></td>
				<td><code>shift + arrows</code></td>
			</tr>
			<tr>
				<td>Select all text</td>
				<td><code>ctrl + a</code></td>
				<td><code>command + a</code></td>
			</tr>
			<tr>
				<td>Indent</td>
				<td><code>tab</code></td>
				<td><code>tab</code></td>
			</tr>
			<tr>
				<td>Outdent</td>
				<td><code>shift + tab</code></td>
				<td><code>shift + tab</code></td>
			</tr>
			<tr>
				<td>Toggle comment</td>
				<td><code>ctrl + /</code></td>
				<td><code>command + /</code></td>
			</tr>
			<tr>
				<td>Find</td>
				<td><code>ctrl + f</code></td>
				<td><code>command + f</code></td>
			</tr>
			<tr>
				<td>Find and replace</td>
				<td><code>ctrl + h</code></td>
				<td><code>command + opt + f</code></td>
			</tr>
			<tr>
				<td>Select current word again</td>
				<td><code>ctrl + d</code></td>
				<td><code>command + d</code></td>
			</tr>
			<tr>
				<td>Get suggestions</td>
				<td><code>ctrl + space</code></td>
				<td><code>ctrl + space</code></td>
			</tr>
			<tr>
				<td>Complete a suggestion</td>
				<td><code>tab</code> or <code>enter</code></td>
				<td><code>tab</code> or <code>enter</code></td>
			</tr>
			<tr>
				<td>Rename variable</td>
				<td><code>f2</code></td>
				<td><code>f2</code></td>
			</tr>
			<tr>
				<td>Cancel selection</td>
				<td><code>escape</code></td>
				<td><code>escape</code></td>
			</tr>
			<tr>
				<th colSpan={3}>
					Moving the cursor <br/>
					<span style={{fontWeight: "normal"}}>Hold <code>shift</code> with any of these to select text as you go.</span>
				</th>
			</tr>
			{/* // r>['Moving the cursor</tr> */}
			{/* // r>['Hold shift with any of these to select text while moving</tr> */}
			<tr>
				<td>Move forward/back one word</td>
				<td><code>ctrl + right/left</code></td>
				<td><code>option + right/left</code></td>
			</tr>
			<tr>
				<td>Move to beginning of line</td>
				<td><code>home</code></td>
				<td><code>command + left</code></td>
			</tr>
			<tr>
				<td>Move to end of line</td>
				<td><code>end</code></td>
				<td><code>command + right</code></td>
			</tr>
			<tr>
				<td>Move up or down a whole screen</td>
				<td><code>page up/page down</code></td>
				<td><code>fn + up/down</code></td>
			</tr>
			<tr>
				<td>Move to the very beginning</td>
				<td><code>ctrl + home</code></td>
				<td><code>command + up</code></td>
			</tr>
			<tr>
				<td>Move to the very end</td>
				<td><code>ctrl + end</code></td>
				<td><code>command + down</code></td>
			</tr>
			{/* {shortcuts.map(shortcut => (
				shortcut.length == 3
				? <tr>
					<td>{shortcut[0]}</td>
					<td className="code">{shortcut[1]}</td>
					<td className="code">{shortcut[2]}</td>
				</tr>
				: <tr>
					<th colSpan={3}>{shortcut[0]}</th>
				</tr>
			))} */}
		</tbody></table>

		<p className="centered">
			<PrefixedLink to="">
				<Button intent="primary">Back to home</Button>
			</PrefixedLink>
		</p>
	</Layout>
)};


export { KeyboardShortcutsPage }
