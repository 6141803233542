import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
// import { CanvasRunner } from './lib/CanvasRunner';
import { CanvasDemo } from './lib/CanvasDemo';
import { spotlights, spotlights2, howToReadHex, randomFill, colorPicker } from './colorDemos';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';

// const dinoShape = [0, 7, 1, 7, 1, 9, 2, 9, 2, 10, 3, 10, 3, 11, 5, 11, 5, 10, 6, 10, 6, 9, 8, 9, 8, 8, 9, 8, 9, 7, 10, 7, 10, 1, 11, 1, 11.8, 1.4, 11.8, 2.4, 13.1, 2.4, 13.1, 1.4, 11.8, 1.4, 11, 1, 11, 0, 19, 0, 19, 1, 20, 1, 20, 5, 15, 5, 15, 6, 18, 6, 18, 7, 14, 7, 14, 9, 16, 9, 16, 11, 15, 11, 15, 10, 14, 10, 14, 13, 13, 13, 13, 14, 12, 14, 12, 15, 11, 15, 11, 19, 12, 19, 12, 20, 10, 20, 10, 17, 9, 17, 9, 16, 8, 16, 8, 17, 7, 17, 7, 18, 6, 18, 6, 19, 7, 19, 7, 20, 5, 20, 5, 16, 4, 16, 4, 15, 3, 15, 3, 14, 2, 14, 2, 13, 1, 13, 1, 12, 0, 12];
// const declareDinoShape = `var dinoShape = [${dinoShape.join(', ')}];`

const ColorsLesson = () => {
	return (
		<Layout narrow>
			{/* <> */}
			<h1>All About Colors</h1>

			<PagedContent pages={[
				<>
					<p className="intro">
						Today we're going to learn more about colors, including how
						screens display different colors, the RGB color format{/*  commonly used in programming */},
						and how to make use of random or generated colors in our programs.
					</p>

					<CanvasDemo codeFn={randomFill} />
				</>,<>

					<h2 className="hBlue">How a screen makes colors</h2>

					<p>
						Have you ever seen the colored lights above a stage?
						They are red, green, and blue, but when they shine down together on something, the light is white.
						That's because when you add red, green, and blue light together, they make white light.
					</p>

					<CanvasDemo codeFn={spotlights} />

				{/* </>,<>
					<h2 className="hBlue">How a screen makes colors</h2>
				 */}
					<p>
						And by changing the brightness of the red, green, and blue lights, they can create different lighting colors.
					</p>

					<CanvasDemo codeFn={spotlights2} />

					<p>
						A screen, like a computer or phone screen, works the same way.
						Each pixel, or dot on the screen, has a tiny red, green, and blue
						light, and they light up different amounts to show different colors.
					</p>

					<p>
						Because of this, any color can be described by the amount of red, green, and blue light that makes up the color.
						This is measured on a scale from 0 to 255.  Why 255? We'll get to that in a minute.
					</p>

					<div style={{background: 'rgb(174, 40, 255)', width: 100, height: 100, margin: 20, padding: 10, color: 'white'}} >
						red: 174 <br/>
						green: 40 <br/>
						blue: 255
					</div>

					<p>
						This color, for example, is maxed out on blue, has a good amount of red,
						and just a little green.  Together it makes a nice shade of purple.
					</p>
				</>,<>
					<h2 className="hBlue">Try it</h2>

					<p>
						Try changing around the amounts of red, green, and blue here by clicking on the bars, to see how it changes the overall color.
					</p>

					<p>
						Think of a color and see if you can make it. Can you find all of these colors?
					</p>

					<ul>
						<li>black</li>
						<li>white</li>
						<li>gray</li>
						<li>yellow</li>
						<li>orange</li>
						<li>brown</li>
						<li>pink</li>
					</ul>

					<CanvasDemo codeFn={colorPicker} />

				{/* </>,<> */}
					<h2 className="hBlue">What is a hex code?</h2>

					<p>
						In the program above, it shows something called a <strong>hex code</strong> next to the color you've created.
						The hex code for the shade of purple above is <strong>#ae28ff</strong>.
						u may have seen colors represented like this before: a <code>#</code> followed by
						six numbers or letters. Their long name is <strong>hexadecimal codes</strong>, or just hex codes.
						Let's learn how they work.
					</p>
				</>,<>
					<h2 className="hBlue">What is a hex code?</h2>

					<p>
						I could describe a color by saying
						"red is 174, green is 40, and blue is 255."
						But I need a way to write those down that the computer can also understand.
						And that's what a hex code is.
					</p><p>
						The first two digits of a hex code describe the level of red in the color.
						The next two describe the level of green in the color, and the last two describe the level of blue.
					</p><p>
						Each digit is either a number (0 through 9), or a letter.
						The letters represent the numbers 10 through 15 -
						a is 10, b is 11, on up through f which is 15.
					</p><p>
						Remember how the colors are on a scale from 0 to 255? That makes 256 possible levels of each color,
						because 0 is also possible.
					</p><p>
						Two combinations of digits that have 16 possible values each make 16 &times; 16, or 256 possible values.
						In other words, each two-digit combo can represent on of those 256 different numbers.
					</p><p>
						Each number can be calculated by converting any letters to numbers,
						then multiplying the left number by 16, then adding the right number.
						Here's an example:
					</p>

				{/* </>,<> */}
					<CanvasDemo codeFn={howToReadHex} />

					<h2 className="hBlue">Do I need all this math?</h2>

					<p>
						Most of the time, you don't need to do the math for these numbers yourself.
						If you want to pick a color, you can use a color picker.
						Then you just give the computer the code for the color you want, and it displays the color.
					</p>

					<p>
						But it's really helpful to understand how they work, and what the code is telling you.
						And if you understand them, you can also develop the skill to read a hex code,
						and know about what that color should look like.
						This is a useful skill for artists, designers, programmers, and game developers.
					</p>

					<h2 className="hBlue">Guess the color</h2>

					<p>
						If you want to practice this skill, here's a game I made (inspired by one I found online)
						that lets you practice reading and interpreting hex color codes:
					</p>

					<p>
						<PrefixedLink to="/published/xquxuall">Guess the Color</PrefixedLink>
					</p>
				</>,<>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Here's a nice little design. But let's be honest, the colors are kind of boring.
						Use your artistic vision and your newfound ability to find and choose to great colors
						to make it look really great.
						If you want, you can use
						the <PrefixedLink target="_blank" to="/lesson/colors/3">color picker</PrefixedLink> from this lesson.
					</p>

					<CanvasRunner vertical editorHeight={500} console docName="colors.colorDesign" code={`
						var colors = [
							'#ffffff',
							'#dddddd',
							'#bbbbbb',
							'#aaaaaa',
							'#888888',
							'#666666'
						];
						var colorIndex = 0;

						canvas.background('black');
						canvas.changePosition(200, 200);

						for (var i = 0; i < 1000; i++) {
							canvas.rotateDeg(27);
							canvas.scale(.995);

							var color = colors[colorIndex];
							canvas.fill(color);

							canvas.ellipse(300, 0, 70);

							colorIndex++;
							if (colorIndex == colors.length) {
								colorIndex = 0;
							}
						}
					`} />

					<h3>Bonus:</h3>
					<p>
						Try playing around with the numbers,
						especially the rotation and the number of colors,
						to see how it changes the spiral. Have fun with it!
					</p>
				</>
			]} />

		</Layout>
	);
};

export { ColorsLesson }
