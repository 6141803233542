import { noteNumber, noteFrequency } from './musicLib';

interface Envelope {
	/** the time in seconds it takes the note to reach full volume */
	attack: number,
	/** the time in seconds it takes to drop from the peak level to the sustain level */
	decay: number,
	/** the volume level to sustain the note at, as a fraction of the peak level */
	sustain: number,
	/** the time in seconds it takes to drop from the sustain level to zero */
	release: number,
}

class Instrument {
	_volume: number;
	_ctx: AudioContext;
	_wave: OscillatorType;
	envelope: Envelope;
	// _openNotes: any;

	constructor(context: AudioContext, wave: OscillatorType = 'triangle', volume = 1) {
		this._ctx = context;
		this._volume = volume;
		this._wave = wave;
		this.envelope = {
			attack: .01,
			decay: 0,
			sustain: 1,
			release: .05,
		}
		// this._openNotes = [];
	}

	playNote(noteOrFreq: string | number, duration: number, timeOffset = 0, volume?: number) {
		let frequency: number;
		if (typeof noteOrFreq == 'string') {
			frequency = noteFrequency(noteOrFreq);
		}
		else {
			frequency = noteOrFreq;
		}

		let osc = this._ctx.createOscillator();
		let gainNode = this._ctx.createGain();

		osc.connect(gainNode).connect(this._ctx.destination);

		osc.frequency.value = frequency;
		osc.type = this._wave;

		// set envelope
		volume = volume || this._volume;
		let t = this._ctx.currentTime + timeOffset;
		let env = this.envelope;
		let attack = env.attack;
		let decay = env.decay;
		let sustainVol = volume * env.sustain;
		let sustainDur = duration || .3;
		let release = .05;

		gainNode.gain.setValueAtTime(0, t);
		gainNode.gain.linearRampToValueAtTime(volume, t + attack);
		gainNode.gain.linearRampToValueAtTime(sustainVol, t + attack + decay);
		gainNode.gain.linearRampToValueAtTime(sustainVol, t + sustainDur - release);
		gainNode.gain.linearRampToValueAtTime(0, t + sustainDur);

		osc.start(t);
		osc.stop(t + sustainDur);
	}

	playChord(noteNames: string, duration: number, timeOffset = 0, volume: number, transpose = 0) {
		let notes = noteNames.split(' ');
		let rootOffset = null,
			lastOffset = null;

		let parser = /^(\+|[A-Ga-g][#b]?)?(\d{1,2})$/;

		for (let name of notes) {
			let match = parser.exec(name);
			if (!match) {
				// throw new Error("Invalid chord format");
				console.warn("Invalid chord format: ignoring note " + name);
			}
			else {
				let base = match[1],
					num = +match[2];
				if (base == '+' || !base) {
					let baseOffset = (!base) ? rootOffset : lastOffset;
					if (baseOffset == null) {
						console.warn("Invalid chord format: no root note before " + name);
					}
					else {
						this.playNote(noteFrequency(baseOffset + num + transpose), duration, timeOffset, volume);
					}
				}
				else {
					let offset = noteNumber(name);
					lastOffset = offset;
					if (!rootOffset) rootOffset = offset;
					this.playNote(noteFrequency(offset + transpose), duration, timeOffset, volume);
				}
			}
		}
	}

	// beginNote() {

	// }

	// endNote() {

	// }

	// stopAll() {

	// }
}

// for noise, there's an example here:
// https://developer.mozilla.org/en-US/docs/Web/API/AudioBufferSourceNode
// /*

export { Instrument };
