import * as React from 'react';
import { WrappedCanvas } from './WrappedCanvas';
import { CodeRunner } from './CodeRunner';
import { c } from './classNames';
import { handleInConsole } from './runner';
import { CanvasInterface, canvasTypedef } from './canvasWrapper';
import { Music, musicTypeDef } from './sound/Music';
import { useCleanup } from './hookUtils';
import { useTimeouts } from './useTimeouts';

interface CanvasRunnerProps {
	context?(...args: any[]): void
	docName?: string
	code: string
	exports?: string[]
	arrows?: boolean
	// noButtons? boolean
	// layout?: 'horizontal' | 'vertical'
	vertical?: boolean
	height?: number/*  | string */
	width?: number | string
	autorun?: boolean
	console?: boolean
	canvasHeight?: number
	editorHeight?: number
}

const CanvasRunner: React.FC<CanvasRunnerProps> = (props) => {
	// let canvasWrapperRef = React.useRef<CanvasWrapper>(null);
	let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	let canvasHeight = props.canvasHeight || props.height || 250;
	let totalHeight = canvasHeight;
	let editorHeight;

	if (props.vertical) {
		editorHeight = props.editorHeight || 400;
		totalHeight = editorHeight + canvasHeight;
	}
	else {
		totalHeight = Math.max(props.editorHeight || 0, canvasHeight + 2);
	}
	// let height = props.vertical ? canvasHeight + editorHeight : canvasHeight;

	let styles: React.CSSProperties = {
		width: props.width,
		height: totalHeight,
		display: 'flex',
		flexDirection: props.vertical ? 'column' : 'row',
	}

	let timeouts = useTimeouts();

	useCleanup(() => {
		Music.stopAll();
		timeouts.cancelAll();
		let canvas = canvasInterfaceRef.current && canvasInterfaceRef.current.wrapper;
		if (canvas) canvas.reset();
	});

	return (
		<div className={c('CanvasRunner', props.vertical && 'vertical')} style={styles}>
			<WrappedCanvas runButton={false} canvasInterfaceRef={canvasInterfaceRef}  scaledHeight={canvasHeight} scaledWidth={canvasHeight} />
			<CodeRunner
				noConsole={!props.console}
				height={props.vertical ? editorHeight : "100%"}
				docName={props.docName}
				code={props.code}
				context={(console) => {
					let context: any = {};
					if (props.context && typeof props.context == 'function') {
						context = props.context(console, canvasInterfaceRef);
					}

					let canvas = canvasInterfaceRef.current && canvasInterfaceRef.current.wrapper;

					return Object.assign({ canvas, Music, setTimeout: timeouts.setTimeout, setInterval: timeouts.setInterval }, context);
				}}
				exports={[
					'whenKeyPressed',
					'whenKeyReleased',
					'whenMouseMoved',
					'whenMouseClicked',
					...(props.exports || [])
				]}
				beforeRun={(source, console, context, exports) => {
					timeouts.cancelAll();
					context.canvas.reset();
					context.Music.stopAll();
					let cInterface = canvasInterfaceRef.current;
					cInterface.handlers.onKeyPressed = null
					cInterface.handlers.onKeyReleased = null
					cInterface.handlers.onMouseClicked = null
					cInterface.handlers.onMouseMoved = null

					cInterface.console = console;
					cInterface.hasRun = true;
				}}
				afterInit={api => {
					if (canvasInterfaceRef.current) {
						canvasInterfaceRef.current.handlers.onRun = api.runCode;
					}
					else {
						console.warn('afterInit happened before canvasInterface set')
					}
				}}
				// canvasInterface={canvasInterfaceRef.current,
				afterRun={(exported, {console/* , source */}) => {
					// debugger;
					if (exported.whenKeyPressed) {
						canvasInterfaceRef.current.handlers.onKeyPressed = handleInConsole(exported.whenKeyPressed, console/* , source */);
					}
					if (exported.whenKeyReleased) {
						canvasInterfaceRef.current.handlers.onKeyReleased = handleInConsole(exported.whenKeyReleased, console/* , source */);
					}
					if (exported.whenMouseMoved) {
						canvasInterfaceRef.current.handlers.onMouseMoved = handleInConsole(exported.whenMouseMoved, console/* , source */);
					}
					if (exported.whenMouseClicked) {
						canvasInterfaceRef.current.handlers.onMouseClicked = handleInConsole(exported.whenMouseClicked, console/* , source */);
					}
				}}
				extraLibs={canvasTypedef + musicTypeDef}
				autorun={props.autorun}
				outerHeight={props.vertical}
			/>
		</div>
	);
}

export { CanvasRunner, CanvasRunnerProps }
