import * as React from 'react';
import { Link } from 'react-router-dom'
import { Layout } from './Layout';
import { CourseMaterialsList } from './CourseMaterialsList';

const SVLAHome = () => (
	<Layout solid>
		<h1>Code Padawans</h1>

		<p>Welcome to Code Padawans! This is the class page.  I'll add class materials here as we move along.</p>

		<CourseMaterialsList classStr="svla" />
	</Layout>
);


export { SVLAHome }
