import * as React from 'react';
import { Button } from 'sancho/esm/Button';

import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Answer } from './lib/Quiz';

const MathOperatorsLesson: React.FC = () => {
	return <Layout narrow>
		<h1>Lesson: Math Operators</h1>

		<p className="intro">
			This lesson is a simple one, but it's very important,
			because you will use this all the time.
		</p>

		<p>
			We'll be learning about math <strong>operators</strong>.
			In Javascript, operators are symbols that are used to perform common actions.
		</p>

		<p>
			You have already learned about <code>=</code>, which is the <strong>assignment operator</strong>.
			Its action is to assign values from right to left.
		</p>

		<p>
			And you have learned about <code>==</code>, which is the <strong>equality operator</strong>.
			It tests if the values on either side of it are equal.
		</p>

		<p>
			Now it's time to learn some new ones. You'll recognize these first ones already.
		</p>

		<hr className="spacer"/>
		<h3 className="hBlue">Basic Arithmetic Operators</h3>
		<p>These ones do exactly what you think they do.</p>
		<ul>
			<li><code>+</code> adds two numbers together</li>
			<li><code>-</code> subtracts the right number from the left number</li>
			<li><code>*</code> multiplies two numbers together. Don't use x.  It won't work.</li>
			<li><code>/</code> divides the left number by the right number. Don't use \. It won't work either.</li>
		</ul>

		<CodeBox code={`
			var result = 10 + 2; // result = 12;
			var result = 10 - 2; // result = 8;
			var result = 10 * 2; // result = 20;
			var result = 10 / 2; // result = 5;
		`}/>

		<p>
			<strong>Note:</strong> You've seen <code>+</code> already, right?
			You can use it to put two strings together, like this:
			<code>console.log("Fuzzy Wuzzy was a " + animal);</code>
		</p>

		<p>
			So <code>+</code> can do different things to different types of values.
			Because of this, be careful when working with numbers and strings together.
			<code>'1' + '1'</code> and <code>1 + 1</code> are very different.
			Run this code and see:
		</p>

		<CodeRunner code={`
			console.log(1 + 1);
			console.log('1' + '1');
			// how about these?
			console.log(1 + '1');
			console.log('1' + 1);
		`} />

		<hr className="spacer"/>
		<h3 className="hBlue">Shorthand Arithmetic Operators</h3>
		<p>
			These ones change an existing number by another number.
			This is very useful when you are keeping a running total,
			such as adding points to a score, or counting how many times something happens.
		</p>

		<p>
			<code>a += b</code> is the same as <code>a = a + b</code>.
			In other words, you are updating <code>a</code> by adding <code>b</code> to it.
			Here's an example of how you might use it while calculating your grocery bill:
		</p>

		<CodeRunner code={`
			var total = 0;
			var milk = 1.99;
			var cereal = 2.99;
			var apples = 2.02;

			var tax = 1.07;

			total += milk;
			total += cereal;
			total += apples;
			total *= tax;

			console.log("Your total is", total.toFixed(2));
			console.log("Thank you for shopping at Jabba's");
		`} />

		<ul>
			<li><code>+=</code> adds and assigns.</li>
			<li><code>-=</code> subtracts and assigns.</li>
			<li><code>*=</code> multiplies and assigns.</li>
			<li><code>/=</code> divides and assigns.</li>
		</ul>

		<hr className="spacer"/>
		<h3 className="hBlue">Even Shorter: Increment/Decrement Operators</h3>
		<p>
			There are two more shorthand operators that are very common in programming.
			Don't be intimidated by the fancy name. You can just think of them as the "add one"
			and "subtract one" operators.
		</p>

		<p>
			Very often we need to count how many times something happens.
			You did it in your quiz when somebody got an answer right, but
			you did it the long way.  Adding one to a variable is so common
			that it has its own operator: <code>++</code>.
		</p>

		<p>
			If you might write <code>score = score + 1</code>,
			you can write it as <code>score++</code> instead.
		</p>

		<p>
			The same goes for subtracting one.<code>livesLeft = livesLeft - 1</code>
			can be written as <code>livesLeft--</code> instead.
		</p>

		<p>
			Using <code>++</code> or <code>--</code> is a very common
			pattern in loops, which we'll learn about very soon.
		</p>


		<h3 className="hPurple">Quiz Yourself</h3>
		<p>
			What will the code below print out?  Calculate it yourself before you run it, and see if you got it right.
		</p>

		<CodeBox code={`
			var magicNumber = 5 + 8;
			magicNumber += 2;
			magicNumber++;
			magicNumber /= 4;
			magicNumber *= 3;
			magicNumber--;

			console.log(magicNumber);
		`} />

		<Answer id="mathops-q-sequence:11">
			11
		</Answer>

		<h2 className="hYellow">Bonus Challenge</h2>
		<p>Take a look at this program:</p>

		<CodeRunner docName="mathops-birthdayBonus" code={`
			var birthYear = prompt('what year were you born?')
			var year = 2019;
			var age = year - birthYear;

			alert("you are about " + age + " years old");
		`} />

		<p>
			It's pretty close, but it's not right all the time.
			Specifically, if you haven't had your birthday yet this year,
			it will say you are older than you really are.
		</p>

		<p>
			Can you fix it so it is always right?  You'll need to ask them if they have had their birthday yet,
			and if they haven't, adjust their age accordingly.
		</p>

		<h3>Another one?</h3>

		<p>
			Did you do it? Great! Here's another:
		</p>
		<p>
			Can you write a program that does just the opposite?
			Instead of asking for your birth year and telling your age,
			have it ask for your age, and then tell what year you were born.
		</p>

		<p>To get it exactly right, it should also ask if you have already had a birthday this year.</p>

		<div className="container blOrange">
			<h2 className="hOrange">Discuss</h2>
			<p>Show your program to a neighbor, and have them show you theirs.</p>

			<ul>
				<li>What differences do you see between your two programs? How are they similar?</li>
				<li>Do they function the same? Can you find any differences?</li>
				<li>Can you come up with another program that could work in a similar way, but calculate something different?</li>
			</ul>
		</div>

		<PrefixedLink to="/project/simple-calc">
			<Button intent="primary">Go to Calculator Project</Button>
		</PrefixedLink>

		{/* <Links /> */}
	</Layout>
}


export { MathOperatorsLesson }
