import * as React from 'react';
import { c } from './lib/classNames';

interface CodeBoxProps {
	code: string
	updateCode?(code: string, run?: boolean): void
	// runnable?: boolean
	docName?: string
	clickToShow?: boolean
}

declare var hljs: any;

const CodeBox: React.FC<CodeBoxProps> = (props) => {
	let [show, setShow] = React.useState(!props.clickToShow);
	let [hideable, setHideable] = React.useState(() => !show);

	let code = outdent(props.code)/*  || props.children */;
	const boxRef: React.MutableRefObject<HTMLElement> = React.useRef(null);
	React.useEffect(() => {
		hljs.highlightBlock(boxRef.current);
	});

	return <div>
		<code className={c('box javascript', !show && 'hidden', hideable && 'hideable')}
			ref={boxRef}
			onClick={_ => {setShow(true); setTimeout(setHideable, 400, false)}}
			>
			{code}
		</code>
		{props.updateCode && <>
			<button className="grayBtn" onClick={() => props.updateCode(code)}>Send to editor</button>
			{} &nbsp; {}
			<button className="grayBtn" onClick={() => props.updateCode(code, true)}>Send and run</button>
		</>}
	</div>
}

const outdent = (str: string) => {
	if (str[0] == '\n') str = str.slice(1);
	let padding = str.match(/^\n?(\s*)/)[1];
	if (!padding) return str;
	let outdented = str.replace(new RegExp('(^|\\n)' + padding, 'g'), '$1');
	let trimmed = outdented.trimRight();
	return trimmed;
}

const Snippet: React.FC<{}> = (props) => <code className="snippet">{props.children}</code>

export { CodeBox, CodeBoxProps, Snippet, outdent }
