import * as React from 'react';
import { shuffle } from './shuffle';
import { c } from './classNames';
// import { Button } from 'sancho/esm/Button';
// import { TextArea, InputGroup, TextAreaProps } from 'sancho/esm/Form';
// import { useUserDoc } from '../model/userDoc';
// import { Layout } from '../Layout';
// import { useCoursePrefix } from './useCoursePrefix';
// import { Link } from 'react-router-dom';

// use this elsewhere
interface MatchingQuestionProps {
	id: string
	name: string
	className?: string
	title: string
	intro: React.ReactNode
	questions: MatchingQuestionData[]
	asCode?: boolean
	// id: string
	// textAreaProps?: TextAreaProps
	onInit?(stateManager: QuestionStateManager)
}

interface MatchingQuestionData {
	text: string
	answer: React.ReactNode
}

const MatchingQuestion: React.FC<MatchingQuestionProps> = (props) => {
	const questionStateManager = useQuestionState(props);

	return (
		<div className={c('MatchingQuestion', props.className)}>
			<h2>{props.title}</h2>
			<p>{props.intro}</p>

			<div className="selected-list">
				{questionStateManager && questionStateManager.questions.map(questionState => {
					if (!questionState.selectedMatch) return null;
					return (
						<div key={questionState.question.text} className="matched" onClick={() => questionStateManager.resetQuestion(questionState)} >
							<a className="close">&times;</a>
							<strong className={c('q', props.asCode && 'code')}>
								{questionState.question.text}
							</strong>
							<span className="ans">
								{questionState.selectedMatch.question.answer}
							</span>
							{/* <a onClick={() => questionStateManager.resetQuestion(questionState)}>&times;</a> */}
						</div>
					);
				})}
			</div>

			<div className="cols">
				<div className="question-list">
					{questionStateManager && questionStateManager.questions.map(questionState => {
						if (questionState.selectedMatch) return null;
						let questionDesc = questionState.question;
						let questionText = questionDesc.text;
						return (
							<div key={questionState.question.text} className={c('question', questionState.active && 'active', props.asCode && 'code')}
								onClick={() => questionStateManager.toggleQuestion(questionState)}
							>
								<strong>{questionText}</strong>
								{/* {questionState.selectedMatch && <div className="ans">
									{questionState.selectedMatch.question.answer}
								</div>} */}
							</div>
						);
					})}
				</div>
				<div className="answer-list">
					{questionStateManager && questionStateManager.answers.map(answerState => {
						if (answerState.selectedMatch) return null;

						let questionDesc = answerState.question;
						let answerText = questionDesc.answer;
						return (
							<div key={answerState.question.text} className={c('answer', answerState.active && 'active')}
								onClick={() => questionStateManager.toggleAnswer(answerState)}
							>
								{answerText}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

interface QuestionStateManager {
	questionId: string
	questionName: string
	questions: Readonly<QuestionTestState[]>
	answers: Readonly<QuestionTestState[]>
	toggleQuestion(question: QuestionTestState)
	toggleAnswer(answer: QuestionTestState)
	resetQuestion(question: QuestionTestState)
	applyAnswers(map: OMap)
	serializeAnswers(): OMap
	// showAnswers()
	// checkAnswers()
}

interface QuestionTestState {
	active: boolean
	// matched: boolean
	question: MatchingQuestionData
	selectedMatch: QuestionTestState
}

function toQuestionState(question: MatchingQuestionData): QuestionTestState {
	return {
		active: false,
		// matched: false,
		question: question,
		selectedMatch: null,
	}
}

function useQuestionState(props: MatchingQuestionProps) {
	let [state, updateState] = React.useState<QuestionStateManager>(null);
	// let [counter, setCounter] = React.useState(0);

	React.useEffect(() => {
		let activeQuestion: QuestionTestState = null;
		let activeAnswer: QuestionTestState = null;

		let shuffledForQs = props.questions.map(toQuestionState);
		let shuffledForAs = props.questions.map(toQuestionState);

		shuffle(shuffledForQs);
		shuffle(shuffledForAs);

		let stateManager: QuestionStateManager = {
			questionId: props.id,
			questionName: props.name,
			questions: shuffledForQs,
			answers: shuffledForAs,
			toggleQuestion: (question: QuestionTestState) => {
				// console.log('toggleQuestion');
				// selecting a match for the already selected answer
				if (activeAnswer) {
					question.selectedMatch = activeAnswer;
					question.active = false;
					activeAnswer.selectedMatch = question;
					activeAnswer.active = false;
					activeAnswer = null;
					activeQuestion = null;
				}
				// setting/unsetting the first half of the selection
				else if (activeQuestion) {
					if (activeQuestion == question) {
						activeQuestion.active = false;
						activeQuestion = null;
					}
					else {
						activeQuestion.active = false;
						activeQuestion = question;
						activeQuestion.active = true;
					}
				}
				else {
					activeQuestion = question;
					activeQuestion.active = true;
				}

				// rerender
				// console.log('rerendering?'); setCounter(counter + 1);
				updateState({...stateManager});
			},
			toggleAnswer: (answer: QuestionTestState) => {
				// console.log('toggleAnswer');
				// selecting a match for the already selected question
				if (activeQuestion) {
					answer.selectedMatch = activeQuestion;
					answer.active = false;
					activeQuestion.selectedMatch = answer;
					activeQuestion.active = false;
					activeAnswer = null;
					activeQuestion = null;
				}
				// setting/unsetting the first half of the selection
				else if (activeAnswer) {
					if (activeAnswer == answer) {
						activeAnswer.active = false;
						activeAnswer = null;
					}
					else {
						activeAnswer.active = false;
						activeAnswer = answer;
						activeAnswer.active = true;
					}
				}
				else {
					activeAnswer = answer;
					activeAnswer.active = true;
				}


				// rerender
				// console.log('rerendering?'); setCounter(counter + 1);
				updateState({...stateManager});
			},
			resetQuestion: (question: QuestionTestState) => {
				// console.log('resetQuestion');
				let answer = question.selectedMatch;
				answer.selectedMatch = null;
				question.selectedMatch = null;
				// console.log('rerendering?'); setCounter(counter + 1);
				updateState({...stateManager});
			},
			applyAnswers: (qToAMap: OMap) => {
				let answerStateMap = {} as OMap<QuestionTestState>;
				// let aToQMap = {} as {[key: string]: string};

				// for (let key in qToAMap) {
				// 	aToQMap[qToAMap[key]] = qToAMap[key];
				// }

				stateManager.answers.forEach(aState => {
					answerStateMap[aState.question.text] = aState;
					aState.selectedMatch = null;
					aState.active = null;
				});

				stateManager.questions.forEach(qState => {
					qState.selectedMatch = null;
					qState.active = null;

					let answerKey = qToAMap && qToAMap[qState.question.text];
					if (answerKey) {
						let answerState = answerStateMap[answerKey];
						qState.selectedMatch = answerState;
						answerState.selectedMatch = qState;
					}
				});

				updateState({...stateManager});
			},
			serializeAnswers: () => {
				let map: OMap = {};

				stateManager.questions.forEach(qState => {
					if (qState.selectedMatch) {
						map[qState.question.text] = qState.selectedMatch.question.text;
					}
				});

				return map;
			},
		}

		if (props.onInit) props.onInit(stateManager);

		updateState(stateManager);
		// return stateManager;
	}, []);

	console.log('state is', state);

	return state;
}

export { MatchingQuestion, MatchingQuestionProps, MatchingQuestionData, QuestionStateManager }
