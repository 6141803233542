import * as React from 'react';
import { Route, Switch, Link } from 'react-router-dom'
import { RouteChildrenProps, RouteProps, Redirect } from 'react-router';

import { useAppContext, useDb } from './AppContext';
import { Layout, ScrollToTop } from './Layout';
import { SampleProjectPage } from './SampleProjectPage';
// import { SampleProjectPage2 } from './SampleProjectPage2';
import { MadLibsProject } from './MadLibsProject';
import { MadLibsProject2 } from './MadLibsProject2';
import { VariablesLesson } from './VariablesLesson';
import { AHSJHome } from './AHSJHome';
import { SVLAHome } from './SVLAHome';
import { LogIn } from './LogIn';
import { useAppAuthState } from './firebase';
import { IntroLesson } from './IntroLesson';
import { VariablesQuiz } from './VariablesQuiz';
import { FunctionCallsLesson } from './FunctionCallsLesson';
import { InteractiveMadLibsProject } from './InteractiveMadLibsProject';
import { InteractiveMadLibsProject2 } from './InteractiveMadLibsProject2';
import { IntroduceGameStudio } from './IntroduceGameStudio';
import { FunctionsLesson } from './FunctionsLesson';
import { IfStatementsLesson1 } from './IfStatementsLesson1';
import { IfStatementsLesson2 } from './IfStatementsLesson2';
import { CanvasPage } from './CanvasPage';
import { QuizGame1 } from './QuizGame1';
import { ScratchPad } from './ScratchPad';
import { QuizGame2 } from './QuizGame2';
import { IfStatementsQuiz } from './IfStatementsQuiz';
import { FunctionsQuiz } from './FunctionsQuiz';
import { SimpleCalculatorProject } from './SimpleCalculatorProject';
import { CanvasDocPage } from './CanvasDocPage';
import { ColorDocPage } from './ColorDocPage';
import { CanvasLesson } from './CanvasLesson';
import { MathOperatorsLesson } from './MathOperatorsLesson';
import { CanvasHangmanExample } from './CanvasHangmanExample';
import { DrawProject } from './DrawProject';
import { CanvasReview } from './CanvasReview';
import { BlankCanvas } from './BlankCanvas';
import { MathOperatorsQuiz } from './MathOperatorsQuiz';
import { LoopsLesson } from './LoopsLesson';
import { ArraysLesson } from './ArraysLesson';
import { EventsLesson } from './EventsLesson';
import { UserProjectsPage } from './UserProjectsPage';
import { UserProjectPage } from './UserProjectPage';
import { ArraysLesson2 } from './ArraysLesson2';
import { NotFound } from './NotFound';
import { SharedProjectPage } from './SharedProjectPage';
import { SharedProjectsPage } from './SharedProjectsPage';
import { SokobanProject } from './SokobanProject';
import { LoopsReview } from './LoopsReview';
import { Canvas2Lesson } from './Canvas2Lesson';
import { Arrays2Lesson } from './Arrays2Lesson';
import { ShoppingListProject } from './ShoppingListProject';
import { AndOrNotLesson } from './AndOrNotLesson';
import { AndOrNotLesson2 } from './AndOrNotLesson2';
import { AnimationLesson } from './AnimationLesson';
import { FreeProjectLesson } from './FreeProjectLesson';
import { ObjectsLesson } from './ObjectsLesson';
import { RandomNumbersLesson } from './RandomNumbersLesson';
import { GameLoopLesson } from './GameLoopLesson';
import { FinalQuiz } from './FinalQuiz';
import { StringsLesson } from './StringsLesson';
import { SoundLesson } from './SoundLesson';
import { GridLesson } from './GridLesson';
import { PracticeChallengesLesson } from './PracticeChallengesLesson';
import { PongProject } from './PongProject';
import { DebuggingLesson } from './DebuggingLesson';
import { TypingGameLesson } from './TypingGame';
import { TransformationsLesson } from './TransformationsLesson';
import { ColorsLesson } from './ColorsLesson';
import { FinalProjectLesson } from './FinalProjectLesson';
import { Functions2Lesson } from './Functions2Lesson';
import { BouncingDotsLesson } from './BouncingDotsLesson';
import { KeyboardShortcutsPage } from './KeyboardShortcutsPage';

interface MainProps {}

const Home = () => (
	<Layout>
		<h1>Let's Code with JS</h1>

		<p className="centered">You are probably looking for one of these classes:</p>

		<ul className="basicList centered">
			{/* <li><Link to="/svla">Code Padawans (South Valley Leadership Academy)</Link></li> */}
			{/* <li><Link to="/ahsj">Let's Code with JS (American Heritage South Jordan)</Link></li> */}
			<li><a href="/svla">Code Padawans (South Valley Leadership Academy)</a></li>
			<li><a href="/ahsj">Let's Code with JS (American Heritage South Jordan)</a></li>
		</ul>
	</Layout>
);

const Main: React.FC = () => {
	let db = useDb();
	let context = useAppContext();
	(window as any).appContext = context;
	(window as any).db = db;

	return (
		<Switch>
			<Route exact path={['/', '/index.html']} component={Home} />
			<Route path="/svla/published/:docName" component={SharedProjectPage} />
			<Route path="/ahsj/published/:docName" component={SharedProjectPage} />

			<PrivateRoute path={['/ahsj', '/ahsj.html']} component={AHSJRouter} />} />
			<PrivateRoute path={['/svla', '/svla.html']} component={SVLARouter} />} />

			<Route path="/published/:docName" component={SharedProjectPage} />
			<Route path="/preview/:uid/:docName" component={SharedProjectPage} />

			{/* <PrivateRoute exact path="/user/projects" component={UserProjectsPage} />
			<PrivateRoute path="/user/project/:docName" component={UserProjectPage} /> */}

			<Route component={NotFound} />
		</Switch>
	)
}

const AHSJRouter = (props) => <CourseRouter {...props} home={AHSJHome} />;
const SVLARouter = (props) => <CourseRouter {...props} home={SVLAHome} />;

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...routeProps }) => {
	let [user/* , loading, error */] = useAppAuthState();
	// return <p>Hello</p>;

	return (
		<Route {...routeProps} render={props =>
			user ? <Component {...props} /> : <LogIn />
		} />
	);
}

interface CourseRouterProps extends RouteChildrenProps {
	home: React.ComponentType
}

const CourseRouter = (props: CourseRouterProps) => {
	// console.log('route props', props)
	let url = props.match.url;

	return <>
		<ScrollToTop />
		<Switch>
			<Route exact path={url} component={props.home} />

			<Route exact path={`${url}/keyboard-shortcuts`} component={KeyboardShortcutsPage} />
			<Route exact path={`${url}/project`} component={SampleProjectPage} />
			<Route exact path={`${url}/shared-projects`} component={SharedProjectsPage} />

			<Route path={`${url}/lesson/hello-world`} component={IntroLesson} />
			{/* TODO: convert this to a paged lesson with PagedContent  */}
			<Route exact path={`${url}/project/mad-libs`} component={MadLibsProject} />
			<Route exact path={`${url}/project/mad-libs/2`} component={MadLibsProject2} />
			<Route path={`${url}/lesson/variables`} component={VariablesLesson} />
			<Route path={`${url}/quiz/variables`} component={VariablesQuiz} />
			<Route path={`${url}/lesson/function-calls`} component={FunctionCallsLesson} />
			{/* TODO: convert this to a paged lesson with PagedContent  */}
			<Route exact path={`${url}/project/interactive-mad-libs`} component={InteractiveMadLibsProject} />
			<Route exact path={`${url}/project/interactive-mad-libs/2`} component={InteractiveMadLibsProject2} />
			<Route path={`${url}/introduce-game-studio`} component={IntroduceGameStudio} />

			<Route path={`${url}/lesson/functions`} component={FunctionsLesson} />
			{/* TODO: convert this to a paged lesson with PagedContent  */}
			<Route exact path={`${url}/lesson/if-statements`} component={IfStatementsLesson1} />
			<Route exact path={`${url}/lesson/if-statements/2`} component={IfStatementsLesson2} />
			<Route path={`${url}/project/quiz-game`} component={QuizGame1} />
			<Route path={`${url}/project/quiz-game-2`} component={QuizGame2} />
			<Route exact path={`${url}/quiz/if-statements`} component={IfStatementsQuiz} />
			<Route exact path={`${url}/quiz/functions`} component={FunctionsQuiz} />
			{/* <Route exact path={`${url}/quiz/functions`} component={FunctionsQuiz} /> */}

			<Route exact path={`${url}/lesson/math-operators`} component={MathOperatorsLesson} />
			<Route exact path={`${url}/project/simple-calc`} component={SimpleCalculatorProject} />
			<Route exact path={`${url}/quiz/math-operators`} component={MathOperatorsQuiz} />

			<Route exact path={`${url}/lesson/canvas`} component={CanvasLesson} />
			<Route exact path={`${url}/project/draw`} component={DrawProject} />

			<Route exact path={`${url}/scratch-pad`} component={ScratchPad} />
			<Route exact path={`${url}/colors`} component={ColorDocPage} />

			<Route exact path={`${url}/canvas`} component={CanvasDocPage} />
			<Route exact path={`${url}/canvas/example/guessing-game`} component={CanvasHangmanExample} />
			<Route exact path={`${url}/canvas/review`} component={CanvasReview} />

			{/* week 5 */}

			<Route path={`${url}/lesson/events`} component={EventsLesson} />

			<Route path={`${url}/lesson/loops`} component={LoopsLesson} />
			<Route path={`${url}/lesson/loops-review`} component={LoopsReview} />
			{/* TODO: convert this to a paged lesson with PagedContent  */}
			<Route exact path={`${url}/lesson/arrays`} component={ArraysLesson} />
			<Route exact path={`${url}/lesson/arrays/2`} component={ArraysLesson2} />

			<Redirect from={`${url}/canvas-page`} to={`${url}/blank-canvas`} />
			<Route exact path={`${url}/blank-canvas`} component={BlankCanvas} />
			<Route exact path={`${url}/user/projects`} component={UserProjectsPage} />
			<Route path={`${url}/user/project/:docName`} component={UserProjectPage} />
			<Route path={`${url}/project/sokoban`} component={SokobanProject} />
			<Route path={`${url}/lesson/canvas-2`} component={Canvas2Lesson} />
			<Route path={`${url}/lesson/arrays-2`} component={Arrays2Lesson} />
			<Route path={`${url}/project/shopping-list`} component={ShoppingListProject} />
			{/* TODO: convert this to a paged lesson with PagedContent  */}
			<Route exact path={`${url}/lesson/and-or`} component={AndOrNotLesson} />
			<Route exact path={`${url}/lesson/and-or/2`} component={AndOrNotLesson2} />
			<Route path={`${url}/lesson/animation`} component={AnimationLesson} />
			<Route path={`${url}/lesson/free-project`} component={FreeProjectLesson} />
			<Route path={`${url}/lesson/objects`} component={ObjectsLesson} />
			<Route path={`${url}/lesson/random`} component={RandomNumbersLesson} />
			<Route path={`${url}/lesson/game-loop`} component={GameLoopLesson} />
			<Route path={`${url}/quiz/final`} component={FinalQuiz} />
			<Route path={`${url}/lesson/strings`} component={StringsLesson} />
			<Route path={`${url}/lesson/sounds`} component={SoundLesson} />
			<Route path={`${url}/lesson/grids`} component={GridLesson} />
			<Route path={`${url}/lesson/practice-challenges`} component={PracticeChallengesLesson} />
			<Route path={`${url}/lesson/debugging`} component={DebuggingLesson} />
			<Route path={`${url}/lesson/typing-game`} component={TypingGameLesson} />
			<Route path={`${url}/lesson/transformations`} component={TransformationsLesson} />
			<Route path={`${url}/lesson/colors`} component={ColorsLesson} />
			<Route path={`${url}/lesson/final-project`} component={FinalProjectLesson} />
			<Route path={`${url}/lesson/functions2`} component={Functions2Lesson} />
			<Route path={`${url}/lesson/bouncing-dots`} component={BouncingDotsLesson} />

			<Route path={`${url}/ai-pong`} component={PongProject} />

			<NotFound/>
		</Switch>
	</>
}

export { Main, MainProps }
