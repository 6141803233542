import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const ArraysLesson = () => {
	// let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	// React.useEffect(() => {
	// 	if (canvasInterface) {
	// 		setupDemo(canvasInterface);

	// 	}
	// }, [canvasInterface]);
	// const onInit = (canvasInterface) => setupDemo(canvasInterface);
	// const onInit = setupDemo

	return (
		<Layout narrow>
			<h1>Arrays</h1>

			<p className="intro">
				Today we will add a power tool to our programming tool belt!
				Almost every program you will ever write will make use of this one.
			</p>

			<p>Let's say I want to make a program that introduces me and all my siblings.</p>

			<CodeRunner code={`
				var me = 'Brian';
				var sibling1 = 'Jessica';
				var sibling2 = 'Eric';
				var sibling3 = 'Brittany';

				console.log('Hi, my name is ' + me);
				console.log('I have 3 siblings:');
				console.log(sibling1, sibling2, sibling3);
			`} />

			<p>
				It works, but it's not the most useful program, right?
				I can make it more useful by letting anybody introduce themselves
				and their siblings.
			</p>

			<CodeRunner code={`
				var me = prompt('What is your name?');
				var sibling1 = prompt("What is your first sibling's name?");
				var sibling2 = prompt("What is your second sibling's name?");
				var sibling3 = prompt("What is your third sibling's name?");

				console.log('Hi, my name is ' + me);
				console.log('I have 3 siblings:');
				console.log(sibling1, sibling2, sibling3);
			`} />

			<p>Try it out.  Put in your name, and your three siblings.</p>

			<p>But wait! What if you don't have three siblings? I can't just assume that, can I?</p>

			<p>
				Now my program can list anybody's siblings.  Ok, kind of.
				It works, as long as you have exactly 3 siblings.
				But what if you don't?
			</p>

			<h3 className="hBlue">Introducing... the array!</h3>

			<p>
				There's a much better way to store a list of similar things
				without having to create a separate variable for each one.
				It's called an <strong>array</strong>.
			</p>

			<p>
				An array is the structure that represents a list
				in a javascript program.
				let's learn how to create one!
			</p>

			<CodeRunner code={`
				var me = prompt('What is your name?');
				var siblings = ['Jessica', 'Eric', 'Brittany'];

				console.log('Hi, my name is ' + me);
				console.log('I have ' + siblings.length + ' siblings:');
				console.log(siblings);
			`} />

			<p>
				You can create an array using square brackets: <code>[</code> and <code>]</code>.
				(They are to the right of the P on the keyboard.)
				Each item in the array goes in between the brackets.
				Items in an array can be strings or numbers, or any of the other types that we will learn about.
			</p>

			<p>
				Arrays are awesome and amazing! One reason is because they can be any size.
				In other words, they can hold any number of things in them.
				{/* They can even change size.  You can add and remove things from them. */}
			</p>

			<p>
				You can access each item in an array separately, too.
				See if you can guess how it works from this example:
			</p>

			<CodeRunner code={`
				var siblings = ['Jessica', 'Eric', 'Brittany'];

				var oldest = siblings[0];
				console.log(oldest + ' is the oldest sibling');

				var middle = siblings[1];
				console.log(middle + ' is the next sibling');

				var youngest = siblings[2];
				console.log(youngest + ' is the youngest sibling');
			`} />

			<p>
				<code>sibilngs[0]</code> means "the first item in the siblings array."
				{/* You can use the <code>[]</code> (square brackets) after the name of the array
				with a number  */}
			</p>

			<p>
				Arrays start at zero, which can be kind of confusing at first.
				Think of it as the distance from the beginning of the array.
			</p>

			<p>
				How old are you when you are first born? Zero years old.
				How far have you gone when you first start running a race? Zero miles.
				Arrays work the same way.
			</p>

			<p>If you think of arrays that way, it will be easier to get used to them starting at zero.</p>

			{/* <p>
				Since the first thing in the array is at 0,
				the last thing in the array is at <code>siblings.length - 1</code>.
				In this example, <code>siblings.length</code> is 3, but
				<code>siblings[2]</code> is the last sibling.
			</p> */}

			<h3 className="hPurple">Quiz Yourself</h3>

			{/* <li> */}
				<Question>
					What does this code print?
					<CodeBox code={`
						var colors = ['red', 'blue', 'yellow', 'green'];

						console.log(colors[1]);
						console.log(colors[3]);
						console.log(colors[0]);
					`} />
				</Question>
				<Answer id="arrays-lesson.q1">
					blue<br/>
					green<br/>
					red
				</Answer>

				<Question>
					<strong>Bonus:</strong> How about this?
					<CodeBox code={`
						var colors = ['red', 'blue', 'yellow', 'green'];

						var position = colors.length - 1;

						console.log(colors[position]);
					`} />
				</Question>
				<Answer id="arrays-lesson.q2">
					It prints "green".

					<p>
						Since the first thing in the array is at 0,
						the last thing in the array is at <code>colors.length - 1</code>.
						In this example, <code>colors.length</code> is 4, but
						<code>colors[3]</code> is the last color.
					</p>
				</Answer>

			{/* </li> */}

			<h3 className="hGreen">Your Turn</h3>

			<p>
				Now, you try. Use this code editor, and follow the steps below.
			</p>

			<CodeRunner docName="arrays-lesson.favoriteThings" code="// write your array code here" height="300" />

			<h3>Step 1</h3>
			<p>
				Create your own array of favorite things.  Give it a name,
				like <code>favoriteColors</code>, or <code>favoriteBooks</code>, or <code>favoriteMovies</code>,
				for example.
				Just make sure it describes what you're putting in it, and put a few strings in it.
			</p>

			<CodeBox clickToShow code={`
				var favoriteShows = ['The Office', 'Chuck', 'American Ninja Warrior'];
			`} />

			<h3>Step 2</h3>

			<p>
				Use square brackets and numbers (like <code>[0]</code>) to access and print the items in your array.
			</p>

			<CodeBox clickToShow code={`
				console.log('I really like ' + favoriteShows[0]);
				console.log('I also like ' + favoriteShows[1]);
				console.log('And I like ' + favoriteShows[2]);
			`} />

			<h3>Nice work!</h3>

			<p>
				Next you'll learn how to do something with the entire array.
			</p>

			<p>
				<PrefixedLink to="/lesson/arrays/2"><Button intent="primary">Continue</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p>

		</Layout>
	);
};

export { ArraysLesson }
