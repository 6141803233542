import * as React from 'react';
import { Layout } from './Layout';
import { CanvasDemoFn, CanvasDemo } from './lib/CanvasDemo';
import { Link } from 'react-router-dom';

const astronaut: CanvasDemoFn = canvas => {
	var astronaut = canvas.loadImage('https://robotpng.netlify.com/astronaut.png');
	var seed = Date.now();
	var xOffset = 0,
		yOffset = 0;
	var xDir = 1,
		yDir = -.5;
	var canvasSize = 400;
	var astronautRot = 0;
	canvas.textAlign('center', 'bottom');
	canvas.textSize(40);

	canvas.animate(mainLoop);

	function mainLoop() {
		xOffset += xDir;
		yOffset += yDir;

		draw();
	}

	function draw() {
		canvas.background('black');
		drawStars(seed, xOffset, yOffset, xDir, yDir);

		canvas.changePosition(200, 200);
		canvas.rotateDeg(astronautRot++);
		canvas.drawImage(astronaut, -70, -55, 120, 120);
		canvas.resetPosition(true);
		canvas.fill('white');
		canvas.text('you seem lost', 200, 360);
	}

	function drawStars(seed, xOffset, yOffset, xSpeed, ySpeed) {
		var bgSize = 800;

		var rand = seedRandom(seed);

		for (var i = 0; i < 400; i++) {
			var depth = rand(),
				x = (rand() * bgSize - depth * xOffset) % bgSize,
				y = (rand() * bgSize - depth * yOffset) % bgSize;

			while (x < 0) x += bgSize;
			while (y < 0) y += bgSize;

			if (x < canvasSize && y < canvasSize) {
				var radius = .5 + depth * 2;
				var color = `hsl(0, 0%, ${depth * 100 | 0}%`;

				// canvas.line(x, y, x - ((xDir / 5) ** 2), y - ((yDir / 5) ** 2));
				if (typeof xSpeed == 'number' && typeof ySpeed == 'number') {
					canvas.stroke(color);
					canvas.strokeWeight(radius);
					canvas.line(x, y, x - xSpeed / 2, y - ySpeed / 2);
				}
				else {
					canvas.fill(color);
					canvas.ellipse(x, y, radius);
				}
			}
		}
	}

	// magical mystery magic
	function seedRandom(s) {
		return function() {
			return (2 ** 31 - 1 & (s = Math.imul(48271, s))) / 2 ** 31;
		}
	}
}


const NotFound = () => (
	<Layout>
		{/* <p>Uh oh! This is not the page you are looking for!</p> */}
		<CanvasDemo codeFn={astronaut}/>
		<div className="centered">
			<p></p>
			<p>The page you are looking for has gone missing.</p>
			<p>Please try going back, or to the <Link to="/">home page</Link>.</p>
		</div>
	</Layout>
);

export { NotFound }
