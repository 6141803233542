import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { WrappedCanvas, CanvasWrapper, canvasTypedef, CanvasInterface } from './lib/WrappedCanvas';
import { createButtonWithConsole } from './lib/runner/createButton';

const SampleProjectPage: React.FC = () => {
	// let canvasWrapperRef = React.useRef<CanvasWrapper>(null);
	let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	return <ProjectPage
		pageContent={<PageContent canvasInterfaceRef={canvasInterfaceRef}/*  canvasWrapperRef={canvasWrapperRef}  *//>}
		useCodeRunner
		codeRunnerProps={{
			docName: 'asdf-test',
			code: '// write some code here',
			context: (console) => {
				let images = {};
				// 1-16
				const imageSrc = (number) => `https://robotpng.netlify.com/robot${number}.png`

				// createButton: createButtonWithConsole(contentRef.current, console),
				// createCanvas: createWrappedCanvas(contentRef.current),
				// whenKeyPressed:
				let canvas = canvasInterfaceRef.current.wrapper;
				let loadRobot = (num: number) => {
					if (typeof num != 'number' || num % 1 || num < 1 || num > 16) {
						console.error('num must be a number between 1 and 16');
						return;
					}
					if (!images[num]) {
						let image = new Image();
						image.src = imageSrc(num);
						// image.complete
						images[num] = image;
					}
					return images[num];
				}

				let drawRobot = (num: number, x: number, y: number, size: number, flipped?: boolean) => {
					if (typeof num != 'number' || num % 1 || num < 1 || num > 16) {
						console.error('num must be a number between 1 and 16');
						return;
					}

					let image = loadRobot(num);

					if (image.complete) {
						if (flipped) {
							canvas.context.save()
							canvas.context.scale(-1, 1);
							// x = -x - size;
							x = -x;
						}

						canvas.context.drawImage(image, x, y, flipped ? -size : size, size);

						if (flipped) {
							canvas.context.restore();
						}

						return true;
					}
					else {
						return false;
					}
				};

				return {
					canvas,
					loadRobot,
					drawRobot,
				};
			},
			exports: ['whenKeyPressed', 'whenMouseMoved', 'whenMouseClicked'],
			beforeRun(source, console, context, exports) {
				context.canvas.reset();
				canvasInterfaceRef.current.handlers.onKeyReleased = null
				canvasInterfaceRef.current.handlers.onKeyPressed = null
				canvasInterfaceRef.current.handlers.onMouseClicked = null
				canvasInterfaceRef.current.handlers.onMouseMoved = null
			},
			afterInit: api => {
				canvasInterfaceRef.current.handlers.onRun = api.runCode;
			},
			// canvasInterface: canvasInterfaceRef.current,
			afterRun: (exported) => {
				if (exported.whenKeyPressed) {
					canvasInterfaceRef.current.handlers.onKeyPressed = exported.whenKeyPressed
				}
				if (exported.whenKeyReleased) {
					canvasInterfaceRef.current.handlers.onKeyReleased = exported.whenKeyReleased
				}
				if (exported.whenMouseMoved) {
					canvasInterfaceRef.current.handlers.onMouseMoved = exported.whenMouseMoved
				}
				if (exported.whenMouseClicked) {
					canvasInterfaceRef.current.handlers.onMouseClicked = exported.whenMouseClicked
				}
			},
			extraLibs: canvasTypedef,
		}}
	/>
}

interface PageContentProps {
	canvasInterfaceRef: React.MutableRefObject<CanvasInterface>
	// canvasWrapperRef: React.MutableRefObject<CanvasWrapper>
	// onKeyPressed(button: string)
}

let i = 0;
const PageContent: React.FC<PageContentProps> = (props) => {
	console.log('render PageContent', i++)

	return <>
		<WrappedCanvas /* onKeyPressed={props.onKeyPressed} */ canvasInterfaceRef={props.canvasInterfaceRef} />

		{/* <p>This is a canvas runner.</p> */}

		{/* <CanvasRunner
			code="ctx.fillRect(10, 20, 100, 200)"
			codeRunnerProps={{height: 600, exports: ['draw', 'projectName', 'onClick']}}
		/> */}

		{/*
		<h1>
			<span style={{color: '#35D461', background: 'white'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: '#35D461', background: 'black'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'white', background: '#35D461'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'black', background: '#35D461'}}>&nbsp; Hello &nbsp;</span>
		</h1>
		<h1>
			<span style={{color: '#F9E104', background: 'white'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: '#F9E104', background: 'black'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'white', background: '#F9E104'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'black', background: '#F9E104'}}>&nbsp; Hello &nbsp;</span>
		</h1>
		<h1>
			<span style={{color: '#F99D07', background: 'white'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: '#F99D07', background: 'black'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'white', background: '#F99D07'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'black', background: '#F99D07'}}>&nbsp; Hello &nbsp;</span>
		</h1>
		<h1>
			<span style={{color: '#882FF6', background: 'white'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: '#882FF6', background: 'black'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'white', background: '#882FF6'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'black', background: '#882FF6'}}>&nbsp; Hello &nbsp;</span>
		</h1>
		<h1>
			<span style={{color: '#37B6F6', background: 'white'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: '#37B6F6', background: 'black'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'white', background: '#37B6F6'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'black', background: '#37B6F6'}}>&nbsp; Hello &nbsp;</span>
		</h1>
		<h1>
			<span style={{color: '#ff348f', background: 'white'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: '#ff348f', background: 'black'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'white', background: '#ff348f'}}>&nbsp; Hello &nbsp;</span>
			<span style={{color: 'black', background: '#ff348f'}}>&nbsp; Hello &nbsp;</span>
		</h1>
 */}
		{/* <CanvasRunner
			code={`// randomBox();`}
			context={(context) => {
				context.randomBox = () => {
					context.ctx.fillRect(Math.random() * 200, Math.random() * 200, Math.random() * 200, Math.random() * 200);
				}
			}}
			codeRunnerProps={{height: 600}}
		 /> */}
	</>
};


export { SampleProjectPage }
