import * as React from 'react';
import { useState } from 'react';
import { view } from 'react-easy-state';

import { InputGroup, Input } from 'sancho/esm/Form';
import { Button } from 'sancho/esm/Button';
import { ResponsivePopover } from 'sancho/esm/Popover';
import { IconButton } from 'sancho/esm/IconButton';
import { IconHelpCircle } from 'sancho/esm/Icons/icons/IconHelpCircle';
import { VisuallyHidden } from 'sancho/esm/VisuallyHidden';
import { Alert } from 'sancho/esm/Alert';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useDb } from './AppContext';
import { Layout } from './Layout';
import { ActivationCode } from './model/activationCode';
import { useCoursePrefix } from './lib/useCoursePrefix';

interface LogInProps {}

const LogIn: React.FC<LogInProps> = view(() => {
	let db = useDb();
	let [ email, setEmail ] = useState('');
	let [ password, setPassword ] = useState('');
	let [ showPassword, setShowPassword ] = useState(false);
	let [ username, setUsername ] = useState('');
	let [ error, setError ] = useState('');
	let [ activationCodeStr, setActivationCodeStr ] = useState(/* () => parse(location.search.slice(1)).activationCode ||  */'');
	let [ showReg, setShowReg ] = useState(() => !!activationCodeStr);
	let prefix = useCoursePrefix();

	const tryReset = async () => {
		try {
			await firebase.auth().sendPasswordResetEmail(email, {
				url: location.origin + prefix,
			});
			alert('A password reset email has been sent.')
		}
		catch (e) {
			setError(e.message);
		}
		// alert("This feature isn't ready yet.  Please email Brian at bgmort@gmail.com and ask him for a password reset.")
	}

	let login = async (e) => {
		e.preventDefault();

		try {
			let result = await firebase.auth().signInWithEmailAndPassword(email, password)

			// console.log('result', result);
		}
		catch (e) {
			// var errorCode = e.code;
			// var errorMessage = e.message;
			console.error(e);
			let message: string = e.message;
			if (message.startsWith('There is no user record')) {
				message = `There is no user with the email ${email}. Have you registered?`;
			}
			setError(message);
		}
	}

	let register = async (e) => {
		e.preventDefault();

		let ref = db.doc('/activation_codes/' + activationCodeStr);
		let snap = await ref.get(/* { source: 'default' } */)
		let activationCode = snap.data() as ActivationCode;

		if (!activationCode) {
			setError('The activation code does not exist.');
		}
		else if(activationCode.used) {
			setError('The activation code has already been used for ' + activationCode.user_email + '.  Please sign in with your password.');
		}
		else {
			try {
				let result = await firebase.auth().createUserWithEmailAndPassword(email, password);
				if (result.user) {
					result.user.updateProfile({ displayName: username });
					try {
						ref.delete()
					}
					catch (e) {
						console.error('failed to delete activation code', e);
					}
				}
				else {
					setError('A user could not be created.');
				}
			}
			catch (e) {
				/*
				Error Codes
				auth/email-already-in-use
					Thrown if there already exists an account with the given email address.
				auth/invalid-email
					Thrown if the email address is not valid.
				auth/operation-not-allowed
					Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
				auth/weak-password
					Thrown if the password is not strong enough.
				*/

				// var errorCode = e.code;
				// var errorMessage = e.message;
				console.error(e);
				setError(e.message);
			}
		}
	}

	return <Layout>
		<div style={{ maxWidth: 450, margin: 'auto', background: 'white', padding: 50, borderRadius: 10 }}>

			{!showReg &&
				<form onSubmit={login}>
					<p>Sign in with your email address and password to access the course.</p>

					<InputGroup hideLabel label="Email address">
						<Input type="text" placeholder="Email"  value={email} onChange={e => setEmail(e.target.value)} />
					</InputGroup>
					<InputGroup hideLabel label="Password">
						<Input type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password" />
						<label>
							<input type="checkbox" checked={showPassword} onChange={_ => setShowPassword(!showPassword)} /> Show Password
						</label>
					</InputGroup>

					<InputGroup label="">
						<Button intent="primary" type="submit">Log In!</Button>
						{/* <Button intent="primary" onPress={login}>Log In</Button>
						<VisuallyHidden>
							<button type="submit">Log In</button>
						</VisuallyHidden> */}
					</InputGroup>

					{error && <p><Alert intent="danger" title={error} /></p>}

					<p>First time? <a onClick={() => setShowReg(true)}>Register with your activation code</a></p>
					<p>Forgot your password? <a onClick={tryReset}>Send a password reset email</a></p>
				</form>
			}

			{showReg &&
				<form onSubmit={register}>
					<p>To register for this course, enter your activation code, and details for your account.</p>

					<InputGroup label="Activation Code" helpText="This comes from an email from the instructor.">
						<Input placeholder="Activation Code"  value={activationCodeStr} onChange={e => setActivationCodeStr(e.target.value)} />
						{/* <Button intent="primary" onPress={testActivationCode}></Button> */}
						{/* <aside> */}
							{/* This comes from an email from the instructor. */}
							<ResponsivePopover content={
								<div style={{ padding: '2rem', fontSize: ".9em", lineHeight: '1.75em' }}>
									You should receive an activation code in an email from Brian once you have paid for the course.
									If you don't have one, please email Brian at <a href="mailto:bgmort@gmail.com">bgmort@gmail.com</a>.
								</div>}
							>
								<IconButton variant="ghost" label="Need Help?" icon={<IconHelpCircle />} size="sm" />
							</ResponsivePopover>
						{/* </aside> */}
					</InputGroup>
					<InputGroup label="Student Name" helpText="Enter your first and last name.">
						<Input placeholder="Name" value={username} onChange={e => setUsername(e.target.value)} />
					</InputGroup>
					<InputGroup label="Email Address">
						<Input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
					</InputGroup>
					<InputGroup label="Create a Password">
						<Input type="password" placeholder="New Password" value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password" />
					</InputGroup>

					<InputGroup label="">
						<Button intent="primary" onPress={register}>Register</Button>
						<VisuallyHidden>
							<button type="submit">Register</button>
						</VisuallyHidden>
					</InputGroup>

					{error && <p><Alert intent="danger" title={error} /></p>}

					<p>Already registered? <a onClick={() => setShowReg(false)}>Log in</a></p>
				</form>
			}
		</div>
	</Layout>
});

export { LogIn, LogInProps }
