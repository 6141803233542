import * as React from 'react';
import { shuffle } from './shuffle';

// const imgSrc = require('../images/gears.svg');

// type ImgProps = React.ImgHTMLAttributes<HTMLImageElement>;

// const GearSpinner: React.FC<ImgProps> = (props) => (
// 	<img style={{margin: 'auto'}} {...props} src={imgSrc} />
// );
interface GearSpinnerProps {
	colors?: [string, string, string]
	style?: React.CSSProperties
	center?: boolean
}

type Triplet = [string, string, string];
// const defaultColors: Triplet = [
// 	'#ea1f4b',
// 	'#37B6F6',
// 	'#35d461',
// ]

const GearSpinner: React.FC<GearSpinnerProps> = (props) => {
	let colors //: Triplet;
	if (props.colors) {
		colors = props.colors;
	}
	else {
		colors = [
			'#0077ff',
			'#35d461',
			// '#37b6f6',
			'#882ff6',
			// '#89eeff',
			// '#ea1f4b',
			// '#f99d07',
			// '#f9e104',
			'#ff00aa',
			// '#ff5378',
		];
		shuffle(colors);
		colors.length = 3;
	}

	let style: React.CSSProperties = { display: 'block', margin: 'auto', width: 200, maxWidth: '100%' };
	if (props.center) style.height = '100%';
	if (props.style) Object.assign(style, props.style);

	return (
		<svg className="GearSpinner" style={style} xmlns="http://www.w3.org/2000/svg" width="320" height="120" viewBox="-160 -60 320 120">
			<defs>
				<path id="gear" d="M -7.853981633974483 42.146018366025515 L -5.654866776461628 56.283185307179586 L 5.654866776461628 56.283185307179586 L 7.853981633974483 42.146018366025515 L 18.41880342300344 38.713299679579436 L 28.507542952520836 48.857900705945454 L 37.6573095986883 42.210206116179805 L 31.12681265379159 29.480390527127156 L 37.65623160449695 20.49341633219443 L 51.78104020938787 22.77055865400764 L 55.275940079093715 12.01436286093804 L 42.51025920131062 5.554255508486649 L 42.510259201310625 -5.554255508486644 L 55.27594007909371 -12.014362860938032 L 51.781040209387875 -22.770558654007633 L 37.65623160449696 -20.493416332194425 L 31.126812653791593 -29.480390527127145 L 37.65730959868831 -42.210206116179805 L 28.507542952520843 -48.857900705945454 L 18.418803423003443 -38.71329967957943 L 7.853981633974488 -42.146018366025515 L 5.654866776461635 -56.283185307179586 L -5.6548667764616205 -56.283185307179586 L -7.8539816339744775 -42.146018366025515 L -18.418803423003435 -38.713299679579436 L -28.50754295252083 -48.857900705945454 L -37.65730959868829 -42.210206116179805 L -31.126812653791585 -29.480390527127156 L -37.65623160449695 -20.493416332194432 L -51.78104020938787 -22.770558654007647 L -55.275940079093715 -12.014362860938046 L -42.51025920131062 -5.554255508486654 L -42.510259201310625 5.554255508486639 L -55.27594007909371 12.014362860938025 L -51.781040209387875 22.770558654007626 L -37.65623160449696 20.49341633219442 L -31.1268126537916 29.480390527127145 L -37.657309598688315 42.210206116179805 L -28.50754295252085 48.857900705945454 L -18.41880342300345 38.713299679579436 Z">
				<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="36" to="0" dur="0.4s" additive="sum" repeatCount="indefinite" />
			</path>
			</defs>
			<use x="100" y="0" href="#gear" fill={colors[0]} />
			<use x="0" y="0" href="#gear" fill={colors[1]} transform="scale(1, -1) rotate(18 0 0)" />
			<use x="-100" y="0" href="#gear" fill={colors[2]} />
		</svg>
	)
};


export { GearSpinner }
