import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CanvasRunner, CanvasRunnerProps } from './lib/CanvasRunner';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasWrapper } from './lib/canvasWrapper';
import { CanvasDemoFn, CanvasDemo } from './lib/CanvasDemo';
import { CodeRunner } from './lib/CodeRunner';
import { Emoji } from './lib/Emoji';
// import { useCleanup } from './lib/hookUtils';

// const MusicRunner: React.FC<CodeRunnerProps> = props => (
// 	<CodeRunner {...props} context={{Music}} extraLibs={musicTypeDef} beforeRun={() => {
// 		Music.stopAll();
// 	}} />
// );

const ChallengeRunner = (props: (Partial<CanvasRunnerProps> & {code: string}) = {code: ''}) => (
	<CanvasRunner console canvasHeight={400} editorHeight={600} {...props} />
);

const PracticeChallengesLesson = () => {
	const root = '/lesson/practice-challenges/';
	const eStyles = {width: '1.5em', display: 'inline-block', marginRight: 10}

	const pages = [
		<><Emoji style={eStyles} e="🐓" /> Chicken</>,
		<><Emoji style={eStyles} e="↘" /> Bouncing Box</>,
		<><Emoji style={eStyles} e="🐣" /> Infinite Chickens</>,
		<><Emoji style={eStyles} e="🎶" /> Write a Song</>,
		<><Emoji style={eStyles} e="🎁" /> Surprise Gift</>,
		<><Emoji style={eStyles} e="🌌" /> Starry Sky</>,
	];

	return (
		<Layout solid>
			{/* <> */}
			<h1>Practice Challenges <Emoji e="👾" /></h1>

			<PagedContent pages={[
				<>
					<p>&nbsp;</p>
					<p className="intro">
						Today we will hone our skills with some fun code challenges.
					</p>

					<p className="intro">
						Each challenge is designed to help you review skills and concepts from past lessons. <br/>
						They will take a while – you won't get through them in class, but you should keep working on them at home. <br/>
						{/* They are ordered from easiest to most difficult, but they don't have to be done in order.
						Feel free to skip around and choose which ones to work on. */}
						I challenge you to complete them all!
					</p>

					{/* <p className="intro">
						The sounds we'll be making in this class are similar to
						the ones in classic games on consoles such as the NES and the Atari.
					</p> */}


					<div style={{marginLeft: 15}}>
					<h2>List of Challenges</h2>

					<ul className="basicList" style={{marginLeft: 50, lineHeight: '1.9em'/* fontSize: '1.3em' */}}>
						{pages.map((title, i) =>
							<li key={i}><PrefixedLink to={root + (i + 2)}>{title}</PrefixedLink></li>)
						}
					</ul>
					</div>

				</>,<>

					<h2 className="hBlue">Chicken {/* <Emoji style={eStyles} e="🐓" /> */}</h2>

					<p>
						Here's your first challenge: Draw a chicken. Yep, that's it! Just draw a chicken.
						Feel free to draw it however you like. Mixing up styles is encouraged!
					</p>

					<h3>Helpful resources:</h3>

					<p>
						<PrefixedLink to="/lesson/canvas-2">Canvas lesson</PrefixedLink><br/>
						Review the basics of drawing rectangles, circles, lines, and text on a canvas.
					</p>
					<p>
						<PrefixedLink to="/lesson/canvas-2">canvas.shape lesson</PrefixedLink><br/>
						Draw shapes, diamonds, and any other polygon.
					</p>

					<ChallengeRunner docName="challenges.chicken" code={`
						// draw your chicken!
					`} />

					<h3>Bonus</h3>
					<ul>
						<li>Make your chicken change colors by clicking or pressing a key</li>
					</ul>

				</>,<>

					<h2 className="hBlue">Bouncing box {/* <Emoji style={eStyles} e="↘" /> */}</h2>

					<p>
						In this challenge, you should create a moving box that bounces off the sides of the screen.
					</p>

					<p>Here's an example:</p>

					<CanvasDemo codeFn={canvas => {
						var x = Math.random() * 360, y = Math.random() * 360;
						var xVel = 3, yVel = 2.5;

						canvas.animate(() => {
							x += xVel;
							y += yVel;
							if (x <= 0 || x >= 360) {
								xVel = -xVel;
							}
							if (y <= 0 || y >= 360) {
								yVel = -yVel;
							}

							canvas.background('black');
							canvas.fill('blue');
							canvas.rect(x, y, 40, 40);
						});
					}} />

					<h3>Helpful resources:</h3>

					<p>
						<PrefixedLink to="/lesson/game-loop">canvas.animate lesson</PrefixedLink><br/>
						Review how to draw smooth animations.
					</p>

					<ChallengeRunner docName="challenges.bouncingBox" code={`
						// draw your box
					`} />

					<h3>Bonus:</h3>
					<ul>
						<li>Make the box change colors when it bounces of the edge of the canvas.</li>
						<li>Make sure the box doesn't hit the same spot each time.</li>
						<li>If the box hits the exact corner, print "That was so awesome".</li>
						<li>Add the text "DVD video" to the box (see this <a target="_blank" href="https://www.youtube.com/watch?v=QOtuX0jL85Y">clip from The Office</a> for an explanation.)</li>
					</ul>

				</>,<>

					<h2 className="hBlue">Infinite Chickens {/* <Emoji style={eStyles} e="🐣" /> */}</h2>

					<p>
						Using your code from the Chicken challenge,
						create a function <code>drawChicken(x, y)</code> that will
						allow you to draw a chicken anywhere.
					</p><p>
						Then, use that function to draw several chickens on the screen.
					</p>

					<h3>Helpful resources:</h3>

					<p>
						<PrefixedLink to="/lesson/events/5">canvas.changePosition()</PrefixedLink><br/>
						Move a drawing anywhere around the canvas.
					</p>

					<ChallengeRunner docName="challenges.infChickens" code={`
						// add your chickens!
					`} />

					<h3>Bonus</h3>
					<ul>
						<li>Move a chicken around the screen using the arrow keys.</li>
						<li>Each time the canvas is clicked, add another chicken where the canvas was clicked.</li>
						<li>Make your chicken fly around on its own.</li>
					</ul>

				</>,<>

					<h2 className="hBlue">Write a Song {/* <Emoji style={eStyles} e="🎹" /> */}</h2>

					<p>
						Pick a simple song, and make it play.
						Feel free to use addition, a loop, and/or a function to help you play the notes.
						DO NOT write out the <code>timeOffset</code> numbers by hand.
						There are better ways.
					</p><p>
						You can choose a song you know, or even make up a song.
					</p><p>
						Here are the notes to Twinkle Twinkle, Little Star if you want to start with those.
						It's up to you to decide how to format them. Make sure to give the notes an octave number.
						(3-4 work great).
					</p><p>
						<code>c c g g a a g (rest) f f e e d d c</code>
					</p>

					<h3>Helpful resources:</h3>

					<p>
						<PrefixedLink to="/lesson/sounds">Sounds Lesson</PrefixedLink><br/>
						Learn how to create your own songs and sound effects
					</p>

					<ChallengeRunner docName="challenges.song" code={`
						// write your song!
					`} />

					<h3>Bonus</h3>
					<ul>
						<li>Draw a play button on the screen. Play the song when the play button is clicked.</li>
					</ul>

				</>,<>

					<h2 className="hBlue">Surprise Gift {/* <Emoji style={eStyles} e="🎁" /> */}</h2>

					<p>
						Give a surprise present! This one has a few steps:
					</p>
					<ul>
						<li>
							Choose three (or more) "presents" to draw on the screen:
							One valuable (think a ring or a car),
							one not so valuable (a chicken maybe? piece of bread?),
							and one nobody would want, like a dirty sock or a moldy piece of bread.
						</li>
						<li>
							Create the drawings for your three gifts.
						</li>
						<li>
							Draw a gift box on the screen.
							Print "Click to open your gift" above or below the box.
						</li>
						<li>
							When the box is clicked, choose one of the three presents at random,
							and replace the gift box on the screen with their present.
						</li>
					</ul>

					<p>Here's an example of how it could work:</p>

					<CanvasDemo codeFn={surpriseDemo} />

					<h3>Helpful resources:</h3>

					<p>
						<PrefixedLink to="/lesson/animation">Animation lesson</PrefixedLink><br/>
						This one talks about how we respond to clicks and key presses,
						update our program state with variables,
						then redraw our screen in response to those updates.
					</p>

					<p>
						<PrefixedLink to="/lesson/canvas-2">canvas.shape lesson</PrefixedLink><br/>
						Draw shapes, diamonds, and any other polygon.
					</p>

					<p>
						<PrefixedLink to="/published/h3mckpx2">Graph Drawing</PrefixedLink><br/>
						This project can help you draw shapes using canvas.shape().
						Every shape you draw will print the code used to draw it in the console.
					</p>

					<ChallengeRunner docName="challenges.surprise" code={`
						// create your surprise program!
					`} />

					<h3>Bonus</h3>
					<ul>
						<li>Draw a play button on the screen. Play the song when the play button is clicked.</li>
					</ul>

				</>,<>

					<h2 className="hBlue">Starry Sky {/* <Emoji style={eStyles} e="🌌" /> */}</h2>

					<p>
						Draw a night sky full of stars in random positions.
						Use a loop to draw your stars,
						and <code>Math.random()</code> to draw them in random locations.
					</p>

					<CanvasDemo codeFn={starryDemo} />

					<h3>Helpful resources:</h3>

					<p>
						<PrefixedLink to="/lesson/loops">Loops lesson</PrefixedLink><br/>
						How to use a loop to repeat something multiple times.
					</p>
					<p>
						<PrefixedLink to="/lesson/random">Random numbers lesson</PrefixedLink><br/>
						How to use <code>Math.random()</code> to generate a random number.
					</p>

					<p>
						<PrefixedLink to="/lesson/events/5">canvas.changePosition()</PrefixedLink><br/>
						Move a drawing anywhere around the canvas.
					</p>

					<ChallengeRunner docName="challenges.nightChicken" code={`
						// draw your night scene!
					`} />

					<h3>Bonus</h3>
					<ul>
						<li>Make your stars different sizes and colors</li>
						<li>Add the chicken from the first challenge to your drawing.</li>
					</ul>

				</>,<>

					<h2 className="hBlue">More Challenges to Come!</h2>

					<p>
						I hope you have enjoyed these challenges! More challenges are in store.
						Please keep working on them at home! The only way to develop your skills
						is to practice.
					</p>

					<p>
						And don't forget, you can create and share your projects!
						If you made something you like in this lesson that you would like to share,
						just copy the code into a new project of your own and share it from there.
					</p>

					<p>
						As a reminder, you can get to the projects page by clicking on your name at the top right,
						then "Your Projects".  From there you can create a project, and share a project when it's ready.
					</p>

					<p>
						Happy coding!
					</p>

				</>
			]} />

		</Layout>
	);
};

const surpriseDemo: CanvasDemoFn = canvas => {
	var open = false;
	canvas.textAlign('center', 'middle');

	draw();

	function drawGift(x, y) {
		canvas.changePosition(x, y);
		canvas.fill('blue');
		canvas.rect(-100, -75, 200, 150);
		canvas.fill('lightblue');
		canvas.rect(-100, -15, 200, 30);
		canvas.rect(-15, -75, 30, 150);
		canvas.ellipse(-20, -75, 40)
		canvas.ellipse(20, -75, 40)
		canvas.ellipse(0, -95, 40)

		canvas.resetPosition();
	}

	function drawChicken(x, y) {
		canvas.changePosition(x, y);

		canvas.textAlign('center', 'middle');
		canvas.fill('yellow');
		canvas.ellipse(0, 0, 125);
		canvas.ellipse(60, -50, 65);
		canvas.fill('orange');
		canvas.shape(85, -50, 105, -45, 85, -40);
		canvas.fill('sienna');
		canvas.ellipse(67, -60, 10);
		canvas.stroke('black');
		canvas.strokeWeight(5);
		var spread = 8;
		canvas.line(-10 + -spread, 60, -12 + -spread, 90);
		canvas.line(-12 + -spread, 90, -24 + -spread, 100);
		canvas.line(-12 + -spread, 90, -2 + -spread, 100);
		canvas.line(-12 + -spread, 90, -14 + -spread, 104);

		canvas.line(10 + spread, 60, 12 + spread, 90);
		canvas.line(12 + spread, 90, 24 + spread, 100);
		canvas.line(12 + spread, 90, 2 + spread, 100);
		canvas.line(12 + spread, 90, 14 + spread, 104);
		canvas.noStroke();

		canvas.resetPosition();
	}

	function drawCar(x, y) {
		canvas.fill(randomColor());
		canvas.changePosition(x - 220, y - 220);
		canvas.shape(100, 260, 140, 240, 160, 200, 240, 200, 280, 240, 340, 260, 340, 300, 100, 300);
		canvas.fill('black');
		canvas.ellipse(140, 300, 40);
		canvas.ellipse(300, 300, 40);
		canvas.resetPosition();
	}

	function drawSock(x, y) {
		var sockColor = 'hsl(20, 25%, 86%)';
		canvas.changePosition(x + 40, y + 50);
		canvas.strokeWeight(100);
		canvas.stroke(sockColor);
		canvas.line(0, 0, 0, -100);
		canvas.line(0, 0, -100, 50);
		canvas.strokeWeight(20);
		canvas.line(-40, -140, 40, -140);
		canvas.noStroke();
		canvas.fill(sockColor);
		canvas.rect(-50, -140, 100, 40);
		canvas.fill('red');
		canvas.rect(-50, -135, 100, 15);
		canvas.fill('blue');
		canvas.rect(-50, -105, 100, 15);
		canvas.fill('hsl(20, 25%, 60%)');
		canvas.ellipse(-71, 74, 20);
		canvas.ellipse(-14, 42, 20);
		canvas.ellipse(8, 35, 20);
		canvas.resetPosition();
	}

	function whenMouseClicked() {
		open = !open;
		draw();
	}

	function draw() {
		canvas.background('#ccc');
		canvas.fill('white');
		canvas.rect(2, 2, 396, 396);
		canvas.fill('black');

		if (open) {
			var rand = Math.random();
			var prize;
			if (rand < 1 / 10) {
				prize = 'a new car';
				drawCar(200, 200);
			}
			else if (rand < 1 / 2) {
				prize = 'a chicken';
				drawChicken(200, 200);
			}
			else {
				prize = 'a dirty sock!';
				drawSock(200, 200);
			}
			canvas.fill('black');
			canvas.text('You won ' + prize + '!', 200, 50);
		}
		else {
			canvas.text('Click to claim your prize!', 200, 50);
			drawGift(200, 235);
		}
	}

	function randomColor() {
		return '#' + Math.random().toString(16).slice(2, 8);
	}

	return { whenMouseClicked }

}

const starryDemo: CanvasDemoFn = (canvas) => {
	draw();

	function draw() {
		canvas.background('darkblue');

		canvas.fill('white');
		for (var i = 0; i < 100; i++) {
			canvas.ellipse(Math.random() * 400, Math.random() * 300, 3 + Math.random() * 1);
		}
		canvas.fill('sienna');
		canvas.rect(0, 300, 400, 400);

		drawChicken(200, 220);
	}

	function drawChicken(x, y) {
		canvas.changePosition(x, y);

		canvas.textAlign('center', 'middle');
		canvas.fill('yellow');
		canvas.ellipse(0, 0, 125);
		canvas.ellipse(60, -50, 65);
		canvas.fill('orange');
		canvas.shape(85, -50, 105, -45, 85, -40);
		canvas.fill('sienna');
		canvas.ellipse(67, -60, 10);
		canvas.stroke('black');
		canvas.strokeWeight(5);
		var spread = 8;
		canvas.line(-10 + -spread, 60, -12 + -spread, 90);
		canvas.line(-12 + -spread, 90, -24 + -spread, 100);
		canvas.line(-12 + -spread, 90, -2 + -spread, 100);
		canvas.line(-12 + -spread, 90, -14 + -spread, 104);

		canvas.line(10 + spread, 60, 12 + spread, 90);
		canvas.line(12 + spread, 90, 24 + spread, 100);
		canvas.line(12 + spread, 90, 2 + spread, 100);
		canvas.line(12 + spread, 90, 14 + spread, 104);
		canvas.noStroke();

		canvas.resetPosition();
	}
}

export { PracticeChallengesLesson }
