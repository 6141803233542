import * as React from 'react';
import { PrefixedLink } from './lib/PrefixedLink';
import { PagedContent } from './lib/PagedContent';
import { CanvasDemo } from './lib/CanvasDemo';
import { bouncingDotsDemo, bouncingDotsInitial } from './demos/bouncingDots';
import { CanvasPage } from './CanvasPage';
import { CanvasRunner } from './lib/CanvasRunner';
import { CodeBox } from './CodeBox';

const BouncingDotsLesson: React.FC = () => {
	return (
		<CanvasPage
			docName="bouncingDotsProject"
			code="// write your code here"
			pageContent={<div>
				<h1 id="final-project">Practice: Bouncing Dots</h1>

				<PagedContent pages={[
					<>
						<p>
							For today's lesson we are going to work on using arrays.
							We'll start by reviewing some of the material from the original arrays lesson,
							then we'll use arrays to add some new abilities to a project.
						</p>

						<p>
							To get started, we'll refresh our memories on arrays using the arrays lesson.
						</p>

						<p>
							<PrefixedLink to="/lesson/arrays">Arrays</PrefixedLink>
						</p>

						<p>After we review, we'll begin.</p>
					</>,<>
						<div style={{clear: 'both'}} />

						<h2 className="hGreen">Bouncing Dots Program</h2>

						<p>
							Let's take a look at what we're going to build.
							It starts with one bouncing dot on the screen.
							It bounces around off the walls. You can click the box
							to add more bouncing dots, and press backspace to remove them.
							Try pressing 'C' or space too.
						</p>

						<div style={{clear: 'both'}} />
						<CanvasDemo codeFn={bouncingDotsDemo} />

					</>,<>
						<div style={{clear: 'both'}} />
						<h2 className="hGreen">Getting started</h2>

						<p>
							We're not going to build this from scratch. Instead, we're going to start
							with this program. It has one dot, but you can't add or remove them.
							Let's take a look at what we're going to build.
							It starts with one bouncing dot on the screen.
							It bounces around off the walls. You can click the box
							to add more bouncing dots, and press backspace to remove them.
						</p>

						{/* <CanvasRunner vertical editorHeight={400} code={bouncingDotsInitial} /> */}

						<p>
							To get started, copy the code below (using ctrl+c) into the editor on the right.
							You'll use this editor to make your changes.
						</p>

						<CodeBox code={bouncingDotsInitial} />

						<p>
							Run your code once you've copied it in to see what it's doing.
						</p>

					</>,<>
						<div style={{clear: 'both'}} />

						<h2 className="hGreen">Getting Familiar</h2>

						<p>
							Since you'll be starting with code you didn't write,
							the first thing you need to do is study the code that's already
							there to make sure you understand what it's doing.
						</p>

						<p>
							Look over each section and read the comments. Can you understand
							what's happening? We'll talk about it in class too.
						</p>
					</>,<>
						<div style={{clear: 'both'}} />
						<h2 className="hGreen">Making our changes</h2>

						<p>
							We'll talk about this all together, but I'll try to have it written
							out for those who may miss class. These are the changes we need to make:
						</p>

						<ul>
							<li>move our code that creates a dot into a function, so we can reuse it.</li>
							<li>create an array so we can store any number of dots</li>
							<li>add our first dot to the array</li>
							<li>change our code that updates the dot to update every dot in the array using a loop</li>
							<li>change our code that draws the dot to draw every dot in the array using a loop</li>
							<li>create a new dot when the screen is clicked or a key is pressed</li>
							<li>remove a dot or clear the dots when <code>backspace</code> or <code>c</code> is pressed.</li>
						</ul>
					</>,<>
						<div style={{clear: 'both'}} />
						<h2 className="hGreen">Review</h2>
						<p>
							Discuss these questions:
						</p>
						<ul>
							<li>What did we learn from this lesson?</li>
							<li>What other ways can arrays be helpful?</li>
							<li>How can this help you in your own projects?</li>
						</ul>

						<h2 className="hOrange">Bonus Changes</h2>

						<p>
							Once you have all the changes from the list done, what else can you do?
							Here are some ideas:
						</p>

						<ul>
							<li>Show the current number of dots on the screen</li>
							<li>draw something more interesting than dots, like people, dinosaurs, or cars.</li>
							<li>make dots disappear after a while</li>
							<li>make dots grow gradually, then disappear once they get too big</li>
						</ul>
					{/*
					</>,<>
						<h2 className="hRed">Lessons Learned</h2>

						<p>
							What lessons did we learn from this project?
						</p>

						<ul>
							<li>Arrays make working with multiple things much easier</li>
							<li>create an array so we can store any number of dots</li>
							<li>add our first dot to the array</li>
							<li>change our code that updates the dot to update every dot in the array</li>
							<li>change our code that draws the dot to draw every dot in the array</li>
							<li>create a new dot when the screen is clicked or a key is pressed</li>
							<li>remove a dot or clear the dots when <code>backspace</code> or <code>c</code> is pressed.</li>
						</ul>
					*/}
					</>
				]} />
			</div>
			}
		/>
	)
};

export { BouncingDotsLesson }
