import { createContext, useContext } from 'react'
import { store } from 'react-easy-state'
import { initFirebase, useDb } from './firebase';


// type Store<T extends object> = T;

// interface AuthState /* Store<> */{
// 	user: firebase.User,
// 	authed: boolean
// 	error: firebase.auth.Error,
// 	// username?: string
// 	// email?: string
// 	// result?: any // firebase auth result
// }

interface AppContext {
	// authState: AuthState,
	firebase: firebase.app.App,
	db: firebase.firestore.Firestore,
	auth: firebase.auth.Auth,
	store: AppStore,
}

interface AppStore {
	// roughly corresponds to url.  replace with router?
	statePath: string[]
}

const appContextBuilder = {} as AppContext;
/* const firebaseApp =  */initFirebase(appContextBuilder);
// const authState = initAuthState(appContextBuilder);

const AppContext = createContext<AppContext>(appContextBuilder);

const useAppContext = () => useContext(AppContext);

export { AppContext, useAppContext, useDb }
