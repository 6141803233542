import * as React from 'react';

import { CodeBox } from './CodeBox';
import { ProjectPage } from './ProjectPage';
import { PagedContent } from './lib/PagedContent';
import { Vocab } from './lib/Vocab';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const QuizGame2: React.FC = (props) => {
	return (
		<ProjectPage
			// useCodeRunner
			// codeRunnerProps={{
			// 	docName: "if-quiz-project",
			// 	code: '// Make sure you do part 1 of this project before you come here!',
			// }}
			monacoProps={{
				docName: "if-quiz-project",
				initialCode: '// Make sure you do part 1 of this project before you come here!',
			}}
			pageContent={/* <PagedContent pages={[ */
				/****
				 * page 1
				 */
				<>
					<h1>Using Functions to Build a Quiz</h1>

					<p className="intro">
						Now that you know how to create a function, you are ready for the next challenge – improving your quiz!
					</p>

					<p>
						<strong>Note:</strong> If you haven't completed <PrefixedLink to="/project/quiz-game">part 1</PrefixedLink> of this project,
						you must complete it before you do this part.
					</p>

					<p>
						Do you remember the problem we ran into at the end of part 1 of this project? I'll tell you if you forgot:
						Each quiz question required us to copy and paste a bunch of code all over the place, which is bad in a few ways:
						<ol>
							<li>It takes too long</li>
							<li>It's hard to make changes multiple copies of the same code</li>
							<li>It leads to <strong>bugs</strong> (mistakes) in our code.</li>
						</ol>
					</p>

					<p>
						Well, now you know a way to solve that problem: functions!
					</p>

					<p>
						Before we continue, take a minute to think about how functions might be able to help us solve this problem.
					</p>

					<hr className="spacer"/>

					<h2 className="hBlue">Create your function</h2>

					<p>
						Remember how you copied the code for your first question and turned it into two questions?
						Instead of copying, we're going to put the common parts into a function,
						and use that function multiple times to ask multiple questions.
					</p>

					<p>
						First, define your function.  Call it what you like.
						I'm going to use the name <code>quizQuestion</code> for my example.
					</p>

					<p>
						<CodeBox clickToShow code={`
							function quizQuestion() {

							}`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Next, put all the code you had to ask the question inside your function.
						(Everything but defining the score at the top, and showing the score at the bottom).
					</p>

					<p>
						<CodeBox clickToShow code={`
							function quizQuestion() {
								var yourAnswer = prompt("What is the capital of Utah?");
								var correctAnswer = 'Salt Lake City';

								if (yourAnswer == correctAnswer) {
									alert("Correct!");
									score++;
								}
								else {
									alert("The correct answer is " + correctAnswer);
								}
							}`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Now you can call your function, but it's going to ask you about the capital of Utah every time.
						We need to make it flexible, by giving it some parameters.  What do you think we should pass to the function?
					</p>

					<p>
						If you said the question, and the correct answer, you are correct!
						Make your function flexible by having it take two parameters called <code>question</code> and <code>correctAnswer</code>.
						Then, change the body of the function to ask <code>question</code>,
						and delete the line that sets <code>correctAnswer</code> to <code>"Salt Lake City"</code>.
					</p>

					<p>
						<CodeBox clickToShow code={`
							function quizQuestion(question, correctAnswer) {
								var yourAnswer = prompt(question);

								if (yourAnswer == correctAnswer) {
									alert("Correct!");
									score++;
								}
								else {
									alert("The correct answer is " + correctAnswer);
								}
							}`}
						/>
					</p>

					<hr className="spacer" />

					<p>
						Now, it's time to call your function! Can you figure out how to call it?
					</p>

					<p>
						<CodeBox clickToShow code={`
							quizQuestion("What is the capital of Utah?", 'Salt Lake City');`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Once that's working, go ahead and add as many questions as you like.
					</p>

					<p>
						<CodeBox clickToShow code={`
							quizQuestion("What is the capital of Utah?", 'Salt Lake City');
							quizQuestion("What year did the US declare independence?", '1776');
							quizQuestion("Who is your favorite programming teacher?", 'Brian');
						`} />
					</p>

					<p>Run your code.  How's it working?  Once you have it done, feel free to customize your quiz.</p>

					<h2 className="hRed">Programming Principle: D.R.Y.</h2>
					<p>
						Did you notice how much easier it is to add or change a question now?
						How about if you need to change something about how the question is asked,
						or how the score works?
					</p>

					<p>
						Programmers have a saying: "Don't Repeat Yourself", or the D.R.Y. principle.
						You have just made your code DRY, by putting the repetitive parts inside a function.
						This will help you make better programs faster, and understand what your program is doing better!
					</p>

					<hr className="spacer"/>

					<h3>Give yourself a pat on the back!</h3>
					<p>
						You still have a lot to learn, young padawan, but you know enough to write some pretty awesome programs already!
					</p>
					{/* <p>
						Next week, we'll learn to work w

					</p> */}
					<p>
						<PrefixedLink to="/project/quiz-game"><Button intent="primary">Next</Button></PrefixedLink>
					</p>
				</>
			}
			// ]} />}
		/>
	)
}

export { QuizGame2 }
