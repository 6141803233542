import * as React from 'react';
import { CanvasPage } from './CanvasPage';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const concentricSquares = require('./images/example/concentricSquares.png');
const circleQuadrants = require('./images/example/circleQuadrants.png');
const cardinalDirs = require('./images/example/cardinalDirs.png');
const sun = require('./images/example/sun.png');

const CanvasReview = () => {
	const imgStyles: React.CSSProperties = {float: 'left', marginRight: 10, width: 200, marginBottom: 10};
	const hStyles: React.CSSProperties = {clear: 'both'/* , marginTop: 10 */};

	return <CanvasPage
		pageContent={(canvas) => (
			<div className="container CanvasReview">
				<div>
					<h1>Canvas Review</h1>

					<div className="canvasWrapper">
						{canvas}
					</div>


					<p className="intro">
						Instead of a quiz, we will review the canvas functions we learned with some review exercises.
						I want you to try to recreate each of the following images
						using the functions you learned in the <PrefixedLink to="/lesson/canvas">canvas lesson</PrefixedLink>.
					</p>

					<p>Use the editor on the right to write your canvas code, and it will display on the canvas here on the left.</p>

					<p>If you don't remember how to do something, you can find it in the lesson.</p>

					<p>
						Once you are done with an exercise, keep the code for it in the editor so you can come back to it later.
						You can either (<code>{'//'} comment it out</code>),
						or just leave it, and add a <code>canvas.background('white');</code> after it to clear the canvas and start over.
					</p>
				</div>

				<h3 className="hGreen" style={hStyles}>Exercise 1: Rectangles</h3>
				<img src={concentricSquares} style={imgStyles} />
				<p>
					Remember, the order that you draw the rectangles matters!  New things are drawn on top of what's already been drawn.
				</p>

				<h3 className="hGreen" style={hStyles}>Exercise 2: Circles/Ellipses</h3>
				<img src={circleQuadrants} style={imgStyles} />
				<p>
					Rather than guessing here, try to think of what numbers you should use here first.
					Where do the centers need to be for the edges to touch like this?
					How big do they need to be?
				</p>

				<h3 className="hGreen" style={hStyles}>Exercise 3: Text</h3>
				<img src={cardinalDirs} style={imgStyles} />
				<p>
					Remember, you can use <code>canvas.textAlign()</code> to specify how the text lines up with its anchor point.
				</p>

				<h3 className="hGreen" style={hStyles}>(Bonus) Exercise 4: Lines</h3>
				<img src={sun} style={imgStyles} />
				<p>
					This one is tricky!
					It might be easier to use addition and subtraction expressions here to help compute the right
					positions for all the corners.
				</p>
				<p><strong>Note:</strong> The color of the sky is <code>'skyblue'</code>.</p>

				<hr className="spacer" style={{clear: 'both'}} />
				<h2 className="hBlue">Well Done!</h2>

				<p>
					If you understand the basics of all these drawing skills,
					you have the ability to combine them to draw pretty much anything you want.
					That's exciting!
				</p>

				<p>
					Hopefully you're having fun, too.
					If you'd like to play around drawing on your own,
					here's a <PrefixedLink to="/blank-canvas">blank canvas</PrefixedLink> you can draw on, and it will save.
					Soon you'll be able to save and name all your creations, but in the meantime, you can use that.
				</p>

				<p>
					<PrefixedLink to=""><Button intent="primary">Back to Home</Button></PrefixedLink>
				</p>

			</div>
		)}
		code={[1, 2, 3, 4].map(n => `// write your exercise ${n} code here\n`).join('\n\n\n')}
		docName="canvas-review"
	/>;
};
export { CanvasReview }
