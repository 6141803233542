import * as React from 'react';
import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CodeRunner } from './lib/CodeRunner';
import { Question, Answer } from './lib/Quiz';

const objectsPng = require('./images/example/objects.png');

const ObjectsLesson = () => {
	// canvas.fill('red');
	// canvas.rect(50, 50, 100, 100);

	// canvas.fill('blue');
	// canvas.rect(200, 100, 150, 50);

	// canvas.fill('green');
	// canvas.rect(100, 200, 100, 150);
	const circlesCode = `
		canvas.fill('red');
		canvas.ellipse(100, 100, 50, 50);

		canvas.fill('blue');
		canvas.ellipse(300, 200, 50, 50);

		canvas.fill('green');
		canvas.ellipse(100, 300, 50, 50);
	`;

	return (
		<Layout narrow>
			<h1>Objects</h1>
			<PagedContent pages={[
				<>
					<p className="intro">
						Today's lesson will help us write more organized code and make it easier
						to do some things that were complicated before.  We'll be learning about objects,
						what they are in Javascript, and how to use them.
					</p>

					<h2 className="hBlue">What is an object?</h2>

					<p>Let's say I have a bunch of shapes that I want to draw. And suppose they look like this:</p>

					<img style={{width: 300, margin: 'auto', display: 'block', border: '1px solid black'}} src={objectsPng} />

					{/* <WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={setupDemo} /> */}
					{/* <WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={setupDemo} /> */}
					{/* <CanvasRunner autorun code={circlesCode} /> */}

					<p>
						I could write them out using some code that looks like this:
					</p>

					<CodeBox code={circlesCode} />

					<p>
						But, suppose I also want to be able to change, add, or remove shapes too – maybe I want to add them one at a time,
						or maybe these shapes represent items in a game, and I want to remove them when a player runs into them.
					</p>

					<p>
						I need a way to represent those shapes in my program, so I can keep track of them,
						and change them if needed.
					</p>

					<p>
						How would you solve this problem using the skills you already know?
						Take a minute and think about it, then tell your neighbor what you think.
					</p>

					<p>After you have thought about it and shared your ideas with someone, hit 'Continue'.</p>
				</>,
				<>
					<p>Did you think about it? I hope so! You might have come up with something like this:</p>

					<CanvasRunner code={`
						var colors = ['red', 'blue', 'green']
						var xPositions = [100, 300, 100];
						var yPositions = [100, 200, 300];

						for (var i = 0; i < colors.length; i++) {
							canvas.fill(colors[i]);
							canvas.ellipse(xPositions[i], yPositions[i], 50);
						}
					`} />

					<p>
						This is a good idea.
						You can add new shapes to it by adding one new value to each array.
						And you can have as many shapes as you want without having to write tons of extra code.
					</p>

					<p>
						But, it's hard to look a one shape by itself,
						because each aspect or property of the shape (such as
						its color, x position, and y position) is stored in a separate array.
					</p>

					<p>
						You might also have thought of something like this:
					</p>

					<CanvasRunner code={`
						var shapes = [
							['red', 100, 100],
							['blue', 300, 200],
							['green', 100, 300],
						];

						for (var i = 0; i < shapes.length; i++) {
							var shape = shapes[i];
							var color = shape[0];
							var x = shape[1];
							var y = shape[2];
							canvas.fill(color);
							canvas.ellipse(x, y, 50);
						}
					`} />

					<p>
						This is a good idea too.  I can store all the values that describe one shape in one place –
						in an array that describes the shape.  I store the color in the first position, and its x
						and y values in the next two positions.
					</p>

					<p>
						But there are some drawbacks here too.  Let's say I want also be able to describe the width and height.
						So now instead of describing a shape as <code>['red', 100, 100]</code>,
						I would describe it as <code>['red', 100, 100, 50, 50]</code>.
					</p>

					<p>
						The problem here is that the longer the list gets, the more confusing it gets,
						and the harder it is to tell what is going to happen when that shape is drawn.
					</p>

					<p>
						You want your code to always be easy to read and understand, so other people can understand it,
						and also so you can understand it when you come back to work on it later.
					</p>

					<p>
						Wouldn't it be nice if you could know just by looking at it,
						which of those numbers is the height, which is the width, and which are the x and y positions?
						It would be a lot easier to not make mistakes,
						and also easier to add new properties to it.
					</p>
				</>,
				<>
					<h3 className="hGreen">Introducing Objects</h3>
					<p>
						This is where <strong>objects</strong> come in.  In javascript,
						the word <strong>object</strong> has a very specific meaning.
						It's a structure that represents multiple replated values.
					</p>

					<p>
						An object is represented like this:
					</p>

					<CodeBox code={`
						var myShape = {
							color: 'red',
							x: 100,
							y: 100,
						}
					`} />

					<p>
						As you can see, it starts with a <code>{'{'}</code> (left curly bracket),
						and ends with a <code>{'}'}</code> (right curly bracket).
					</p>

					<p>
						Inside the brackets you have the <strong>properties</strong> of your object.
						Each property has a name and a value. The name, like a variable name,
						should only consist of letters and numbers, no spaces.
					</p>

					<p>
						The value can be any value you know: a string, a number, a boolean, or even an array or another object.
					</p>

					<p>
						You can refer to these properties by their names by using the <code>.</code> (dot operator).
						So for example, <code>myShape.color</code> would be <code>"red"</code>, and <code>myShape.x</code> {}
						would be <code>100</code>.
					</p>
				</>,
				<>
					<h3 className="hGreen">Thinking About Objects</h3>
					<p>
						There are different ways to think about objects that can help you understand them.
						The first is to think of them as a way of representing physical objects.
						A shape might have size, position, and color.
						A person might have a name, age, height, and address.
						Objects give us a handy way to represent these properties together as a single item.
					</p>

					<p>
						You can also think of objects as a group of variables.
						Instead of three variables for <code>playerX</code>, <code>playerY</code>, and <code>playerScore</code>,
						you can have one <code>player</code> variable
						that has <code>player.x</code>, <code>player.y</code>, and <code>player.score</code> properties.
					</p>
				</>,
				<>
					<h3 className="hGreen">Using Objects</h3>

					<p>Let's see what the example from before would look like if we represent our shapes using objects:</p>

					<CanvasRunner code={`
						var shapes = [
							{color: 'red', x: 100, y: 100},
							{color: 'blue', x: 300, y: 200},
							{color: 'green', x: 100, y: 300},
						];

						for (var i = 0; i < shapes.length; i++) {
							var shape = shapes[i];
							canvas.fill(shape.color);
							canvas.ellipse(shape.x, shape.y, 50);
						}
					`} />
				{/* </> */}
					<p>
						This fixes the problems that we ran into before: Every property has a name with it,
						so you don't have to worry about remembering the order of x, y, width, and height anymore.
						That means you're less likely to make mistakes that lead to bugs, and it will be easier to make
						changes to your code.
					</p>

					<p>
						It's also easy to add new properties to your objects. Let's say you want to label each one.
						Sure, just add a new property called 'label' to your objects, then use it when you draw the shapes:
					</p>

					<CanvasRunner code={`
						var shapes = [
							{color: 'red', x: 100, y: 100, label: "Europe"},
							{color: 'blue', x: 300, y: 200, label: "Asia"},
							{color: 'green', x: 100, y: 300, label: "Africa"},
						];

						for (var i = 0; i < shapes.length; i++) {
							var shape = shapes[i];
							// draw the circle
							canvas.fill(shape.color);
							canvas.ellipse(shape.x, shape.y, 50);

							// draw the label
							canvas.fill('black');
							canvas.textAlign('center');
							canvas.text(shape.label, shape.x, shape.y + 7)
						}
					`} />

					<div style={{marginTop: 10}} className="container blRed">
						<p>
							<h3 className="hRed"><code>console</code> and <code>canvas</code> are also objects</h3>
							You may notice that <code>shape.x</code> looks similar to <code>console.log</code> or <code>canvas.rect</code>.
							That's because <code>console</code> and <code>canvas</code> are objects too! Objects can even have properties
							that are functions.  We won't use them that way yet, but you might do it further down the road.
						</p>
					</div>
				</>,
				<>
					<h3 className="hGreen">Your Turn</h3>

					<p>
						Now it's your turn.  Here are some objects that represent shapes.  Do the following to the list:
					</p>

					<ul>
						<li>Move the red shape all the way up into the top left corner so it's edges touch the edge of the canvas.</li>
						<li>Change the blue shape to be purple.</li>
						<li>Add a new shape of any color. It can be anywhere, but make sure it isn't overlapping another shape.</li>
						<li>Change the labels of all the shapes to be something completely different.</li>
						<li>
							Add a new property called <code>size</code> to each object.  Use it to make the shapes different sizes,
							and make sure they get drawn with their new sizes.
						</li>
					</ul>

					<CanvasRunner console vertical canvasHeight={300} editorHeight={540} docName="objects.changeList" code={`
						var shapes = [
							{color: 'red', x: 100, y: 100, label: "Europe"},
							{color: 'blue', x: 300, y: 200, label: "Asia"},
							{color: 'green', x: 100, y: 300, label: "Africa"},
						];

						for (var i = 0; i < shapes.length; i++) {
							var shape = shapes[i];
							// draw the circle
							canvas.fill(shape.color);
							canvas.ellipse(shape.x, shape.y, 50);

							// draw the label
							canvas.fill('black');
							canvas.textAlign('center');
							canvas.text(shape.label, shape.x, shape.y + 7)
						}
					`} />

					<p>
						Once you have done all that, here are some harder challenges to try.
						You don't have to do all of them, but I recommend doing as many as you can
						and trying all of them. Feel free to ask for help figuring out how to do them.
					</p>

					<ul>
						<li>Add a shape property to the shapes. Allow both circles and rectangles, and draw them correctly.</li>
						<li>When someone clicks the canvas, add a new shape where the mouse was clicked.</li>
						<li>When someone presses <code>enter</code>, add a new shape to the list.  You decide how to determine the shape's properties.</li>
						<li>When someone presses <code>backspace</code>, remove the last shape from the canvas.</li>
						<li>Experiment with the function <code>Math.random()</code>.  How can you use it to add a new shape in a random location?</li>
						<li>When someone clicks on a shape, give them a point. Display a score on the canvas.</li>
					</ul>

				</>,
				<>
					<h3 className="hPurple">Quiz Yourself</h3>
					<p>
						Think about what you've learned today, then answer the following questions:
					</p>

					<Question>What is an object?</Question>
					<Answer id="objects.qWhatIsObject">
						An object is a javascript structure that can help you represent complex things,
						such as shapes or players,
						that have multiple related values.
					</Answer>


					<Question>Why are objects useful?</Question>
					<Answer id="objects.qHowUseful">
						Objects can help you to make your code more simple, organized, and easy to read.
						They make it easy to work with complex data.
					</Answer>

					<Question>How can you use objects to improve your own project?</Question>
					<Answer id="objects.qHowToApply">
						The answer will depend on what you are making,
						but objects can be useful for combining all sorts of related values.
						For example, anything with an x and a y can be represented with an object.
						Shapes, players and other game elements, quiz questions (and answers), movie facts...
						Objects are useful for representing just about anything.
					</Answer>

					<p>&nbsp;</p>

					<p>
						You're done with the lesson, but feel free to try jumping into
						your <PrefixedLink to="/user/projects">own projects</PrefixedLink> and
						start using objects in them!
					</p>
				</>,
			]} />

			{/* <p>
				<PrefixedLink to="/"><Button intent="none">Home</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/lesson/and-or/2"><Button intent="primary">Continue</Button></PrefixedLink>
			</p> */}
		</Layout>
	);
};

export { ObjectsLesson }
