import * as React from 'react';
import { IconChevronUp } from 'sancho/esm/Icons/icons/IconChevronUp';
import { IconChevronDown } from 'sancho/esm/Icons/icons/IconChevronDown';

interface CollapseProps {
	// content: JSX.Element
	// heading: React.ReactNode
	title: string
	header?: React.ReactNode
	collapsed?: boolean
	children: JSX.Element | JSX.Element[]
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>
	className?: string
	tag?: keyof JSX.IntrinsicElements
}

const Collapse: React.FC<CollapseProps> = (props) => {
	let [collapsed, setCollapsed] = React.useState(!!props.collapsed);
	// let heading = typeof props.heading == 'string' ? <h2>{props.heading}</h2> : props.heading;
	let content = collapsed ? null : props.children;

	let header: React.ReactNode;
	if (!props.header) {
		const Tag = props.tag || 'h2';
		header = <Tag style={{cursor: 'pointer'}} className={props.className} onClick={() => setCollapsed(!collapsed)}>{props.title} {!collapsed ? <IconChevronUp /> : <IconChevronDown />}</Tag>
	}
	else {
		header = <div style={{cursor: 'pointer'}} className={props.className} onClick={() => setCollapsed(!collapsed)}>{props.header} {!collapsed ? <IconChevronUp /> : <IconChevronDown />}</div>
	}

	return <div {...props.wrapperProps}>
		{header}
		{/* {heading} */}
		{content}
		{/* <Button
		aria-controls="collapse"
		variant="ghost"
		intent="primary"
		iconAfter={show ? <IconChevronUp /> : <IconChevronDown />}
		onPress={() => setShow(!show)}
		> */}
		{/* {show ? "Learn less" : "Learn more"} */}
		{/* </Button> */}
	</div>
};

export { Collapse, CollapseProps }
