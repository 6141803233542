import { CanvasInterface } from "./lib/canvasWrapper";
import { Music } from "./lib/sound/Music";

type DebugDraw = (x: number, y: number, color: string) => void
type PongAI = (info: PongInfo) => number

interface PongInfo {
    // player: Player
    // opponent: Player
    // ball: Ball
    playerY: number
    playerX: number
    playerSpeed: number
    playerWidth: number
    playerSign: number
    playerVy: number
    playerScore: number

    ballY: number
    ballX: number
    ballVx: number
    ballVy: number
    // ballSize: number
    ballMinY: number
    ballMaxY: number
    ballMinX: number
    ballMaxX: number

    opponentY: number
    opponentX: number
    opponentSpeed: number
    opponentWidth: number
    opponentSign: number
    opponentVy: number
    opponentScore: number
}

interface Pong {
    registerAI(func: PongAI, name: string)
    debugDraw: DebugDraw
}

const pongTypeDef = `
type DebugDraw = (x: number, y: number, color: string) => void
type PongAI = (info: PongInfo) => number

interface PongInfo {
    // player: Player
    // opponent: Player
    // ball: Ball
    playerY: number
    playerX: number
    playerSpeed: number
    playerWidth: number
    playerSign: number
    playerVy: number
    playerScore: number

    ballY: number
    ballX: number
    ballVx: number
    ballVy: number
    // ballSize: number
    ballMinY: number
    ballMaxY: number
    ballMinX: number
    ballMaxX: number

    opponentY: number
    opponentX: number
    opponentSpeed: number
    opponentWidth: number
    opponentSign: number
    opponentVy: number
    opponentScore: number
}

interface Pong {
    registerAI(func: PongAI, name: string)
    debugDraw: DebugDraw
}

declare var Pong: Pong
`

interface PongRunner {
    initGame(canvasInterface: CanvasInterface): void
    interface: Pong
}

// interface AIObject {
//     name: string
//     func: PongAI
// }

interface PlayerAI {
    name: string
    nextMove(player: any, ball: any, opponent: any): number
}

function createRunner(/* canvasInterface: CanvasInterface */) {
    // const canvas = canvasInterface.wrapper;

    const initGame = (canvasInterface: CanvasInterface) => {
        runPong(canvasInterface, ais, pong/* , Music */);
    }
    // const registerAI = (ai: )

    const ais = [];

    const registerAI = (aiFunction: PongAI, aiName: string) => {
        ais.push(createOpponent(aiFunction, aiName));
    }

    const pong: Pong = {
        registerAI: registerAI,
        debugDraw: () => {},
    };

    let runner: PongRunner = {
        initGame,
        interface: pong,
    };

    return runner;
}

function createOpponent(aiFunction: PongAI, aiName: string): PlayerAI {
    return {
        name: aiName,
        nextMove: (player, ball, opponent) => {
            return aiFunction(createProps(player, ball, opponent));
        }
    }
}

function createProps(player, ball, opponent): PongInfo {
    return {
        playerY: player.y,
        playerX: player.sign > 0 ? ball.maxX : ball.minX,
        playerSpeed: player.speed,
        playerWidth: player.h,
        playerSign: player.sign,
        playerVy: player.vy,
        playerScore: player.score,

        ballY: ball.y,
        ballX: ball.x,
        ballVx: ball.vx,
        ballVy: ball.vy,
        // ballSize: ball.size,
        ballMinY: ball.minY,
        ballMaxY: ball.maxY,
        ballMinX: ball.minX,
        ballMaxX: ball.maxX,

        opponentY: opponent.y,
        opponentX: opponent.sign > 0 ? ball.maxX : ball.minX,
        opponentSpeed: opponent.speed,
        opponentWidth: opponent.h,
        opponentSign: opponent.sign,
        opponentVy: opponent.vy,
        opponentScore: opponent.score,
    }
}

function runPong(canvasInterface: CanvasInterface, userAis: PlayerAI[], pong/* , Music: Music */) {
    const canvas = canvasInterface.wrapper;
    pong.debugDraw = debugDraw;

    var player1 = {
        x: 10,
        sign: -1,
        y: 200,
        vy: 0,
        w: 5,
        h: 40,
        speed: 2.25,
        score: 0,
        ai: null,
    }

    var player2 = {
        x: 390,
        sign: 1,
        y: 200,
        vy: 0,
        w: 5,
        h: 40,
        speed: 2.25,
        score: 0,
        ai: null,
    }

    var ball = {
        x: 0,
        y: 0,
        vx: 0,
        vy: 0,
        size: 15,
        // really properties of the game area
        minY: 0,
        maxY: 0,
        minX: 0,
        maxX: 0,
    }

    ball.minY = ball.size / 2;
    ball.maxY = 400 - ball.minY;
    ball.minX = player1.x + 5;
    ball.maxX = player2.x - 5;

    var opponents: PlayerAI[] = getOpponents();
    var opponentIndexes = [0, 0];

    opponents.unshift(...userAis)

    var started = false;

    var debugPoints: [number, number, string][] = [];

    reset();

    canvas.animate(main);

    function main() {
        update();
        draw();
    }

    function update() {
        if (!started) {
            return;
        }
        movePlayer(player1);
        movePlayer(player2);

        ball.x += ball.vx;
        ball.y += ball.vy;
        if (ball.vy > 0 && ball.y > ball.maxY) {
            ball.y = ball.maxY + (ball.maxY - ball.y);
            ball.vy = -ball.vy;
        }
        else if (ball.vy < 0 && ball.y < ball.minY) {
            ball.y = ball.minY + (ball.minY - ball.y);
            ball.vy = -ball.vy;
        }

        if (ball.vx > 0) {
            if (ball.x + ball.size / 2 >= player2.x) {
                checkCollision(ball, player2, player1);
            }
        }
        else {
            if (ball.x - ball.size / 2 <= player1.x) {
                checkCollision(ball, player1, player2);
            }
        }

        if (player1.ai) {
            player1.vy = capVel(player1, player1.ai.nextMove(player1, ball, player2/* , debugDraw */));
        }
        if (player2.ai) {
            player2.vy = capVel(player2, player2.ai.nextMove(player2, ball, player1/* , debugDraw */));
        }
        // getNextIntersect(player1, ball, player2);
        // getNextIntersect(player2, ball, player1)
    }

    function movePlayer(player) {
        player.y += player.vy;
        if (player.y - player.h / 2 < 0) {
            player.y = player.h / 2;
        }
        else if (player.y + player.h / 2 > 400) {
            player.y = 400 - player.h / 2;
        }
    }

    function checkCollision(ball, player, opponent) {
        var vert = ball.y - player.y;
        // console.log(ball.x, ball.y);
        if (Math.abs(vert) > player.h / 2) {
            // miss
            // console.log(ball, player, opponent);
            opponent.score++;
            started = false;
            reset();
        }
        else {
            ball.vx = -ball.vx;
            ball.vy += vert / 10;
            // console.log(ball.vy);
            ping(ball.vx > 0);
        }
    }

    function reset() {
        ball.x = 200;
        ball.y = 200;
        ball.vx = Math.random() > .5 ? 5 : -5;
        ball.vy = Math.random() * 2 - 1;

        player1.y = 200;
        player2.y = 200;
        player1.vy = 0;
        player2.vy = 0;
    }

    function draw() {
        // if (player2.ai && !started) return;
        canvas.background('black');
        canvas.fill('white');

        drawPlayer(player1);
        drawPlayer(player2);

        canvas.textAlign('center', 'middle');
        if (player2.ai !== null) {
            // draw ball
            canvas.ellipse(ball.x, ball.y, ball.size)
        }
        else {
            var nextPName = (player1.ai === null) ? 'player 1' : 'player 2';
            // if (!opponent) {
            var oi = player1.ai === null ? 0 : 1;
            var currentOI = opponentIndexes[oi];
            canvas.text('press up/down', 200, 50);
            canvas.text(`to select ${nextPName}:`, 200, 80);
            var min = Math.max(0, currentOI - 3)
            var max = Math.min(min + 6, opponents.length);
            for (var i = min; i < max; i++) {
                var opp = opponents[i];
                var y = 140 + (i - min) * 30;
                canvas.text(opp ? opp.name : 'human', 200, y);
                if (i == currentOI) {
                    canvas.shape(100, y, 90, y - 10, 90, y + 10);
                }
            }
            // }
        }
        if (!started) {
            canvas.text('press space to start', 200, 350);
            canvas.text('r to reset', 200, 380);
        }

        canvas.textAlign('right', 'top');
        canvas.text('' + player1.score, 180, 10);
        canvas.text(player1.ai ? player1.ai.name : 'player 1', 140, 10);
        canvas.textAlign('left', 'top');
        canvas.text('' + player2.score, 220, 10);
        canvas.text(player2.ai ? player2.ai.name : 'player 2', 260, 10);

        while (debugPoints.length) {
            let pt = debugPoints.shift();
            if (pt[2]) canvas.fill(pt[2]);
            canvas.ellipse(pt[0], pt[1], 10);
        }
    }

    function drawPlayer(player) {
        if (player.target) {
            canvas.fill('red');
            canvas.ellipse(player.x, player.target, 10);
            canvas.fill('white');
        }
        canvas.rect(player.x - player.w / 2, player.y - player.h / 2, player.w, player.h);
    }

    // var _check: DebugDraw = debugDraw;
    function debugDraw(x: number, y: number, color: string) {
        debugPoints.push([x, y, color]);
    }

    function whenKeyPressed(key) {
        if (key == 'r') {
            player1.ai = null;
            player2.ai = null;
            player1.score = 0;
            player2.score = 0;
            reset();
            started = false;
        }
        else if (key == 'space') {
            if (started) {
                started = false;
            }
            else if (player1.ai === null) {
                player1.ai = opponents[opponentIndexes[0]];
            }
            else if (player2.ai === null) {
                player2.ai = opponents[opponentIndexes[1]];
                started = true;
            }
            else {
                started = true;
            }
        }
        else if (started) {
            var p1Up, p1Down, p2Up, p2Down;

            if (!player1.ai) {
                p1Up = 'up'
                p1Down = 'down';
                if (!player2.ai) {
                    p2Up = 'w';
                    p2Down = 's';
                }
            }
            else if (!player2.ai) {
                p2Up = 'up';
                p2Down = 'down';
            }

            if (key == p1Up) {
                player1.vy = -player1.speed;
            }
            else if (key == p1Down) {
                player1.vy = player1.speed;
            }
            else if (key == p2Up) {
                player2.vy = -player2.speed;
            }
            else if (key == p2Down) {
                player2.vy = player2.speed;
            }
        }
        else {
            if (key == 'up') {
                var oi = player1.ai === null ? 0 : 1;
                opponentIndexes[oi] -= 1;
                if (opponentIndexes[oi] < 0) opponentIndexes[oi] = opponents.length - 1;
            }
            else if (key == 'down') {
                var oi = player1.ai === null ? 0 : 1;
                opponentIndexes[oi] += 1;
                if (opponentIndexes[oi] >= opponents.length) opponentIndexes[oi] = 0;
            }
        }
    }

    function whenKeyReleased(key) {
        // if (key == 'left' && player1.vx < 0 || key == 'right' && player1.vx > 0) {
        //     player1.vx = 0;
        // }
        var p1Up, p1Down, p2Up, p2Down;
        if (!player1.ai) {
            p1Up = 'up'
            p1Down = 'down';
            if (!player2.ai) {
                p2Up = 'w';
                p2Down = 's';
            }
        }
        else if (!player2.ai) {
            p2Up = 'up';
            p2Down = 'down';
        }
        if (key == p1Up && player1.vy < 0 || key == p1Down && player1.vy > 0) {
            player1.vy = 0;
        }
        if (key == p2Up && player2.vy < 0 || key == p2Down && player2.vy > 0) {
            player2.vy = 0;
        }
    }

    var inst = Music.createInstrument('triangle');

    function ping(pingOrPong) {
        inst.playNote(pingOrPong ? 'A3' : 'E3', .05);
        inst.playNote(pingOrPong ? 'E3' : 'B4', .1, .05);
    }

    function getOpponents(): PlayerAI[] {
        var basic = {
            name: 'basic',
            nextMove(player, ball, opponent) {

                var vert = ball.y - player.y;
                if (Math.abs(vert) > 1) {
                    return vert > 0 ? player.speed : -player.speed;
                }
                else {
                    return 0;
                }
            }
        }

        var smart = {
            name: 'smart',
            nextMove(player, ball, opponent) {
                var nextInt: any = getNextIntersect(player, ball, opponent);
                nextInt = nextInt[0];
                var yDist = nextInt - player.y;
                var target = yDist;
                // var target = yDist + Math.sin(nextInt) * 10;
                return Math.min(player.speed, Math.max(-player.speed, target));
            }
        }

        var defensive = {
            name: 'defensive',
            nextMove(player, ball, opponent) {
                var nextInt: any = getNextIntersect(player, ball, opponent);
                var nextIntVel = nextInt[1];
                nextInt = nextInt[0];
                var yDist = nextInt - player.y;
                var target = yDist + Math.min(15, Math.max(-15, nextIntVel * -10));
                return Math.min(player.speed, Math.max(-player.speed, target));
            }
        }

        var evil = {
            name: 'offensive',
            nextMove(player, ball, opponent) {
                var nextInt: any = getNextIntersect(player, ball, opponent);
                var nextIntDir = Math.sign(nextInt[1]) || 1;
                nextInt = nextInt[0];
                var yDist = nextInt - player.y;
                var target = yDist + nextIntDir * 15;
                return Math.min(player.speed, Math.max(-player.speed, target));
            }
        }

        var strategic = {
            name: 'strategic',
            nextMove(player, ball, opponent) {
                var nextInt: any = getNextIntersect(player, ball, opponent);
                var nextIntVel = -nextInt[1];
                nextInt = nextInt[0];


                // figure out how to put the ball in the corner
                var width = ball.maxX - ball.minX;
                var xSpeed = Math.abs(ball.vx);;
                var ballSlope = nextIntVel / xSpeed;
                var nextNextInt = nextInt + ballSlope * width;
                var nextTarget;
                var bounceHeight = ball.maxY - ball.minY;
                if (ball.x == (player.sign > 0 ? ball.minX : ball.maxX)) {
                    if (player.repeatSidesCount == undefined) {
                        player.repeatSidesCount = 0;
                        player.lastSide = ball.y > 200 ? 1 : 0;
                    }
                    else {
                        var side = ball.y > 200 ? 1 : 0;
                        if (side == player.lastSide) {
                            player.repeatSidesCount++;
                        }
                        else {
                            player.repeatSidesCount = 0;
                        }
                        player.lastSide = side;
                    }
                }
                // if (false) {
                //     debugger
                //     nextTarget = opponent.y < 200 ? ball.maxY - 1 : ball.minY + 1;
                //     if (nextNextInt < 200) {
                //         while (nextNextInt - nextTarget < -bounceHeight) {
                //             nextTarget -= 2 * bounceHeight;
                //         }
                //     }
                //     else {
                //         while (nextNextInt - nextTarget > bounceHeight) {
                //             nextTarget += 2 * bounceHeight;
                //         }
                //     }
                // }
                // else {
                if (player.repeatSidesCount >= 4) {
                    nextNextInt = 400 - nextNextInt;
                    // console.log('reversing target', nextNextInt);
                }
                if (nextNextInt < 200) {
                    nextTarget = ball.minY + 1;
                    while (nextNextInt - nextTarget < -200) {
                        nextTarget -= bounceHeight;
                    }
                }
                else {
                    nextTarget = ball.maxY - 1;
                    while (nextNextInt - nextTarget > 200) {
                        nextTarget += bounceHeight;
                    }
                }
                // }
                // console.log(nextNextInt, nextTarget);
                var yDiff = nextTarget - nextNextInt;
                var velDiff = yDiff / width * xSpeed;
                var targetOffset = -velDiff * 10;

                var targetDist = nextInt - player.y;;

                targetOffset = Math.min(19, Math.max(-19, targetOffset));
                targetDist += targetOffset;
                // // is it possible?
                // if (Math.abs(targetOffset) < 20) {
                //     // put it in the corner!
                //     // debugger
                //     // targetOffset = Math.min(19, Math.max(-19, targetOffset));
                //     targetDist += targetOffset;
                //     // console.log('on');
                // }
                // else {
                //     // try to get the velocity to the target velocity
                //     var targetVel = 3 * Math.sign(nextIntVel);
                //     velDiff = targetVel - nextIntVel;
                //     targetOffset = Math.min(18, Math.max(-18, velDiff * -10));
                //     targetDist += targetOffset;
                // }

                return Math.min(player.speed, Math.max(-player.speed, targetDist));
            }
        }

        return [undefined, basic, smart, defensive, evil /* , superEvil */ , strategic];
    }

    function capVel(player, vel) {
        return Math.min(Math.max(vel, -player.speed), player.speed);
    }

    function getNextIntersect(player, ball, opponent) {
        // math is hard.
        // this will be wrong if the ball size or ball vx changes
        var intersectX = player.x - 5 * player.sign;
        // var intersectX = player.x - ball.size * player.sign / 2;
        var xDist = ball.x - intersectX;
        if (ball.vx * player.sign < 0) {
            var bounceX = 400 - intersectX;
            xDist = 2 * bounceX - intersectX - ball.x;
        }
        xDist = Math.abs(xDist);
        var intersectY = ball.y + (ball.vy / Math.abs(ball.vx)) * xDist;

        var flipped = false;
        while (intersectY < ball.minY || intersectY > ball.maxY) {
            if (intersectY < ball.minY) {
                intersectY = 2 * ball.minY - intersectY;
            }
            else {
                intersectY = 2 * ball.maxY - intersectY;
            }
            flipped = !flipped;
        }

        // player.target = intersectY;

        return [intersectY, flipped ? ball.vy : -ball.vy];
    }

    canvasInterface.handlers.onKeyPressed = whenKeyPressed
    canvasInterface.handlers.onKeyReleased = whenKeyReleased
}

export { createRunner, pongTypeDef, PongRunner, PlayerAI }
