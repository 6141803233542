import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { Link } from 'react-router-dom';
import { Button } from 'sancho/esm/Button';

const VariablesLesson: React.FC = () => {
	const prefix = useCoursePrefix();

	return <Layout narrow>
		<h1>Lesson: Variables</h1>

		<p>
		Have you ever filled out a form?
		Or filled in a blank on a test or an assignment?
		</p>

		<p>
		Congratulations, you already know something about a fundamental concept of programming: <strong>variables.</strong>
		</p>

		<p>
		A variable is a placeholder with for a piece of information that can change. <br/>
		For example, a program might need to know a user's name or email address. <br/>
		Or, a game might need to keep track of your score, the time, and the amount of life you have left.
		</p>

		<p>
		Variables help us keep track of the information in our programs. <br/>
		You can store just about any sort of information in a variable. <br/>
		We'll learn more about <strong>data types</strong>, or the types of information we can store, a little later.
		</p>

		<h3>Storing information in variables</h3>
		<p>
			You shared some basic facts with the class at the beginning.  Now it's time to put them into code! <br/>
			Put the facts you shared into the program below, and watch it run.
		</p>

		<CodeRunner code={`
			// first, we define some variables
			var name = '';
			var age = 0;
			var funFact = '';

			// now we can use those variables
			console.log('My name is', name)
			console.log('I am', age, 'years old')
			console.log("Here's a fun fact about me:")
			console.log(funFact);
			`}
		/>

		{/* <p>Explain to you </p> */}

		<h3>Going Deeper: When should I use a variable?</h3>

		<ul>
			<li>Values that aren't known when the program is created</li>
			<li>Values that are different each time the program runs</li>
			<li>Values that need to be reused more than once</li>
		</ul>


		{/* <h3>Another analogy: buckets</h3>

		<p>
			Let's imagine you are making a game, and you want to Suppose I'm
		</p>
		<p>
			A variable is a place to store a value with a descriptive name.
			You use the name of the variable to access the value, and the
			 is like a bucket with a label.  You can put whatever you want in the bucket, and </p>

		<CodeRunner code="var greeting = 'Welcome to JS!';" />
		*/}

		{/* <h3>Bonus</h3>
		<p>
			There's another function similar to console.log. It starts with an e. Can you guess what it is?
		</p>

		<p>
			Try typing <code>console.e</code> in the editor, and see what happens...
			Did you find it?
			How is it different than console.log?
		</p> */}

		<Link to={prefix}>
			<Button intent="primary">Finished</Button>
		</Link>

		{/* <Links /> */}
	</Layout>
}


export { VariablesLesson }
