import * as React from 'react';
import { c } from './classNames';

interface EmojiProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	e: string
}

const Emoji: React.FC<EmojiProps> = props => {
	let {e, className, style, ...otherProps } = props;
	let spanStyle = {...style, backgroundImage: `url("${getSrc(e)}")`};

	return <span {...props} className={c('Emoji', props.className)} style={spanStyle} children={e} />
}

const getSrc = (emojiStr: string) => {
	let hex = '';
	if (/^[a-fA-F0-9-]+$/.test(emojiStr)) {
		hex = emojiStr.toLowerCase();
	}
	else {
		// split the string by code points, then map to hex code points
		// turns 👨🏻‍💻 into 1f468-1f3fb-200d-1f4bb
		// NOTE: until we add a .tsconfig and compile to ES6, this is how you iterate over an emoji string.
		// in the future, we can use the commented line (with [...emojiStr]) instead.
		// let chars = getSymbols(emojiStr);
		// actually, this works, and doesn't get down-compiled
		let chars = Array.from(emojiStr);
		hex = chars.map(c => c.codePointAt(0).toString(16)).join('-').toLowerCase();
	}
	// const hex = [...emojiStr].map(c => c.codePointAt(0).toString(16)).join('-').toLowerCase();

	// return `https://cdnjs.cloudflare.com/ajax/libs/twemoji/2.0.0/svg/${cp}.svg`;
	// these also work
	// https://twemoji.maxcdn.com/v/12.1.5/svg/2764.svg
	// https://twemoji.maxcdn.com/v/12.1.5/72x72/2764.png
	return `https://abs-0.twimg.com/emoji/v2/svg/${hex}.svg`
}

// // TODO: switch compile target to ES6 and get rid of all this garbage.
// // Must add a warning that IE11 is not supported first.
// // code from https://mathiasbynens.be/notes/javascript-unicode#iterating-over-symbols
// function getSymbols(string: string) {
// 	var index = 0;
// 	var length = string.length;
// 	var output = [];
// 	for (; index < length; ++index) {
// 		var charCode = string.charCodeAt(index);
// 		if (charCode >= 0xD800 && charCode <= 0xDBFF) {
// 			charCode = string.charCodeAt(index + 1);
// 			if (charCode >= 0xDC00 && charCode <= 0xDFFF) {
// 				output.push(string.slice(index, index + 2));
// 				++index;
// 				continue;
// 			}
// 		}
// 		output.push(string.charAt(index));
// 	}
// 	return output;
// }

export { Emoji }
