import React from "react";

/** can be used like  componentWillMount */
// const useInit =  <T,>(initCb: () => T): T => React.useState(initCb)[0];
const useInit =  initCb => React.useState(initCb)[0];

/** can be used like  componentWillUnmount */
const useCleanup = cleanupCb => React.useEffect(() => cleanupCb, []);

export { useInit, useCleanup }
