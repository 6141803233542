import * as React from 'react'
import { outdent } from '../CodeBox';
import { MonacoFrame2, MonacoFrameInterface } from './MonacoFrame2';
import { run, useConsole } from './runner';

interface CodeRunnerProps<T extends {[string: string]: any} = any> {
	height?: number | string
	width?: number | string
	code?: string
	context?: T | ((console: Console) => T)
	storageKey?: string
	docName?: string
	docGroup?: string
	docType?: string
	noReset?: boolean
	beforeRun?(source: string, console: Console, context: T, exports?: string[]): void
	afterRun?(returnVal: any, { source: string, console: Console, context: any, }): void
	afterInit?(api: MonacoFrameInterface): void
	exports?: string[]
	extraLibs?: string | string[]
	noConsole?: boolean
	autorun?: boolean
	outerHeight?: boolean
	unsavedChangesWarning?: boolean
}

const CodeRunner: React.FC<CodeRunnerProps> = (props) => {
	let consoleRef: React.Ref<HTMLDivElement> = React.useRef(null);
	let fakeConsole = useConsole(consoleRef);

	// console.log('MonacoRunner.render');

	let code = props.code;

	if (typeof code !== 'string') {
		if (typeof props.children !== 'string') {
			throw new Error('MonacoRunner requires a code prop, or children to be a string');
		}
		else {
			code = props.children;
		}
	}

	code = outdent(code);

	let height = props.height;
	if (height === undefined) {
		let lines = code.split('\n').length;
		height = 25 + Math.max(4, lines + 1) * 21;
		// height = 25 + 21 * lines;
	}

	let outerStyle = props.outerHeight ? {height: height} : null;
	// let frameHeight = props.outerHeight ? null : height;
	let frameHeight = props.outerHeight ? null : height;

	return <div className="CodeRunner" style={outerStyle}>
		<div className="ed-wrapper">
			<MonacoFrame2 initialCode={code}
				storageKey={props.storageKey}
				// frameSrc="http://localhost:5000/monaco-frame"
				// frameOrigin="http://localhost:5000"
				docName={props.docName}
				docGroup={props.docGroup}
				docType={props.docType}
				height={frameHeight}
				width={props.width}
				resize
				beforeRun={source => {
					let context = typeof props.context == 'function' ? props.context(fakeConsole) : props.context || {};

					if (props.beforeRun) props.beforeRun(source, fakeConsole, context, props.exports);
					let ret = run(source, fakeConsole, context, props.exports);
					if (props.afterRun) props.afterRun(ret || {}, {source, console: fakeConsole, context});
				}}
				afterInit={props.afterInit}
				extraLibs={props.extraLibs}
				autorun={props.autorun}
				noReset={props.noReset}
				unsavedChangesWarning={props.unsavedChangesWarning}
			/>
		</div>
		{!props.noConsole &&
			<div className="console-wrap">
				<div ref={consoleRef} className="console"></div>
				<button className="clear" onClick={fakeConsole.clear}>Clear Console</button>
			</div>
		}
	</div>
}

export { CodeRunner, CodeRunnerProps }
