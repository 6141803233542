class Drums {
	_volume: number;
	_ctx: AudioContext;
	_noiseBuffer: AudioBuffer;

	constructor(context: AudioContext, volume = 1) {
		this._ctx = context;
		this._volume = volume;
		// this._openNotes = [];

		this._noiseBuffer = context.createBuffer(1, 44100, 44100); // 44100 is a standard sample rate for most sound cards
		let noiseBufferOutput = this._noiseBuffer.getChannelData(0);
		for (let i = 0; i < 44100; i++) {
			noiseBufferOutput[i] = Math.random() * 2 - 1;
		}
	}

	playNote(note: 'bass' | 'snare' | 'hihat' | 'tom', timeOffset = 0, duration?: number, volume?: number, freq?: number) {
		if (note == 'bass') {
			this.bass(timeOffset, duration, volume, freq);
		}
		else if (note == 'snare') {
			this.bass(timeOffset, duration, volume, freq);

		}
		else if (note == 'hihat') {
			this.bass(timeOffset, duration, volume, freq);
		}
	}

	cymbal(timeOffset = 0, duration = .75, volume?: number, freq = 12000, pass: 'highpass' | 'lowpass' | 'bandpass' = 'bandpass', q = 1) {
		this.hihat(timeOffset, duration, volume, freq, pass, q);
	}

	hihat(timeOffset = 0, duration = .1, volume?: number, freq = 10000, pass: 'highpass' | 'lowpass' | 'bandpass' = 'highpass', q = 1) {
		let context = this._ctx;
		let noise = context.createBufferSource();
		let noiseFilter = context.createBiquadFilter();
		let noiseEnvelope = context.createGain();
		let vol = volume || this._volume;
		let t = this._ctx.currentTime + timeOffset;

		noise.buffer = this._noiseBuffer;
		noiseFilter.type = pass;
		noiseFilter.frequency.value = freq;
		// only applies to bandpass
		noiseFilter.Q.value = q;

		noise.connect(noiseFilter);
		noiseFilter.connect(noiseEnvelope);
		noiseEnvelope.connect(context.destination);
		noiseEnvelope.gain.setValueAtTime(vol * 0.7, t);
		noiseEnvelope.gain.exponentialRampToValueAtTime(vol * 0.5, t + duration / 2);
		noiseEnvelope.gain.exponentialRampToValueAtTime(vol * 0.01, t + duration);
		noise.start(t);
		noise.stop(t + duration);
	}

	snare(timeOffset = 0, duration = .2, volume?: number, noiseFreq = 1000, drumFreq = 220) {
		let context = this._ctx;

		//noise
		let noise = context.createBufferSource();
		let noiseFilter = context.createBiquadFilter();
		let noiseEnvelope = context.createGain();
		let t = this._ctx.currentTime + timeOffset;

		noise.buffer = this._noiseBuffer;
		noiseFilter.type = 'highpass';
		noiseFilter.frequency.value = noiseFreq;
		noise.connect(noiseFilter);
		noiseFilter.connect(noiseEnvelope);
		noiseEnvelope.connect(context.destination);
		noiseEnvelope.gain.setValueAtTime(volume || this._volume, t);
		noiseEnvelope.gain.exponentialRampToValueAtTime(0.01, t + duration);
		noise.start(t);
		noise.stop(t + duration);

		// oscillator
		let osc = context.createOscillator();
		let oscEnvelope = context.createGain();

		osc.type = 'triangle';
		osc.connect(oscEnvelope);
		oscEnvelope.connect(context.destination);
		osc.frequency.setValueAtTime(drumFreq, t);
		oscEnvelope.gain.setValueAtTime(volume || this._volume, t);
		oscEnvelope.gain.exponentialRampToValueAtTime(0.01, t + duration / 2);
		osc.start(t);
		osc.stop(t + duration);
	}

	tom(whichTom = 0, timeOffset = 0, duration = .5, volume?: number) {
		let freq = 400 - whichTom * 75;
		this.bass(timeOffset, duration, volume || this._volume, freq);
	}

	bass(timeOffset = 0, duration = .5, volume?: number, freq = 150) {
		let context = this._ctx;
		let osc = context.createOscillator();
		let gain = context.createGain();
		let t = this._ctx.currentTime + timeOffset;

		osc.frequency.setValueAtTime(freq, t);
		osc.frequency.exponentialRampToValueAtTime(0.01, t + duration);
		gain.gain.setValueAtTime(volume || 3 * this._volume, t);
		gain.gain.exponentialRampToValueAtTime(0.01, t + duration);
		osc.connect(gain);
		gain.connect(context.destination);
		osc.start(t);
		osc.stop(t + duration);
	}
}

export { Drums };
