import { CanvasDemoFn } from "../lib/CanvasDemo";

const makeChickenDemo: CanvasDemoFn = canvas => {
	// write your code here

	canvas.animate(draw);

	// You can define functions at the bottom, but they will
	// be defined at start of the program, so you can use them
	// above

	function draw(f) {
		canvas.background('#d4d2d0');

		var offsetX = 25,
			offsetY = 75,
			width = 400;
		var left = -25,
			top = 200
		canvas.fill('#888')
		canvas.rect(0, top, width, offsetY)
		canvas.fill('#555')
		canvas.rect(0, top + offsetY, width, 20)

		var spacing = 50;
		for (var i = left; i < width / spacing; i++) {
			var x = i * spacing + (f * .6 % spacing);
			canvas.line(x, top + 1, x + offsetX, top + offsetY - 1);
		}

		for (var x = 10; x < 400; x += 20) {
			canvas.fill('#333');
			var y = top + offsetY + 10
			canvas.changePosition(x, y)
			canvas.rotate(f / 20)
			canvas.ellipse(0, 0, 15)
			canvas.line(-7, 0, 7, 0)
			// canvas.line(0, -7, 0, 7)
			canvas.resetPosition()
			canvas.resetPosition()
		}

		var chickenSpacing = 50;
		var dOffset = f * .6 % chickenSpacing;
		for (var i = 0; i < 11; i++) {
			var x = dOffset - 20 + i * chickenSpacing
			drawChicken(x, 230, .15)
		}

		canvas.fill('#666');
		canvas.rect(0, top - 20, 100, offsetY + 20)
		canvas.fill('black');
		canvas.textSize(12);
		canvas.text('makeChicken()', 5, top + 32)
	}

	function drawChicken(x, y, scale = 1) {
		canvas.changePosition(x, y);
		if (scale != 1) {
			canvas.scale(scale);
		}

		canvas.textAlign('center', 'middle');
		canvas.fill('yellow');
		canvas.ellipse(0, 0, 125);
		canvas.ellipse(60, -50, 65);
		canvas.fill('orange');
		canvas.shape(85, -50, 105, -45, 85, -40);
		canvas.fill('sienna');
		canvas.ellipse(67, -60, 10);
		canvas.stroke('black');
		canvas.strokeWeight(5);
		var spread = 8;
		canvas.line(-10 + -spread, 60, -12 + -spread, 90);
		canvas.line(-12 + -spread, 90, -24 + -spread, 100);
		canvas.line(-12 + -spread, 90, -2 + -spread, 100);
		canvas.line(-12 + -spread, 90, -14 + -spread, 104);

		canvas.line(10 + spread, 60, 12 + spread, 90);
		canvas.line(12 + spread, 90, 24 + spread, 100);
		canvas.line(12 + spread, 90, 2 + spread, 100);
		canvas.line(12 + spread, 90, 14 + spread, 104);
		canvas.noStroke();

		canvas.resetPosition();
		if (scale != 1) {
			canvas.resetPosition()
		}
	}
}
export { makeChickenDemo }
