import * as React from 'react';
import { CanvasInterface, scaleCanvas, createCanvasInterface } from './canvasWrapper';
// import { scaleCanvas } from './canvasWrapper';
// import { createCanvasInterface } from './canvasWrapper';


// function createCanvasWrapper(fakeConsole: Console) {
// 	const canvas = document.createElement('canvas');
// 	// do something with the canvas

// 	const context = canvas.getContext('2d');
// 	const contextWrapper = createContextWrapper(context, canvas);

// 	return contextWrapper;
// }

interface WrappedCanvasProps {
	// canvasWrapperRef: React.MutableRefObject<CanvasWrapper>
	canvasInterfaceRef: React.MutableRefObject<CanvasInterface>
	// canvasRef: React.MutableRefObject<HTMLCanvasElement>
	width?: number
	height?: number
	scaledWidth?: number
	scaledHeight?: number
	arrows?: boolean
	runButton?: boolean
	fullScreen?: boolean
	// onKeyPressed?(button: string): void
	// onMouseClick?(x: number, y: number): void
	// onMouseMove?(x: number, y: number): void
	onInit?(canvasInterface: CanvasInterface): void
}

const WrappedCanvas: React.FC<WrappedCanvasProps> = (props) => {
	let [canvas, setCanvas] = React.useState<HTMLCanvasElement>(null);
	let canvasRef = React.useCallback(canvas => {
		setCanvas(canvas);
	}, []);

	let canvasInterfaceRef = props.canvasInterfaceRef;
	// let canvasInterface = props.canvasInterfaceRef.current/*  || {} as CanvasInterface */;

	let width = props.width || 400
	let height = props.height || width;

	let scaledWidth = props.scaledWidth || width;
	let scaledHeight = props.scaledHeight || height;

	React.useEffect(() => {
		if (canvas) {
			// console.log('initing WrappedCanvas wrapper');
			let ctx = canvas.getContext('2d');
			scaleCanvas(canvas, width, height);

			// props.canvasWrapperRef.current = createContextWrapper(ctx, canvas, console);
			var canvasInterface = createCanvasInterface(ctx, canvas);
			canvasInterface.wrapper.reset();

			if (props.canvasInterfaceRef) {
				props.canvasInterfaceRef.current = canvasInterface;
			}
			// (window as any).ctx = ctx;
			if (props.onInit) props.onInit(canvasInterface);
		}

		// if (onKeyPressed) {
		const keyHandler = (e: KeyboardEvent) => {
			// do nothing if another canvas or an input is selected
			if (document.activeElement.nodeName == 'CANVAS' && document.activeElement != canvas) {
				return;
			}
			else if (document.activeElement.nodeName == 'INPUT' || document.activeElement.nodeName == 'TEXTAREA' || document.activeElement.nodeName == 'BUTTON') {
				return;
			}

			let canvasInterface = canvasInterfaceRef.current
			if (canvasInterface && canvasInterface.handlers.onKeyPressed) {
				let key = processKey(e.key, e.code);
				// console.log(e.key, e.code, key);
				if (key) {
					// avoid accidental scrolling
					if (key == 'space' || key == 'enter' || key == 'up' || key == 'down' || key == 'left' || key == 'right') e.preventDefault();
					let ret = canvasInterface.handlers.onKeyPressed(key);
					if (ret === false) e.preventDefault();
				}
			}
		};

		const keyUpHandler = (e: KeyboardEvent) => {
			let canvasInterface = canvasInterfaceRef.current
			if (canvasInterface && canvasInterface.handlers.onKeyReleased) {
				let key = processKey(e.key, e.code);
				canvasInterface.handlers.onKeyReleased(key);
			}
		}

		document.addEventListener('keydown', keyHandler);
		document.addEventListener('keyup', keyUpHandler);

		return () => {
			document.removeEventListener('keydown', keyHandler);
			document.removeEventListener('keyup', keyUpHandler);
		}
		// }

	}, [canvas]);

	// these buttons simulate key presses
	const handleButtonClick = (button: string) => {
		if (!canvasInterfaceRef.current) return;
		let onKeyPressed = canvasInterfaceRef.current && canvasInterfaceRef.current.handlers.onKeyPressed;
		if (onKeyPressed) {
			onKeyPressed(button);
		}
	}

	// const handleKeyEvent = (e: React.KeyboardEvent) => {}

	const handleMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
		if (!canvasInterfaceRef.current) return;

		// allow running code for the first time by clicking on the empty canvas
		if (e.type == 'click' && !canvasInterfaceRef.current.hasRun && canvasInterfaceRef.current.handlers && canvasInterfaceRef.current.handlers.onRun) {
			handleRun();
			return;
		}

		let { onMouseClicked, onMouseMoved } = canvasInterfaceRef.current.handlers;

		if (e.type == 'mousemove' && onMouseMoved || e.type == 'click' && onMouseClicked) {
			// calculate x and y
			let rect = e.currentTarget.getBoundingClientRect();
			var left = rect.left;
			var top = rect.top;
			// measure correctly when the canvas is scaled, such as when it's fullscreen
			// assume square. this needs to change if we add support for a non-square canvas
			var actualWidth = rect.width;
			var actualHeight = rect.height;
			if (actualWidth != actualHeight) {
				if (actualWidth > actualHeight) {
					// actual frame is wider than the canvas.
					left += (actualWidth - actualHeight) / 2;
					actualWidth = actualHeight;
				}
				else {
					top += (actualHeight - actualWidth) / 2;
					actualHeight = actualWidth;
				}
			}
			let x = e.clientX - left, y = e.clientY - top;

			x = Math.round(x * width / actualWidth);
			y = Math.round(y * height / actualHeight);

			(e.type == 'mousemove' ? onMouseMoved : onMouseClicked)(x, y);
		}
	}

	const handleRun = () => {
		let handler = canvasInterfaceRef.current && canvasInterfaceRef.current.handlers.onRun;
		if (typeof handler == 'function') handler();
	}

	const handleFullScreen = () => {
		canvas.requestFullscreen();
		// handleRun();
	}

	const buttonStyle = {width: 80, background: 'white', color: 'black', fontFamily: 'Rubik', fontSize: 24, margin: 4}

	return (<div className="WrappedCanvas">
		{/* <div style={{width, height, position: 'relative'}}> */}
			{/* <div style={{position: 'absolute', top: '50%', width: '100%', marginTop: '-1em', textAlign: 'center'}}>Click to Run</div> */}
		<canvas onMouseMove={handleMouseEvent} onClick={handleMouseEvent}/*  onKeyDown={handleKeyEvent} */ ref={canvasRef} style={{width: scaledWidth, height: scaledHeight}} />
		{/* </div> */}

		{(props.runButton !== false || props.arrows) &&
			<div style={{width: scaledWidth, display: 'flex', background: '#bbb'}}>
				{props.runButton !== false &&
					<div>
						<button style={buttonStyle} onClick={handleRun}>Run</button>
					</div>
				}
				{props.arrows &&
					<div style={{flex: 1, textAlign: 'center', margin: 10}}>
						<button style={buttonStyle} onClick={() => handleButtonClick('up')}>up</button><br/>
						<button style={buttonStyle} onClick={() => handleButtonClick('left')}>left</button><button style={buttonStyle} onClick={() => handleButtonClick('right')}>right</button><br/>
						<button style={buttonStyle} onClick={() => handleButtonClick('down')}>down</button>
					</div>
				}
				{props.fullScreen &&
					<button title="Go full screen" className="flatButton" onClick={handleFullScreen}>[ ]</button>
				}
			</div>
		}
	</div>);
}

const processKey = (key: string, code: string) => {
	if (code.startsWith('Key')) {
		return key;
	}
	else if (code.startsWith('Arrow')) {
		return code.slice(5).toLowerCase();
	}
	else if (code == 'Enter' || code == 'Space' || code == 'Delete' || code == 'Backspace' || code == 'Escape') {
		return code.toLowerCase();
	}
	else if (key == 'Shift' || key == 'Alt' || key == 'Control' || key == 'Meta') {
		return undefined;
	}
	else {
		return key;
	}
}

export { WrappedCanvas, WrappedCanvasProps/* , CanvasWrapper, canvasTypedef, CanvasInterface, CanvasEventHandlers */ }
