import 'regenerator-runtime/runtime'
import * as React from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import { useAppContext } from './AppContext';
import { Main } from './Main';
import { ErrorBoundary } from './ErrorBoundary';
// import { view } from 'react-easy-state';
import { useAppAuthState, useDb } from './firebase';
import { useAppContext } from './AppContext';
import { LogIn } from './LogIn';
import { GearSpinner } from './lib/GearSpinner';
// import * as FirebaseNS from 'firebase/index'

// type Firebase = typeof FirebaseNS;
// declare const firebase: Firebase;


// import firebaseui from 'firebaseui';

const App: React.FC = /* view */(() => {
	console.log('render App')
	let [user, loading, error] = useAppAuthState();
	// let context = useAppContext();
	// let db = useDb();
	// useEffect(() => {
	// 	(window as any).db = db;
	// 	(window as any).appContext = context;
	// }, [])

	let content;
	if (loading) {
		// console.log("spinner")
		// content = <Spinner label="Loading..." center delay={300} />
		content = <GearSpinner center />
	}
	else {
		// console.log(user ? 'Main' : 'LogIn')
		// content = user ? <Main /> : <LogIn />;
		content = <Main />;
	}

	return (
		<ErrorBoundary>
			<Router>
				{content}
			</Router>
		</ErrorBoundary>
	);
})

ReactDOM.render(<App />, document.querySelector('#app'));

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/sw.js')
	.then(function() { console.log("Service Worker Registered"); });
}
