import * as React from 'react';
import { CanvasPage } from './CanvasPage';
import { namedColors } from './lib/namedColors';
import { CodeBox } from './CodeBox';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { CodeRunner } from './lib/CodeRunner';

const CanvasDocPage = () => (
	<CanvasPage
		pageContent={
			<div>
				<h2>Drawing on the Canvas</h2>
				<p className="intro">
					This is a <strong>canvas</strong>. It is controlled by <code>canvas</code> in the editor on the right.
				</p>

				<p className="intro">
					{/* Use canvas functions like <code>canvas.rect()</code>, <code>canvas.ellipse()</code>,
					and <code>canvas.line()</code> to draw on it. */}
					<code>canvas</code> has lots of functions that allow you to draw on it.
				</p>

				<p>
					Locations on the canvas are defined by a grid that is 400 pixels by 400 pixels.

					That means any point on the canvas can be described by two numbers.  The first
					0, 0 The grid starts at the top left.
				</p>

				<h2 style={{clear: 'both', paddingTop: 20}}>
					Canvas functions
				</h2>

				<h3 className="hGreen">
					<code>canvas.background()</code>
				</h3>

				<p>
					<code>canvas.background(someColor)</code> draws a background <PrefixedLink to="/colors">color</PrefixedLink>.
					It draws over everything, so it should always be the first thing you draw.
				</p>

				<CanvasRunner autorun code={`canvas.background('skyblue'); `}/>

				<hr className="spacer"/>

				<h3 className="hGreen">
					<code>canvas.fill()</code>
				</h3>

				<p>
					<code>canvas.fill(someColor)</code> sets the color to fill in shapes with.
					You won't see it do anything by itself.
					When you draw shapes after it, those shapes will be the color you set.
				</p>

				<p>
					To learn how choose just the right color, visit the <PrefixedLink to="/colors">colors</PrefixedLink> page.
				</p>

				<CanvasRunner autorun code={`
					canvas.fill('blue');
					canvas.rect(150, 175, 50, 50);
					canvas.fill('red');
					canvas.rect(250, 175, 50, 50);
				`} />

				<hr className="spacer"/>


				<h3 className="hGreen">
					<code>canvas.ellipse()</code>
				</h3>

				<p>
					<code>canvas.ellipse(centerX, centerY, width, height)</code> draws an ellipse or a circle
					<h4>Parameters</h4>
					{/* <ParamList items={{
						centerX: `horizontal position of the center of the circle/ellipse`,
						centerY: `vertical position of the center of the circle/ellipse`,
						width: `width (diameter) of the circle/ellipse`,
						'height (optional)': `height (diameter) of the circle/ellipse.  If left out, the height will be the same as the width, so you'll get a circle.`,
					}} /> */}

					<dl className="paramList">
						<dt>centerX</dt>
						<dd>horizontal position of the center of the circle/ellipse</dd>

						<dt>centerY</dt>
						<dd>vertical position of the center of the circle/ellipse</dd>

						<dt>width</dt>
						<dd>width (diameter) of the circle/ellipse</dd>

						<dt>height (optional)</dt>
						<dd>height (diameter) of the circle/ellipse.  If left out, the height will be the same as the width, so you'll get a circle.</dd>
					</dl>
				</p>

				<CanvasRunner autorun code={`
					// draw an ellipse in the center that is 100 wide and 75 high
					canvas.ellipse(200, 100, 100, 75);

					// draw a circle that is 100 in diameter
					canvas.ellipse(200, 300, 100);
				`}/>

				<hr className="spacer"/>

				<p>and here's a CanvasRunner</p>

				<CanvasRunner code="hello" height={300} />

				<p>and this is just a CodeRunner</p>

				<CodeRunner code="hello" />

				<h2 id="colors" className="hRed">Colors</h2>
				<p>
					There are a few different ways to specify colors. The first is by names, such as 'red' or 'blue'.
					See the <PrefixedLink to="/colors">Colors</PrefixedLink> page for all of the possible names you can use.
				</p>
				<p>
					You can also use hexadecimal (or hex) colors. For more details about hex colors,
					or to pick a color, see the <PrefixedLink to="/colors">Colors</PrefixedLink> page.
				</p>
			</div>
		}
		code={`
			// fill the whole canvas with a color;
			canvas.background('white');

			// set the color for filling in shapes and text
			canvas.fill('blue');

			canvas.text("this is a blue square", 100, 260);

			// draw a rectangle at x=100, y=300, width=80, height=50
			canvas.rect(100, 300, 80, 50);
		`}
		// context={() => {}}
		docName="canvas-docpage"
	/>
);

const ColorPicker = () => {
	let [pickedColor, setPickedColor] = React.useState('#ff0000');
	return <p style={{textAlign: 'center'}}>
		<input type="color" value={pickedColor} onChange={e => setPickedColor(e.target.value)} /> {pickedColor}
	</p>
}

// interface ParamListProps {
// 	items: {[name: string]: React.ReactNode},
// }

// const ParamList: React.FC<ParamListProps> = (props) => {
// 	<dt>centerX</dt>
// 						<dd>horizontal position of the center of the circle/ellipse</dd>

// 						<dt>centerY</dt>
// 						<dd>vertical position of the center of the circle/ellipse</dd>

// 						<dt>width</dt>
// 						<dd>width (diameter) of the circle/ellipse</dd>

// 						<dt>height (optional)</dt>
// 						<dd>height (diameter) of the circle/ellipse.  If left out, the height will be the same as the width, so you'll get a circle.</dd>
// }

export { CanvasDocPage }
