import { processStack } from "./runner";
import { getCurvePoints } from "./getCurvePoints";

interface CanvasInterface {
	wrapper: CanvasWrapper
	handlers: CanvasEventHandlers
	console: Console
	hasRun: boolean
	// keyPressMap: {[key: string]: boolean}
	// reset(): void
}

interface CanvasEventHandlers {
	onKeyPressed?(key: string): boolean | void
	onKeyReleased?(key: string)
	onMouseClicked?(x: number, y: number)
	onMouseMoved?(x: number, y: number)
	onRun?()
}

// // thanks to https://github.com/microsoft/TypeScript/issues/28046
// type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never
// type Font = ElementType<typeof fonts>

// if you add a font to this, add it to
type Font = "Rubik" | "Press Start 2P"

interface CanvasWrapper {
	/** change the line and outline color */
	stroke(color: string): void
	/** disable outlines on shapes */
	noStroke(): void
	/** change line and outline width */
	strokeWeight(px: number): void
	/** disable filling in shapes */
	noFill(): void
	/** change shape fill color */
	fill(color: string): void
	/** overwrite the whole canvas with a background color */
	background(color: string): void
	/** draw an ellipse (circle or stretched circle) */
	ellipse(x: number, y: number, w: number, h?: number): void
	/** draw an rectangle or square */
	rect(x: number, y: number, w: number, h: number): void
	/** draw some text on the screen */
	text(text: string, x: number, y: number): void
	/** set the text size */
	textSize(size: number): void
	/** set how text should be positioned */
	textAlign(align: 'left' | 'right' | 'center', verticalAlign?: CanvasTextBaseline)
	/** draw a straight line from one point to another */
	line(x1: number, y1: number, x2: number, y2: number)
	/** draw any polygon */
	shape(...coordsArgs: number[] | [number[] | Float32Array])
	/** draw a curve through a list of points. closed=true will fill in the shape. closed=false will draw a curved line. */
	curve(coords: number[], closed?: boolean, numSegments?: number, tension?: number): void
	/** draw an arc (circle or ellipse section) */
	arc(x: number, y: number, size: number, startAngle: number, stopAngle: number)
	/** move the canvas origin (start point) by the number of pixels specified  */
	changePosition(x: any, y: any): void
	/** change the zoom level of the canvas */
	scale(xScale: number, yScale?: number): void
	/** rotate the entire canvas around the current position by radians */
	rotate(radians: number): void
	/** rotate the entire canvas around the current position by degrees */
	rotateDeg(degrees: number): void
	/** undo the last changePosition(), scale(), or rotate()  */
	resetPosition(resetAll?: boolean): void
	/** clear the canvas and all drawing settings */
	reset(): void
	/** run this function in a loop at up to 60 frames per second */
	animate(drawFunc: Function): void
	/** run this function in a loop at `framesPerSecond` */
	animate(drawFunc: Function, framesPerSecond: number): void
	/** run this function in a loop at `framesPerSecond` for `frameCount` frames */
	animate(drawFunc: Function, framesPerSecond: number, frameCount: number): void
	/** stop an animation triggered by `canvas.animate()` */
	stop(): void
	loadImage(src: string): HTMLImageElement
	drawImage(image: CanvasImageSource, x: number, y: number, w?: number, h?: number): void
	context: CanvasRenderingContext2D
	getFonts(): Font[]
	setFont(font: Font)
}

// this needs to be kept up to date manually :(
const CanvasTypedef = `
type Font = "Rubik" | "Press Start 2P"

interface Canvas {
	/** change the line and outline color */
	stroke(color: string): void
	/** disable outlines on shapes */
	noStroke(): void
	/** change line and outline width */
	strokeWeight(px: number): void
	/** disable filling in shapes */
	noFill(): void
	/** change shape fill color */
	fill(color: string): void
	/** overwrite the whole canvas with a background color */
	background(color: string): void
	/** draw an ellipse (circle or stretched circle) */
	ellipse(x: number, y: number, w: number, h: number): void
	/** draw an rectangle or square */
	rect(x: number, y: number, w: number, h: number): void
	/** draw some text on the screen */
	text(text: string, x: number, y: number): void
	/** set the text size */
	textSize(size: number): void
	/** set how text should be positioned */
	textAlign(align: 'left' | 'right' | 'center')
	textAlign(align: 'left' | 'right' | 'center', verticalAlign: 'top' | 'middle' | 'bottom' | 'alphabetic')
	/** draw a straight line from one point to another */
	line(x1: number, y1: number, x2: number, y2: number)
	/** draw any polygon */
	shape(...coords: number[])
	/** draw a curve through a list of points. closed=true will fill in the shape. closed=false will draw a curved line. */
	curve(coords: number[], closed?: boolean, numSegments?: number, tension?: number): void
	/** draw an arc (circle or ellipse section) */
	arc(x: number, y: number, size: number, startAngle: number, stopAngle: number)
	/** move the canvas origin (start point) by the number of pixels specified  */
	changePosition(x: any, y: any): void
	/** change the zoom level of the canvas */
	scale(xScale: number, yScale?: number): void
	/** rotate the entire canvas around the current position by radians */
	rotate(radians: number): void
	/** rotate the entire canvas around the current position by degrees */
	rotateDeg(degrees: number): void
	/** undo the last changePosition(), scale(), or rotate()  */
	resetPosition(resetAll?: boolean): void
	/** clear the canvas and all drawing settings */
	reset(): void
	/** run this function in a loop at up to 60 frames per second */
	animate(drawFunc: Function): void
	/** run this function in a loop at \`framesPerSecond\` */
	animate(drawFunc: Function, framesPerSecond: number): void
	/** run this function in a loop at \`framesPerSecond\` for \`frameCount\` frames */
	animate(drawFunc: Function, framesPerSecond: number, frameCount: number): void
	/** stop an animation triggered by \`canvas.animate()\` */
	stop(): void
	/** Load an image from a URL (such as http://https://robotpng.netlify.com/robot1.png) */
	loadImage(src: string): Image
	/** Draws an image on the canvas. Does nothing if the image hasn't loaded yet. */
	drawImage(image: Image, x: number, y: number, w?: number, h?: number): void
	// context: CanvasRenderingContext2D;
	getFonts(): Font[]
	setFont(font: Font)
}

interface Image {
	src: string
	width: number
	height: number
	complete: boolean
	onload(): void
}
`;

// const createCanvasTypedef = CanvasTypedef +`
// /** create a canvas and draw on it */
// declare function createCanvas(x: number, y: number): Canvas;`

const canvasTypedef = CanvasTypedef +`
declare var canvas: Canvas;`

const fonts: Font[] = ['Rubik', 'Press Start 2P'];

function scaleCanvas(canvas: HTMLCanvasElement, width, height) {
	// Get the device pixel ratio, falling back to 1.
	var dpr = window.devicePixelRatio || 1;
	// Get the size of the canvas in CSS pixels.
	// var rect = canvas.getBoundingClientRect();
	// Give the canvas pixel dimensions of their CSS
	// size * the device pixel ratio.
	canvas.width = /* rect. */width * dpr;
	canvas.height = /* rect. */height * dpr;
	var ctx = canvas.getContext('2d');
	// Scale all drawing operations by the dpr, so you
	// don't have to worry about the difference.
	ctx.scale(dpr, dpr);
}

function createCanvasInterface(context: CanvasRenderingContext2D, canvas: HTMLCanvasElement/* , fakeConsole: Console */): CanvasInterface {
	let cInterface: CanvasInterface = {
		handlers: {},
		wrapper: null,
		console: null,
		hasRun: false,
	}

	cInterface.wrapper = createContextWrapper(cInterface, context, canvas);

	return cInterface;

	// return {
	// 	handlers: {},
	// 	wrapper,
	// 	console: fakeConsole,
	// }
}

function createContextWrapper(cInterface: CanvasInterface, context: CanvasRenderingContext2D, canvas: HTMLCanvasElement/* , fakeConsole: Console */): CanvasWrapper {
	let noStroke = false;
	let noFill = false;
	let font: Font = 'Rubik';
	let fontSize = 24;

	context.lineCap = 'round';
	let stackSize = 0;
	// let translated = false;
	let stopped = false;
	let animCounter = 0;

	function setFont() {
		context.font = `${fontSize}px "${font}"`;
	}

	const canv: CanvasWrapper = {
		context: context,
		stroke(color: string) {
			context.strokeStyle = color;
			noStroke = false;
		},
		noStroke() {
			noStroke = true;
		},
		strokeWeight(px: number) {
			context.lineWidth = px;
		},
		fill(color: string) {
			context.fillStyle = color;
			noFill = false;
		},
		noFill() {
			// context.fillStyle = 'none';
			noFill = true;
		},
		background(color: string) {
			context.save();
			context.fillStyle = color;
			context.fillRect(0, 0, canvas.width, canvas.height);
			context.restore();
		},
		ellipse(x: number, y: number, w: number, h: number = w) {
			context.beginPath();
			context.ellipse(x, y, w / 2, h / 2, 0, Math.PI * 2, 0);
			!noFill && context.fill();
			!noStroke && context.stroke();
		},
		rect(x: number, y: number, w: number, h: number) {
			!noFill && context.fillRect(x, y, w, h);
			!noStroke && context.strokeRect(x, y, w, h);
		},
		text(text: string, x: number, y: number) {
			context.fillText(text, x, y);
		},
		textSize(size: number) {
			fontSize = size;
			setFont()
		},
		// CanvasTextBaseline = "top" | "hanging" | "middle" | "alphabetic" | "ideographic" | "bottom";
		textAlign(align: 'left' | 'right' | 'center', verticalAlign?: CanvasTextBaseline) {
			if (align) {
				context.textAlign = align;
			}
			if (verticalAlign) {
				context.textBaseline = verticalAlign;
			}

		},
		line(...coordsArgs: number[] | [number[] | Float32Array]) {
			if (coordsArgs.length == 1 && (Array.isArray(coordsArgs[0]) || (coordsArgs[0] instanceof Float32Array))) {
				coordsArgs = coordsArgs[0] as number[];
			}
			let points = coordsArgs as number[];

			if (points.length < 4) {
				cInterface.console.error('canvas.line requires 4 or more coordinates');
				return;
			}

			context.beginPath();
			context.moveTo(points[0], points[1]);
			for (let i = 2; i < points.length; i += 2) {
				context.lineTo(points[i], points[i + 1]);
			}
			context.stroke();
		},
		shape(...coordsArgs: number[] | [number[] | Float32Array]) {
			if (coordsArgs.length == 1 && (Array.isArray(coordsArgs[0]) || (coordsArgs[0] instanceof Float32Array))) {
				coordsArgs = coordsArgs[0] as number[];
			}
			let coords = coordsArgs as number[];

			if (coords.length < 6 || coords.length % 2) {
				cInterface.console.error('canvas.shape requires an even number of numbers (at least 6)');
			}

			context.beginPath();
			context.moveTo(coords[0], coords[1]);

			for (let i = 0; i < coords.length; i += 2) {
				context.lineTo(coords[i], coords[i + 1]);
			}

			!noFill && context.fill();
			!noStroke && context.stroke();
		},
		arc(x: number, y: number, size: number, startAngle: number, endAngle: number) {
			if (!noFill) {
				context.beginPath();
				context.moveTo(x, y);
				// context.ellipse(x, y, w / 2, h / 2, 0, -compassToRad(startAngle), -compassToRad(endAngle));
				context.arc(x, y, size / 2, compassToRad(startAngle), compassToRad(endAngle));
				context.moveTo(x, y);
				context.fill();
				context.closePath()
			}
			if (!noStroke) {
				context.beginPath();
				// context.ellipse(x, y, w / 2, h / 2, 0, -compassToRad(startAngle), -compassToRad(endAngle));
				context.arc(x, y, size / 2, compassToRad(startAngle), compassToRad(endAngle));
				context.stroke();
				context.closePath()
			}
		},
		changePosition(x, y) {
			// this.resetPosition();
			stackSize++;
			context.save();
			context.translate(x, y);
			// translated = true;

		},
		scale(xScale: number, yScale = xScale) {
			stackSize++;
			context.save();
			context.scale(xScale, yScale);
		},
		rotate(radians: number) {
			stackSize++;
			context.save();
			context.rotate(radians);
		},
		rotateDeg(degrees: number) {
			this.rotate(degrees / 180 * Math.PI);
		},
		resetPosition(resetAll = false) {
			do {
				if (stackSize) {
				// if (translated) {
					stackSize--;
					// translated = false;
					context.restore();
					// return true;
				}
			} while (resetAll && stackSize > 0);
		},
		reset() {
			this.stop();
			this.resetPosition(true);
			this.background('white');
			this.stroke('black');
			this.strokeWeight(1);
			this.noStroke();
			this.fill('black');
			this.setFont('Rubik');
			this.textSize(24)
			this.textAlign('left');
		},
		animate(drawFunc: (frame: number) => void, framesPerSecond?: number, frameCount?: number) {
			stopped = false;
			animCounter++;
			// if (stopped) return;

			let frame = 0;
			let animId = animCounter;
			// if (!frameCount) frameCount = -1;

			let interval = 0;
			if (framesPerSecond) {
				interval = 1000 / framesPerSecond;
			}

			let frameFn = () => {
				if (stopped || animId < animCounter) return;
				if (frameCount > 0 && frame >= frameCount) return;

				try {
					drawFunc(frame);
				}
				catch(e) {
					printCanvasError(e, cInterface.console, canv);

					stopped = true;
					return;
				}

				frame++;

				if (!framesPerSecond) {
					requestAnimationFrame(frameFn);
				}
				else {
					setTimeout(frameFn, interval);
				}
			};
			frameFn();
		},
		stop() {
			// stop or return false if already stopped
			return !stopped && (stopped = true);
		},
		// this really should be outside of canvasWrapper.
		loadImage(src: string) {
			var image = new Image();
			image.src = src;

			return image;
		},
		drawImage(image, ...points: number[]) {
			if (!(image instanceof Image)) {
				printCanvasError('must be an image', cInterface.console, canv);
			}
			else if (points.length != 2 && points.length != 4) {
				throw new Error('x and y are required, w and h are optional.');
			}
			else {
				context.drawImage(image, ...points as [number, number]);
				// if (image.complete)
			}
		},
		getFonts() {
			return fonts.slice();
		},
		setFont(newFont) {
			if (fonts.indexOf(newFont) > -1) {
				font = newFont;
			}
			else {
				font = 'Rubik'
			}

			setFont();
		},
		curve(coords: number[], closed = true, numSegments = 20, tension = .5) {
			if (coords.length < 6 || coords.length % 2) {
				cInterface.console.error('canvas.curve requires an even number of numbers (at least 6)');
			}

			var points = getCurvePoints(coords, tension, numSegments, closed);

			if (closed) {
				this.shape(points);
			}
			else {
				this.line(points);
			}
		}
	}

	return canv;
}


// need a good name for this.  abstract away the details of sine/cosine, radians.
// use -Math.sin so that a positive angle can point upwards, even though y decreases going up.
function vect(angle, distance) {
    var a = compassToRad(angle);
	return [Math.cos(a) * distance, -Math.sin(a) * distance];
}

function radToCompass(r) {
	return 90 - (r * 180 / Math.PI)
}

function compassToRad(deg) {
	// we want 0 to point up, not down, so we actually return 180 - (real compass angle)
	return (deg - 90) * Math.PI / 180
	// return (90 + deg) * Math.PI / 180
}

// function execute<T extends (...args: any[]) => any>(fn: T, console: Console/* , source: string */, ...args: Parameters<T>): ReturnType<T> {
// 	try {
// 		return fn(...args);
// 	}
// 	catch(e) {
// 		window.console.error(e);
// 		try {
// 			let stack = processStack(e);
// 			console.error(stack)
// 		}
// 		catch (e) {
// 			window.console.error('failed to process stack', e);
// 		}
// 	}
// }


function printCanvasError(e: any, fakeConsole: Console, canvas: CanvasWrapper) {
	console.error(e);
	// if (cInterface.console) {
	try {
		let stack = processStack(e);

		if (fakeConsole) {
			fakeConsole.error(stack);
		}

		canvas.background('rgba(255, 255, 255, .7)');
		canvas.fill('red');
		canvas.noStroke()
		canvas.textSize(20);
		canvas.textAlign('left');
		let lines = stack.split('\n');
		for (let i = 0; i < lines.length; i++) {
			canvas.text(lines[i], 5, 40 + i * 30);
		}
	}
	catch (e) {
		console.error('failed to process stack', e);
	}
	// }
}

export { CanvasWrapper, CanvasInterface, CanvasEventHandlers, createCanvasInterface, scaleCanvas, canvasTypedef }
