import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer, FreeAnswer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { CanvasRunner } from './lib/CanvasRunner';

const FreeProjectLesson = () => {
	return (
		<Layout narrow>
			<h1>Project Day</h1>
			<p class="intro">
				Today you will work on a project of your choice!  You can use the
				skills you have learned so far in class to create anything you want.
				{/* But with great power comes great responsibility. */}
				{/* You will need  */}
			</p>

			<p>
				The first thing to do is to decide what you want to work on.  If you have
				something in mind already, you can work on that.  If  not, here are some possibilities:
			</p>

			{/* <p>
				<strong>NO GAMES!</strong> You are here to learn.
				Please use the class time to practice and learn.
				If you are going to be in class, you will need to participate with the class.
				If you do not want to participate, you will be asked to leave.
			</p>
			<p>
				Choose a project idea you would like to work on. If you need help coming up with
				an idea, we will talk about some ideas in class.
			</p> */}

			<ul>
				<li>A drawing</li>
				<li>An interactive drawing</li>
				<li>A slideshow</li>
				<li>A simple game</li>
				<li>An illustrated story</li>
			</ul>

			<p>
				Programming can a very creative art.  For example, If you decide to make a drawing,
				you can decide what to draw, and how you want to draw it.  There's a lot of freedom here!
				But that also means it's up to you to decide what you want to do.
			</p>

			{/* <CanvasRunner console vertical canvasHeight={250} editorHeight={400} docName="freeProject.starter" code={`
				// what do you want your project to be?
			`} /> */}

			<h2 className="hGreen">Choose a project</h2>
			<p>
				If you already have something in mind that you would like to create, great! Tell your neighbor about your idea.
				Ask them for ideas on what they might want to do with a similar project.
			</p>

			<p>
				If you are not sure what you would like to do yet,
				talk with your neighbor and see if you can come up with an idea together.
				We will also talk about our ideas as a class.
			</p>

			<h2 className="hGreen">Make a plan</h2>
			<p>
				Now you have some planning to do.  If you chose a drawing,
				you'll need to think about what you want your drawing to look like.
				Maybe you'll even want to sketch it out on paper or a whiteboard first.
			</p>

			<p>
				You may want to ask yourself what functions you will need to use to draw the shapes you need.
				Also decide if there's anything you are not sure how to draw, that you will need to ask for help with.
			</p>

			<p>
				If you are doing a game or an animation, there are more questions you will also need ask yourself:
			</p>

			<p>
				How will the screen be updated?
				Do I need to respond to key presses? Which ones?
				Do I need to respond to mouse clicks? What should they do?
			</p>

			<p>
				Whatever your program is, are there shapes you are going to need to draw over and over?
				Or commands you will need to repeat over and over?  How can you use functions to help you
				organize those repeated things?
			</p>

			<p>
				Finally, ask yourself what other kinds of problems you might run into.
			</p>

			<h3>Describe your project</h3>
			<p>In the box below, answer the following questions:</p>
			<ol>
				<li>What kind of a project are you going to make? (Is it a drawing? a game? a slideshow?)</li>
				<li>What will the title of your project be?</li>
				<li>Describe the subject of your project - what you are going to draw, how your game works, etc.</li>
			</ol>

			<FreeAnswer id="freeProject.plan" textAreaProps={{rows: 7, defaultValue: ''}} />


			<h2 className="hGreen">Start your project</h2>
			<p>
				Next, you'll need to create an empty project.
				This will give you a place to write and run the code for your project.
			</p>

			<p>
				In a separate tab, open <PrefixedLink to="/user/projects">your projects page</PrefixedLink>,
				and click the New Project Button.
				Give your project a title.
			</p>

			<p>
				Once your project is created, it's time to get to work!
				Use your plan to help you decide how to get started.
			</p>

			<p>
				You can also use the {/* <a href="#example-projects"> */}example projects{/* </a> */} below to help you solve problems you may run into.
			</p>

			<a id="example-projects"></a>
			<h2 className="hBlue">Useful Example Projects</h2>

			<h3><PrefixedLink to="/published/h3mckpx2">Graph Drawing</PrefixedLink></h3>
			<p>
				This project can help you draw shapes using canvas.shape().
				Every shape you draw will print the code used to draw it in the console.
			</p>

			<h3><PrefixedLink to="/published/9dsslxn9">Array Rainbow</PrefixedLink></h3>
			<p>
				This project is a good example of how to use an array and a for loop to draw multiple things –
				In this case, the different rings of a rainbow.
			</p>

			<h3><PrefixedLink to="/published/2emil4jl">Simple Flipbook</PrefixedLink></h3>
			<p>
				This project demonstrates using a variable to decide which page of a slideshow to show,
				and changes that variable using arrows. In other words, you can use left and right to flip the pages.
				Also illustrates using functions to reuse code and draw shapes and canvas.changePosition().
			</p>

			<h3><PrefixedLink to="/published/5zzj42ch">Story Book</PrefixedLink></h3>
			<p>
				This is like the flipbook, but more advanced, and interactive.
				You can move the person around on the screen.
			</p>

			{/* <h3>Games</h3>	 */}
			<h3><PrefixedLink to="/published/anbyx69w">Tic Tac Toe</PrefixedLink></h3>
			<p>
				This is a basic tic-tac-toe game that doesn't determine winners or losers.
				Demonstrates nested arrays (for the grid), if/else logic,
				and number comparisons to decide which space was clicked.
			</p>

			<h3><PrefixedLink to="/published/ovvr39th">Guessing Game</PrefixedLink></h3>
			<p>
				This game works like hangman, with less death. Showcases a lot of different concepts.
				Uses if/else, for loops, arrays, canvas.shape(), and alert() and prompt().
			</p>

			<div className="blOrange">
				<h2 className="hOrange">Sharing Your Project</h2>

				<div className="container">
					<p>
						On the project page, if you want, you can share the project. That will allow other students, friends, and family
						to see what you are making using the sharing link.
					</p>
				</div>
			</div>

			<br/><br/>

			<p style={{textAlign: 'center'}}>
				<PrefixedLink to="/"><Button intent="primary" size="lg">Home</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

export { FreeProjectLesson }
