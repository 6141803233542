import * as React from 'react';
import { FlexLayout } from './Layout';
import { SplitPane } from './components/SplitPane';
import { CodeRunner, CodeRunnerProps } from './lib/CodeRunner';

type MonacoFrameProps = CodeRunnerProps & {
	initialCode: string;
}

interface ProjectPageProps {
	// or use React.ComponentType<any> if we want to pass component
	// without rendering it (pageContent={SomePageContent} vs pageContent={<SomePageContent/>})
	pageContent: JSX.Element
	monacoProps?: MonacoFrameProps
	codeRunnerProps?: CodeRunnerProps
	useCodeRunner?: boolean
	className?: string
}

const ProjectPage: React.FC<ProjectPageProps> = (props) => {
	let runnerProps: CodeRunnerProps;
	if (props.codeRunnerProps) {
		runnerProps = props.codeRunnerProps;
	}
	else if (props.monacoProps) {
		runnerProps = props.monacoProps;
		runnerProps.code = props.monacoProps.initialCode;
	}

	return (
		<FlexLayout className={props.className}>
			<SplitPane
				leftContent={/* <div className="container"> */
					props.pageContent
				/* </div> */}
				rightContent={<CodeRunner height="100%" {...runnerProps} />}
			/>
		</FlexLayout>
	)
}

export { ProjectPage, ProjectPageProps }
