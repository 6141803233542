import { CanvasDemoFn } from "./lib/CanvasDemo";

const spotlights: CanvasDemoFn = canvas => {
	canvas.animate(function (f) {
		var offset = (1 + Math.sin(f / 33)) * 50;
		canvas.context.globalCompositeOperation = 'source-over';
		canvas.background('black');
		canvas.context.globalCompositeOperation = 'screen';
		var xOffset = Math.sqrt(3) * offset / 2;

		canvas.fill('#f00');
		canvas.ellipse(200 - xOffset, 200 - offset / 2, 200);
		canvas.fill('#0f0');
		canvas.ellipse(200 + xOffset, 200 - offset / 2, 200);
		canvas.fill('#00f');
		canvas.ellipse(200, 200 + offset, 200);
	})
}

const spotlights2: CanvasDemoFn = canvas => {
	canvas.animate(function (f) {
		var offset = (1 + Math.sin(f / 33)) * 50;
		canvas.context.globalCompositeOperation = 'source-over';
		canvas.background('black');
		canvas.context.globalCompositeOperation = 'screen';
		var xOffset = Math.sqrt(3) * offset / 2;

		canvas.fill('#f00');
		canvas.ellipse(200 - xOffset, 200 - offset / 2, 200);
		canvas.fill('#0a0');
		canvas.ellipse(200 + xOffset, 200 - offset / 2, 200);
		canvas.fill('#008');
		canvas.ellipse(200, 200 + offset, 200);
	})
}

const randomFill: CanvasDemoFn = canvas => {
	var startH = Math.floor(Math.random() * 360);
	for (var r = 0; r < 16; r++) {
		// var h = Math.floor(Math.random() * 360);
		var h = startH + r * 22.5;
		for (var c = 0; c < 16; c++) {
			// var s = 100;
			var s = 50 + Math.floor(Math.random() * 51);
			// var l = 15 + c * 4.5;
			var l = 20 + Math.floor(Math.random() * 60);
			canvas.fill('hsl(' + h + ', ' + s + '%, ' + l + '%)');
			canvas.rect(c * 25, r * 25, 25, 25);
		}
	}
}

const colorPicker: CanvasDemoFn = canvas => {
	var color = {
		r: 174, //Math.floor(Math.random() * 256),
		g: 40, //Math.floor(Math.random() * 256),
		b: 255, // Math.floor(Math.random() * 256),
	};

	draw();

	function draw() {
		canvas.background('white');
		canvas.textAlign('left', 'top');
		canvas.textSize(20);
		drawBar('red', color.r, 20);
		drawBar('green', color.g, 60, '#0f0');
		drawBar('blue', color.b, 100);

		canvas.fill('black');
		var hexColor = toHexString(color.r, color.g, color.b);

		canvas.text('click the bars to change the color', 10, 160);
		canvas.textAlign('right');
		canvas.text('your color →', 180, 220);
		canvas.text('hex code:', 180, 300);
		canvas.text(hexColor, 180, 330);
		canvas.fill(hexColor);
		canvas.rect(200, 200, 180, 180);
	}

	function drawBar(label: string, value: number, y: number, barColor?: string) {
		canvas.fill('black');
		canvas.text(label + ': ' + value, 10, y + 10);

		canvas.fill('#888');
		canvas.rect(125, y, 255, 35);
		canvas.fill(barColor || label);
		var barWidth = value; // / 255 * 200;
		canvas.rect(125, y, barWidth, 35);
	}

	function toHexString(r, g, b) {
		var rString = Math.round(r).toString(16);
		if (rString.length == 1) rString = '0' + rString;
		var gString = Math.round(g).toString(16);
		if (gString.length == 1) gString = '0' + gString;
		var bString = Math.round(b).toString(16);
		if (bString.length == 1) bString = '0' + bString;
		return '#' + rString + gString + bString;
	}

	function whenMouseClicked(x, y) {
		var c;
		if (x >= 115 && x < 390 && y >= 20 && y < 135) {
			if (y < 55) {
				c = 'r';
			}
			else if (y < 95) {
				c = 'g'
			}
			else {
				c = 'b'
			}
			var val = x - 125; // / 255 * 255;
			val = Math.min(Math.max(val, 0), 255);

			color[c] = Math.round(val);
		}

		draw();

	}

	return { whenMouseClicked }
}

const howToReadHex: CanvasDemoFn = canvas => {
	canvas.changePosition(-30, 40)
	canvas.scale(1.1)
	canvas.fill('black');
	canvas.text('How to read a hex number:', 40, 20);
	canvas.text('red', 105, 90);
	canvas.text('green', 200, 90);
	canvas.text('blue', 305, 90);

	// canvas.line(140, 190, 100, 150);
	// canvas.line(140, 260, 100, 350);
	canvas.textSize(16);
	canvas.fill('#800')
	var yoff = 70;
	canvas.text('a = 10, e = 14', 90, 130 + yoff)
	canvas.text('10 * 16 + 14', 100, 155 + yoff)
	canvas.text('=', 30 + 120, 180 + yoff)
	canvas.textSize(20);
	canvas.text('174', 20 + 120, 205 + yoff)
	// canvas.text('14', 150, 205 + yoff)

	canvas.textSize(16);
	canvas.fill('#080')
	canvas.text('2 * 16 + 8', 200, 155 + yoff)
	canvas.text('=', 30 + 200, 180 + yoff)
	canvas.textSize(20);
	canvas.text('40', 20 + 200, 205 + yoff)
	canvas.textSize(20);
	// canvas.text('8', 237, 205 + yoff)

	canvas.textSize(16);
	canvas.fill('#008')
	canvas.text('f = 15', 308, 130 + yoff)
	canvas.text('15 * 16 + 15', 295, 155 + yoff)
	canvas.text('=', 30 + 283, 180 + yoff)
	canvas.textSize(20);
	canvas.text('255', 20 + 283, 205 + yoff)
	// canvas.text('15', 333, 150 + yoff)

	canvas.scale(1.1)
	canvas.changePosition(-10, 0)
	canvas.textSize(72);
	canvas.fill('black');
	var top = 150;
	canvas.text('#', 53, top);
	canvas.fill('#f00');
	canvas.text('ae', 100, top);
	canvas.fill('#0f0');
	canvas.text('28', 187, top);
	canvas.fill('#00f');
	canvas.text('ff', 283, top);
}

export { spotlights, spotlights2, randomFill, colorPicker, howToReadHex }
