import * as React from 'react'
import { outdent } from '../CodeBox';
import { useUser, useIsAdmin } from '../firebase';

export const Note: React.FC<React.HTMLProps<HTMLParagraphElement>> = (props) => {
	let {children, ...otherProps} = props;
	if (typeof children == 'string') children = outdent(children);

	let isAdmin =useIsAdmin();

	let styles = isAdmin ? {} : {display: 'none'};

	return <p className="instructor-note" style={styles} {...otherProps} children={children} />
};
