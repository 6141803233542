import * as React from 'react';
import { CanvasRunner } from './lib/CanvasRunner';
import { Layout } from './Layout';
import { CodeBox } from './CodeBox';
import { PagedContent } from './lib/PagedContent';

const EventsLesson = () => {
	// let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	// React.useEffect(() => {
	// 	if (canvasInterface) {
	// 		setupDemo(canvasInterface);

	// 	}
	// }, [canvasInterface]);
	// const onInit = (canvasInterface) => setupDemo(canvasInterface);
	// const onInit = setupDemo

	return (
		<Layout narrow>
			<h1>Events</h1>

			<PagedContent pages={[
				<>
					<p className="intro">
						Let's learn about how to make things interactive!
						To do it, we'll need to learn about events.
					</p>

					<p>
						In programming, inputs like mouse clicks and key presses are types of events.
						An event is something that happens inside the program that your code can respond to,
						like a click, key press, or even a file loading, or a window being resized.
					</p>

					<p>
						An event handler is a function that gets called when a certain type of event happens.
					</p>

					<p>
						In our canvas, events happend when you click on the canvas with the mouse, and when you press any key.
					</p>

					<h3 className="hBlue">whenMouseClicked</h3>

					<p>
						In our canvas, you can create event handlers by defining functions with special names.
						If you define a function called <code>whenMouseClicked</code>, that function will be called
						when you click the mouse on the canvas.  It will be passed two parameters:
						the <code>x</code> position of the click,
						and the <code>y</code> position of the click.
						Let's see a simple example (Don't forget to click Run first):
					</p>

					<CanvasRunner key={1} console vertical editorHeight={300} /* canvasHeight={200} */ code={`
						canvas.textAlign('center');
						canvas.text('Click me!', 200, 210);

						function whenMouseClicked(x, y) {
							console.log('mouse clicked', x, y);
						}
					`} />

					<p>
						This example just prints out where you clicked.
						But inside that function, you can do anything you want -
						including draw new things on the canvas.
						Which means we can do something like this:
					</p>

					<CanvasRunner key={2} console vertical editorHeight={350} docName="events.mouseClick" code={`
						canvas.textAlign('center');
						canvas.text('Click me!', 200, 210);

						function whenMouseClicked(x, y) {
							console.log('mouse clicked', x, y);
							canvas.fill('red');
							canvas.ellipse(x, y, 20);
						}
					`} />

					{/* <p>Now we're drawing a circle where you clicked.  </p> */}

					<h3 className="hGreen">Your Turn</h3>

					<p>
						Instead of drawing a circle where you clicked,
						can you make it draw a line from the center (200, 200)
						to where you clicked instead?
					</p>

					<p>
						For a bonus challenge, can you make each line go from the last point clicked to the current point?
						This will take some problem solving.
						If you can't figure it out now, try coming back after you finish this lesson.
					</p>


				</>,
				<>
					<h3 className="hBlue">whenKeyPressed</h3>
					<p>
						There's also an event when you press a key.
						You can define the <code>whenKeyPressed</code> function to handle it.
					</p>

					<CanvasRunner key={3} console vertical editorHeight={350} /* canvasHeight={200} */ code={`
						canvas.textAlign('center');
						canvas.text('Press any key!', 200, 210);

						function whenKeyPressed(key) {
							console.log('key pressed', key);
						}
					`} />

					<p>
						You might be thinking, "Great, so I can print out what key the pressed.  What else can I do?""
					</p>

					<p>
						Let's see some examples.  First, a simple one:
					</p>

					<CanvasRunner key={4} console vertical editorHeight={450} /* canvasHeight={200} */ code={`
						canvas.textAlign('center');
						canvas.text('Press any key!', 200, 210);

						var x = 20;

						canvas.ellipse(x, 300, 20, 20);

						function whenKeyPressed(key) {
							x += 25;

							canvas.ellipse(x, 300, 20, 20);
						}
					`} />

					<p>Read through the code carefully. Can you understand what's happening?</p>

					<p>
						Each time a key is pressed, the whenKeyPressed function is called.
						25 is added to x (which starts at 20),
						and then a new circle is drawn at position x.

						Each circle is added to the canvas, creating a dotted line.
					</p>

					<p>
						Cool, but we can do better... read on!
					</p>
				</>,
				<>
					<p>
						This time we'll handle specific keys -- the arrow keys.
						Remember your if statements? They will come in handy here.
					</p>
					<CanvasRunner key={5} console vertical editorHeight={600} docName="events.arrowKeys" /* canvasHeight={200} */ code={`
						canvas.textAlign('center');
						canvas.text('Press an arrow key!', 200, 100);

						var x = 200, y = 200;

						canvas.ellipse(x, y, 20, 20);

						function whenKeyPressed(key) {
							if (key == 'left') {
								x -= 25;
							}
							else if (key == 'right') {
								x += 25;
							}
							// Can you make 'up' and 'down' work too?

							console.log(x, y);
							canvas.ellipse(x, y, 20, 20);
						}
					`} />

					<p>
						This example is just like the last one, except we are moving
						in the direction of the arrow key that is pressed.
						We move, then draw the new circle.
					</p>

					<h3 className="hGreen">Your Turn</h3>

					<p>
						The example above handles the left and right keys.
						Can you make it handle up and down as well?
						What do you need to do differently for up and down vs. left and right?
					</p>
				</>,
				<>
					<p>
						So far, everything we have drawn is added to what's already on the canvas.
						But what if you want to move something, and not leave a trail behind?
					</p>

					<p>
						There is no undo, or undraw, on a canvas. You can't erase directly.
						Instead, you draw the whole thing over again.  Let's update the last example
						to see what that looks like.
					</p>
				{/* </>,
				<> */}
					<CanvasRunner key={6} console vertical editorHeight={600} docName="events.moving-circle" code={`
						var x = 200, y = 200;

						drawScene();

						canvas.textAlign('center');
						canvas.text('Press an arrow key!', 200, 100);

						function whenKeyPressed(key) {
							if (key == 'left') {
								x -= 25;
							}
							else if (key == 'right') {
								x += 25;
							}
							else if (key == 'up') {
								// remember that y=0 is at the top of the canvas.
								y -= 25;
							}
							else if (key == 'down') {
								y += 25;
							}

							drawScene();
						}

						function drawScene() {
							canvas.background('white');
							canvas.ellipse(x, y, 20, 20);
						}
					`} />

					<p>
						Here, we put all of our drawing code into a <code>drawScene</code> function.
						Then, each time we make a change to how the canvas should be drawn,
						we just call that function over again.
					</p>

					<p>
						Our example is very simple, but this will work just as well
						if you have a complicated background, and complicated players or other elements to draw.
					</p>

					<p>
						Also notice that once you move the circle, the "Press an arrow key!" text disappears. Can you see why?
					</p>

					<p>
						It's because that text is not drawn in the <code>drawScene</code> function.
						It shows up at the beginning because we call <code>drawScene</code>,
						then draw the text right after.
					</p>

					<h3 className="hGreen">Your turn!</h3>

					<p>Can you change the program so that "Press an arrow key!" keeps displaying after you move?</p>

					<p>Can you add some other shapes background to the background?</p>

					<p>
						Can you display "you pressed the left key" on the canvas
						when the left key is pressed?
						How about for every key?
					</p>
				</>,
				/* () =>  */<>
					<h2>A very handy trick: <code>canvas.changePosition</code></h2>

					<p>
						Let's learn one more thing that will make drawing a lot easier.
						Here's a nice stick figure:
					</p>

					<div>
					<CanvasRunner key={7} console vertical editorHeight={600} code={`
						canvas.stroke('red');
						canvas.strokeWeight(4);
						canvas.line(200, 230, 200, 270);
						canvas.line(200-20, 240, 200, 230);
						canvas.line(200+20, 240, 200, 230);
						canvas.line(200-20, 300, 200, 270);
						canvas.line(200+20, 300, 200, 270);

						canvas.fill('red');
						canvas.ellipse(200, 210, 30, 30);

					`} />
					</div>

					<p>
						Let's say I want to draw more of those, in different places.
						Or maybe just one, but I want to move it around the screen.
					</p>

					<p>
						Either way, I'd want to create a function to do it.  Let's see what that might look like:
					</p>

					<CanvasRunner key={8} console vertical editorHeight={600} code={`
						drawPerson(100, 300, 'red');
						drawPerson(300, 300, 'blue');

						function drawPerson(x, y, color) {
							canvas.stroke(color);
							canvas.strokeWeight(4);
							canvas.line(x, y - 70, x, y - 30);
							canvas.line(x - 20, y - 60, x, y - 70);
							canvas.line(x + 20, y - 60, x, y - 70);
							canvas.line(x - 20, y, x, y - 30);
							canvas.line(x + 20, y, x, y - 30);

							canvas.fill(color);
							canvas.ellipse(x, y - 90, 30, 30);
						}
					`} />

					<p>
						See what I did?  The position of each line and shape
						gets x and y added to it, so I can draw it anywhere
						on the canvas.  I can also say what color it should be.
					</p>

					<p>
						But it can be a pain to have to have every position be x + something and y + something.
						There's a handy function called <code>canvas.changePosition(x, y)</code>.
						It moves the canvas coordinates.  So something like this:
					</p>

					<CodeBox code={`
						canvas.changePosition(100, 200);
						canvas.rect(0, 0, 50, 50);
						canvas.rect(100, 0, 50, 50);
						canvas.resetPosition();
					`} />

					<p>
						Would actually draw the rectangles at <code>100, 200</code> and <code>300, 200</code>.
						That makes it much easier to draw the same thing in different places, especially if
						it is complicated.
					</p>

					<p>
						Here's our <code>drawPerson</code> function, updated
						to use <code>canvas.changePosition()</code> instead of
						calculating the position for each individual drawing command.
					</p>

					<CanvasRunner key={9} console vertical editorHeight={600} code={`
						drawPerson(100, 300, 'red');
						drawPerson(300, 300, 'blue');

						function drawPerson(x, y, color) {
							// this moves the canvas coordinates, so
							// everything is now drawn relative to x and y
							canvas.changePosition(x, y);

							canvas.stroke(color);
							canvas.strokeWeight(4);
							canvas.line(0, -70, 0, -30);
							canvas.line(-20, -60, 0, -70);
							canvas.line(20, -60, 0, -70);
							canvas.line(-20, 0, 0, -30);
							canvas.line(20, 0, 0, -30);

							canvas.fill(color);
							canvas.ellipse(0, -90, 30, 30);

							// this restores the canvas coordinates to 0, 0
							canvas.resetPosition();
						}
					`} />

					<p>
						To sum it up, <code>canvas.changePosition</code> makes it possible to draw any
						shape anywhere on the canvas, without having to change the coordinates used to draw that shape!
					</p>

					<p>
						This will come in handy for making games and animations, when you're moving things around the canvas.
					</p>

				</>,
				/* () =>  */<>
					<h3 className="hGreen">Your Turn!</h3>

					<p>
						Now, it's your turn.
						You are going to create your own function that
						uses <code>canvas.changePosition</code> to draw something in multiple places.
					</p>

					{/*
						This div is a hacky workaround.
						Without it, react's reconciler will try to reuse the old frame from the same position
						on the previous page of content, and bad stuff will happen.
					*/}
					{/* <div> */}
					<CanvasRunner key={10} console vertical
						canvasHeight={300}
						editorHeight={400}
						docName="events.changePosition"
						code="// write your canvas code here"
					/>
					{/* </div> */}


					<p>
						First, draw something anywhere on the canvas.  It can be a tree, a house, a bicycle, or whatever.
						Don't spend too long on it, but use at least three or four
						{} <code>canvas.rect</code>, <code>canvas.ellipse</code>, or <code>canvas.line</code> calls.
					</p>

					<p>
						Next, create a function, named after what you drew.
						If you drew a tree, call it <code>drawTree</code>.
						If you drew a bicycle, call it <code>drawBicycle</code>.
						You get the point.
					</p>

					<p>
						Next, move all of your drawing code into that function, and call the function.
						It should work exactly the same as it did before.
					</p>

					<p>
						Now, to make it mobile, add <code>x</code> and <code>y</code> parameters to the function.
						Go back to the <code>drawPerson</code> example if you need help.
					</p>

					<p>
						Then, add <code>canvas.changePosition(x, y);</code> to the beginning of the function,
						and <code>canvas.resetPosition();</code> to the end of the function.
					</p>

					<p>
						Then, call your function twice: Once with <code>0, 0</code> as parameters,
						and once with a different set of numbers as parameters.
					</p>

					<p>Run it again, and you should see two of your shape, in different places!</p>

					<h3 className="hYellow">Did you get it?</h3>

					<p>
						If it didn't work, try these tips:
						</p>

					<p>
						<ul>
							<li>Compare your code to the stick figure code on the last page. What differences do you see?</li>
							<li>Is there an error message? If so, what is it telling you? Where does it say the problem is?</li>
							<li>Ask a neighbor for help</li>
							<li>Ask the teacher for help</li>
						</ul>
					</p>

					<h3 className="hGreen">Nailed It.</h3>

					<p>
						Congratulations! You are well on your way.  You'll be writing your own games in no time.
					</p>

					<p>
						For a bonus challenge, try using then <code>whenMouseClicked</code> function to draw a new shape
						wherever you click the mouse!
					</p>
				</>,
			]} />
		</Layout>
	);
};

export { EventsLesson }
