import * as React from 'react';

interface ColorPickerProps {
	initialColor: string
}

const ColorPicker = (props: ColorPickerProps) => {
	let [pickedColor, setPickedColor] = React.useState(props.initialColor || '#ff0000');
	return <p style={{textAlign: 'center'}}>
		<input type="color" value={pickedColor} onChange={e => setPickedColor(e.target.value)} /> {pickedColor}
	</p>
}

const ColorPicker2 = (props?: ColorPickerProps) => {
	const elRef = React.useRef(null);
	let [pickedColor, setPickedColor] = React.useState(props.initialColor || '#00ffff');
	let [CP, setCP] = React.useState(() => (window as any).CP);

	React.useEffect(() => {
		if (!CP) {
			let s = document.createElement('script');
			// s.src = 'https://taufik-nurrohman.github.io/color-picker/color-picker.min.js';
			s.src = 'https://taufik-nurrohman.github.io/color-picker/color-picker.js';
			s.onload = () => {
				// debugger
				setCP(() => {
					// debugger;
					return (window as any).CP
				});
			}
			document.documentElement.appendChild(s);
		}
	}, [CP]);


	React.useEffect(() => {
		if (elRef.current && CP) {
			const picker = new CP(elRef.current);
			picker.set(pickedColor);
			picker.on('change', color => setPickedColor('#' + color));

			return () => {
				picker.off('change');
			}
		}
	}, [elRef.current, CP]);

	return (<>
		{/* <script src="https://taufik-nurrohman.github.io/color-picker/color-picker.min.js"/> */}
		<link rel="stylesheet" href="https://taufik-nurrohman.github.io/color-picker/color-picker.min.css"/>

		<div style={{ margin: 20 }}>
			<div ref={elRef} style={{display: 'inline-block', background: pickedColor, border: '1px solid black', width: 80, height: 40, marginRight: 20, cursor: 'pointer'}}/>
			{/* <div></div> */}
			Your color: {pickedColor}
		</div>
		{/* <p>Your color: {pickedColor}</p> */}
	</>);
}

export { ColorPicker, ColorPicker2 }
