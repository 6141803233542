import * as React from 'react';
import { Dialog } from 'sancho/esm/Dialog'
import { Collapse } from './lib/Collapse';
import { Button } from 'sancho/esm/Button';
import { useUser } from './firebase';
import { loadTawkApi } from './lib/tawkApi';
// import { jsx } from "@emotion/core";
// import "@emotion/core";

interface HelpDialogProps {
	onRequestClose(): void
}

const openChat = async (user?: firebase.User) => {
	let Tawk_API = await loadTawkApi(user && user.displayName);

	if (Tawk_API && Tawk_API.getStatus()) {
		if (Tawk_API.isChatHidden()) Tawk_API.showWidget();
		if (Tawk_API.isChatMinimized()) Tawk_API.toggle();
	}
	else {
		alert('chat is unavailable right now. Try email instead.');
	}
};


const HelpDialog: React.FC<HelpDialogProps> = props => {
	const user = useUser();

	return (
		<Dialog isOpen onRequestClose={props.onRequestClose}>
			<div style={{padding: 50}}>
				<h2 className="hRed">Need help?</h2>
				{/* If you're not sure what to do, try these things: */}
				<Collapse collapsed title="What am I supposed to do?">
					<p>
						If you're not sure what you are supposed to be doing on an exercise, first,
						make sure you've read the instructions!
						This can be an easy step to forget sometimes. Every lesson and exercise explains what you should do.
					</p>
					<p>
						Also, exercises and lessons tend to build on each other, so if you skip over an exercise,
						that might make the next one more confusing. Try to go through each exercise in order.
					</p>
				</Collapse>
				<Collapse collapsed title="Why isn't my code working?">
					<p>
						A big part of programming is figuring out why your code isn't working, and how to fix it.
						So if your code isn't working, that's normal!
						It doesn't mean you're not getting it – you just aren't finished yet!
					</p>

					<p>
						Here are some tips to help you figure out what's wrong.
					</p>

					<ul>
						<li>
							<strong>Look for error messages</strong>
							{' '}Often when your code isn't working, there will be an error, and information about the error will be printed in the console.
							Look for a message printed in red.  Usually it will say what the error is, and where it happened.
							These messages can be confusing at first, but they are very valuable, and learning to interpret them is an important skill to have.
						</li>
						<li>
							<strong>Use <code>console.log</code> statements to track down the problem.</strong>
							{' '}Every exercise and project has a console where <code>console.log</code> statements are printed.
							Use it wisely! When part of your code isn't working as expected, you can add logging statements
							around that part of the code to help you see what's happening.
						</li>
						<li>Ask a neighbor for help.</li>
						<li>Ask the teacher for help.</li>
					</ul>
				</Collapse>
				<Collapse collapsed title="Chat with the teacher">
					<p>
						If you are working on something at home and you get stuck,
						and you have tried the tips above (under "Why isn't my code working?"),
						you can start a chat with me right here.
					</p>

					<p>
						If I am offline, I will get back to you when I am back online.
					</p>

					<p>
						<Button intent="primary" onPress={() => openChat(user)}>Start Chat</Button>
					</p>
				</Collapse>
				<Collapse collapsed title="Email the teacher">
					<p>
						If you are working on something at home and you get stuck,
						and you have tried the tips above (under "Why isn't my code working?"),
						you are always welcome to email me for help.
					</p>

					<p>
						If you do, I will want to know what you are working on, what the problem is, what you have already tried to solve it.
						If you just tell me your code isn't working, expect some follow-up questions.
					</p>

					<p>
						You can reach me at <code>bgmort@gmail.com</code>.
						Please include the name of the class in the subject line so I don't overlook it.
					</p>
				</Collapse>
			</div>
		</Dialog>
	);
}

export { HelpDialog, openChat }
