import * as React from 'react';
import { Layout } from './Layout';
import { PagedContent } from './lib/PagedContent';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { Popover } from 'sancho/esm/Popover';
import { Tooltip } from 'sancho/esm/Tooltip';

const StringsLesson = () => {
	return (
		<Layout narrow>
			{/* <> */}
			<h1>Strings</h1>


			<PagedContent pages={[
				<>
					<p className="intro">
						You already know that a string is a piece of text in a program,
						and that it can be used print messages on the screen, in the console,
						and even set colors.
					</p>

					<p className="intro">
						But strings can do so much more! Today we'll learn a few of the many things
						you can do with strings.
					</p>

					<p>
						Here's a quick overview of what we'll go over today:
					</p>

					<ul>
						<li><code>string.toUpperCase()</code></li>
						<li><code>string.toLowerCase()</code></li>
						<li><code>string.indexOf()</code></li>
						<li><code>string.length</code></li>
						{/* {/* <li><del><code>string.slice()</code></del></li> */}
						{/* {/* <li><del><code>string.split()</code></del></li> */}
						<li><code>string.replace()</code></li>
					</ul>
				</>,<>

					<p>
						Here's a problem: Let's say you are making a quiz game. You have questions and answers,
						and when someone gets an answer correct, they get points. One of your questions is this:
						"What 1977 film created by George Lucas began one of the most successful film sagas of all time?"
						</p>

					<p>Then, testing out your own game, you type your answer: "Star wars".</p>

					<p>"Incorrect! The answer was "Star Wars".</p>

					<p>
						Not cool, computer! Sure, you understand that when you're comparing to strings,
						they're only equal if they're <strong>exactly</strong> equal. That includes capitalization.
					</p>

					<p>
						But that's not what you want for your game. You want them to be able to type "Star Wars", "star wars",
						"STAR WARS", or even "sTaR wArS", and have it work. But how do you do that?
					</p>

				</>,<>
					<p>
						It turns out that strings have a nifty secret that I haven't told you yet:
						They have functions built right into them!
						Let me show you what I mean.
					</p>

					<CodeRunner key="up" code={`
						var greeting = 'Hello, my name is Chewie.';
						var loudGreeting = greeting.toUpperCase();
						console.log(loudGreeting);
					`} />

					<p>
						Let's take a closer look at the second line:
						<code>var loudGreeting = greeting.toUpperCase();</code>
					</p>

					<p>
						As you know <code>greeting</code> is the string <code>'Hello, my name is Chewie.'</code>
						<code>greeting.toUpperCase</code> is a function that uses <code>greeting</code>,
						and gives you back an upper cased version.
					</p>

					<p>
						When you use a string function like <code>toUpperCase</code>, you aren't changing the string itself.
						Instead, you are returning a new string that is based on the old one, but changed in some way,
						such as being made all upper case.
					</p>

					<p>
						Let's look at an example to see what I mean:
					</p>

					<CodeRunner key="moreUp" code={`
						// we'll start with this:
						var message = "ABC def Ghi";

						message.toUpperCase();

						// this hasn't changed.
						console.log('1:', message);

						var allCaps = message.toUpperCase();
						console.log('2:', allCaps);

						// this will print an upper case string, but won't change message.
						console.log('3:', message.toUpperCase());

						// still hasn't changed
						console.log('4:', message);

						// this is how you change a string: assign a new value to it.
						message = message.toUpperCase();

						// now it has changed.
						console.log('5:', message)
					`} />

					<p>
						Run the example and look at what it printed out.  Why aren't lines 1 and 4 upper case?
						It's because <code>toUpperCase</code> doesn't change the variable. It gives you a new string,
						and your code is in charge of what to do with it, whether that's nothing, or printing the new upper case string,
						or storing it in a variable to keep using.
					</p>

					<p>
						The only line that changes the variable <code>message</code> is the one
						that says <code>message = message.toUpperCase();</code>.
						So when <code>message</code> is printed out the first couple times, it hasn't been changed yet.
					</p>

				</>,<>

					<p>
						Okay, so you now know how to convert a whole string to upper case.
						And, as you might guess, there's a way to convert to lower case as well:
					</p>

					<CodeRunner key="down" code={`
						var intenseMessage = "EVERYBODY SETTLE DOWN";
						var message = intenseMessage.toLowerCase();

						console.log(message);
					`} />

					<p>
						So how do you use these functions to compare two strings in a way that capitalization doesn't matter?
					</p>

					<p>
						Before I give the answer, can you figure it out on your own?
						Can you change this program to work for "STAR WARS", "star wars", and "STAR wARs"?
					</p>

					<CodeRunner key="starWarsCaps" docName="strings.starWarsCaps" code={`
						var answer = prompt("What 1977 film created by George Lucas began one of the most successful film sagas of all time?");
						var correctAnswer = "Star Wars";

						// what should you do here?
						if (answer == correctAnswer) {
							alert('Correct! Eleven points for you.');
						}
						else {
							alert('Wrong! The correct answer was ' + correctAnswer);
						}
					`} />
				</>,<>
					<p>
						When you compare two strings, if you convert them both to lower case,
						or to upper case, before you compare them, then any differences in capitalization will disappear.
					</p>

					<p>
						That could look like this:
					</p>

					<CodeRunner key="urff" code={`
						// Try typing "sTaR wArS"
						var answer = prompt("What 1977 film created by George Lucas began one of the most successful film sagas of all time?");
						var correctAnswer = "Star Wars";

						// what should you do here?
						if (answer.toLowerCase() == correctAnswer.toLowerCase()) {
							alert('Correct! Eleven points for you.');
						}
						else {
							alert('Wrong! The correct answer was ' + correctAnswer);
						}
					`} />

					<p>
						Notice how if you get it wrong, it still tells you that the correct answer is "Star Wars", not "star wars".
						That's because <code>answer</code> and <code>correctAnswer</code> weren't changed.
						Instead, toLowerCase() creates a lower-case copy, and those two lower case copies were compared.
					</p>
				</>,<>
					<p>
						So now you know how to change strings to upper and lower case, and compare them.
						Let's learn another very, <strong>very</strong> useful string function.
					</p>

					<h3 className="hBlue code">string.indexOf</h3>

					<p>
						Next we're going to learn how to find another smaller string (sometimes called a <strong>substring</strong>)
						inside of another string. Watch this:
					</p>

					<CodeRunner key="indexOf" code={`
						var sentence = 'I like programming :)';
						var smiley = ':)';
						var smileyIndex = sentence.indexOf(smiley);

						if (smileyIndex > -1) {
							console.log('There is a smiley at position', smileyIndex);
						}
						else {
							console.log('No smileys were found in the sentence');
						}
					`} />

					<p>
						<code>string.indexOf(searchString)</code> will look inside of <code>string</code> for <code>searchString</code>,
						and if it finds it, it will tell you what position it found it at.  The first position is 0, just like in an array.
					</p>

					<p>
						If it's not found, it will return -1 instead of the position, so you see the result of  <code>indexOf</code> compared
						with -1 a lot, if you want to know whether the string is found at all.
					</p>

					<p>
						<code>indexOf</code> only tells you where the first occurence of a string is,
						so <code>'I :) love :) pie :)'.indexOf(':)')</code> will return <code>2</code>.
						{/* , but there are ways to find the rest if you need to */}
						{/* <Tooltip content={
							<div style={{ fontSize: '.95rem', fontFamily: 'inherit', padding: "2rem" }}>
								<code>string.indexOf(searchString, 10)</code> will start searching at the 10th position in the string.
								And <code>string.lastIndexOf(searchString)</code> will search backwards from the end of the string.
							</div>
						}
						>
							<a>ways</a>
						</Tooltip> */}

							{/* <button>ways</button> */}
							{/* <a href="javascript:">ways</a> */}
					</p>

					<h3 className="hGreen">Your Turn</h3>
					<p>
						Here's a list of foods in an array.
						Make two lists: One of the foods that are pie, and another of the foods that are not pie.
					</p>

					<CodeRunner key="pielist" docName="strings.pieList" code={`
						var foods = [
							'bacon',
							'cheese',
							'apple pie',
							'pie a la mode',
							'bread pudding',
							'pumpkin pie',
							'banana cream pie',
							'turkey',
							'jello',
						];

						var pies = [];
						var notPies = [];

						for (var i = 0; i < foods.length; i++) {
							var food = foods[i];
							// is it pie? change this line.
							var isPie = false;
							if (isPie) {
								pies.push(food);
							}
							else {
								notPies.push(food);
							}
						}

						console.log('pies:', pies);
						console.log('not pies:', notPies);
					`} />

				</>,<>

					<h3 className="hBlue code">string.length</h3>

					<p>
						This one is very straightforward. Sometimes you need to know how long a string is.
						There's an easy way to find out: <code>string.length</code>.
						Let's see an example in action:
					</p>

					<CodeRunner key="username" code={`
						var username = prompt('Please choose a username');
						if (username.length != 10) {
							alert('Your username must be exactly 10 characters. Please try again.');
						}
						else {
							alert('Your username has been set to ' + username);
						}
					`} />

					<h3 className="hGreen">Your Turn</h3>
					<p>
						Here's a list of words. Use string.length to find the longest one.
					</p>

					<CodeRunner key="longestWord" docName="strings.longestWord" code={`
						function findLongestWord(words) {
							var longest = '';

							for (var i = 0; i < words.length; i++) {
								var word = words[i];
								// find the longest word here.

							}

							return longest;
						}

						var longestWord = findLongestWord([
							'one',
							'two',
							'three',
							'spaghetti',
							'carbon fiber nanotube space elevator',
							'moderately satisfied',
							'shrimp and grits',
						]);

						console.log('the longest word is', longestWord);

					`} />

				</>,<>

					<h3 className="hBlue">Individual letters in a string</h3>

					<p>
						Strings are a lot like arrays - an array is a list of items, and
						a string is a sequence (or a list) of letters. They each have a length property.
						You can access the items in the array using <code>array[index]</code>.
						For example, the first item in an array is <code>array[0]</code>.
					</p>

					<p>
						You can also access the individual letters in a string using <code>[]</code> and a number:
					</p>

					<CodeRunner code={`
						var name = 'Brian';
						var firstLetter = name[0];
						console.log('My first initial is', firstLetter);

						// print every letter separately.
						for (var i = 0; i < name.length; i++) {
							var letter = name[i];
							console.log(letter);
						}

						var sentence = 'What time is it?';
						var lastLetter = sentence[sentence.length - 1];
						var isQuestion = lastLetter == '?';

						if (isQuestion) {
							console.log(sentence, 'is a question.');
						}
						else {
							console.log(sentence, 'is a not question.');
						}
					`} />

					<p>
						Notice how similar this is to working with an array.  The first letter is at index <code>0</code>,
						and the last one is at index <code>string.length - 1</code>.
						You can use a regular for loop to iterate over each letter.
					</p>

					<h3 className="hGreen">Your Turn</h3>
					<p>
						Print out a statement one letter at a time.
						Alternate printing the letters in upper case and lower case.
						So "i love code" could be printed like this:
					</p>

					{/* <CodeBox code={``}/> */}
					<p>
						i<br/>
						<br/>
						l<br/>
						O<br/>
						v<br/>
						E<br/>
						<br/>
						C<br/>
						o<br/>
						D<br/>
						e<br/>
					</p>

					<CodeRunner key="alternateCase" docName="strings.alternateCase" code={`
						var phrase = prompt('Enter a phrase');
						var upperCase = false;
						for (var i = 0; i < phrase.length; i++) {
							// print out each letter.
							// change back and forth from using toUpperCase
							// and toLowerCase with each letter.
						}
					`} />


					{/* <h3 className="hGreen">A new way to write strings</h3>
					<p>
						So far, you have always written strings in either <code>'single quotes'</code> or <code>"double quotes"</code>.
						There's a third way that is newer. It might not work everywhere that you can use javascript (on code.org, for example),
						but should work almost anywhere.
					</p>

					<p>
						You can also write strings in <code>`backticks`</code>.
						The backtick key is to at the top left of most keyboards, just left of the 1.
						Strings written inside backticks have two special powers:
						They can span multiple lines, and they can have variables embedded inside them.
					</p> */}

				</>,<>

					<h3 className="hBlue code">string.replace</h3>

					<p>
						Sometimes you need to change part of a string. <code>string.replace</code> can
						help you with that. Here's an example:
					</p>

					<CodeRunner key="cats" code={`
						var sentence = 'I really like cats';
						var betterSentence = sentence.replace('cats', 'dogs');
						console.log(betterSentence);
					`} />

					<p>
						You can also replace something with the empty string, <code>''</code>,
						to get rid of it:
					</p>

					<CodeRunner key="period" code={`
						var sentence = 'I am not hungry';
						var betterSentence = sentence.replace('not ', '');
						console.log(betterSentence);
					`} />

					<h3 className="hGreen">Your Turn</h3>
					<p>
						Use <code>string.replace</code> twice to change the sentence below
						to say "I want a cake for my birthday", or anything else you want to say.
					</p>

					<CodeRunner docName="strings.replace" code={`
						var sentence = 'I want a hippopotamus for Christmas';
						// use sentence.replace() here to change your sentence.
						// see the example above.
						console.log(sentence);
					`} />
				</>,<>

					<h3 className="hRed">Dig Deeper</h3>

					<p>
						Today you learned about all of these string functions:
					</p>

					<ul>
						<li><code>string.toUpperCase()</code></li>
						<li><code>string.toLowerCase()</code></li>
						<li><code>string.indexOf()</code></li>
						<li><code>string.length</code></li>
						{/* {/* <li><del><code>string.slice()</code></del></li> */}
						{/* {/* <li><del><code>string.split()</code></del></li> */}
						<li><code>string.replace()</code></li>
					</ul>

					<p>
						These will be very helpful for almost any program you will write.
						If you want to learn about some more useful string functions,
						see this example project:
					</p>

					<p><PrefixedLink to="/published/ybdovd13">More string functions</PrefixedLink></p>

					<p>
						If you want to make a text-based game using some of these functions,
						check out <PrefixedLink to="/published/glb7zjlc">Text Box Demo</PrefixedLink>.
					</p>
				</>
			]} />



			{/* <h3 className="hPurple">Quiz Yourself</h3> */}
			{/* <Question>
				What does this code print?
				<CodeBox code={`
					var colors = ['red', 'blue', 'yellow', 'green'];

					console.log(colors[1]);
					console.log(colors[3]);
					console.log(colors[0]);
				`} />
			</Question>
			<Answer id="arrays-lesson.q1">
				blue<br/>
				green<br/>
				red
			</Answer> */}

			{/* <p>
				<PrefixedLink to="/lesson/arrays/2"><Button intent="primary">Continue</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p> */}
			{/* </> */}

		</Layout>
	);
};

export { StringsLesson }
