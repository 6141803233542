import * as React from 'react';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { Layout } from './Layout';
// import { WrappedCanvas, CanvasInterface, CanvasWrapper } from './lib/WrappedCanvas';
// import { canvasBirdDemo } from './projects/canvasBirdDemo';
// import { Button } from 'sancho/esm/Button';
import { CodeBox } from './CodeBox';
import { LinkButton } from './lib/LinkButton';
import { PagedContent } from './lib/PagedContent';
import { Question, Answer } from './lib/Quiz';
import { CodeRunner } from './lib/CodeRunner';
import { WrappedCanvas, CanvasWrapper } from './lib/WrappedCanvas';

const LoopsLesson = () => {
	// let canvasInterfaceRef = React.useRef<CanvasInterface>(null);

	// React.useEffect(() => {
	// 	if (canvasInterface) {
	// 		setupDemo(canvasInterface);

	// 	}
	// }, [canvasInterface]);
	// const onInit = (canvasInterface) => setupDemo(canvasInterface);
	// const onInit = setupDemo

	return (
		<Layout narrow>
			<h1>Loops{/*  and Arrays */}</h1>

			{/* <p className="intro">
				Today we will add a power tool to our programming tool belt!
				Almost every program you will ever write will make use of th
			</p> */}

			<PagedContent pages={[
				<>
					<p>
						Imagine I want to write a program that prints out every number from 1 to 100.
						I could write something like this:
					</p>

					<CodeBox code={`
						console.log(1);
						console.log(2);
						// lots more of that..
						console.log(99);
						console.log(100);
					`} />

					<p>
						<strong>Yuck!</strong> Even writing out those four lines
						for this example felt repetitive, and we programmers don't like that –
						remember what we learned in the <PrefixedLink to="/project/quiz-game/2">quiz game</PrefixedLink> project?
					</p>

					<div className="container blRed">
						<strong>D.R.Y.</strong>, or Don't Repeat Yourself.
						<p>
							If find yourself repeating the same thing and over and over, there's probably a better way.
						</p>
					</div>

					<p>
						In this case, the better way is called a <strong>for loop</strong>.
					</p>
				</>,
				<>
					<h2 className="hGreen">For Loop</h2>

					<p>Here's what a for loop looks like, and how we would print all of the numbers up to 100 using it:</p>

					<CodeBox code={`
						for (var i = 1; i <= 100; i++) {
							console.log('i equals ' + i);
						}
					`} />

					<p>
						There's a lot going on there, so let's break it down.
					</p>

					<p>
						Much like the <strong>if</strong> statement, a <strong>for loop</strong> starts
						with a keyword (<strong>for</strong>),
						then has parenthesis with some code inside, then a block of code.
					</p>

					<p>
						And like an if statement, the code inside the parenthesis decides when to run the code in the block.
					</p>

					<p>
						Unlike an if statement, there are three separate statements inside the parenthesis of a for loop.
					</p>

					<p>
						The first statement is run once at the very beginning, before the loop begins, and is usually used to set up a variable to use for counting inside the loop.
						In our example, this first statement is <code>var i = 1;</code>
					</p>

					<p>
						The second statement is run at the start of each loop.  Its job is to decide whether or not to continue looping.
						It should evaluate to either <code>true</code> or <code>false</code>.  If the statement is <code>true</code>,
						the loop will continue. If it is <code>false</code>, the loop will stop.
						It is usually a comparison between a looping variable (<code>i</code>), and another number that represents when to stop.
						In our example, this statement is <code>i &lt;= 100</code>.
					</p>

					<p>
						<code>i &lt;= 100</code> is something you haven't seen before.  It's another <strong>comparison operator</strong>,
						like <code>==</code>, but this one tests if the left side is less than or equal to the right side.

						In other words, the statement could be read as <span className="">the variable i is less than or equal to 100</span>.
						More about comparison operators later.
					</p>

					<p>
						The third statement runs after each run (called an <code>iteration</code>) of the loop body.
						It is usually used to update the looping variable.  Most of the time that means adding or subtracting one to it.

						In our example, this is statement is <code>i++</code>, which you remember means <code>i = i + 1</code>.
					</p>
				</>,
				<>
					<p>Here's that loop again:</p>

					<CodeBox code={`
						for (var i = 1; i <= 100; i++) {
							console.log('i equals ' + i);
						}
					`} />

					<p>
						So altogether, the whole loop works like this:
					</p>

					<table className="codeTable">
						<tr>
							<td/>
							<td /* colSpan={2} */>First the loop is initialized:</td>
						</tr>
						<tr>
							<td className="code">var i = 0</td>
							<td>Create a variable called <code>i</code>, and set its value to 1.</td>
						</tr>
						<tr>
							<td className="code">i &lt;= 100</td>
							<td>Is <code>i</code> less than or equal to 100? If it is, run the code block.</td>
						</tr>
						<tr>
							<td/>
							<td /* colSpan={2} */><code>i</code> is 1, which is less than 100, so the block runs.</td>
						</tr>
						<tr>
							<td className="code">console.log('i equals ' + i)</td>
							<td>'i equals 1' is printed to the console.</td>
						</tr>
						<tr>
							<td className="code">i++</td>
							<td>Add 1 to <code>i</code>. <code>i</code> is now 2.</td>
						</tr>
						<tr>
							<td/>
							<td /* colSpan={2} */>Now the loop repeats again:</td>
						</tr>
						<tr>
							<td className="code">i &lt;= 100</td>
							<td>Is <code>i</code> less than or equal to 100? If it is, run the code block.</td>
						</tr>
						<tr>
							<td/>
							<td /* colSpan={2} */><code>i</code> is 2, which is less than 100, so the block runs.</td>
						</tr>
						<tr>
							<td className="code">console.log('i equals ' + i)</td>
							<td>'i equals 2' is printed to the console.</td>
						</tr>
						<tr>
							<td className="code">i++</td>
							<td>Add 1 to <code>i</code>. <code>i</code> is now 3.</td>
						</tr>
						<tr>
							<td/>
							<td /* colSpan={2} */><em>97 iterations later...</em></td>
						</tr>
						<tr>
							<td className="code">i &lt;= 100</td>
							<td>Is <code>i</code> less than or equal to 100? If it is, run the code block.</td>
						</tr>
						<tr>
							<td/>
							<td /* colSpan={2} */><code>i</code> is 100, which is equal to 100, so the block runs.</td>
						</tr>
						<tr>
							<td className="code">console.log('i equals ' + i)</td>
							<td>'i equals 100' is printed to the console.</td>
						</tr>
						<tr>
							<td className="code">i++</td>
							<td>Add 1 to <code>i</code>. <code>i</code> is now 101.</td>
						</tr>
						<tr>
							<td className="code">i &lt;= 100</td>
							<td>Is <code>i</code> less than or equal to 100? If it is, run the code block.</td>
						</tr>
						<tr>
							<td/>
							<td /* colSpan={2} */><code>i</code> is 101, which is greater than 100, so the loop exits.</td>
						</tr>
					</table>
				</>,
				<>
					<p>Now it's your turn! First, run this example code.</p>

					<CodeRunner code={`
						for (var i = 1; i <= 10; i++) {
							console.log(i, 'squared is', i * i);
						}
					`} />

					<p>Now try modifying the loop in the following ways:</p>

					<ul>
						<li>
							Change the loop to start at 10 and finish at 20.
						</li>
						<li>
							Change the body of the loop to do something different than <code>console.log</code>.
						</li>
						<li>
							Rename the variable <code>i</code> to <code>counter</code>.
							There is nothing special about the name <code>i</code>,
							but it is common to use <code>i</code> as the name for a loop variable.
							Make sure you change every <code>i</code>, or it won't work.
						</li>
					</ul>
				</>,
				<>
					<h3 className="hPurple">Quiz Yourself</h3>
					{/* <Question>How many times will this loop run?</Question>
					<CodeBox code={`
						for (var i = 0; i <= 5; i++) {

						}
					`} /> */}
					<Question>What are the three statements inside a for loop? When do they run, and what do they do?</Question>
					<Answer id="loops-q1">
						The first statement is run once at the very beginning, and sets up a variable to use for counting inside the loop. <br/>
						The second statement is run at the start of each loop.  Its job is to decide whether or not to continue looping. <br/>
						The third statement runs after each loop iteration, and is used to update the loop counter variable.
					</Answer>

					<Question>Write a loop that just prints out "There's no place like home." three times.</Question>
					<CodeRunner docName="loops-q2" code="// write your loop here" />
				</>,
				<>
					<h3 className="hBlue">Drawing with Loops</h3>
					<p>
						Loops are handy for all sorts of things.
						Imagine I want to draw a whole bunch of shapes on my canvas.
						How do you think a loop might be able to help with this?
					</p>
					{/* <CanvasProvider render={(canvasWrapper, )} /> */}

					<CanvasRunner vertical console code={`
						canvas.fill('blue');
						for (var i = 0; i < 10; i++) {
							canvas.rect(i * 40, i * 40, 40, 40);
						}
					`} />

					<p>
						Here's an example of how you can use a loop to draw shapes.
						Notice that we're using the <code>i</code> variable to change
						the position of the rectangle each time it's drawn.
					</p>

					<p>
						If we didn't, it would be drawn in the same place each time, and you
						wouldn't see multiple shapes.
					</p>

					<p>
						Here's another one.
						This time we're using a little more math to decide where each box should go,
						so it helps to use some variables to make things easier to read and understand.
					</p>

					<CanvasRunner vertical console code={`
						canvas.fill('blue');
						var startX = 40;
						var startY = 140;
						var numSquares = 7;
						var squareXOffset = 45;
						var squareYOffset = 20;
						var squareSize = 40;

						for (var i = 0; i < numSquares; i++) {
							var squareX = startX + i * squareXOffset;
							var squareY = startY + i * squareYOffset;
							canvas.rect(squareX, squareY, squareSize, squareSize);
						}
					`} />
				</>,
				<>
					<h3 className="hGreen">Try it yourself</h3>

					<p>
						Now it's your turn.  Can you use a loop to turn a bug into a caterpillar?
						Here's the caterpillar:
					</p>

					<div style={{margin: 'auto', width: 400}}>
						<WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={wrapper => runCaterpillarExample(wrapper.wrapper)} />
					</div>

					<p>And here's a bug.  Use a for loop to turn the bug into a caterpillar! Use the examples on the previous page for help.</p>

					<CanvasRunner vertical console canvasHeight={400} editorHeight={480} code={`
						// you don't need to change any of this.
						canvas.background('skyblue');
						canvas.noStroke();
						canvas.fill('sienna');
						canvas.rect(0, 300, 400, 400)
						canvas.stroke('limegreen');
						canvas.strokeWeight(3);
						canvas.line(100, 300, 100 - 20, 300 - 20);
						canvas.line(100, 300, 100 + 20, 300 - 20);
						canvas.noStroke();
						canvas.fill('limegreen');

						// add a for loop here!
						canvas.ellipse(100, 300, 30, 30);
					`} />

					<h3>Great Work!</h3>

					<p>
						Next, you will learn about arrays,
						which are a way of storing multiple things in a list.
					</p>
				</>
				]} />
		</Layout>
	);
};

function runCaterpillarExample(canvas: CanvasWrapper) {
	canvas.background('skyblue');
	canvas.noStroke();
	canvas.fill('sienna');
	canvas.rect(0, 300, 400, 400)
	canvas.stroke('limegreen');
	canvas.strokeWeight(3);
	canvas.line(100, 300, 100 - 20, 300 - 20);
	canvas.line(100, 300, 100 + 20, 300 - 20);
	canvas.noStroke();
	canvas.fill('limegreen');

	for (var i = 0; i < 8; i++) {
		canvas.ellipse(100 + 30 * i, 300, 30, 30);
	}
}


export { LoopsLesson }
