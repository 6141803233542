import * as React from 'react';
import { CodeRunner } from './lib/CodeRunner';
import { CanvasRunner } from './lib/CanvasRunner';
import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const loopsHorizontal = require('./images/example/loops.horizontal.png');
const loopsVertical = require('./images/example/loops.vertical.png');
const loopsDiag = require('./images/example/loops.diag.png');
const loopsDiag2 = require('./images/example/loops.diag2.png');

const LoopsReview = () => {
	const imgStyles: React.CSSProperties = {float: 'left', marginRight: 10, width: 200, marginBottom: 10, border: '2px solid #d2d2d2'};
	const Clear = () => <div style={{clear: 'both'}} />;

	return (
		<Layout narrow>
			<h1>Review: Loops</h1>

			<p>
				Let's review what we learned about loops!
			</p>

			<p>
				One more time, here's a basic for loop:
			</p>

			<CodeRunner code={`
				// loop 10 times, from 1 to 10
				for (var num = 1; num <= 10; num++) {
					console.log(num);
				}
			`} />

			<p>
				Let's talk about the parts of the loop and what they do:
			</p>

			<table className="codeTable">
				<tr>
					<th>Code</th>
					<th>What it does</th>
					<th>When it runs</th>
				</tr>
				<tr>
					<td className="code">for (...)</td>
					<td>declares the for loop</td>
					<td></td>
				</tr>
				<tr>
					<td className="code">var num = 1;</td>
					<td>Create a variable called <code>num</code>, and set its value to 1.</td>
					<td>Runs once before the loop starts</td>
				</tr>
				<tr>
					<td className="code">num &lt;= 10;</td>
					<td>Tests if the variable <code>num</code> is less than or equal to 10. If it is, run the loop block.</td>
					<td>Runs at the beginning of each iteration to test if the loop should keep running.</td>
				</tr>
				<tr>
					<td className="code">num++</td>
					<td>Same as <code>num = num + 1</code>. Adds 1 to num, so each time the loop runs, <code>num</code> is 1 higher than before.</td>
					<td>Runs after the loop block.</td>
				</tr>
				<tr>
					<td className="code">{`{\n\tconsole.log(num);\n}`}</td>
					<td>Prints the loop variable, <code>num</code>.
						This part can be anything you need it to be, and can be as many lines of code as you need.
					</td>
					<td>Runs after the test condition, if the test was true.</td>
				</tr>
			</table>

			<p>
				And here's an example of some different ways we can change
				what's happening on each <strong>iteration</strong> (a single run)
				of the loop.  You can use any of these ways to help you complete
				the exercises below.
			</p>

			<CanvasRunner console vertical editorHeight={480} code={`
				// x is set up here and updated inside the loop
				var x = 10;

				// loop from 1 to 10
				for (var count = 1; count <= 10; count++) {
					// y is calculated inside the loop
					var y = (count * 40) - 10;

					// print the count variable, which is the managed by the loop, as text
					console.log('drawing', count, 'at', x, 'and', y);
					canvas.text(count, x, y);

					// update x
					x += 40;
				}
			`} />

			<p>
				Run the code, and read through it.  Try to understand what each line is doing.
				When you're ready, move on to these exercises:
			</p>



			<h3 className="hGreen">Exercise 1: Moving horizontally with a loop</h3>
			<img src={loopsHorizontal} style={imgStyles} />
			<p>
				Use a loop to reproduce this image.
				Remember, <code>canvas.ellipse</code> has four parameters:
				{} <strong>x</strong>, <strong>y</strong>, <strong>width</strong>, and <strong>height</strong>.
				<strong>x</strong> and <strong>y</strong> refer to the center of the circle.
			</p>

			<Clear />
			<CanvasRunner console vertical canvasHeight={200} editorHeight={300} docName="loops.horizontal" code=" // your code here " />



			<h3 className="hGreen">Exercise 2: Moving vertically with a loop</h3>
			<img src={loopsVertical} style={imgStyles} />
			<p>
				Use a loop to reproduce this image. If you got the first one, this one is very similar.
			</p>

			<Clear />
			<CanvasRunner console vertical canvasHeight={200} editorHeight={300} docName="loops.vertical" code=" // your code here " />



			<h3 className="hGreen">Exercise 3: Moving diagonally with a loop</h3>
			<img src={loopsDiag} style={imgStyles} />
			<p>
				Use a loop to reproduce this image.
				Remember, <code>canvas.rect</code> has four parameters:
				{} <strong>x</strong>, <strong>y</strong>, <strong>width</strong>, and <strong>height</strong>.
				<strong>x</strong> and <strong>y</strong> refer to the top left of the rectangle.
			</p>

			<Clear />
			<CanvasRunner console vertical canvasHeight={200} editorHeight={300} docName="loops.diag" code=" // your code here " />



			<h3 className="hGreen">Bonus Exercise 4: Moving diagonally with a loop</h3>
			<img src={loopsDiag2} style={imgStyles} />
			<p>
				Use a loop to reproduce this image. <strong>Hint:</strong> You'll need to do some subtraction on this one!
			</p>

			<Clear />
			<CanvasRunner console vertical canvasHeight={200} editorHeight={300} docName="loops.diag2" code=" // your code here " />

			<h3>Great Work!</h3>

			<p>
				Learning to write a loop is tricky at first, but it be helpful for all sorts of things.
				Especially with our next concept, arrays.
			</p>

			<p>
				<PrefixedLink to=""><Button intent="primary">Back to Home</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

export { LoopsReview }
