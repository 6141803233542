import * as React from 'react';
import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const FinalProjectLesson: React.FC = () => {
	const rubrik = [
		{type: 'component', name: 'Variables', description: "Makes good use of variables to store repeated and changing information.", points: 10},
		{type: 'component', name: 'Functions', description: "Code is organized into functions. Avoid “vain repetitions” by using functions appropriately.", points: 15},
		{type: 'component', name: 'If Statements', description: "Uses if statements for some conditional logic", points: 15},
		{type: 'component', name: 'Arrays', description: "Lists of similar things are stored in arrays. A list of numbers, players, colors, shapes, notes, etc. should be stored in an array.", points: 15},
		{type: 'component', name: 'Loops', description: "Loops are used to things a number of times, or to do something with each item in an array.", points: 15},
		{type: 'component', name: 'Comments', description: "Your code has helpful comments that help you to remember, and other people to understand what your code is doing.", points: 10},
		{type: 'component', name: 'Naming', description: "You use good names for your functions and variable names that help make your code easy to understand.", points: 10},
		{type: 'component', name: 'Creativity', description: "You will be given credit for being creative. Make it original, and fun! Don't just recreate something I have already created.", points: 10},
		{type: 'component', name: 'Organization', description: "The more organized your code is, the easier it will be to make changes to it as you keep working on in.", points: 10},
		{type: 'component', name: 'Functionality', description: "Your code should be functional; in other words, it should work :).  It doesn't have to be fancy, but it should do what it's intended to do.", points: 10},
		// {type: 'divider', name: 'Features'},
		{type: 'component', name: 'Animation', description: <>Use <code>canvas.animate</code> correctly to animate your program.</>, points: 10},
		{type: 'component', name: 'Input', description: "Use keyboard input, mouse input, or both.", points: 10},
		{type: 'component', name: 'Other functions', description: <>Makes use of <code>Date.now()</code>, <code>Math.random()</code>, <code>Music.createInstrument</code>, or <code>Music.createDrums</code></>, points: 10},
	];

	var total = rubrik.reduce((sum, item) => sum + (+item.points || 0), 0);

	return (
		<Layout narrow>
			<h1 id="final-project">Final Project</h1>
			{/* <p>Today instead of a regular lesson, we’re going to talk about your final project. If you’re joining me for class, we’ll cover it all there. If not, I have put it all here so you can go over it on your own.</p> */}

			<p>
				We are going to have a final project finish out the class.
				I want each of you to choose a project to work on over the next four weeks.
				On the last day of class, we will spend the whole class taking turns demonstrating our projects.</p>

			<p>
				The goal for the project is to help you put together the ideas that we've been learning in class
				into something you can be proud of, and hopefully be excited about,
				and to help you practice and remember everything you've learned.
			</p>

			<p>We'll still have new lessons to do in the next few weeks, but I also want to set aside some time each week for  helping you with your projects.
				I think it will be helpful if we look at the things you need help with together as a class, so everyone can learn from each other’s projects.
			</p>
			<p>
				It's important for you to try to make the most out of this project,
				so <strong>I will be giving grades on your project</strong>.
				I’ll give details on that toward the end.
			</p>

			{/* <!-- My goal for this project is to help you put together
			the ideas that we've been learning in class
			together into something you can be proud of, and hopefully be excited about,
			and to help you
			- to give

			you will all show your projects to each other --> */}

			<h2 className="hBlue" id="choosing-your-project">Choosing your project</h2>
			<p>You can choose what to do for you project. I will give some suggestions for ideas, but if you have an idea that is different that the ones I am giving you, you may use it.</p>
			<p>If you have an idea that you want to use, but it doesn’t fit all the requirements, you can do more than one smaller projects.</p>
			<p>For example, if you want to make a game that doesn’t need any animation, because the only time things change is when you click or type something, you can do it, and make another simple project that does an animation.</p>

			<h2 className="hBlue" id="requirements">Requirements</h2>
			<p>Your project (or projects) need to demonstrate all of the following: </p>
			<ul>
				<li>uses canvas.animate() correctly to draw something that moves or changes every frame.</li>
				<li>responds to mouse clicks, keyboard input, or both.</li>
				<li>uses one or more of each of the following appropriately:
					<ul>
						<li>variables</li>
						<li>loops</li>
						<li>arrays</li>
						<li>if statements</li>
						<li>functions (besides keyboard, mouse, and drawing functions)</li>
					</ul>
				</li>
				<li>you must explain what your code is doing using comments. You don’t need to comment every line, but you should explain every “step” that is happening.</li>
			</ul>

			<p>Your program should also make use of one or more of the following:</p>
			<ul>
				<li><code>Date.now()</code> (for timing things)</li>
				<li><code>Math.random()</code></li>
				<li>Music functions (<code>Music.createInstrument()</code>, <code>Music.createDrums()</code>, or both).</li>
			</ul>

			{/* <!-- - more than one "screen"
				- a title/instructions screen and a main game screen would work, for example. --> */}

			<h2 className="hBlue" id="project-ideas">Project ideas</h2>
			<p>
				Here are some project ideas that you can use. Remember, these are just ideas, but you can choose anything you like
				as long as your program meets the requirements.
			</p>
			<ul>
				<li>A presentation that teaches a programming</li>
				<li>A music video</li>
				<li>A fun and interesting animation</li>
				<li>An “app”: some kind of useful tool, such as:
					<ul>
						<li>a shopping list manager</li>
						<li>a calculator</li>
						<li>a stopwatch and countdown timer</li>
					</ul>
				</li>
				<li>A game. Some game ideas:
					<ul>
						<li>an obstacle-avoiding (dino, dodgeball) type of game</li>
						<li>a maze</li>
						<li>snake</li>
						</ul>
					</li>
				<li>a music maker - something that lets the player create their own music, like a keyboard</li>
			</ul>
			<p>If you are already working on something that you would like use, you may use it, but all the requirements apply. You’ll be graded on how well you make use of the principles we have learned in class.</p>

			<h2 className="hBlue" id="collaboration">Collaboration</h2>
			<p>If you want to work with other students on your project, you may. In fact, I’d encourage you to help each other. BUT, you must still each have your own project.</p>
			<p>So, If Alex and Billy want to work together, they can share help each other, and share code with each other. Their projects can be similar, but they can’t be the same, and they</p>
			<p>If you want to work with someone, I need you to decide and let me know who you’re working with in the next week. I don’t want anybody to put off doing their own project, then in the very last week, ask someone else to “work together” on theirs. Everybody needs to be working on their projects each week.</p>


			<h2 className="hBlue" id="grading">Grading</h2>
			<p>Your project will be graded on how well you make use of the principles we have learned in class. I’m still figuring out how the points will work, but here are the things the project will be graded on:</p>
			<table className="codeTable">
				<thead>
					<tr className="header">
						<th>Component</th>
						<th>Description</th>
						<th>Points</th>
					</tr>
				</thead>
				<tbody>
					{rubrik.map(item =>
						item.type == "component" ? (
							<tr>
								<td>{item.name}</td>
								<td>{item.description}</td>
								<td>{item.points}</td>
							</tr>
						)
						: <tr>
							<th colSpan={3}>{item.name}</th>
						</tr>
					)}
					<tr>
						<th colSpan={2}>Total</th>
						<th>{total}</th>
					</tr>
				</tbody>
			</table>

			<p>&nbsp;</p>

			<h2 className="hBlue">Due Date</h2>
			<p>
				<strong>April 28</strong> is the last day of class. Everyone will have a few minutes to demonstrate their project to the class.
			</p>


			<h2 className="hBlue" id="im-here-to-help">I’m here to help</h2>
			<p>If you need help with absolutely anything, please ask. I want you guys to get as much as you can out of this class, and I want to help you succeed. Remember you can always chat with me right here on the site, or send me an email.</p>


			<p style={{ textAlign: 'center' }}>
				<PrefixedLink to="/"><Button intent="primary" size="lg">Home</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

export { FinalProjectLesson }
