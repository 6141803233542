import * as React from 'react';
import { QuizPage } from './lib/Quiz';
import { CodeBox } from './CodeBox';

const IfStatementsQuiz: React.FC = () => {
	return <QuizPage title="If Statements" id="q.if" questions={[
		[
			<>What does an if statement do?</>,
			<>It let's you run some code conditionally – only is a certain condition is true.</>
		],
		[
			<>What's an example of how you might use an if statement in a program of your own?</>,
			<>If I want to give a user a point only if they chose the right answer.</>
		],
		[
			<>What is the difference between <code>=</code> and <code>==</code> in Javascript?</>,
			<>
				<code>=</code> assigns the value on the right to the variable on the left.<br/>
				<code>==</code> compares the values on the left and right to see if they are equal, and is useful in an if statement.
			</>
		],
		[
			<>What is an else block?</>,
			<>An else block runs anytime an if condition is false.  Or in other words, whenever the if block did not execute.</>
		],
		[
			<>What's an example of how you might use an if/else statement in a program of your own?</>,
			<>If I got the password right, sign me in.  Otherwise, show a message saying that the password was wrong.</>
		],
		[
			<>How do you indent and outdent your code? Why should you do it?</>,
			<>
				Using the <em>tab</em> or <em>shift+tab</em> keys.
				You should indent your code to keep it organized.
				It helps you see where blocks start and end.
			</>
		],
		[
			<>
				What does this code do?
				<CodeBox code={`
					var weather = prompt("Is it sunny or rainy today?");
					if (weather == 'sunny') {
						alert('Maybe I should wear sunscreen');
					}
					else {
						alert('Maybe I should take an umbrella');
					}
				`} />
			</>,
			<>
				<p>It asks the user with a prompt, "Is it sunny or rainy today?".</p>
				<p>If they respond with "sunny", it will show a popup with the message, "Maybe I should wear sunscreen".</p>
				<p>If they respond with anything else (even "SUNNY"), it will show a popup with the message, "Maybe I should take an umbrella".</p>
			</>
		],
	]} />
}

export { IfStatementsQuiz }
