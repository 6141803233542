import * as React from 'react';

import { CodeBox } from './CodeBox';
import { ProjectPage } from './ProjectPage';
import { PagedContent } from './lib/PagedContent';

// const HelpPopout: React.FC<{}> = (props) => (
// 	<Popover content={<div className="helpContent">{props.children}</div>}>
// 		<IconButton tabIndex={-1} variant="ghost" label="Need Help?" icon={<IconHelpCircle />} size="sm" />
// 	</Popover>
// );

const QuizGame1: React.FC = (props) => {
	return (
		<ProjectPage
			monacoProps={{
				docName: "if-quiz-project",
				initialCode: `
					// follow the instructions on this page
					// to get started building your quiz`,
			}}
			pageContent={<PagedContent pages={[
				/****
				 * page 1
				 */
				<>
					<h1>Using If Statements to Build a Quiz</h1>

					<p className="intro">
						Now that you know how to use an if statement, you are ready for the next challenge – building a quiz!
					</p>

					<p className="intro">
						In this exercise, you should try to write as much of the code yourself as you can.
						<br/>
						Build the quiz step by step in the editor at the right by following along with these steps.
						<br/>
						{/*
						If you need help, click the <IconButton variant="ghost" label="Need Help?" icon={<IconHelpCircle />} size="sm" /> {}
						buttons for hints and examples.
						*/}
						If you need help, click the box below each step to see an example.
					</p>

					<h2 className="hBlue">Build your first question</h2>

					<p>
					Let's get started by making the first question.  First, you'll want to write some code that does the following:
					</p>

					<p>
						Use <code>prompt()</code> to ask the question "What is the capital of Utah?",
						and save the answer to a new variable called <code>yourAnswer</code>.
					</p>

					<p>
						<CodeBox clickToShow code={`
							var yourAnswer = prompt('What is the capital of Utah?');`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Next, assign the correct answer, "Salt Lake City", to a new variable called <code>correctAnswer</code>.
						<br/>
						</p>

					<p>
						<CodeBox clickToShow code={`
							var correctAnswer = 'Salt Lake City';`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Use <code>prompt()</code> to ask the question "What is the capital of Utah?",
						and save the answer to a variable called yourAnswer.
						</p>

					<p>
						<CodeBox clickToShow code={`
							var yourAnswer = prompt('What is the capital of Utah?');`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Next, create an if statement where you compare the variables <code>yourAnswer</code> and <code>correctAnswer</code>
						to see if they are equal.
					</p>

					<p>
						<CodeBox clickToShow code={`
							if (yourAnswer == correctAnswer) {

							}`
						} />
					</p>

					<hr className="spacer" />

					<p>
						If they are equal, use the function <code>alert</code> to alert the message, "Correct!"
					</p>

					<p>
						<CodeBox clickToShow code={`
							if (yourAnswer == correctAnswer) {
								alert("Correct!");
							}`
						} />
					</p>

					<hr className="spacer" />

					<p>
						Otherwise, tell them what the correct answer was.
						This will use an <code>else block</code> after your <code>if</code> statement.
					</p>

					<p>
						<CodeBox clickToShow code={`
							if (yourAnswer == correctAnswer) {
								alert("Correct!");
							}
							else {
								alert("The correct answer is " + correctAnswer);
							}`
						} />
					</p>
				</>,
				/****
				 * page 2
				 */
				<>
					<h1>Build a Quiz, continued</h1>

					<p className="intro">
						Nice work! Now it's time to turn it into a real quiz by adding more questions and keeping score.
					</p>

					<div className="container blWhite">
						<h2 className="hBlue">Adding a second question: how to copy</h2>

						<p>
							First, we'll add a second question.  This is pretty easy since you already have one.
						</p>

						<p>
							To get started, copy your entire program, and paste a copy of it below.
							If you know how to do this already, you can skip ahead to the <a href="#add-2nd">next section</a>.
							If you don't know how to copy and paste, here are two ways:
						</p>
					</div>

					{/* <Vocab term="Copying and Pasting"> */}

					<div className="container blOrange">
						<h3 className="hOrange">The mouse way</h3>
						<p>Most people like to learn things with the mouse first, because it's easy to remember.</p>
						<ul>
							<li>Select all of the text you want to copy by dragging the mouse over the text.</li>
							<li>Right click on the editor and choose "Copy" from the menu.</li>
							<li>Click to put your cursor where you want to paste the text.</li>
							<li>Right click on the editor and choose "Paste" from the menu.</li>
						</ul>

						<h3 className="hOrange">The keyboard way</h3>
						<p>Programmers and other people who type a lot like to use the keyboard, because it's a lot faster once you're used to it. Here's how you do it with the keyboard:</p>
						<ul>
							<li>Select all of the text you want to copy by holding the <strong>shift</strong> key while you use the arrows to move the cursor.</li>
							<li>Press Ctrl+C (hold down the control key at the bottom left of the keyboard, then press C) to copy the text.</li>
							<li>Use the arrows to put your cursor where you want to paste the text.</li>
							<li>Press Ctrl+V to paste the text.</li>
						</ul>
					</div>

					{/* </Vocab> */}

					{/* <hr className="spacer"/> */}

					<div id="add-2nd" className="container blWhite">
						<h2 className="hBlue">Adding a second question</h2>

						<p>
							Copy your entire program so far, and paste it at the end, so it will basically run the same code twice.
						</p>

						<p>
							Then, change your program so that the questions and answers are different, and try running your code.
						</p>

						<p>
							If you have followed the instructions carefully, you should now have a working quiz that asks two questions.
							Good job! Pat yourself on the back.
						</p>
					</div>

					{/* <hr className="spacer"/> */}

					<div className="container blWhite">
						<h2 className="hBlue">Keeping Score</h2>

						<p>
							If we're going to have multiple questions, we better keep score too, and show the score at the end, right?
						</p>

						<p>
							At the very beginning of your program, create a variable for storing the score, and start the score at <code>0</code>.
							When they get a question right, we will update the score.
						</p>

						<p>
							<CodeBox clickToShow code={`
								// at the top
								var score = 0;`
							} />
						</p>

						<hr className="spacer" />


						<p>
							If your question was answered correctly, you will need to add a point to the score.  You can add one to a variable like this:
						</p>

						<p>
							<CodeBox code={`
								score = score + 1;`
							} />
						</p>

						<p>
							Let's talk about what that's doing.  On the right side, it's reading the variable <code>score</code>,
							and adding one to it.
							Then it's assigning it back to the variable on the left side – which is the same variable, <code>score</code>.
							So it's really just adding one to the score.  Exactly what we want.
						</p>

						<p>
							You can put this in the same <code>if</code> block where you are saying "Correct!",
							because those two things should happen together.
							You'll need to do it twice since you have two questions now.
						</p>


						<p>
							<CodeBox clickToShow code={`
								// in both questions
								if (yourAnswer == correctAnswer) {
									score = score + 1;
									alert("Correct!");
								}`
							} />
						</p>
					</div>

					<hr className="spacer" />


					<div className="container blRed">
						<h3 className="hRed">Deep Question...</h3>
						<p>Did your code work?  Hopefully it did.  But, we are starting to create a bit of a problem.  Any idea what that could be?</p>

						<p>
							Imagine you build a quiz with 20 questions.
							Then, you decide you want to change how the program works, so that instead of
							telling the user what the correct answer was, you just want to say "Nope, sorry!" instead.
							Or, you decide to add a score like you just did, but after you already wrote 20 questions.
						</p>

						<p>
							Well, now you have to make the exact change to your code in 20 places,
							and it's going to take 20x longer than it should have to.
							You want to get your homework done fast so you can get back to playing Fortnite, right?
						</p>

						<h3>Ok, well teach me the right way then.</h3>

						<p id="the-way">
							Yeah, sorry about that.
							I taught you the wrong way so I can show you what's wrong with it.
							But in order to teach you the right way{/* <a href="#a-way">*</a> */}{/* <strong>*</strong> */}, we're going to have to learn... another new concept!
							Brace yourself! But that will come in the next lesson.
						</p>

						{/* <p id="a-way">
							<strong>*</strong>I actually mean <em>a</em> right way, because there are multiple ways to solve this problem.
							We're going to learn a couple of them in this class.
						</p> */}
					</div>

				</>,
			]} />}
		/>
	)
}

export { QuizGame1 }
