/**
 * @typedef {import("../ahs-js-course/lib/CanvasDemo").CanvasDemoFn} CanvasDemoFn
 * @type CanvasDemoFn
 */
export default function(canvas) {
	var letters = [];
	var letterIndex = 0;
	var letterCount = 10;
	var wrongLetterCount = 0;
	var rightLetterCount = 0;
	var roundDuration = 15 * 1000;
	var startTime = 0;
	var endTime = 0;
	var clockTicking = false;
	var score = 0;
	var highScore = 0;

	canvas.animate(draw);

	function draw() {
		if (clockTicking && Date.now() >= endTime) {
			clockTicking = false;
			if (score > highScore) highScore = score;
		}
		if (clockTicking) {
			drawGame();
		}
		else {
			drawStartScreen();
		}
	}

	function drawGame() {
		canvas.background('white');
		canvas.textAlign('center', 'top');

		canvas.textSize(40);

		for (var i = 0; i < letters.length; i++) {
			var letter = letters[i];
			canvas.fill(i < letterIndex ? 'green' : 'black');
			canvas.text(letter, 20 + i * 40, 190);

		}

		canvas.textSize(24);
		canvas.fill('black');

		// draw timer
		var timeLeft = (endTime - Date.now()) / 1000;
		canvas.text(timeLeft.toFixed(1), 10, 10);

		// draw score
		canvas.text(score, 300, 10);

	}

	function drawStartScreen() {
		canvas.background('white');
		canvas.textAlign('center', 'middle');
		if (highScore == 0) {
			canvas.text('type the letters that', 200, 100)
			canvas.text('you see on screen.', 200, 130)
		}
		else {
			canvas.text('Your score was ' + score, 200, 100)
			if (score == highScore) {
				canvas.text('You set a new high score!', 200, 130)
			}
			else {
				canvas.text('The high score is ' + highScore, 200, 130)
			}
		}
		canvas.text('press space to begin', 200, 300)
	}

	function whenKeyPressed(key) {
		if (clockTicking == false) {
			if (key == 'space') {
				startGame();
			}
		}
		else {
			checkKey(key);
		}
	}

	function startGame() {
		score = 0;
		letterIndex = 0;
		clockTicking = true;
		endTime = Date.now() + roundDuration;
		letters = getNewLetters();
	}

	function checkKey(key) {
		if (key == letters[letterIndex]) {
			letterIndex++;
			if (letterIndex == letters.length) {
				letterIndex = 0;
				letters = getNewLetters();
			}

			rightLetterCount++;
			score += 10;
		}
		else {
			wrongLetterCount++;
			score -= 1;
		}
	}

	function getRandomLetter() {
		// return String.fromCharCode(97 + Math.floor(Math.random() * 26));
		var letters = 'aaaaabbcccdddeeeeeeeffgghhiiijkklllmmnnnnoooppqrrrrssssttttuuvwwxyyz';
		// var letters = 'abcdefghijklmnopqrstuvwxyz';
		var n = Math.floor(Math.random() * letters.length);
		return letters[n];
	}

	function getNewLetters() {
		var newLetters = [];
		for (var i = 0; i < letterCount; i++) {
			newLetters.push(getRandomLetter());
		}

		return newLetters;
	}

	return { whenKeyPressed }
}
