import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

const ArraysLesson2 = () => {
	return (
		<Layout narrow>
			<h1>Looping with Arrays</h1>

			<p>
				Lots of times when you have an array, you will want to do something
				with every item in the array.
				For example, I might want to list everything in my shopping list,
				or draw something in every color from a list of colors.
			</p>

			<p>
				Remember how we just learned about <PrefixedLink to="/lesson/loops">loops</PrefixedLink>?
				They can help us out here!
			</p>

			<p>
				You might have noticed something in the siblings example that I haven't mentioned yet:
				Every array has a <code>.length</code> that tells how many things are in it.
				Here's another example:
			</p>

			<CodeRunner code={`
				var planets = [
					"Mercury",
					"Venus",
					"Earth",
					"Mars",
					"Jupiter",
					"Saturn",
					"Uranus",
					"Neptune",
					// "Pluto",  // sorry, Pluto :(
				];

				var totalPlanets = planets.length;

				console.log("There are " + totalPlanets + " planets today.");
			`} />

			<p>
				Well, if we know how many things are in an array,
				we can use a <strong>for loop</strong> to access
				each one of them.
			</p>

			<h3 className="hOrange">Review: For Loops</h3>
			<p>
				Let's review how for loops work.  They have three statements that control the loop.
			</p>

			<CodeBox clickToShow code={`
				for (var counter = 0; counter < 5; counter++) {
					console.log(counter);
				}
			`} />

			<p>
				In this example, the first one sets the loop variable, which I called <code>counter</code>, to 0.
			</p>

			<p>
				The second one tests if <code>counter</code> is less than five,
				and if it is, it runs the block.
				If it is not less that five, the loop is over.
			</p>

			<p>
				The third one adds one to <code>counter</code>, so when the test runs again,
				<code>counter</code> will be something different.
			</p>

			<h3 className="hPurple">Quiz Yourself</h3>

			<Question>
				What does the code above print?
			</Question>
			<Answer id="arrays-lesson.q1">
				0<br/>
				1<br/>
				2<br/>
				3<br/>
				4
			</Answer>

			<p>
				Now, let's put that together with an array.
			</p>

			<CodeRunner code={`
				var toppings = ['cheese', 'pepperoni', 'olives'];
				console.log("I'd like to order a pizza with these toppings:");
				for (var i = 0; i < toppings.length; i++) {
					console.log(toppings[i]);
				}
			`} />

			<p>
				Let's break that down.  This loop is a lot like the last one,
				but instead of writing in that I want the loop to happen a specific
				number of times, I write it so that it runs as many times as the
				size of the array <code>toppings</code>, or the number of things in it.
			</p>

			<p>
				In other words, it runs one <strong>for</strong> each item in the array.
				(That's actually why it's called a <strong>for loop</strong>.)
			</p>

			<p>
				And, each time it runs, the variable <code>i</code> is different.
				So <code>toppings[i]</code> is looking at a different position in the array
				each time the loops runs.
			</p>

			<p>
				The first time it runs, <code>i</code> will be <code>0</code>, which will point at
				the first thing in the array.
				Since it will only run while <code>i &lt; toppings.length</code>,
				the last time it runs, <code>i</code> will be the same as <code>toppings.length - 1</code>,
				which will point at the last item in the array.
			</p>

			<div className="container blOrange">
				<p>
					If that's a little confusing, don't worry.  You'll get it with time.
					The important thing to remember is this:
					You can use a <strong>for loop</strong> to loop
					over every item in an array and do something with it.
				</p>

				<p>
					Soon you'll know how to write it by heart, but it's okay if in the meantime,
					you come back to examples in this lesson for help.
					That's something even professional programmers do all the time
					with new things, and they are always learning new things.
				</p>
			</div>

			<h3 className="hGreen">Your Turn</h3>
			<p>
				Remember the favorite things example you wrote?
				Now it's time to rewrite it to use a for loop instead.
			</p>

			<CodeRunner docName="arrays-lesson.favoriteThingsLoop" code="// write your array code here" height="350" />

			<h3>Step 1</h3>
			<p>
				Same as before. Create your own array of favorite things.  Give it a name,
				like <code>favoriteColors</code>, or <code>favoriteBooks</code>,
				or <code>favoriteMovies</code>, for example.
				Just make sure it describes what you're putting in it, and put a few strings in it.
			</p>

			<CodeBox clickToShow code={`
				var favoriteShows = ['The Office', 'Chuck', 'American Ninja Warrior'];
			`} />

			<h3>Step 2</h3>

			<p>
				Use a <strong>for loop</strong> to access and print each item in your array.
			</p>

			<CodeBox clickToShow code={`
				console.log('I really like all these shows:');
				for (var i = 0; i < favoriteShows.length; i++) {
					var show = favoriteShows[i];
					console.log(show);

					// Or in one line, like this:
					// console.log(favoriteShows[i]);
				}
			`} />

			<h3>Step 3</h3>

			<p>
				Add a new item to your list of favorites, and run the program again.
				Does it still work? Why or why not?
			</p>

			<h3>Great Work!</h3>

			<p>
				Next, you will learn some ways that arrays can be useful when drawing on a canvas.
				But that's all for today!
			</p>

			<h3>Array Examples</h3>
			<p>
				Here's an example that uses an array of colors to draw a rainbow: <br/>
				<a href="https://code.mortensoncreative.com/published/9dsslxn9" target="_blank">https://code.mortensoncreative.com/published/9dsslxn9</a>
			</p>

			<p>
				And here's another that stores positions of bricks in an array, which allows us to draw them on the canvas, and even undo them!<br/>
				<a href="https://code.mortensoncreative.com/published/35mgypxy" target="_blank">https://code.mortensoncreative.com/published/35mgypxy</a>
			</p>

			<p>
				<PrefixedLink to="/lesson/arrays"><Button>Back</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p>

		</Layout>
	);
};

export { ArraysLesson2 }
