import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { CanvasRunner } from './lib/CanvasRunner';

const AndOrNotLesson2 = () => {
	return (
		<Layout narrow>
			<h1>Booleans, AND, and OR, part 2</h1>

			<p>
				Sometimes you want to combine your comparisons to do something a little more complicated.
				Think about how you might try to write these programs:
			</p>

			<ul>
				<li>Give a point when someone clicks on the top left corner of the canvas</li>
				<li>Undo the last move when either the backspace or the delete key was pressed</li>
			</ul>

			<p>Talk with you neighbor, then with the class, about what you would need to do.</p>

			<h2 className="hBlue code">The hard way</h2>
			<p>
				You have enough tools in your belt to do both of these right now.
				Let's see what those would look like.
				First, clicking in the corner:
			</p>

			<CanvasRunner console vertical canvasHeight={200} editorHeight={460} code={`
				canvas.fill('green');
				canvas.rect(0, 0, 50, 50);
				canvas.text('<- click there', 60, 30);

				function whenMouseClicked(x, y) {
					// if you clicked the left side
					if (x < 50) {

						// if you clicked the top
						if (y < 50) {
							console.log('You clicked the box!');
						}
					}
				}
			`} />

			<p>
				That's not so bad, is it? It works. But what if I want to print something else
				when you clicked outside the box?
			</p>

			<CanvasRunner console vertical canvasHeight={200} editorHeight={500} code={`
				canvas.fill('green');
				canvas.rect(0, 0, 50, 50);
				canvas.text('<- click there', 60, 30);

				function whenMouseClicked(x, y) {
					// if you clicked the left side
					if (x < 50) {

						// if you clicked the top
						if (y < 50) {
							console.log('You clicked the box!');
						}
						else {
							console.log('You clicked outside the box.');
						}
					}
					else {
						console.log('You clicked outside the box.');
					}
				}
			`} />

			<p>
				Well, this is getting messy.  There are two separate <strong>else</strong> blocks:
				One is for if you clicked the left, but not the top left,
				and the other is for if you didn't click the left part.
			</p>

			<h2 className="hBlue">Introducing <code>&&</code> (and)</h2>

			{/* <p>
				Have you ever used a search engine that lets you type AND or OR to combine search terms?
			</p> */}

			<p>
				You can write <code>&&</code> to combine two booleans together.  If both statements are <code>true</code>,
				then the whole statement combined is also <code>true</code>.  Otherwise, it will be <code>false</code>.
			</p>

			<p>Here's a basic example:</p>

			<CodeRunner code={`
				// should I be charged the adult price?
				var age = 34;
				var kidMaxAge = 17;
				var seniorMinAge = 65;

				var isAdult = age > kidMaxAge && age < seniorMinAge;
				console.log('is adult:', isAdult);
			`} />

			<p>Here's another one:</p>

			<CodeRunner code={`
				var birthCountry = prompt("What country were you born in? (Type USA for the United States)");
				var age = prompt("How old are you?");

				var bornInUsa = birthCountry == 'USA';

				// you must be at least 35 and born in the US to run for president.
				var canRunForPresident = bornInUsa && age >= 35;

				if (canRunForPresident) {
					alert('You may run for president :D');
				}
				else {
					console.log('I am sorry. You may not run for president :(');
				}
			`} />

			<p>
				Try running this a few times as different people, and see if you can run for president.
			</p>

			<p>
				Both of these programs use the <strong>and operator</strong> (<code>&&</code>) to combine two
				booleans into one.
			</p>

			<p className="blRed">
				<strong>Note:</strong> You should read <code>&&</code> out loud as "and",
				so <code>{`age > kidMaxAge && age < seniorMinAge`}</code>
				{} should be read as "age is greater than kidMaxAge and age is less than seniorMinAge".
			</p>

			<h2 className="hGreen">Your Turn</h2>
			<p>
				Can you change this program to use <code>&&</code> instead of using to separate if statements?
			</p>

			<CanvasRunner console vertical canvasHeight={200} editorHeight={500} docName="andOrNot.clickedTopLeft" code={`
				canvas.fill('green');
				canvas.rect(0, 0, 50, 50);
				canvas.text('<- click there', 60, 30);

				function whenMouseClicked(x, y) {
					// if you clicked the left side
					if (x < 50) {

						// if you clicked the top
						if (y < 50) {
							console.log('You clicked the box!');
						}
					}
				}
			`} />

			<p>
				After you make that change, and a single <code>else</code> statement to print when you clicked outside the box.
			</p>


			<p>
				<strong>Bonus Challenge: </strong>
				Can you change this program to tell when they clicked on the box?
				This box is in the middle, so you'll have to do more checking than before.
				But you can do it!
			</p>

			<CanvasRunner console vertical canvasHeight={200} editorHeight={440} docName="andOrNot.clickedMiddle" code={`
				canvas.fill('green');
				canvas.rect(150, 150, 100, 100);
				canvas.text('<- click there', 260, 210);

				function whenMouseClicked(x, y) {
					// determine if they clicked inside the box
					if (false) {
						console.log('You clicked the box!');
					}
					else {
						console.log('You clicked outside the box');
					}
				}
			`} />

			<h2 className="hBlue">Introducing <code>||</code> (or)</h2>
			<p>
				Just like we need an AND operator, we also need an OR operator.  Here's a good example:
			</p>

			<CodeRunner code={`
				var answer = prompt('Name one color of the American flag');
				if (answer == 'red') {
					alert('Correct!');
				}
				else if (answer == 'white') {
					alert('Correct!');
				}
				else if (answer == 'blue') {
					alert('Correct!');
				}
				else {
					alert('Incorrect! The correct answers are red, white, and blue');
				}
			`} />

			<p>
				The answer is right if they typed red, white, or blue.
				But just because there are three answers, doesn't mean I should have to
				write three if statements to check for the right answer.
			</p>

			<p>
				I can solve this using <code>||</code>, the OR operator.
				It produces <code>true</code> when both statements surrounding it are <code>true</code>.
				If either one is <code>false</code>, the result will be <code>false</code>.
			</p>

			<p>Here's a basic example:</p>

			<CodeRunner code={`
				// should I be given a discount?
				var age = 34;
				var kidMaxAge = 17;
				var seniorMinAge = 65;

				var getsDiscount = age <= kidMaxAge || age >= seniorMinAge;
				console.log('gets discount', getsDiscount);
			`} />

			<p>
				This one is the opposite of the <code>isAdult</code> above.
				I should get a discount if I am 17 or under, OR I am 65 or older.
			</p>

			<h2 className="hGreen">Your Turn</h2>

			<p>
				Can you rewrite this example to use one if statement?
				You'll need to use <code>||</code>.
				You don't need to change the else statement.
			</p>

			<CodeRunner docName="andOrNot.flagColors" code={`
				var answer = prompt('Name one color of the American flag');
				if (answer == 'red') {
					alert('Correct!');
				}
				else if (answer == 'white') {
					alert('Correct!');
				}
				else if (answer == 'blue') {
					alert('Correct!');
				}
				else {
					alert('Incorrect! The correct answers are red, white, and blue');
				}
			`} />

			<h2 className="hOrange">Bonus Challenge</h2>
			<p>
				Can you write a single if statement that will print when any of these squares have been clicked?
				You can use variables if you like to combine values, or you can put it all inside the if statement.
				It will take both <code>&&</code> and <code>||</code>.
			</p>

			<CanvasRunner console vertical canvasHeight={200} editorHeight={540} docName="andOrNot.clickedCorners" code={`
				canvas.fill('green');
				canvas.rect(0, 0, 50, 50);
				canvas.rect(350, 350, 50, 50);
				canvas.rect(350, 0, 50, 50);
				canvas.rect(0, 350, 50, 50);
				canvas.text('<- click there', 60, 30);

				function whenMouseClicked(x, y) {
					// write an if statement that is true when any of the four boxes is clicked.

					// if (???) {
						console.log('You clicked the box!');
					// }
					// else {
					//	console.log('You clicked outside the box.');
					// }
				}
			`} />


			<h2 className="hPurple">Conclusion</h2>
			<p>
				Today you learned about using AND and OR when your programs need complex logic.
				Can you explain to a neighbor, a friend, or a parent, how and why you would use them?
			</p>


			<p>
				<PrefixedLink to="/lesson/and-or"><Button intent="none">Back</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

export { AndOrNotLesson2 }
