import * as React from 'react';
import { Layout } from './Layout';
import { Link } from 'react-router-dom';
import { Button } from 'sancho/esm/Button';
import { useCoursePrefix } from './lib/useCoursePrefix';

const IntroduceGameStudio: React.FC = () => {
	const prefix = useCoursePrefix();

	let code = '';
	if (prefix == '/svla') code = 'CYFFQM';
	if (prefix == '/ahsj') code = 'YJRFTK';

	let url = 'https://studio.code.org/join?section_code=' + code;

	return <Layout narrow>
		<h1>Game Studio</h1>

		<p>With a handle on functions and how to call them, we're going to explore what functions can do in Game Studio.</p>

		<h3>Step 0: Log in to Code.org</h3>
		<p>First, join our class section here:</p>

		<p><a target="_blank" href={url}>{url}</a></p>

		<p>Next, explore this project in Game Studio:</p>

		<p><a target="_blank" href="https://studio.code.org/projects/gamelab/230kPn55FWZccrwR2T9FhpbXmZCbNQUKUR4_4TQ2U5M/edit">https://studio.code.org/projects/gamelab/230kPn55FWZccrwR2T9FhpbXmZCbNQUKUR4_4TQ2U5M/edit</a></p>

		<p>Once you're in the project, click the 'Remix' button at the top.  This will create your own copy that you can edit.</p>
		<p>In the new copy, click on the blue 'Drawing' category in the toolbox. Then click 'Show code' on the right to be able to edit the project as code.</p>

		<p>
			<strong>Hint:</strong> You can switch back and forth between code and blocks at any time.
			I think you'll learn more and be able to go faster using code, but you can also try blocks and see if it's helpful.
		</p>

		<h3>Step 1: Explore</h3>
		<p>
			Look over the code in the editor to try to understand what it's doing.  Try changing the numbers to learn what each one does.
		</p>

		<h3>Step 2: Add some clouds</h3>
		<p>
			Which lines of code are creating the clouds? Can you add some more clouds?
		</p>

		<h3>Step 3: Add some lines</h3>
		<p>
			Which lines of code are creating the red line?  Can you add more lines to make a stick figure?
		</p>

		<h3>Step 4: Use your imagination</h3>
		<p>
			Using the functions that are already in the project, change it however you like.  The sky is the limit!  Can you draw a bird? a house? a car?
		</p>

		<Link to={prefix}>
			<Button intent="primary">Back</Button>
		</Link>


		{/* <Links /> */}
	</Layout>
}


export { IntroduceGameStudio }
