import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { PagedContent } from './lib/PagedContent';
import { CanvasDemo } from './lib/CanvasDemo';
import { makeChickenDemo } from './demos/makeChicken';
import { Question, Answer, FreeAnswer } from './lib/Quiz';
import { CanvasRunner } from './lib/CanvasRunner';
// import { PrefixedLink } from './lib/PrefixedLink';

const Functions2Lesson: React.FC = () => (
	<Layout narrow>
		<h1>Functions: The Sequel</h1>


		<PagedContent pages={[
			<>
				<p className="intro">Today we'll review some concepts about functions, and introduce some new ones.</p>

				<CanvasDemo codeFn={makeChickenDemo} />

				<p className="intro"> Let's start by reviewing some of what we have learned about functions. </p>

				{/* <p>If you are going through this on your own and want to skip the review, you can <PrefixedLink to="/lesson/functions2/7">jump ahead</PrefixedLink>.</p> */}

			</>,<>
				<h2 className="hBlue">Functions are reusable blocks of code.</h2>

				<p>
					A function is one of the building blocks of a program.
					Like a program, it has inputs, outputs, and calculations.
					When you use a function, it is said that you <strong>call</strong> the function.
					{/* When you call a function, you are asking the function to do some work for you, and to let you know the result of that work. */}
					Calling a function means running the code inside the function.
					You call a function with <strong>parenthesis</strong>, like this:
				</p>

				<CodeBox code={`
					doSomethingUseful()
				`}/>

				<p>
					If you try to call a function without using the parenthesis, <strong>nothing will happen</strong>. It's like
					getting in an elevation without pushing any buttons, then getting back off.
					So make sure you remember to add parenthesis when you're calling function!
				</p>
			</>,<>

				<h2 className="hBlue">Standard functions</h2>

				{/* <p>
					The first function you learned about in this class is <code>console.log</code>.
					It's a function that is built into Javascript, and you can use it anywhere.
					Here a few examples of other functions you have learned that are built into Javascript:
				</p> */}

				<p>
					Some of the functions you have used are built right into Javascript, and
					they can be used anywhere. These functions are very general, so they are useful
					in many different kinds of programs. Here are some examples:
				</p>

				<ul>
					<li><code>console.log()</code></li>
					<li><code>Math.random()</code></li>
					<li><code>Date.now()</code></li>
				</ul>

				<p>
					Other functions that you have learned about are specific to the our class. These are functions I&nbsp;have created
					to help you guys draw and animate.  This includes all of the <code>canvas</code> and <code>Music</code> functions.
				</p>

				<p>
					All of these functions are already defined and available in any project, so you can use them without defining them first.
				</p>

			</>,<>
				<h2 className="hBlue">Writing your own custom functions</h2>

				<p>
					But the real power of using functions is that you can create your own functions to do anything you want.
					Functions are powerful because you can write them once, then reuse them over and over.
					You can even copy a function from one program to another, if you have written your function carefully.
					(Ask me later if you want talk about what I mean by carefully.)
				</p>

				{/* <CodeBox code={`
					function print(aNumber) {
						console.log('printing', aNumber);
					}

					console.log(1);
					print(1);
					console.log(3);
				`}/>
				<p>
					A function is one of the building blocks of a program.
					Like a program, it has inputs, outputs, and calculations.
					When you use a function, it is said that you <strong>call</strong> the function.
					When you call a function, you are asking the function to do some work for you, and to let you know the result of that work.
				</p>

				<p>
					A function allows you to store code to be reused multiple times, or in multiple places in your program.
					You can think of if as a named, reusable block of code.
				</p>
				 */}

				<p>A function definition looks like this:</p>

				<CodeBox code={`
					function doSomethingUseful() {
						console.log('This is a very useful function.');
					}
				`} />

				<p>
					A function doesn't run when it's defined.
					You have to call it to run the function,
					just like you call the other functions you already know.
				</p>

				<CodeRunner code={`
					function doSomethingUseful() {
						console.log('This is a very useful function.');
					}

					console.log('Here goes:');
					doSomethingUseful();
					console.log('How useful!');
				`} />

				<p>You can call a function over and over again too.  That's part of what makes them useful.</p>

				<CodeRunner code={`
					function doSomethingUseful() {
						console.log('This is a very useful function.');
					}

					console.log('Here goes:');
					doSomethingUseful();
					console.log('How useful!');
					doSomethingUseful();
					console.log('Still very useful.');
				`} />
			</>,<>

				<h2 className="hPurple">Quiz Yourself</h2>
				<Question>
					What is this program going to print?
					Write down your guess before you run it, then run it to see if you're right.
				</Question>
				<FreeAnswer id="functions2.chuckNorris"/>

				<CodeRunner code={`
					function chuckNorris() {
						console.log("I'm Chuck Norris");
					}

					console.log("I'm at the top");

					chuckNorris();

					console.log("I'm at the bottom");

				`} />

					{/* I'm at the top <br/>
					I'm Chuck Norris <br/>
					I'm at the bottom <br/>
				</Answer> */}

				{/* <ul>
					<li>What order are these colors going to print out?  Try to decide before you run it, and see if you're right.</li>
				</ul> */}

			</>,<>
				<h2 className="hGreen">Function flow</h2>

				<p>
					Let's talk for a minute about how programs run. A program runs one line of code at a time.
					As soon as it finishes running one line, it moves on to the next. This all happens really,
					really fast. Modern computers can execute (run) millions of lines of code in the blink of an eye.
				</p>

				<p>
					Usually this happens from top to bottom. But when calling a function,
					the program jump to the function, run all the lines of code in it,
					then pick up where it left off before the function.
					because the program will jump into and out of the function.
				</p>

				<p>
					Functions can also be defined after the code that uses them.
					This is handy, because you can put your functions at the end,
					which helps to keep everything organized.
				</p>

				<p>
					Let's look at how this works with the last example:
				</p>

				<CodeRunner code={`
					// Functions are defined at the start, even if they are at the bottom of the program

					// next, this is printed.
					console.log("I'm at the top");

					// Here, we run the function named chuckNorris, and so "I'm Chuck Norris!" will be printed next.
					chuckNorris();

					// Then the program picks up here again, and prints "I'm at the bottom"
					console.log("I'm at the bottom");

					// even though the function is defined at the bottom,
					// you can use it up at the top.
					// Nothing is printed until the function is called.
					function chuckNorris() {
						console.log("I'm Chuck Norris");
					}

				`} />

			</>,<>
				<h2 className="hBlue">Function Parameters</h2>
				<p>
					Functions are great, but for them to be really useful, they need to be flexible.
					Imagine how useless the function <code>console.log</code> would be if it could only
					print the phrase "TUBE SOCKS", and nothing else. You'd never use it, except when
					you want to print "TUBE SOCKS", which is probably never.
				</p>

				<p>
					<code>console.log</code> is useful because it's flexible - you can write anything with it.
					And it's flexible, because it uses <strong>parameters</strong> to let you do it.
				</p>

				<h3>What is a parameter?</h3>

				<p>
					A parameter is an input to a function. It makes a function reusable
					by allowing it to do different things.
				</p>
				<p>
					<code>console.log</code> can print a different message every time,
					because you give the message to log as a parameter:
				</p>

				<CodeBox code={`
					// the parameter
					console.log('Greetings');
					console.log('Earthling');
				`}/>

				<p>
					In this example, the string <code>'Greetings'</code> is the parameter the first time,
					then <code>'Earthling'</code> is the parameter the second time.
				</p>

				<p>
					Let's make our own function with a parameter to see how it works.
					This time we'll use a function that can draw something.
				</p>

				<CanvasRunner code={`
					function drawCircle(color) {
						canvas.fill(color);
						canvas.ellipse(200, 200, 20);
					}

					drawCircle('blue');
				`}/>

				<p>
					Let's look at the first line: <br/>
					<code>{`function drawCircle(color) {`}</code>
				</p>

				<p>
					This line says, "I'm defining a function, and calling it <code>drawCircle</code>.
					You can call it with one parameter, <code>color</code>.
					Here comes the code for the function."
					{/* And the curly bracket <code>{`}`}</code> at the very end says, "That's the end of the code for the function." */}
				</p>

				<p>
					That means that when you call your function, whatever is in between the parenthesis when you call it
					will be put into a variable called <code>color</code> inside the function. So,
					<code>drawCircle('blue')</code> will run the function,
					and <code>color</code> will be <code>'blue'</code>,
					so <code>canvas.fill(color)</code> will make the drawing color
					blue before the circle is drawn.
				</p>

				<p>So now, we have a function that can draw a circle, in any color! Useful, right?</p>

				<CanvasRunner code={`
					function drawCircle(color) {
						canvas.fill(color);
						canvas.ellipse(200, 200, 20);
					}

					drawCircle('red');
					drawCircle('#ffff00');
					drawCircle('blue');
				`} />

				<p>
					Kind of, but not really. I can only draw the circle in one place, so each circle is drawn over the last.
					Let's make our function better! Instead of just specifying the color when you call the function,
					let's make it so you can also specify the x and y coordinates for the circle.
				</p>

				<CodeBox code={`
					function drawCircle(circleColor, circleX, circleY) {
						canvas.fill(circleColor);
						canvas.ellipse(circleX, circleY, 20);
					}
				`} />

				<p>
					Now our function takes three
					parameters: <code>circleColor</code>, <code>circleX</code>, and <code>circleY</code>.
					When you call the function, you need to pass three parameters by putting three things in between the parenthesis.

					So now it can be called like this:
				</p>

				<CanvasRunner editorHeight={300} code={`
					function drawCircle(circleColor, circleX, circleY) {
						canvas.fill(circleColor);
						canvas.ellipse(circleX, circleY, 20);
					}

					// draw a red circle top middle
					drawCircle('red', 200, 100);

					// draw a gray circle bottom middle
					drawCircle('gray', 200, 300);
				`}/>

				<p>
					Notice that I changed <code>circle</code> to <code>circleColor</code>.
					I decided that I want to rename it to be more specific. These are just names, so you can decide what they are.
					It still works because I changed circleColor in the function declaration (the first line), and also in the body
					of the function <code>canvas.fill(circleColor)</code> on the next line.
				</p>

				{/* <p>The important thing is their position - the order that they go in between the  </p> */}

				<h2 className="hGreen">Try it yourself</h2>

				<p>
					It's nice that I can move the circle around now, but what if I want to make it bigger or smaller?
					Add a fourth parameter, and call it <code>circleSize</code>.
					Use it to set the size of the circle, so it isn't always 20.
				</p>

				<CanvasRunner vertical console editorHeight={500} docName="functions2.snowman" code={`
					// add another parameter called circleSize to this function.
					function drawCircle(circleColor, circleX, circleY) {
						canvas.fill(circleColor);
						// use circleSize to set the size of the circle
						canvas.ellipse(circleX, circleY, 20);
					}


					// don't change this! Once your function is working correctly,
					// you'll get a fun picture.
					drawCircle('skyBlue', 200, 200, 600);
					drawCircle('green', 200, 800, 1000);
					drawCircle('white', 200, 270, 100);
					drawCircle('white', 200, 200, 80);
					drawCircle('white', 200, 150, 60);
					drawCircle('yellow', 350, 50, 50);

					drawCircle('black', 185, 160, 6);
					drawCircle('black', 192, 155, 6);
					drawCircle('black', 200, 153, 6);
					drawCircle('black', 208, 155, 6);
					drawCircle('black', 215, 160, 6);

					drawCircle('black', 192, 140, 6);
					drawCircle('black', 208, 140, 6);


				`}/>

				<h2>Question:</h2>
				<Question>Once you fix the function, what does the code above draw?</Question>
				<FreeAnswer id="functions2.snowmanAnswer" />

			</>,<>


				<h2 className="hBlue">Function Return Values</h2>
				<p>
					Another thing that makes functions incredibly useful is that they can do some kind of work or calculation,
					then share the result with the code that called the function. Let me show you what I mean:
				</p>

				<CodeRunner code={`
					function add(numberA, numberB) {
						var sum = numberA + numberB;
						return sum;
					}

					var twoPlusTwo = add(2, 2);
					console.log('2 + 2 is', twoPlusTwo);
				`} />

				<p>
					This is a function that adds two numbers together. I creatively called it <code>add</code>.
					It's pretty simple: You give it two numbers, and it adds them. But here's the neat part:
					It <strong>returns</strong> the two numbers added together. <code>return</code> sends
					a value, such as a number or string, out of a function
					so it can be used by the code that called it. In this example,
					in the line <code>var twoPlusTwo = add(2, 2)</code>,
					we are calling the function <code>add</code> to add 2 and 2 together,
					then storing the result in a variable called <code>twoPlusTwo</code>.
				</p>

				{/* <p>
					Here's a more useful example: It converts a decimal fraction into a percent.
				</p>

				<CodeRunner code={`
					// this function will convert a number to a rounded percentage.
					// for example, .33333333 becomes 33.
					function toPercent(number) {
						number = number * 100;
						number = Math.round(number);
						return number;
					}

					var peopleInMyFamily = 6;
					var pctMe = toPercent(1 / peopleInMyFamily);

					console.log('I make up ' + pctMe + '% of my family');

					var questions = 24;
					var questionsRight = 23;
					var fractionRight = questionsRight / questions;
					var percentRight = toPercent(fractionRight);

				`} /> */}

				{/* <h2 className="hBlue">Functions can have their own variables</h2> */}

				{/* <h2 className="hBlue">Order Matters</h2>
				<p>
					Let's look at another one. This one divides instead of adds.
				</p>

				<CodeRunner code={`
					function divide(numberA, numberB) {
						// remember, / means divided by
						var divided = numberA / numberB;
						return divided;
					}

					var piecesOfPizza = 12;
					var people = 3;
					var piecesPerPerson = divide(people, piecesOfPizza);

					console.log('There are ' + piecesPerPerson + ' slices of pizza for each of us');
				`} />

				<p>
					What is this program going to print out? If you said "There are 4 slices of pizza for each of us",
					you've been tricked! Since we are calling the function like this:<br/>
					<code>divide(people, piecesOfPizza)</code><br/>
					<code>people</code> is the first parameter, so it will be <code>numberA</code> inside the function,
					and <code>piecesOfPizza</code> will be

				</p>

				<p>

				</p>


				<Vocab term="parameter">
					A parameter is an input to a function. It makes a function reusable
					by allowing it to do different things.
					<br/>
					For example, console.log can log a different message every time,
					because you give the message to log as a parameter.
				</Vocab>

				<p>
					When you define a function, you can say what inputs it needs in between the parenthesis.
					Technically, these inputs are called <strong>parameters</strong>.  I'll call them that from now on.
					Here, the parameter is called <code>phrase</code>.
				</p>

				<p>
					Then when you call the function, you give it the parameters (input) it needs.
					Your parameter, <code>"Today is Tuesday"</code> (which was stored in the <code>message</code> variable)
					was passed into the function like this: <br/>
					<code>makeMoreExciting(message)</code>
				</p>
				<p>
					Inside the function, now you have a variable called <code>phrase</code>,
					and you can use it in your function.  We used it to make a more exciting version called <code>moreExcitingPhrase</code>.
				</p>

				<p>
					Then, we used <strong>return</strong> to output the new improved phrase,
					and captured it where we called the function by assigning it to a variable.
				</p>
				*/}

				<h2 className="hGreen">Try it yourself</h2>

				<p>
					Ready to try it out? Here's your challenge: Write a function to help calculate test scores
					as a percentage.  Call your function <code>calculateScore</code>.
					It should take two numbers, <code>correct</code> and <code>total</code>,
					and it should return a number that is the percent correct, rounded.
				</p>

				<p>
					So for example, if your score is 29 out of 30, it would give you back 97,
					because your percentage is 96.6666..., which rounds up to 97. (Hint: use <code>Math.round()</code> for rounding)
					I've started you with some code that you should be able to use with your function.
				</p>

				<CodeRunner docName="functions2.calculateScore" height={480} code={`
					// you don't need to change any of this
					var questionsCorrect = 29;
					var totalQuestions = 30;

					var percentage = calculateScore(questionsCorrect, totalQuestions);
					// this should say "Your score is 97"
					console.log('Your score is', percentage);

					// write your function here!
				`} />

				<p>
					This code won't work until you write the function.
					If you see the error <code>ReferenceError: calculateScore is not defined</code>,
					that's because it's your job to define that function, and write the code to make it work.
				</p>

				<p>
					For your function to work, it should do these things:
					<ol>
						<li>Divide the first number by the second number</li>
						<li>Multiply that number by 100</li>
						<li>Round that number</li>
						<li>Return the number</li>
					</ol>

					If you need help, ask a friend, or the teacher!
				</p>

				<p>
					Once you have it right, the program should run and give you back the correct score of 97.
					Once you have that working, try calling it more times with more numbers.
				</p>

			</>,<>

				<h2 className="hPurple">Quiz Yourself</h2>

				<p>
					Great! You've learn how to write your own functions, pass in multiple parameters to them,
					and return values from your functions.
				</p>

				<Question>What is a function? Why would you want to use one?</Question>
				<FreeAnswer id="functions2.q1" />
				<Question>How do you write a function?</Question>
				<FreeAnswer id="functions2.q2" />
				<Question>How do you pass parameters (inputs) into a function?</Question>
				<FreeAnswer id="functions2.q3" />
				<Question>How do you get output from a function?</Question>
				<FreeAnswer id="functions2.q4" />
			</>,<>
				<h2 className="hGreen">Bonus Challenge</h2>

				<p>
					Here's another challenge you can try:
					Write a function called <code>drawMickey</code>.
					It should draw three circles: A bigger one in the middle,
					and two smaller ones on the side for the two mickey ears.
					Try to make it look like Mickey Mouse.
				</p>

				<p>
					Use function parameters so you can draw Mickey
					with any color, size, and x and y position.
				</p>

				<p>
					Then, use your function to draw Mickey all over the screen
					in different sizes and colors.
				</p>

				<CanvasRunner vertical console editorHeight={500} docName="functions2.mickey" code={`
					// define and use your drawMickey function

				`}/>
			</>
		]} />

	</Layout>
)

export { Functions2Lesson }
