import * as React from 'react';
import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { CodeRunner } from './lib/CodeRunner';
import { Question, Answer } from './lib/Quiz';

const RandomNumbersLesson = () => {
	return (
		<Layout narrow>
			<h1>Random Numbers</h1>


			<PagedContent pages={[
				<>
					<p className="intro">
						Computer programs can only do what they're told.  They're predictable.
						But sometimes, you need some unpredictability, especially if you are making
						a game.
						In programming, unpredictability usually comes from random numbers.
					</p>

					<p>
						In this lesson, we'll learn why and how to make use of random numbers,
						and do some fun things with them.
					</p>
				</>,
				<>
					<p>Here's a program that isn't very fun, especially after you have played it a few times.  Give it a try.</p>

					<CodeRunner code={`
						var secretNumber = 5;

						var guess = prompt("I'm thinking of a secret number between 1 and 10."
							+ " Can you guess what it is?");

						if (guess == secretNumber) {
							alert('Good job! Press "Run" to play again.');
						}
						else {
							alert('Wrong! It was ' + secretNumber + '. Press "Run" to play again.');
						}
					`} />

					<p>
						How many times did you play this game? Was it as fun after a few times? Probably not.
					</p>

					<p>For something like this to work, we need random numbers. Thankfully, they are built into Javascript.</p>
				</>,
				<>
					<h2 className="hBlue code">Math.random()</h2>

					<p>
						The function <code>Math.random()</code> will give us a random number.
						It will be something different every time. Let's try using it in our program:
					</p>


					<CodeRunner code={`
						var secretNumber = Math.random();

						var guess = prompt("I'm thinking of a secret number between 1 and 10. Can you guess what it is?");

						if (guess == secretNumber) {
							alert('Good job! Press "Run" to play again.');
						}
						else {
							alert('Wrong! It was ' + secretNumber + '. Press "Run" to play again.');
						}
					`} />

					<p>
						Play that one a few times... hehehe. Ok, that didn't do quite what we wanted, did it?
						You probably didn't guess any of those numbers.
					</p>

					<p>
						<code>Math.random()</code> will always give you a number somewhere between 0 and 1.
						It's a very precise number too, with a lot of digits.  <code>0.7387608786142441</code> is an example.
					</p>

					<p>
						So what do you do if you want a number between 1 and 10?  With no decimals? Math.
					</p>

				{/* Page Break */}
				</>,<>
					<h2 className="hBlue">Changing the Number</h2>

					<p>
						Since <code>Math.random()</code> always gives a number between 0 and 1, it's easy to turn it into any other number you want,
						using addition and multiplication.
					</p>

					<p>Let's look at some examples of how it's done:</p>

					<CodeRunner code={`
						var numberBetween0and10 = Math.random() * 10;
						var numberBetween1and11 = 1 + Math.random() * 10;
						var numberBetween1and10 = 1 + Math.random() * 9;

						console.log('number between 0 and 10', numberBetween0and10);
						console.log('number between 1 and 11', numberBetween1and11);
						console.log('number between 1 and 10', numberBetween1and10);
					`}/>

					<p>
						You can multiply the result of Math.random() to give you a bigger range,
						and you can add that to a fixed number to make your range higher.
						So if I wanted a number between 40 and 50, I could multiply the result by 10, then add 40 to it.
						Notice that the third example multiplied by 9 instead of 10,
						because the size of the range between 1 and 10 is 10 - 1, or 9.
					</p>

				{/* Page Break */}
				</>,<>

					<h2 className="hBlue">Rounding</h2>
					<p>But what if you want a whole number? I can't guess the number 7.387186662550425.</p>

					<p>
						Now we need to round our number.
						There are some more <code>Math</code> functions to help us with that:
					</p>

					<p><code>Math.round()</code> rounds numbers the way you've been taught: .5 and higher round up, and less than .5 rounds down.</p>
					<p><code>Math.ceil()</code> rounds up: <code>Math.ceil(1)</code> will stay 1, but <code>Math.ceil(1.000001)</code> and <code>Math.ceil(1.99999)</code> will both be 2.</p>
					<p><code>Math.floor()</code> rounds down: <code>Math.floor(2)</code> will stay 2, but <code>Math.floor(1.000001)</code> and <code>Math.floor(1.99999)</code> will both be 1.</p>

					<p>
						For reasons that I don't want to get into right now, <code>Math.round()</code> and <code>Math.ceil()</code> are
						not the best for this job. <code>Math.floor()</code> is the best one to use for this.
					</p>

					<p>
						Let's see how to use it to get a round number between 1 and 10:
					</p>

					<CodeRunner code={`
						// the number is < 11.
						// So the highest number would be be 10.9999...
						// which would round down to 10.
						var decimalNumberBetween1and11 = 1 + Math.random() * 10;
						var roundNumberBetween1and10 = Math.floor(decimalNumberBetween1and11);

						//  now, shorter without using two variables:
						var another = Math.floor(1 + Math.random() * 10);
						console.log('these are both random:', roundNumberBetween1and10, another);
					`} />

					<h2 className="hGreen">Your Turn</h2>

					Now, it's your turn.  Modify the guessing game from earlier to give you a number between 1 and 10.

					<CodeRunner docName="random.guesser" code={`
						var secretNumber = Math.random();

						var guess = prompt("I'm thinking of a secret number between 1 and 10. Can you guess what it is?");

						if (guess == secretNumber) {
							alert('Good job! Press "Run" to play again.');
						}
						else {
							alert('Wrong! It was ' + secretNumber + '. Press "Run" to play again.');
						}
					`} />

					<p>Once you've done that, here are some other optional changes you can make:</p>

					<ul>
						<li>
							Change the range from 1-10 to a new range of your choice that doesn't start with 1 or end with 10.
							Make sure the numbers are always in the range you expect.
						</li>
						<li>
							Write a new function called <code>rollDie()</code> that simulates rolling a six-sided die.
							Use your function to simulate rolling five dice and print out the results.
						</li>
					</ul>
				</>,
				<>
					<h2 className="hBlue">Making Use of Random Numbers</h2>

					<p>
						You might be thinking, "Great, now I can make a number guessing game, big deal.
						That's not that interesting."
					</p>

					<p>
						Well, <em>guess</em> what? You can do a whole lot more than that with random numbers.
						Here are some things you can do with random numbers.
					</p>

					<ul>
						<li>Simulate a die roll or a coin toss</li>
						<li>Generate a random password room name for other players to join.</li>
						<li>Get a random color from all possible colors</li>
						<li>Choose a random color from a list</li>
						<li>Select a player at random to go first</li>
						<li>Decide how much damage a player does to an enemy</li>
						<li>Select a random word from a list</li>
						<li>Place a pickup or an enemy at a random location (see <a target="_blank" href="https://code.mortensoncreative.com/published/e2mpymcq">https://code.mortensoncreative.com/published/e2mpymcq</a></li>
					</ul>
				{/* </>,
				<> */}

					<p>
						One important technique that will help you with a lot of the ideas just mentioned is selected
						a random item from an array.  Can you think of how you might do that?
					</p>

					<p>
						First consider how you would go about doing it, then compare your solution to
						the example below:
					</p>

					<CodeRunner code={`
						var colors = [
							'red', 'orange', 'yellow', 'green',
							'blue', 'purple', 'pink', 'brown',
						];
						var randomColor = colors[Math.floor(Math.random() * colors.length)];

						console.log('random color:', randomColor);
					`} />

					<p>
						The way arrays go from <code>0</code> to <code>array.length - 1</code> lines up nicely
						with the way <code>Math.floor()</code> and <code>Math.random()</code> work together.
						This will give you an equal chance of getting any of the items from the array, and works
						on an array of any size.
					</p>
				</>,<>

					<h2 className="hGreen">Your Turn</h2>

					<p>
						Ok, now it's your turn.
						This one you get to do from scratch.
						Here's your challenge:
					</p>

					<p>
						<strong>Step 1:</strong> Draw 10 circles on the screen. You should use a for loop,
						so you don't have to repeat yourself.
						Each one should be in a different random location each time the program runs.
					</p>

					<p>
						<strong>Step 2:</strong> Make your circles different random colors.  Feel free to use the color example on the last page to help with selecting the colors.
					</p>

					<p>
						<strong>Step 3:</strong> Make your circles different random sizes. You can choose how big you want them to be.
					</p>

					<p>
						<strong>Step 4:</strong> Make some shapes circles, and other shapes rectangles.  Randomly, of course.
						<br/>Hint: You don't need to multiply or add here.  Just do one shape if the random number is &lt; .5, and the other shape if not.
					</p>

					<CanvasRunner console vertical canvasHeight={300} editorHeight={600} docName="random.challenge" code={`
						// Step 1: Draw 10 circles on the screen.
						// Step 2: Make your circles different random colors.
						// Step 3: Make your circles different random sizes.
						// Step 4: Make some shapes circles, and other shapes rectangles.
					`} />

					<p>Remember, it's always okay to ask for help! Hit "Next" when you are done.</p>
				{/* Page Break */}
				</>,<>

					<h2 className="hPurple">Quiz Yourself</h2>

					<p>Great work! Answer these questions to help you review what you've learned.</p>

					<Question>Why would you want or need a random number?</Question>
					<Answer id="random.q1-why">
						To add some unpredictability to a program, so it isn't the same every time.
						Much like the way you need dice in boards game to add luck to the game.
					</Answer>

					<Question>How can you get a random number in Javascript?</Question>
					<Answer id="random.q1-how">
						You can call <code>Math.random()</code>. It will give you a different number each time.
					</Answer>

					<Question>What is the range of numbers you will get from <code>Math.random()</code>?</Question>
					<Answer id="random.q1-range">
						Numbers will be between 0 and 1.
					</Answer>

					<Question>How can you convert a decimal number to a whole (round) number?</Question>
					<Answer id="random.q1-round">
						Use <code>Math.floor()</code> to round down.
					</Answer>

					<p>
						Once you're done with all of that, why not try
						adding some randomness to one of <PrefixedLink to="/user/projects">your own projects</PrefixedLink>?
					</p>

					<p>
						There are also a lot of useful examples here, including a helpful function you can use:{' '}
						<a href="https://code.mortensoncreative.com/published/ynep56j2">Random Numbers Demo</a>
					</p>
				</>,
			]} />

			{/* <p>
				<PrefixedLink to="/"><Button intent="none">Home</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/lesson/and-or/2"><Button intent="primary">Continue</Button></PrefixedLink>
			</p> */}
		</Layout>
	);
};

export { RandomNumbersLesson }
