import * as React from 'react';
import { PrefixedLink } from './lib/PrefixedLink';
import { WrappedCanvas } from './lib/WrappedCanvas';
import { CanvasInterface } from './lib/canvasWrapper';
import { CanvasPage } from './CanvasPage';
import { CodeBox } from './CodeBox';
import { PagedContent } from './lib/PagedContent';
import { Question, Answer } from './lib/Quiz';

const addToStartImg = require('./images/example/addToStart.png');

const ShoppingListProject: React.FC = () => {
	return <CanvasPage
		pageContent={<>
			<h1>Project: Shopping List</h1>

			<p className="intro">
				In this project you will get an incomplete shopping list app working.
			</p>

			<p className="intro">
				Most of the code is there, but there's a little bit of code left to write.
				It's the code that manages the list.
			</p>

			<p>
				You'll need to use the functions from the last lesson
				about <PrefixedLink to="/lesson/arrays-2">adding and removing from arrays</PrefixedLink>: &nbsp;
				<code>array.pop</code>, <code>array.push</code>, <code>array.shift</code>, and <code>array.unshift</code>.
			</p>

			<p>
				Feel free revisit that lesson and use it for help on this project.
			</p>

			<hr className="spacer" style={{clear: 'both'}} />

			<PagedContent pages={[
				<>
					<h2 className="hGreen">A Preview</h2>
						First, here's an example of the app working.  The one you build will work like this too.

					<div style={{margin: 'auto', width: 400}}>
						<WrappedCanvas canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={workingApp} />
					</div>

					<p>
						Notice how you can add and remove items from either end of the list.
						Play around with it and get a feel for how it works, then click Next.
					</p>
				</>,
				<>
					<h2 className="hGreen">Let's build it - Intro</h2>

					<p>
						In the editor on the right, you'll see the code that has already been written for this program.
						The list is already there (it's called <code>shoppingList</code>), and so is the code to draw the list.
					</p>

					<p>
						You will write the code that updates the list when the user presses a button.
					</p>

					<p>
						Your program controls the canvas in the box at the top left.
						Clicking the Run button there will run your code from the editor.
					</p>

					<h3>If you get stuck</h3>
					<p>
						A lot of learning to program is about learning to solve problems.
						Hopefully you are running into some once in a while – but not too many!
						{/* When something doesn't work, always try to analyze the problem. */}
						{/* Try to break it into smaller pieces, and ask yourself  */}
					</p>

					<p>
						If you get errors, look for error messages in the console to help you figure out what went wrong.
						If it's a SyntaxError, that means your code isn't formed properly, so the computer can't understand it to try and run it.
						Look for a red squiggle in the editor. That tells you where the error is happening.
					</p>

					<p>
						In this project, you can always hit the reset button, and it will reset the project
						to how it started. And you can always email me at bgmort@gmail.com for help.
					</p>
				</>,
				<>
					<h2 className="hGreen">Let's build it - Step 1</h2>

					<p>
						The first function you will need to implement (write the code for)
						is <code>addToEnd</code>.
						This function will be called when you click the bottom <code><strong>+</strong></code> button.
					</p>

					<p>
						It needs to do two things: Ask the user for a new shopping list item using <code>prompt()</code>.
						If you need help with <code>prompt()</code>,
						check out the <PrefixedLink to="/lesson/function-calls">function calls lesson</PrefixedLink>.
					</p>

					<p>
						Then it needs to add the new item to the <code>shoppingList</code> array.
						This is where the functions you just learned in the last <PrefixedLink to="/lesson/arrays-2">lesson</PrefixedLink> will
						come in handy.
					</p>

					<p>
						The <code>addToEnd</code> function is already defined in the program.
						You just need to write the code for it.
					</p>

					<img src={addToStartImg} style={{width: 278}} />

					<p>
						The code that has already been written will handle drawing the list,
						and you will see the item appear on the screen!
					</p>

					<p>Write your code, then test it out by running it, then click the bottom <code><strong>+</strong></code> button in your canvas to add something.</p>

					<p>If you got it right, you'll type in your new shopping list item, and see it get added to the list!</p>

					<p>If you didn't, you'll need to try to figure out what went wrong.  Look for errors or warnings in the console.</p>

					<p>
						If you need it, I'll give you a hint for this first one.
						Don't click it unless you're really stuck! It's best to try to solve it yourself first.
					</p>

					<CodeBox clickToShow code={`
						// fill in addToEnd to look like this:
						function addToEnd() {
							// use the prompt() function ask for a new item to add to the list.
							var newItem = prompt('What do you want to add to your list?');

							// add the new item to the end of the shoppingList array
							shoppingList.push(newItem);
						}
					`}/>
				</>,
				<>
					<h2 className="hGreen">Let's build it - Step 2</h2>

					<p>
						Once you can add an item, you'll want to be able to remove it as well.
						Next, you'll implement the <code>removeFromEnd</code> function.
						This function will be called when you click the bottom <strong><code>-</code></strong> button.
					</p>

					<p>
						This one is simpler since you don't need to ask anything.
						All it needs to do is remove the last item from the shopping list.
						Do you remember which <PrefixedLink to="/lesson/arrays-2">array function</PrefixedLink> does that?
					</p>

					<p>
						Again, the <code>removeFromEnd</code> function is already defined in the program.
						You just need to write the code for it.
					</p>

					<p>
						The code that has already been written will handle drawing the list,
						so when you remove it, you will see the item disappear from the screen too.
					</p>

					<p>
						Same as before – Write your code, then test it out by running it.
						This time click the button to add a couple things,
						then try clicking the bottom <strong><code>-</code></strong> button to remove the last one.
					</p>

					<p>If you got it right, the last item in the list will be removed when you click that bottom <strong><code>-</code></strong> button.</p>
					<p>If you didn't, you'll need to try to figure out what went wrong.  Look for errors or warnings in the console.</p>

					<p>
						I'll be nice and provide another hint, if you need it. But next time you're on your own for sure!
						Also, don't click it unless you're really stuck! It's best to try to solve it yourself first.
					</p>

					<CodeBox clickToShow code={`
						function removeFromEnd() {
							// remove one item from the end of the shoppingList array
							shoppingList.pop();
						}
					`}/>
				</>,
				<>
					<h2 className="hGreen">Let's build it - Step 3</h2>

					<p>
						Now it's time to work on editing the start of the list.
						Next you will implement the <code>addToStart</code> function.
						This function will be called when you click the top <strong><code>+</code></strong> button.
					</p>

					<p>
						Much like <code>addToEnd</code>, it needs to do two things:
						Ask the user for a new shopping list item using <code>prompt()</code>,
						and add the new item to the <code>shoppingList</code> array.
						But this time it should go at the beginning of the list.
					</p>

					<p>
						Write your code inside the already existing <code>addToStart</code> function.
					</p>

					<p>
						Keep running your code to test it as you go.
						This is important as your programs get bigger.
					</p>

					<p>
						There's no hint this time.  If you need one, take a look at the hints for the other two steps,
						and ask yourself what needs to be different in this step from what you did in those ones.
					</p>
				</>,
				<>
					<h2 className="hGreen">Let's build it - Step 4</h2>

					<p>
						You're really rolling now! Only one left.
						Next you will implement the <code>removeFromStart</code> function.
						This function will be called when you click the top <strong><code>-</code></strong> button.
					</p>

					<p>
						If you got <code>removeFromEnd</code>, then <code>removeFromStart</code> should be easy.
						This function should remove one item from the start of the <code>shoppingList</code> array.
					</p>

					<p>
						Write your code inside the already existing <code>removeFromStart</code> function.
					</p>

					<p>
						There's no hint this time either.  If you need one, take a look at the hints from the first two steps,
						and ask yourself what needs to be different in this step from what you did in those ones.
					</p>
				</>,
				<>
					<h2 className="hGreen">Step 5 - Making improvements</h2>

					<p>Your shopping list is working! But don't stop there.</p>
					<p>Here are some other ways you can spruce it up if you want to do more:</p>

					<ul>
						<li>Change the colors: text colors, background colors, header colors.</li>
						<li>Draw a line between each item.</li>
						<li>Change one of the buttons to delete all of your shopping list items.</li>
						<li>
							<code>addToStart</code> and <code>addToEnd</code> probably share some code that is the same in both functions.
							Is there something you can do to share that code so you only have to write it once?
						</li>
						<li>
							Make it so you can click the bar at the top to rename your shopping list.
							This will require some thinking.
							(Hint: the click is on the bar if <code>y &lt; 40</code>)
						</li>
					</ul>

					<p>
						Can you think of other improvements you might want to make? I bet there are.
						Think about what you would want to do to make it better.
					</p>
					<p>
						If you know how to do it, then go for it.
						If you don't write it down in a <code>{'//'} comment</code> somewhere in the program.
						Then you can add it later once you have learned how to do it.
					</p>
				</>,
				<>
					<h2 className="hGreen">Review</h2>

					<p>
						This project didn't ask you to write a lot of code,
						but hopefully it helped you understand how the little
						bits and pieces that we're learning can come together
						in bigger programs that can do some pretty useful things.
					</p>

					<p>
						In class, we'll talk about how this program is organized,
						and what the rest of the code is doing.
					</p>

					<p>
						To get ready, here are questions to think about:
					</p>

					<hr className="spacer"/>

					<Question>
						<p>
							1. Look over the <code>draw</code> function. Most of the code in this function
							should look familiar by now.  Can you understand what each line is doing?
							Try to go through it one line at a time, and explain out loud
							(to yourself or to a friend or family member) what each line is doing.
						</p>

						<p>
							It's okay if you don't understand all of it, but try to explain as much as you can.
						</p>
					</Question>

					<hr className="spacer"/>

					<Question>
						<p>2. Which line of code decides whether to print the instructions that show at the start?</p>
						<p>Paste the code from that line here:</p>
					</Question>
					<Answer id="project.shoppingList.q1">
						<CodeBox code="if (shoppingList.length == 0) ..." />
					</Answer>

					<Question>
						3. What does that line of code (from question 2) mean?
						In other words, how does it decide whether the instructions should print?
					</Question>
					<Answer id="project.shoppingList.q2">
						It prints the instructions if the shopping list is empty.
					</Answer>

					<Question>
						4. How easy or hard was this project? How long did you spend on it?

						<p>There are no wrong answers here -- this is more of a survey question.</p>
					</Question>
					<Answer id="project.shoppingList.q3">
						(anything)
					</Answer>
				</>,
			]}/>

			{/* <p>
				<PrefixedLink to=""><Button intent="primary">Done</Button></PrefixedLink>
			</p> */}
		</>}
		docName="project.shoppingList"
		code={`
			var shoppingList = [];


			// These functions are the ones you will need to add code to in order to get your shopping list working.

			function addToEnd() {
				// use the prompt() function ask for a new item to add to the list.

				// add the new item to the end of the shoppingList array

			}

			function removeFromEnd() {
				// remove one item from the end of the shoppingList array

			}

			function addToStart() {
				// use the prompt() function ask for a new item to add to the list.

				// add the new item to the beginning of the shoppingList array

			}

			function removeFromStart() {
				// remove one item from the beginning of the shoppingList array

			}







			// You won't need to change any of this! But feel free to look at what it's doing.




			function draw() {
				canvas.background('white');
				// draw title
				canvas.fill('#ddd');
				canvas.rect(0, 0, 400, 40);
				canvas.fill('black');
				canvas.textAlign('center');
				canvas.textSize(24);
				canvas.text('Shopping List', 200, 30);

				// draw list
				canvas.textAlign('left');
				for (var i = 0; i < shoppingList.length; i++) {
					var x = 10;
					var y = 80 + (i * 30);

					canvas.text(shoppingList[i], x, y);
				}

				// draw instructions when empty
				if (shoppingList.length == 0) {
					var spacing = 30;
					var top = 80;
					canvas.textSize(17);
					canvas.text('Your shopping list is empty.', 10, top); top += spacing;
					canvas.text('Click a  +  button to add to', 10, top); top += spacing;
					canvas.text(' the top or bottom of the list.', 10, top); top += spacing;
					canvas.text('Click a  -  button to remove from', 10, top); top += spacing;
					canvas.text(' the top or bottom of the list.', 10, top); top += spacing;
				}

				// draw buttons
				canvas.textAlign('center');
				canvas.textSize(24);
				for (var i = 0; i < buttons.length; i++) {
					var button = buttons[i];
					var text = button[0], bx = button[1], by = button[2], w = button[3], h = button[4];

					canvas.fill('#bbb');
					canvas.rect(bx, by, w, h);
					canvas.fill('black');
					canvas.text(text, bx + w / 2, by + h * .7);
				}
			}

			function whenMouseClicked(x, y) {
				for (var i = 0; i < buttons.length; i++) {
					var button = buttons[i];
					var bx = button[1], by = button[2], w = button[3], h = button[4], handler = button[5];

					if (x > bx && x < bx + w && y > by && y < by + h) {
						var l = shoppingList.length;

						handler();

						if (l == shoppingList.length) {
							var fname = handler.name;
							var op = fname.slice(0, 3) == 'add' ? 'adds a new item to' : 'removes an item from';
							var place = fname.slice(-3) == 'End' ? 'end' : 'start';

							console.warn("no item was added. Add code to the " + fname + " function");
							console.warn(" that " + op + " the " + place + " of the list.");
						}

						draw();
						break;
					}
				}
			}

			var buttons = [
				['+', 300, 50, 40, 40, addToStart],
				['-', 350, 50, 40, 40, removeFromStart],
				['+', 300, 350, 40, 40, addToEnd],
				['-', 350, 350, 40, 40, removeFromEnd],
			]

			draw();
		`}
	/>
}

const workingApp = (canvasInterface: CanvasInterface) => {
	let canvas = canvasInterface.wrapper;

	var shoppingList = [];

	function addToStart() {
		// use the prompt() function ask for a new item to add to the list.

		// add the new item to the beginning of the shoppingList array
		shoppingList.unshift(prompt('What do you want to add to your list?'))
	}

	function addToEnd() {
		// use the prompt() function ask for a new item to add to the list.

		// add the new item to the end of the shoppingList array
		shoppingList.push(prompt('What do you want to add to your list?'))
	}

	function removeFromStart() {
		// use the prompt() function ask for a new item to add to the list.

		// add the new item to the beginning of the shoppingList array
		shoppingList.shift();
	}

	function removeFromEnd() {
		// use the prompt() function ask for a new item to add to the list.

		// add the new item to the end of the shoppingList array
		shoppingList.pop();
	}


	function draw() {
		canvas.background('white');
		canvas.fill('#ddd');
		canvas.rect(0, 0, 400, 40);
		canvas.fill('black');
		canvas.textAlign('center');
		canvas.textSize(24);
		canvas.text('Shopping List', 200, 30);

		canvas.textAlign('left');
		for (var i = 0; i < shoppingList.length; i++) {
			var x = 10;
			var y = 80 + (i * 30);

			canvas.text(shoppingList[i], x, y);
		}

		if (shoppingList.length == 0) {
			var spacing = 30;
			var top = 80;
			canvas.textSize(17);
			canvas.text('Your shopping list is empty.', 10, top); top += spacing;
			canvas.text('Click a  +  button to add to', 10, top); top += spacing;
			canvas.text(' the top or bottom of the list.', 10, top); top += spacing;
			canvas.text('Click a  -  button to remove from', 10, top); top += spacing;
			canvas.text(' the top or bottom of the list.', 10, top); top += spacing;
		}

		canvas.textAlign('center');
		canvas.textSize(24);
		for (var i = 0; i < buttons.length; i++) {
			var button = buttons[i];
			var text = button[0], bx = button[1], by = button[2], w = button[3], h = button[4];

			canvas.fill('#bbb');
			canvas.rect(bx, by, w, h);
			canvas.fill('black');
			canvas.text(text, bx + w / 2, by + h * .7);
		}
	}

	function whenMouseClicked(x, y) {
		for (var i = 0; i < buttons.length; i++) {
			var button = buttons[i];
			var bx = button[1], by = button[2], w = button[3], h = button[4], handler = button[5];

			if (x > bx && x < bx + w && y > by && y < by + h) {
				handler();
				draw();
				break;
			}
		}
	}

	var buttons: any = [
		['+', 300, 50, 40, 40, addToStart],
		['-', 350, 50, 40, 40, removeFromStart],
		['+', 300, 350, 40, 40, addToEnd],
		['-', 350, 350, 40, 40, removeFromEnd],
	]

	draw();

	canvasInterface.handlers.onMouseClicked = whenMouseClicked;
}

export { ShoppingListProject }
