import * as React from 'react';

import { Layout } from './Layout';
import { Button } from 'sancho/esm/Button';
import { PrefixedLink } from './lib/PrefixedLink';
import { CodeBox } from './CodeBox';
import { CodeRunner } from './lib/CodeRunner';

const IfStatementsLesson1: React.FC = () => {
	return (
		<Layout narrow>
			<h1>Quiz Game part 1: If Statements</h1>

			<p>
				Today we will use <code>prompt()</code> and <code>alert()</code> to make a simple game.
				In the game, we will ask a some questions, and give the user a point if they know the right answer.
				At the end we'll tell them their score.
				But for this game to work, we need to learn a new concept: <strong>if statements</strong>.
			</p>

			{/* <Sidebar> */}
						{/* An if statement, just like the sentence "If it is raining, (THEN) I will wear a jacket.", has two important parts:
						the <em>condition</em> (it is raining), and the result of your condition. 				 */}
			{/* </Sidebar> */}

			<h2 className="hBlue">The <code className="noColor">if</code> statement</h2>

			<p>
				If something is <strong>conditional</strong>, it means it only happens under certain conditions.
			</p>

			<p>
				For example, "If it is raining, I will wear a jacket." is a conditional statement.
				Conditional statements, such as the <strong>if statement</strong>, are common in programming.
			</p>

			<p>
				Let's pretend we're writing a program that simulates what you will wear today.
				It has a variable called <code>currentWeather</code>, that is set to "raining" when it is raining.
				And it has a function called <code>wearJacket</code>, that will put on your jacket.
				To use these functions together to put on your jacket if it's raining, you would write an <strong>if statement</strong>,
				which looks like this:
			</p>

			<CodeBox code={`
				if (currentWeather == 'raining') {
					wearJacket();
				}
			`} />

			{/* <Vocab term="conditional">
				<p>
					If something is <strong>conditional</strong>, it means it only happens under certain conditions.
				</p>
				<p>
					For example, "If it is raining, I will wear a jacket." is a conditional statement.
					Conditional statements, such as the <strong>if statement</strong>, are common in programming.
				</p>
			</Vocab> */}

			<p>
				In this example, the condition is written as <code>currentWeather == 'raining'</code>.
				<br/>You'll notice that there's a new <code>operator</code> that you haven't seen before: <code>==</code>.
			</p>

			<p>
				{/* The  */}<code>==</code>{/*  operator */} is known is the <strong>equality operator,</strong> but the name is not as important
				as what it does: It compares the two values on either side, and tells you if they are the same.
			</p>

			<p>
				You could call it the "is equal to operator" if you like.
				<br/>So if you are reading this code aloud, you could say something like this:
			</p>

			<blockquote>
				If the variable <code>currentWeather</code> is equal to the string <code>'raining'</code>, call the function <code>wearJacket</code>.
			</blockquote>

			<p>
				As always in a program, the structure is important too: It starts with <code>if</code>,
				then parenthesis <code>()</code> with the condition inside, then last, curly braces <code>{'{}'}</code>
				with code to execute inside.
			</p>

			<hr className="spacer --robot" />

			<p>
				Here's another example.
				This one compares two variables, but it's really comparing their values.
				Try running the code.  Does it do what you would expect?  Why or why not?
			</p>

			<CodeRunner code={`
				var today = 'Tuesday';
				var favoriteDay = 'Tuesday';

				console.log("Today is " + today + '.');
				if (today == favoriteDay) {
					console.log("That's my favorite day!");
				}
			`} />

			<h2 className="hGreen">Try it out</h2>

			<p>
				Let's try it out with a program of your own.  Here's a simple program that needs an if statement added to it.
			</p>
			<p>
				Try changing the program so the message will only print when the <code>weather</code> variable is equal to the string <code>'sunny'</code>.
			</p>
			<p>
				{/* <aside> */}
				<strong>Tip:</strong> If you're having trouble finding the <code>{'{'}</code> and <code>{'}'}</code> keys on the keyboard, they are just right of the letter P.
				You'll need to use the shift key, or you'll get <code>[</code> and <code>]</code> instead.
				{/* </aside> */}
			</p>

			<CodeRunner docName="ifsunny" code={`
				var weather = 'sunny';

				// change the program so this message will only
				// print if the weather is sunny.
				// Use the wearJacket() example from above to help you.
				console.log("Looks like I'll be needing my sunglasses!");

				// Then, change the weather to rainy,
				// to make sure your if statement worked.
			`}/>

			<p>
				One common mistake here is that sometimes we forget to use <code>==</code> for comparison and try to use <code>=</code> instead.
				Remember, <code>=</code> assigns what's on the right side to what's on the left side.  It always works that way, even inside an if statement.
				So make sure you're using <code>==</code>, or the program won't work how you expect.
			</p>

			<h2 className="hPurple">Quiz Yourself</h2>
			<ul>{/*  className="basicList" */}
				{/* <li>What is a conditional statement?</li>
				<li>How do you use an if statement?</li> */}
				<li>What does an if statement do?</li>
				<li>What's an example of how you might use an if statement in a program of your own?</li>
				<li>What is the difference between <code>=</code> and <code>==</code> in Javascript?</li>
			</ul>

			{/* <BonusTask>
				If you're getting ahead, try changing this program to use <code>prompt()</code> to ask you for the weather,
				then alert instead of
			</BonusTask> */}

			<p>
				<PrefixedLink to="/lesson/if-statements/2"><Button intent="primary">Next</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p>
		</Layout>
	)
}

export { IfStatementsLesson1 }
