const notes = {
	ab: 11,
	a: 0,
	'a#': 1,
	bb: 1,
	b: 2,
	'b#': 3,
	cb: 2,
	c: 3,
	'c#': 4,
	db: 4,
	d: 5,
	'd#': 6,
	eb: 6,
	e: 7,
	'e#': 8,
	fb: 7,
	f: 8,
	'f#': 9,
	gb: 9,
	g: 10,
	'g#': 11,
}

/** Converts a note name like A4, Bb3, or C#5 to a note number (C3 is 60) */
function noteNumber(noteName: string) {
	let match = /^([A-Ga-g][#b]?)(\d)$/.exec(noteName);
	if (!match) {
		throw new Error("Note must include name and octave (A3, C#2, Bb4, g5)");
	}

	let noteVal: number = notes[match[1].toLowerCase()];
	let octaveVal = (+match[2]) * 12;
	// AO is 21
	return 21 + noteVal + octaveVal;
}

/** Converts a note name, like A4, Bb3, or C#5 or a note number, to a frequency in Hz (A4 or 69 will be 440)  */
function noteFrequency(note: string | number) {
	let noteNum = typeof note == 'string' ? noteNumber(note) : note;
	// this calc uses the A440 reference pitch, whose note number is 69.
	let offsetNum = noteNum - 69;
	return 440 * 2 ** (offsetNum / 12);
}

export {
	noteFrequency,
	noteNumber,
}
