import * as React from 'react';
import { Layout } from './Layout';
import { CodeRunner } from './lib/CodeRunner';
import { CodeBox } from './CodeBox';
import { Question, Answer } from './lib/Quiz';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';
import { CanvasRunner } from './lib/CanvasRunner';

const Arrays2Lesson = () => {
	return (
		<Layout narrow>
			<h1>Arrays</h1>

			<p className="intro">
				Let's learn about adding and removing from the beginning and the end of an <PrefixedLink to="/lesson/arrays">array</PrefixedLink>.
			</p>

			<h3 className="hBlue code">array.push</h3>

			<p>
				In the array lesson, you learned how to assign items at a specific position.
				More often, you'll just want to add something to the end of the list.
				You can do that like this:
			</p>

			<CodeBox code="array[array.length] = 'new thing';" />

			<p>
				But that can be a lot of code to write over and over.
				There's a function to add something to the end,
				and it's called <code>array.push()</code> (think of it as "pushing" something onto a pile or into a container).
			</p>

			<p>It works like this:</p>

			<CodeRunner code={`
				var alphabet = ['a', 'b', 'c'];

				alphabet.push('d');

				console.log(alphabet);
			`} />

			<h3 className="hBlue code">array.pop</h3>

			<p>
				And sometimes you need to remove the last item from the array.
				That one is called <code>array.pop()</code> (think of it as "popping" out of hole or a container).
				It does two things, really:
				<ol>
					<li>Remove the last item from the list, leaving rest of the items where they were.</li>
					<li>Returns the removed item to the caller (the place in the code where it was called.)</li>
				</ol>
			</p>

			<CodeRunner code={`
				var alphabet = ['x', 'y', 'z'];

				var lastLetter = alphabet.pop();
				console.log('got the last letter:', lastLetter);

				lastLetter = alphabet.pop();
				console.log('got another one:', lastLetter);

				console.log(alphabet);
			`} />

			<p>
				Those are the most important ones, because usually you are adding and removing from the end (right side) of the array.
			</p>

			<p>
				But sometimes you want to add or remove from the beginning too.
				This can be handy if you want to process them in order.
			</p>

			<h3 className="hBlue code">array.shift</h3>

			<p>
				<code>array.shift()</code> will take the first item out of the array.
				It's call <code>shift</code> because taking it out shifts the position of everything else in the array
				forward by one.  You can think of this as how people wait it line. When somebody leaves the front of the line,
				everybody behind them steps forward one place.
			</p>

			<CodeRunner code={`
				var customers = [];
				var nextCustomer;

				// Amanda, Bill, and Chris get in line.

				customers.push('Amanda');
				customers.push('Bill');
				customers.push('Chris');

				// next up!
				nextCustomer = customers.shift();
				console.log('the next customer is', nextCustomer); // it's Amanda
				console.log('there are ' + customers.length + 'customers waiting');
			`} />
{/*
				nextCustomer = customers.shift();
				console.log('the next customer is', nextCustomer); // it's Bill
				console.log('there are ' + customers.length + 'customers waiting');

				nextCustomer = customers.shift();
				console.log('the next customer is', nextCustomer); // it's Chris
				console.log('there are ' + customers.length + 'customers waiting'); */}


			<h3 className="hBlue code">array.unshift</h3>

			<p>
				To add something to the front of the array, you can use <code>array.unshift()</code>.
				It's kind of like butting in line. Everything else behind it gets moved (shifted) one place back.
			</p>

			<CodeRunner code={`
				var customers = ['Amanda', 'Bill', 'Chris'];
				var nextCustomer;

				// hey!!!
				customers.unshift('Dwight');
				console.log(customers);

				// next up!
				nextCustomer = customers.shift();
				console.log('the next customer is', nextCustomer); // it's Dwight :|
				console.log('there are ' + customers.length + ' customers waiting');
			`} />


			<div className="container blOrange">
				<h3 className="hOrange">Discuss</h3>

				<p>Talk about these questions with your neighbor, then with the class:</p>

				<ul>
					<li>What are some ways that you think these functions could be useful?</li>
					<li>When would you want to use <code>push</code> and <code>pop</code> together?</li>
					<li>When might you want to use <code>push</code> and <code>shift</code> together?</li>
					<li>How could these functions be useful in a program that is drawing on <code>canvas</code>?</li>
				</ul>
			</div>

			<h3 className="hGreen">Your Turn</h3>
			<p>
				Write a simple program that makes several changes to an array.
				You must use at least two of the functions you have just learned.
			</p>

			<p>
				<h4>Tips:</h4>
				<ul>
					<li>Use one of the situations from the discussion above for your program.</li>
					<li>Use good variable names that clearly describe what they are doing.</li>
					<li>Print out the items in the array between each step.</li>
					<li>Write a comment before each line describing what is happening.</li>
				</ul>
			</p>

			<CodeRunner docName="arrays2.opsPractice" height={280} code={`
				// create your array here

				// use push, pop, shift, and unshift to manage your array

			`} />


			<h3 className="hBlue">Reference</h3>

			<p>
				Here's a summary of the functions you learned.  The examples assume you have an array named <code>array</code>.
				In real code, you wouldn't usually name your arrays <code>array</code>. These functions can be used on any array,
				no matter what it is called.
			</p>

			<table className="codeTable">
				<tr>
					<th>Function</th>
					<th>Description</th>
					<th>Parameters</th>
					<th>Returns</th>
				</tr>
				<tr>
					<td className="code">array.push</td>
					<td>Adds an item to the end (<code>array[array.length]</code>) of an array.</td>
					<td>The new item to add to the array</td>
					<td>The new array length</td>
				</tr>
				<tr>
					<td className="code">array.pop</td>
					<td>Removes an item from the end of an array (<code>array[array.length - 1]</code>)</td>
					<td>None</td>
					<td>The removed item</td>
				</tr>
				<tr>
					<td className="code">array.unshift</td>
					<td>Adds an item to the beginning (<code>array[0]</code>) of an array, moving all the other items back one position.</td>
					<td>The new item to add to the array</td>
					<td>The new array length</td>
				</tr>
				<tr>
					<td className="code">array.shift</td>
					<td>Removes an item from the beginning of an array (<code>array[0]</code>), moving all the other items forward one position.</td>
					<td>None</td>
					<td>The removed item</td>
				</tr>
			</table>

			<p>Here's another example of all four:</p>

			<CodeRunner code={`
				var shoppingList = ['bread', 'cereal'];

				console.log('the list so far:');
				console.log(shoppingList);

				shoppingList.push('milk');
				console.log('added milk')
				console.log(shoppingList);

				shoppingList.push('eggs');
				console.log('added eggs')
				console.log(shoppingList);

				shoppingList.unshift('cookies');
				console.log('added cookies to front of list')
				console.log(shoppingList);

				var firstThing = shoppingList.shift();
				console.log('got the first item:', firstThing);
				console.log(shoppingList);

				var lastThing = shoppingList.pop();
				console.log('got the last item:', lastThing);
				console.log(shoppingList);
			`} />

			<div className="container blGreen">
				<h3 className="hGreen">Bonus Challenge</h3>
				<p>
					<strong>Part 1:</strong><br/>
					Make a <PrefixedLink to="/user/projects">new project</PrefixedLink>.
					Write a program that uses arrow keys and/or mouse clicks to add new shapes to the screen.
					Use <code>push()</code> each time you add a new shape.
				</p>

				<p>
					<strong>Part 2:</strong><br/>
					Add undo to your program by using a key like backspace or delete to remove the last shape that was added.
				</p>

				<p>
					Here's a working example you can compare: <a target="_blank" href="https://code.mortensoncreative.com/published/nilvh7ik">https://code.mortensoncreative.com/published/nilvh7ik</a>
				</p>
			</div>

			<p>
				{/* <PrefixedLink to="/lesson/arrays/2"><Button intent="primary">Continue</Button></PrefixedLink> */}
				&nbsp; <PrefixedLink to="/"><Button intent="primary">Home</Button></PrefixedLink>
			</p>
		</Layout>
	);
};

export { Arrays2Lesson }
