import * as React from 'react';
import { Layout } from './Layout';
import { useAppContext } from './AppContext';
import { UserDoc, getUserDocRef } from './model/userDoc';
import { Spinner } from 'sancho/esm/Spinner';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Button } from 'sancho/esm/Button';
import { IconPlus } from 'sancho/esm/Icons/icons/IconPlus';
import { Alert } from 'sancho/esm/Alert';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import { useCoursePrefix } from './lib/useCoursePrefix';
import { PrefixedLink } from './lib/PrefixedLink';
import { GearSpinner } from './lib/GearSpinner';

interface UserProjectsPageProps extends RouteComponentProps {}

const COURSE_ID = 'js-intro';

const UserProjectsPage = withRouter((props: UserProjectsPageProps) => {
	let prefix = useCoursePrefix();
	let context = useAppContext();
	let uid = context.auth.currentUser ? context.auth.currentUser.uid : '';

	let query = context.db.collection('user_docs').where('user_id', '==', uid).where('group', '==', 'user');

	// let result = useQuery<DocWithRef<UserDoc>>(context.db, 'user_docs', coll => coll.where('user_id', '==', uid).where('group', '==', 'user'), {withChanges: true });


	let [projects, projectsLoading, projectsError] = useCollectionData<UserDoc>(query, {idField: '_id'/* , snapshotListenOptions: {includeMetadataChanges: true} */});

	// React.useEffect(() => {
	projects && projects.sort((a, b) => (b.updated || 0) - (a.updated || 0));
	// }, [projects && projects.length]);

	const createProject = async () => {
		let docName = randomString(8);
		let docRef = getUserDocRef(COURSE_ID, uid, docName);
		let doc: UserDoc = {
			content: '// write your code here',
			course_id: COURSE_ID,
			user_id: uid,
			doc_name: docName,
			group: 'user',
			title: `Untitled Project`,
			updated: Date.now(),
			// for now, they are all canvas projects.
			type: 'canvas',
		};

		try {
			await docRef.set(doc);
			console.log('doc created');

			// navigate
			props.history.push(`${prefix}/user/project/${docName}`);
		}

		catch (e) {
			console.error('doc creation failed', e);
		}
	}

	let href = '';

	return (
		<Layout className="UserProjectsPage">
			<h1>Your Projects</h1>

			<p>These are your own free-form projects.  You can create and save them here.</p>

			<p><Button iconBefore={<IconPlus />} intent="primary" onPress={createProject}>Create a New Project</Button></p>

			<table className="lessonTable codeTable">
				<tbody>
					<tr>
						<td>Title</td>
						{/* <td>ID</td> */}
						<td>Description</td>
						<td>Last Updated</td>
						<td>Sharing</td>
						{/* Type */}
					</tr>

					{projectsLoading && <tr><GearSpinner center /></tr>}
					{projectsError && <tr><Alert intent="danger" title={projectsError.message} /></tr>}

					{projects && projects.map(doc => (
						href = `/user/project/${doc.doc_name}`,
						<tr key={doc.doc_name}>
							<td><PrefixedLink to={href}>{doc.title || 'Untitled Project'}</PrefixedLink></td>
							{/* <td><Link to={href}>{doc.doc_name}</Link></td> */}
							<td style={{whiteSpace: 'pre-line'}}>{doc.description}</td>
							<td>{new Date(doc.updated).toLocaleString()}</td>
							{/* <td>{doc.type}</td> */}
							<td>{doc.sharing && <Link to={`/published/${doc.doc_name}`}>Yes</Link>}</td>
						</tr>
					))}
				</tbody>
			</table>


			{/* {result.loading && <GearSpinner center />}

			{result.value && result.value.map(doc => (
				doc.do
			))} */}
		</Layout>
	)
});

function randomString(len: number) {
	let str = ''
	while (str.length < len) {
	 str += (Math.random() * 36 | 0).toString(36)
	}
	return str
}

export { UserProjectsPage, UserProjectsPageProps }
