import { CanvasInterface } from "./lib/canvasWrapper";

var LEVELS = [
    // mine
    '######\n#@ $.#\n######',
    '#########################\n#@          $          .#\n#########################',
    '   ####\n####  #\n#  @  ##\n# $##  #\n#  #.  #\n########',
    '#######\n#     #\n# $#+ #\n#     #\n#     #\n#######',
    // borrowed
    '######\n#@   #\n# $ $#\n#   .#\n# $..#\n######',
    '  ####\n  #  #\n###  ###\n# $*.@ #\n#      #\n########',
    ' #######\n## @   #\n# $.   #\n# .$####\n##  #\n #  #\n ####',
    ' ######\n #    #\n##$ . #\n#  @  #\n# $#. #\n#  ####\n####', // not bad
    '  ####\n  #  #\n###  #\n#   @##\n# *$. #\n###   #\n  #####',
    '  ######\n  #    #\n###    #\n#   @###\n# *$.#\n###  #\n  ####',
    '  ####\n###  #\n#  *@#\n#  $.##\n###   #\n  #   #\n  #####', // hard-ish
    '####\n#  #####\n# .  $ #\n# $  .@#\n###  ###\n  ####', //hard
    '  ####\n  #  #\n  #  #\n### @##\n# *$. #\n#     #\n##  ###\n ####', // last
];

function sokobanDemo(canvasInterface: CanvasInterface/* , console: Console */) {
    let canvas = canvasInterface.wrapper;

    let { whenKeyPressed, whenMouseClicked } = sokobanRunner(canvasInterface, {
        drawBackground,
        drawWall,
        drawPlayer,
        drawGoal,
        drawBox,
        drawEmpty,
        drawStats,
    }, console);

    canvasInterface.handlers.onKeyPressed = whenKeyPressed;
    canvasInterface.handlers.onMouseClicked = whenMouseClicked;

    function drawBackground() {
        canvas.background('#eee');
    }

    function drawWall(x, y, boxSize) {
        canvas.fill('black');
        canvas.rect(x, y, boxSize, boxSize);
    }

    function drawPlayer(x, y, boxSize) {
        drawEmpty(x, y, boxSize);
        var r = boxSize / 2;
        canvas.fill('red');
        canvas.ellipse(x + r, y + r, boxSize * .9);
    }

    function drawGoal(x, y, boxSize) {
        drawEmpty(x, y, boxSize);
        var r = boxSize / 2;
        canvas.fill('gold');
        canvas.ellipse(x + r, y + r, r);
    }

    function drawBox(x, y, boxSize, isOnGoal) {
        canvas.fill(isOnGoal ? 'green' : 'blue');
        canvas.rect(x, y, boxSize, boxSize);
    }

    function drawEmpty(x, y, boxSize) {
        canvas.fill('white');
        canvas.rect(x, y, boxSize, boxSize);
    }

    function drawStats(moveCount, pushCount, isWinner, currentLevel) {
        canvas.fill('#888');
        canvas.textAlign('center');
        canvas.text('L:' + currentLevel, 200, 30);

        if (isWinner) {
            canvas.text('You win! Press Enter to continue', 200, 390);
        }
        else {
            canvas.text('Press Enter to reset', 200, 390);
        }
        canvas.textAlign('left');
        canvas.text('Moves: ' + moveCount, 10, 30);
        canvas.textAlign('right');
        canvas.text('Pushes: ' + pushCount, 390, 30);
    }
}

interface SokobanProjectExports {
    drawPlayer(x: number, y: number, squareSize: number, isOnGoal?: boolean): void
    drawWall(x: number, y: number, squareSize: number): void
    drawGoal(x: number, y: number, squareSize: number): void
    drawEmpty(x: number, y: number, squareSize: number): void
    drawBox(x: number, y: number, squareSize: number, isOnGoal: boolean): void
    drawStats(moveCount: number, pushCount: number, isWinner: boolean, currentLevel: number): void
    drawBackground(): void
    getCustomLevels?(): string[]
}

function sokobanRunner(canvasInterface: CanvasInterface, exported: SokobanProjectExports, console: Console) {
    const canvas = canvasInterface.wrapper;
    // canvasInterface.handlers.onKeyPressed = whenKeyPressed;

    const warnOnce = (msg: string) => {
        let warned = false;
        return () => {
            if (!warned) {
                console.warn(msg);
                warned = true;
            }
        }
    }

    var drawPlayer = exported.drawPlayer || warnOnce('You need to create a drawPlayer() function!')
    var drawWall = exported.drawWall || warnOnce('You need to create a drawWall() function!')
    var drawGoal = exported.drawGoal || warnOnce('You need to create a drawGoal() function!')
    var drawBox = exported.drawBox || warnOnce('You need to create a drawBox() function!')
    var drawStats = exported.drawStats || warnOnce('You need to create a drawStats() function!')
    var drawEmpty = exported.drawEmpty || warnOnce('You need to create a drawEmpty() function!')
    var drawBackground = exported.drawBackground || warnOnce('You need to create a drawBackground() function!')
    // var drawInstructions = exported.drawInstructions || warnOnce('You need to create a drawInstructions() function!')

    // var noInstructions = !exported.drawInstructions;

    // will never be checked for in a single player game
    var PLAYER = -2;
    var PLAYER_ON_GOAL = -1;

    // box can be pushed here
    var EMPTY = 0;
    var GOAL = 1;

    //player can move here; box can't
    var BOX = 2;
    var BOX_ON_GOAL = 3;

    //blocked
    var WALL = 4;

    var board;
    var pushes;
    var moveCount;

    var playerX = 0, playerY = 0;
    // window.playerX = 0, window.playerY = 0;
    var currentLevel = 0;
    var won = false;
    var started = false;

    var levels: string[];
    if (typeof exported.getCustomLevels == 'function') {
        levels = exported.getCustomLevels() || LEVELS;
    }
    else {
        levels = LEVELS;
    }

    drawInstructions();
    // if (noInstructions) {
    //     beginLevel(levels[currentLevel]);
    // }

    return { whenKeyPressed, whenMouseClicked }

    function beginLevel(newBoard) {
        won = false;
        pushes = [];
        moveCount = 0;
        board = boardFromString(newBoard);
        for (var row = 0; row < board.length; row++) {
            var col = board[row].indexOf(PLAYER);
            if (col >= 0) {
                playerX = col;
                playerY = row;
                break;
            }
            col = board[row].indexOf(PLAYER_ON_GOAL);
            if (col >= 0) {
                playerX = col;
                playerY = row;
                break;
            }
        }

        // printBoard(board);
        drawBoard(board);
    }

    function whenKeyPressed(key) {
        if (!started) return;
        if (key == 'left') {
            attemptMove(playerX, playerY, -1, 0);
        }
        else if (key == 'right') {
            attemptMove(playerX, playerY, 1, 0);
        }
        else if (key == 'up') {
            attemptMove(playerX, playerY, 0, -1);
        }
        else if (key == 'down') {
            attemptMove(playerX, playerY, 0, 1);
        }
        else if (key == 'enter') {
            if (won) {
                currentLevel = (currentLevel + 1) % levels.length;
            }
            beginLevel(levels[currentLevel]);
        }
        else if (key == 'u') {
            undo();
        }
        else if (key == 'j') {
            var level = +prompt('Which level?') - 1;
            if (levels[level]) {
                currentLevel = level;
                beginLevel(levels[currentLevel]);
            }
        }
    }

    function whenMouseClicked() {
        started = true;
        if (won) {
            currentLevel = (currentLevel + 1) % levels.length;
        }
        beginLevel(levels[currentLevel]);
    }

    function boardFromString(string) {
        // string = string.trim();
        var charMap = {
            '#': WALL,
            '@': PLAYER,
            '.': GOAL,
            ' ': EMPTY,
            '+': PLAYER_ON_GOAL,
            '*': BOX_ON_GOAL,
            '$': BOX,
        };

        var board = string.split('\n').map(row =>
            row.split('').map(char => charMap[char] == undefined ? WALL : charMap[char])
        );

        return board;
    }

    function attemptMove(startX, startY, moveX, moveY) {
        var nextX = startX + moveX;
        var nextY = startY + moveY;

        var nextCell = board[nextY][nextX];

        var moved = false;

        if (nextCell < BOX) {
            // move player off of current space
            // PLAYER becomes EMPTY
            // PLAYER_ON_GOAL becomes GOAL
            board[startY][startX] += 2;
            // move player on to next space
            // EMPTY becomes PLAYER
            // GOAL becomes PLAYER_ON_GOAL
            board[nextY][nextX] -= 2;
            moved = true;
        }
        else if (nextCell < WALL) {
            // it's a box. check what's behind it.
            var behindX = nextX + moveX;
            var behindY = nextY + moveY;

            var behindCell = board[behindY][behindX];
            if (behindCell < BOX) {
                // move is possible.

                pushes.push([
                    startX, startY, moveX, moveY,
                    // startX, startY, board[startY][startX],
                    // nextX, nextY, board[nextY][nextX],
                    // behindX, behindY, board[behindY][behindX],
                ]);

                //move player off
                board[startY][startX] += 2;
                // move player on to next space
                // BOX becomes PLAYER
                // BOX_ON_GOAL becomes PLAYER_ON_GOAL
                board[nextY][nextX] -= 4;
                // EMPTY becomes BOX
                // GOAL becomes BOX_ON_GOAL
                board[behindY][behindX] += 2;
                moved = true;

                if (board[behindY][behindX] == BOX_ON_GOAL) {
                    checkForWin(board);
                }
            }
        }

        if (moved) {
            playerX += moveX;
            playerY += moveY;
            moveCount++;
        }

        // printBoard(board);
        drawBoard(board);
    }

    function undo() {
        var move = pushes.pop();
        if (!move) return;
        var startX = move[0];
        var startY = move[1];
        var moveX = move[2];
        var moveY = move[3];

        var nextX = startX + moveX;
        var nextY = startY + moveY;
        var behindX = nextX + moveX;
        var behindY = nextY + moveY;

        //move player to position after move
        if (playerX != nextX || playerY != nextY) {
            //move player off current position
            board[playerY][playerX] += 2;
            board[nextY][nextX] -= 2;
        }

        // now reverse the push
        // player to the start space
        board[startY][startX] -= 2;
        // box on to next space
        board[nextY][nextX] += 4;
        // empty the last space
        board[behindY][behindX] -= 2;

        playerX = startX;
        playerY = startY;

        checkForWin(board);

        drawBoard(board);
    }

    function checkForWin(board) {
        for (var row = 0; row < board.length; row++) {
            for (var col = 0; col < board[row].length; col++) {
                var cell = board[row][col];
                if (cell == BOX || cell == GOAL) {
                    return false;
                }
            }
        }

        won = true;
        return true;
    }

    function drawBoard(board) {
        drawBackground();

        var boardHeight = board.length;
        var boardWidth = 0;
        for (var row = 0; row < board.length; row++) {
            if (board[row].length > boardWidth) {
                boardWidth = board[row].length;
            }
        }

        var squareWidth = Math.min(40, 400 / boardHeight, 400 / boardWidth);
        var xOffset = (400 - squareWidth * boardWidth) / 2;
        var yOffset = (400 - squareWidth * boardHeight) / 2;

        for (var row = 0; row < board.length; row++) {
            var outside = true;
            for (var col = 0; col < board[row].length; col++) {
                var cell = board[row][col];
                if (outside && cell == EMPTY) {
                    continue;
                }
                else {
                    outside = false;
                }

                var x = col * squareWidth + xOffset;
                var y = row * squareWidth + yOffset;

                if (cell == WALL) {
                    drawWall(x, y, squareWidth);
                }
                else if (cell == BOX_ON_GOAL) {
                    drawBox(x, y, squareWidth, true);
                }
                else if (cell == BOX) {
                    drawBox(x, y, squareWidth, false);
                }
                else if (cell == GOAL) {
                    drawGoal(x, y, squareWidth);
                }
                else if (cell < EMPTY) {
                    drawPlayer(x, y, squareWidth, cell == PLAYER_ON_GOAL);
                }
                else {
                    drawEmpty(x, y, squareWidth);
                }
            }
        }

        drawStats(moveCount, pushes.length, won, currentLevel + 1);
    }


    function drawInstructions() {
        var firstRow = 75, spacing = 30, row = 0;
        canvas.textAlign('center');
        canvas.fill('black');
        canvas.text('Use the arrow keys to move', 200, firstRow + (row++ * spacing));
        canvas.text('the boxes to the goal spaces.', 200, firstRow + (row++ * spacing));
        row++;
        canvas.text('If you get stuck,', 200, firstRow + (row++ * spacing));
        canvas.text('press Enter to reset.', 200, firstRow + (row++ * spacing));
        row++;
        canvas.text('Press J to jump', 200, firstRow + (row++ * spacing));
        canvas.text('to any level.', 200, firstRow + (row++ * spacing));
        row++;
        canvas.text('Click to begin.', 200, firstRow + (row++ * spacing));
    }
}

export { sokobanDemo, sokobanRunner, SokobanProjectExports }
