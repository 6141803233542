import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { c } from './lib/classNames';
import { CodeBox } from './CodeBox';
import { PrefixedLink } from './lib/PrefixedLink';
import { Button } from 'sancho/esm/Button';

type Operation = 'add' | 'subtract' | 'multiply' | 'divide';
type CalculateFunc = (num1: number, num2: number, operation: Operation) => number;

const SimpleCalculatorProject: React.FC = () => {
	let [calculate, setCalculate] = React.useState<CalculateFunc>(null);
	let [fakeConsole, setFakeConsole] = React.useState<Console>(null);
	let [num1, setNum1] = React.useState('');
	let [num2, setNum2] = React.useState('');
	let [result, setResult] = React.useState<string | number>('');
	let [ready, setReady] = React.useState(false);

	let [error, setError] = React.useState('');
	let [operation, setOperation] = React.useState<Operation>(undefined);
	let [operator, setOperator] = React.useState('');

	let valid = /* operation && */ (+num1).toString() == num1 && (+num2).toString() == num2;

	let clickOperation = (e: React.MouseEvent<HTMLButtonElement>) => {
		let operation = e.currentTarget.dataset.op as Operation;
		setOperation(operation);
		setOperator(e.currentTarget.dataset.oper);

		clickCalculate(operation);
	}

	const clickCalculate = (operation: Operation) => {
		if (!ready) {
			setError("You haven't run your code yet! Click Run Code in the editor.")
			return;
		}

		if (typeof calculate != 'function') {
			setError(<>you need to create a function called <code>calculate</code> for this button to call</> as any);
			fakeConsole.error('you need to create a function called calculate for this button to call');
			return;
		}

		if (!valid) {
			setError('You must enter two numbers first.');
			fakeConsole.error('You must enter two numbers first.');
			return;
		}

		try {
			let _result = calculate(+num1, +num2, operation);
			if (_result === undefined) {
				setResult('');
				setError('your function did not return a value');
			}
			else {
				setResult(_result);
				setError('');
			}
		}
		catch (e) {
			fakeConsole.error(e.messsage);
			setError('error!');
			setResult('');
		}
	}

	return <ProjectPage
		pageContent={<>
			<h1>Simple Calculator Project</h1>

			<p className="intro">
				In this project, you will make this simple calculator work.
				I'll walk you through it and help you step by step.
				There are hints in the boxes – as always, try to do it yourself
				first without the hints if you can.
			</p>

			<style>{`
				.calculator {
					background: #3B393B;
					color: white;
					padding: 30px;
					font-family: 'Roboto Mono';
				}
				.calculator button {
					font-size: 24px;
					color: white;
					margin: 5px;
					border-width: 0px;
					// width: 57px;
					// height: 47px;
					// font-weight: 300;
					display: inline-block;
					background: #696A69;
					border-radius: 3px;
				}
				.calculator button.active {
					background: #ffa600;
				}
				.calculator input {
					font-size: 24px;
					font-family: 'Roboto Mono';
					width: 4em;
				}
				.calculator .buttons, .calculator .numbers {
					padding: 5px;
				}
				.calculator .operator {
					font-size: 24px;
					display: inline-block;
					min-width: 1em;
					text-align: center;
				}
				`}
			</style>
			<div className={c("calculator", !ready && 'disabled')}/*  onSubmit={clickCalculate} */>
				<div className="numbers">
					{/* First Number:  */}<input value={num1} onChange={e => setNum1(e.target.value)} type="text"/>
					<span className="operator">{operator}</span>
					{/* Second Number:  */}<input value={num2} onChange={e => setNum2(e.target.value)} type="text"/>
					<span className="operator">=</span>
					<span className="operator">{result}</span>
					{/* &nbsp; <button type="submit" /* disabled={!valid} * / onClick={clickCalculate}>Calculate</button> */}
				</div>
				<div className="buttons">
					<button className={c(operation == 'add' && 'active')} data-op="add" data-oper="+" onClick={clickOperation} >+ Add</button>
					<button className={c(operation == 'subtract' && 'active')} data-op="subtract" data-oper="&minus;" onClick={clickOperation} >&minus; Subtract</button>
					<button className={c(operation == 'multiply' && 'active')} data-op="multiply" data-oper="&times;" onClick={clickOperation} >&times; Multiply</button>
					<button className={c(operation == 'divide' && 'active')} data-op="divide" data-oper="&divide;" onClick={clickOperation} >&divide; Divide</button>
				</div>
				{/* <p className="result">{result}</p> */}
				<p className="error">{error}</p>
			</div>

			<p>
				To do it, you will need to make use of functions, if statements, and math operators.
				If you are feeling shaky about your understanding of functions or if statements, you
				might want to refresh your knowledge in one these lessons:
			</p>

			<p>
				<PrefixedLink to="/lesson/functions">Functions lesson</PrefixedLink><br/>
				<PrefixedLink to="/lesson/if-statements">If Statements lesson</PrefixedLink><br/>
				<PrefixedLink to="/lesson/math">Math lesson</PrefixedLink><br/>
			</p>

			<h2 className="hGreen">Let's build it - Step 1</h2>
			<p>
				First, you will need to define a function called calculate.
				This function will be called when you click the calculate button on the calculator.
				I suggest you put a <code>console.log</code> statement inside so you can see when it's being run.
			</p>

			<p>This function should return the result of your calculation, and then the calculator will display it.</p>

			<CodeBox clickToShow code={`
				function calculate() {
					console.log('calculating');
				}
			`}/>

			<p>
				When <code>calculate</code> gets called, it will be passed three parameters:
				the first number, the second number, and the math operation, which could be
				either <code>"add"</code>, <code>"subtract"</code>, <code>"multiply"</code>, or <code>"divide"</code>.
			</p>

			<p>
				{/* They will be passed in that order - the first number comes first, second number comes second */}
				To use them you'll need to define those parameters in your function.
				It's the order, not the names of the parameters matters,
				so if you can call them <code>kip</code>, <code>tina</code>, and <code>pedro</code> if you like, but I recommend
				calling them <code>num1</code>, <code>num2</code>, and <code>operation</code> so your code will make sense.
			</p>

			<CodeBox clickToShow code={`
				function calculate(num1, num2, operation) {
					console.log('calculating', num1, num2, operation)
				}
			`}/>

			<p>
				If you try running your code now, you should be able to see if you enter both numbers,
				click add/subtract/multiply/divide, and click calculate, your function will be called and log something to the console.
				If that's working, you're ready to move to the next step.
			</p>

			<h2 className="hGreen">Step 2</h2>

			<p>
				What you do with the two numbers inside the function depends on which operation you're doing,
				so you'll have to use if statements to decide what to do.
			</p>

			<p>
				For example, if the operation is "add", I'll want to add the two numbers together,
				so I'll want to compare <code>operation</code> and <code>"add"</code>, and
				if they are equal, add the numbers.
			</p>

			<p>
				So in total, you probably want four if statements, one for each operation.
			</p>

			<CodeBox clickToShow code={`
				// here's what the add one could look like.
				if (operation == "add") {
					// add the numbers and return the sum
					var sum = num1 + num2;
					return sum;
				}
			`}/>

			<p>
				One key to writing real programs is to test your code often.
				Try writing the code to make addition work, then run it and test it.
			</p>

			<p>
				If it works, move on to subtraction, then run and test it.
				Continue like that until you have done all four.
			</p>

			<h2></h2>

			<p>
				<PrefixedLink to=""><Button intent="primary">Done</Button></PrefixedLink>
			</p>
		</>}
		useCodeRunner
		codeRunnerProps={{
			docName: 'simple-calc',
			code: '// write some code here',
			exports: ['calculate'],
			beforeRun: (source, console) => {
				setFakeConsole(console);
			},
			afterRun: (exported) => {
				setCalculate(() => exported.calculate);
				setReady(true);
			},
			// extraLibs: canvasTypedef,
		}}
	/>
}

export { SimpleCalculatorProject }
