import * as React from 'react';
import { Layout } from './Layout';
import { PrefixedLink } from './lib/PrefixedLink';
import { CanvasRunner } from './lib/CanvasRunner';
import { PagedContent } from './lib/PagedContent';
import { CodeBox } from './CodeBox';
import { Collapse } from './lib/Collapse';

const GameLoopLesson = () => {
	const firstExample = '';

	return (
		<Layout narrow>
			<h1>Animating with canvas.animate()</h1>

			{/* ## Things to review:

			- how functions work
			- how if statements work */}

			<PagedContent pages={[
				<>
					<p className="intro">
						So far, all of your animations have used mouse clicks or key presses
						to update and draw the updated image.  But sometimes you need things to
						happen on their own.
						<br/>

						In this lesson, we'll learn how to make that happen.
					</p>
{/*
					<h2 className="hGreen">First, let's review</h2>

					<p>
						Here's a simple program. Look it over, and try to figure out exactly what it does.
						After you have a guess, run the code and see if you were right. (You will want to press a key.)
					</p>

					<CanvasRunner code={`
						var textX = 0;

						function drawLoop() {
							textX += 5;
							if (textX > 400) {
								textX = -100;
							}

							canvas.background('white');
							canvas.text('hello', textX, 210);
						}

						function whenKeyPressed(key) {
							drawLoop();
						}

					`} />

					<p>
						This program draws "hello" on the screen. Every time you press a key, the word moves to the right
						across the screen.
						If it goes all the way off the screen, it will jump back to the left side,
						 so it looks like it's looping around.
					</p>

					<p>
						But maybe we want it to be able to move on its own, smoothly, without having to press any buttons.
						For that to happen, we need a way to draw something
					</p> */}

					<p>
						Let's say you want to make a simple animation: An asteroid floating through space.
						You can press keys to make the asteroid move across the screen.
						Here's how that might look (click the canvas, then try pressing keys):
					</p>

					<CanvasRunner vertical canvasHeight={250} editorHeight={450} code={`
						var asteroidX = 50;
						var asteroidY = 200;

						drawLoop();

						function drawLoop() {
							// update
							asteroidX += 5;
							if (asteroidX > 450) {
								asteroidX = -50;
							}

							// draw
							canvas.background('black');
							canvas.fill('#ff0044');
							canvas.ellipse(asteroidX, asteroidY, 50);
						}

						function whenKeyPressed(key) {
							drawLoop();
						}
					`} />


					<p>
						But that's kind of jumpy for an asteroid in space, isn't it?
						And it's only moving when you make it move.
						For the asteroid to really seem like it's floating, it needs
						to move smoothly, all on its own.
					</p>

					<p>
						The way this is done is by automatically updating the asteroid's position and drawing a new image every so often.
						Each image is called a <strong>frame</strong>.  When you draw frames quickly enough,
						it starts to look smooth, like real motion, rather than just a bunch of distinct pictures.
					</p>

					<h2 className="hBlue">Introduction <code>canvas.animate()</code></h2>

					<p>
						There's a way to draw images repeatedly on the canvas.
						The function <code>canvas.animate()</code> lets you call the same function
						periodically, many times per second.  Here's how it works:
						{/* You give it a function to run, and it will run it repeatedly. */}
					</p>

					<CanvasRunner vertical canvasHeight={250} editorHeight={370} code={`
						var asteroidX = 50;
						var asteroidY = 200;

						canvas.animate(drawLoop);

						function drawLoop() {
							// update
							asteroidX += 2.5;
							if (asteroidX > 450) {
								asteroidX = -50;
							}

							// draw
							canvas.background('black');
							canvas.fill('#ff0044');
							canvas.ellipse(asteroidX, asteroidY, 50);
						}
					`} />

					{/* <CanvasRunner code={`
						var textX = 0;

						function drawLoop() {
							textX += 5;
							if (textX > 400) {
								textX = -100;
							}

							canvas.background('white');
							canvas.text('hello', textX, 210);
						}

						canvas.animate(drawLoop);
					`} />

					<p>
						Let's break that down. We define a variable <code>textX</code>,
						which we will use to position the text horizontally.
						Then we define a function called <code>drawLoop</code>,
						and in that function we do a couple things:
					</p>

					<ul>
						<li>Move the text position five pixels to the right</li>
						<li>If the text has scrolled off the right side, reset it so it's off to the left side</li>
						<li>Draw the text in its updated position</li>
					</ul>
 */}
					<p>
						You call <code>canvas.animate()</code> with a function that draws your image.
						But if your function draws the exact same image every time, then it won't be an animation –
						it will just look like a normal still image, even though it's being drawn repeatedly.
					</p>

					<p>
						This is something new that you haven't seen before: passing a function to another function.
						When you call <code>canvas.animate()</code> with your function, you
						share your function with the canvas,
						and ask the canvas to call your function over and over.
					</p>

					<p>
						So now, instead of using <code>whenKeyPressed</code> to update and redraw,
						we use <code>canvas.animate()</code> instead,
						and <code>canvas.animate()</code> calls our function over and over again,
						quickly enough that the animation becomes buttery smooth.
					</p>

					<p>
						Take another look at the example above.
						What causes the image to be different each times it's drawn?
					</p>

					<p>
						Notice that there are two types of things happening in the function:
						First, the variable <code>asteroidX</code> gets updated.
						Second, the image is redrawn, and notice that <code>asteroidX</code> is
						used in drawing the image.  Because <code>asteroidX</code> is different
						each time the function runs, the image is different as well.
					</p>

					{/* <div className="blRed">
						<p>
							<strong>Note:</strong> This is key to drawing animations.
							If you are trying to animate something, but nothing is changing,
							then one of those steps isn't happening.
							Ask yourself:
						</p>
						<ul>
							<li>Is my drawing code being called multiple times?</li>
							<li>Are there variables that are changing in between draws?</li>
							<li>Is the drawing code making use of the variables that are changing?</li>
						</ul>
					</div> */}

				</>,<>
					<h2 className="hGreen">Practice</h2>

					<p>
						Let's practice.  But first, remember the things in the last example that made it work:
					</p>

					<ul>
						<li>It had variables that kept track of position of thing(s) being drawn on the screen.</li>
						<li>It used canvas.animate() to call a function over and over.</li>
						<li>Each time the function ran, it updated some variables.</li>
						<li>After the variables were updated, the whole screen was drawn over again.</li>
						<li>When the screen was drawn, it used those updated variables to draw things in a different position.</li>
					</ul>

					<p>
						Okay, practice time.  Here's a program that is incomplete.
						It's supposed to have a ball bounce around the screen, but it doesn't quite work yet.
						Can you figure out what's missing, and fix it?
					</p>

					<CanvasRunner console vertical canvasHeight={250} editorHeight={680} docName="animate2.bouncyBall" code={`
						var ballX = 200;
						var ballY = 200;
						var ballXDir = 3;
						var ballYDir = 5;

						canvas.animate(drawLoop);

						function drawLoop() {
							// update
							ballX += ballXDir;
							ballY += ballYDir;

							if (ballX > 375) {
								ballXDir = -3;
							}
							else if (ballX < 25) {
								ballXDir = 3;
							}
							if (ballY > 375) {
								ballYDir = -5;
							}
							else if (ballY < 25) {
								ballYDir = 5;
							}

							// draw
							canvas.background('white');
							canvas.fill('blue');
							canvas.ellipse(200, 200, 50);
						}
					`} />

					<p>
						Once it's fixed, the ball will bounce around on the screen.
						Click 'Next' when you have finished.
					</p>

					<Collapse collapsed title="Help!">
						<p>
							If you get stuck, first go over the example on the last page and the description
							of how it works.  Then look at this program.  Which of those important steps is missing?
						</p>

						<p>
							If you're not sure, try to go through the list above.  How can you determine where the problem is?
						</p>

						<Collapse collapsed title="Still stuck?">
							<p>
								First, if you have made changes that didn't work, hit 'Reset' to get the code back to how it started.
							</p>

							<p>
								What is this line of code at the very end going to do every time it's drawn?
							</p>

							<CodeBox code="canvas.ellipse(200, 200, 50);" />

							<p>
								You need to change it so it draws something different each time.  Which variables can you use instead?
							</p>


							{/* <Collapse collapsed title="I give up!">
								<p>
									When drawing the circle,
									use the variables <code>ballX</code> and <code>ballY</code> instead
									of <code>200</code> and <code>200</code>.
								</p>
							</Collapse> */}

						</Collapse>

					</Collapse>
				</>,<>

					{/* <p>
						You need to have a few things for canvas.animate() to work:

					</p>

					<ul>
						<li>global variables that keep track of something that moves or changes over time.</li>
						<li>You must call canvas.animate() with a function that updates and draws an updated image on the screen</li>
						<li>Your function must produce different images over time. You can do this by using your  </li>
					</ul> */}

					{/* <h2 className="hBlue">The Game Loop</h2>

					<p>
						The concept of having a function to update your game state, then
						draw the new frame over and over, is a pattern that is used in many games,
						and is referred to as the <strong>game loop</strong>.
						Games like Fortnite and Minecraft are programmed this same way – though the code
						doing the updating and drawing is much more complicated.
					</p>

					<p>
						But with this pattern, you can create games that are smooth and responsive.
					</p> */}



					{/* <p>
						Let's see an example of what that looks like:
					</p> */}

					{/* // run the myAnimation function at 20 frames per second forever.
						canvas.animate(myAnimation, 20);
						// run the myAnimation function at 5 frames per second for 10 frames.
						canvas.animate(myAnimation, 5, 10); */}

					{/* <p>
						As you can see, there are three ways to use it.
						This function has what are called <strong>optional parameters</strong>.
						You can use them if you need them.
						Kind of like how <code>canvas.ellipse(200, 200, 50)</code> draws a circle
						that is 50 pixels across,
						but <code>canvas.ellipse(200, 200, 40, 50)</code> draws an ellipse
						(stretched circle) that is 40 wide and 50 high.
					</p>

					<p>
						The second parameter, which is optional, is the frame rate. If you pass it,
						your function will be called about that many times per second. If you want to
					</p>

					<p>
						If you don't specify the frame rate, you'll
					</p>
 */}


					<h3 className="hGreen">Your Turn</h3>

					<p>
						Now it's your turn to program your own smooth animation.
						Your program should:
					</p>

					<ul>
						<li>Have one or more global variables {/* <PrefixedLink to="/lesson/animation">global variables</PrefixedLink> */} for keeping track of things that you move on the screen</li>
						<li>Have a function that updates those variables, then draws on the screen.</li>
						<li>Use <code>canvas.animate()</code> to call a your updating/drawing function.</li>
						<li>Use your global variables to draw something.</li>
					</ul>

					<CanvasRunner console vertical canvasHeight={250} editorHeight={680} docName="animate2.ownAnimation" code={`
						// define one or more variables here
						// at least have something to represent the x and/or y position
						// of something you want to draw


						// define your function that updates and draws here.
						// update first, then draw.

						// use canvas.animate to start a loop that calls your function repeatedly.
						// if your function is called updateAndDrawStuff,
						// then \`canvas.animate(updateAndDrawStuff)\` would start the loop,
					`} />

					<p>Click 'Next' once your animation is complete.</p>
				</>,<>
					<h3 className="hGreen">Nicely done!</h3>

					<p>
						You now have the tools to make smooth, fast animations that happen on their own.
					</p>


					{/* <p>
						The next step is to learn how to make your animations respond immediately to keys
						when they are held down.  That lesson is a work in progress
					</p> */}


					{/* <p>
						If you're not sure how to do some of this, please look over the asteroid example again.
						Have questions? Ask for help! The whole point of this class is to help you learn.
						Send an <a href="mailto:bgmort@gmail.com">email</a> if you are working on something at home and you get stuck.
					</p> */}

					{/* <h2 className="hOrange">Avoid common mistakes</h2>
					<p>
						There are some common mistakes you might run into along the way. Here are some of them:
					</p>

					<p>
						masking variables


						<code>var </code>

					</p> */}
				</>,
			]} />

			<p>
				&nbsp;
			</p>

			{/* <p>
				<PrefixedLink to="/"><Button intent="none">Home</Button></PrefixedLink>
				&nbsp; <PrefixedLink to="/lesson/and-or/2"><Button intent="primary">Continue</Button></PrefixedLink>
			</p> */}
		</Layout>
	);
};

export { GameLoopLesson }
