import * as React from 'react';
import { ProjectPage } from './ProjectPage';
import { CodeBox, Snippet } from './CodeBox';
import { Button } from 'sancho/esm/Button';
import { Link } from 'react-router-dom';
import { useCoursePrefix } from './lib/useCoursePrefix';

const madLibsCode2 =
`/*
* Mad Libs - part 2
*/

// change 'Jedi' to 'boy', 'girl', 'rock star', or anything you like.
var person = 'Jedi';
var name = ''; // Type your name in between the ''
// you'll need to create some more variables:
// var adjective = 'exquisite';


// Don't change this part!

console.log('Once there was a ' + person + ' named ' + name + '.');
console.log(name + ' was a very ' + adjective + ' ' + person + '.');
console.log('One day ' + name + ' went to see the king, King ' + sillyName + '.');
console.log('"Oh great King ' + sillyName + ', what is the secret to your success?" said ' + name + '.');
console.log('Listen, ' + name + ', you must always remember this:');
console.log('Never put bad ' + foodIngredient + ' on a good ' + foodType + '.');`;


const MadLibsProject2: React.FC = () => {
	const [frameApi, setFrameApi] = React.useState<any>(null);

	return <ProjectPage
		pageContent={<PageContent frameApi={frameApi} />}
		monacoProps={{
			initialCode: madLibsCode2,
			storageKey: 'madLibs2',
			docName: 'madLibs2',
			afterInit: (api) => {
				(window as any).frameApi = api;
				setFrameApi(api);
			},
		}}
	 />
}

interface PageContentProps {
	frameApi: {
		updateCode(code: string, run?: boolean): void
	}
}

const PageContent: React.FC<PageContentProps> = (props) => {
	const prefix = useCoursePrefix();

	return <>
		<h2>Mad Libs</h2>

		<p>
			Our mad lib story is not very exciting so far.  Let's make it better.
			I've added a few more lines to our story, but you'll need to create some more variables to get it working again.
			Can you tell which variables need to be created?
		</p>

		<p>Try running the code again.  Each time you get a <Snippet>ReferenceError</Snippet>, fix it by defining the missing variable and giving it a value.</p>


		<h2>Bonus</h2>
		<p>What happens when you define a variable, but don't give it a value, like this?</p>

		<CodeBox code="var sillyName;" />

		<p>What do you think <Snippet>undefined</Snippet> means?</p>



		<Link to={`${prefix}/project/mad-libs`}>
			<Button intent="primary" /* onPress={() => props.frameApi.updateCode(madLibsCode2)} */>Back</Button>
		</Link>
		{} &nbsp; {}
		<Link to={prefix}>
			<Button intent="primary" /* onPress={() => props.frameApi.updateCode(madLibsCode2)} */>Finished</Button>
		</Link>
	</>;
}


export { MadLibsProject2 }
