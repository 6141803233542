import { Instrument } from './Instrument';
import { Drums } from './Drums';
import { noteNumber, noteFrequency } from './musicLib';
import { musicTypeDef } from './musicTypeDef';

let context: AudioContext = null;

function createInstrument(wave?: OscillatorType, volume?: number) {
	return new Instrument(getContext(), wave, volume);
}

function createDrums(volume?: number) {
	return new Drums(getContext(), volume);
}

function getContext(): AudioContext {
	return context || (context = new(window.AudioContext || (window as any).webkitAudioContext)());
}

function stopAll() {
	if (context) {
		// context.suspend()
		context.close();
		context = null;
	}
}

const Music = {
	createInstrument,
	createDrums,
	getContext,
	noteNumber,
	noteFrequency,
	stopAll,
}

type Music = typeof Music;

export { Music, musicTypeDef }
