import * as React from 'react'
import { LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from 'sancho/esm/Button';
import { PrefixedLink } from './PrefixedLink';

type LinkButtonProps = LinkProps & {
	buttonProps?: Partial<ButtonProps>
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
	let { children, buttonProps, ...linkProps } = props;
	return <PrefixedLink {...linkProps}><Button intent="primary" {...buttonProps}>{children}</Button></PrefixedLink>
}
