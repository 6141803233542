import * as React from 'react';
import { Button } from 'sancho/esm/Button';
import { TextArea, InputGroup, TextAreaProps } from 'sancho/esm/Form';
import { useUserDoc } from '../model/userDoc';
import { Layout } from '../Layout';
import { useCoursePrefix } from './useCoursePrefix';
import { Link } from 'react-router-dom';

// use this elsewhere
const COURSE_ID = 'js-intro';

const Question: React.FC<{}> = (props) => (
	<div className="question">{props.children}</div>
);

interface AnswerProps {
	id: string
	textAreaProps?: TextAreaProps
}
const Answer: React.FC<AnswerProps> = (props) => {
	let [initialized, setInitialized] = React.useState(false);
	let [text, setText] = React.useState('');
	let answerEnabled = !!text;
	let [showAnswer, setShowAnswer] = React.useState(false);

	let [userDoc, updateDoc, docLoading, docError] = useUserDoc(COURSE_ID, props.id);

	React.useEffect(()=> {
		if (!initialized && userDoc) {
			setText(userDoc.content);
			setInitialized(true);
		}
	});

	return <div className="answerBox">
		<InputGroup label="">
			<TextArea value={text} onChange={e => setText(e.target.value)} onBlur={e => updateDoc(e.target.value)} />
		</InputGroup>
		<InputGroup label="">
			<Button intent="primary" disabled={!answerEnabled} onPress={() => setShowAnswer(!showAnswer)}>Show Answer</Button>
		</InputGroup>
		{showAnswer && <InputGroup label="">
			<div className="answer">{props.children}</div>
		</InputGroup>}
		<br/>
		{/* userDoc: {userDoc && userDoc.content} loading: {docLoading} error: {docError} */}
		<br/>
		<br/>
		<br/>
	</div>
};

const FreeAnswer: React.FC<AnswerProps> = (props) => {
	let [initialized, setInitialized] = React.useState(false);
	let [text, setText] = React.useState('');

	let [userDoc, updateDoc, docLoading, docError] = useUserDoc(COURSE_ID, props.id);

	React.useEffect(()=> {
		if (!initialized && userDoc) {
			setText(userDoc.content);
			setInitialized(true);
		}
	});

	return <div className="answerBox">
		<InputGroup label="">
			<TextArea value={text} {...props.textAreaProps} onChange={e => setText(e.target.value)} onBlur={e => updateDoc(e.target.value)} />
		</InputGroup>
	</div>
};


interface QuizProps {
	id: string,
	title: string,
	questions: [JSX.Element, JSX.Element][],
}

const QuizPage: React.FC<QuizProps> = (props) => {
	const prefix = useCoursePrefix();

	return <Layout narrow>
		<h1>Quiz: {props.title}</h1>

		<p>
			Now it's time to test your knowledge about {props.title.toLowerCase()}.
			This quiz is not scored – it's just for you to help review and remember what you learned.
			It's ok if you don't remember something.
			Just write down as best as you can remember, then compare your answer with the given answer.
		</p>

		<p><strong>Make sure to write down your answer first, before you hit Show Answer!</strong></p>

		<ol>
			{props.questions.map((question, i) => (
				<li>
					<Question>
						{question[0]}
					</Question>
					<Answer id={`${props.id}.${i + 1}`}>
						{question[1]}
					</Answer>
				</li>
			))}
		</ol>

		<p>
			Compare your answers to my answers.  How do you think you did?
			If you feel like you didn't quite get something, you can always go back to the lesson to review it.
		</p>

		<Link to={prefix}><Button intent="primary">Finished</Button></Link>
	</Layout>
}

export { Question, Answer, FreeAnswer, QuizPage }
