import * as React from 'react';
import { WrappedCanvas } from './WrappedCanvas';
import { CanvasWrapper, CanvasInterface } from './canvasWrapper';


interface ExportedEventHandlers {
	whenKeyPressed?(key: string): boolean | void
	whenKeyReleased?(key: string)
	whenMouseClicked?(x: number, y: number)
	whenMouseMoved?(x: number, y: number)
}

type CanvasDemoFn = (canvas: CanvasWrapper) => Partial<ExportedEventHandlers> | void;

interface CanvasDemoProps {
	codeFn: CanvasDemoFn
	size?: number
}

const CanvasDemo: React.FC<CanvasDemoProps> = (props) => {
	let codeFn = props.codeFn;
	let initFn = (canvasInterface: CanvasInterface) => {
		let exported = codeFn(canvasInterface.wrapper);
		if (exported) {
			if (exported.whenKeyPressed) canvasInterface.handlers.onKeyPressed = exported.whenKeyPressed;
			if (exported.whenKeyReleased) canvasInterface.handlers.onKeyReleased = exported.whenKeyReleased;
			if (exported.whenMouseMoved) canvasInterface.handlers.onMouseMoved = exported.whenMouseMoved;
			if (exported.whenMouseClicked) canvasInterface.handlers.onMouseClicked = exported.whenMouseClicked;
		}
		canvasInterface.hasRun = true;
	}

	return <div className="CanvasDemo">
		<WrappedCanvas scaledWidth={props.size}  scaledHeight={props.size} canvasInterfaceRef={React.useRef(null)} runButton={false} onInit={initFn} />
	</div>
}

export { CanvasDemo, CanvasDemoProps, CanvasDemoFn }
