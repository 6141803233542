import { CanvasDemoFn } from "../lib/CanvasDemo";

const bouncingDotsDemo: CanvasDemoFn = canvas => {
	// we want to have more than one dot, so we store them in an array
	var dots = []

	dots.push(createDot());

	canvas.animate(mainLoop)

	// create dot as an object, instead of a bunch of separate variables
	function createDot(x = 0, y = 0) {
		var newBall = {
			x: x || Math.floor(Math.random() * 400),
			y: y || Math.floor(Math.random() * 400),
			xDir: Math.ceil(Math.random() * 5),
			yDir: Math.ceil(Math.random() * 5),
			color: randomColor(),
			radius: 25,
		}
		return newBall
	}

	function mainLoop() {
		for (var i = 0; i < dots.length; i++) {
			var dot = dots[i];

			// update position
			dot.x += dot.xDir
			dot.y += dot.yDir

			// bounce horizontally
			if (dot.x + dot.radius >= 400 && dot.xDir > 0 || dot.x - dot.radius <= 0 && dot.xDir < 0) {
				dot.xDir = -dot.xDir;
			}
			// bounce vertically
			if (dot.y + dot.radius >= 400 && dot.yDir > 0 || dot.y - dot.radius <= 0 && dot.yDir < 0) {
				dot.yDir = -dot.yDir;
			}
		}

		// draw
		canvas.background('skyblue');
		for (var i = 0; i < dots.length; i++) {
			var dot = dots[i];

			canvas.fill(dot.color);
			canvas.ellipse(dot.x, dot.y, dot.radius * 2);
		}
	}

	function randomColor() {
		return '#' + Math.random().toString(16).slice(2, 8);
	}

	function whenKeyPressed(key) {
		if (key == 'c') {
			dots = []
		}
		else if (key == 'backspace') {
			dots.pop()
		}
		else {
			dots.push(createDot());
		}
	}

	function whenMouseClicked(x, y) {
		dots.push(createDot(x, y));
	}

	return {
		whenKeyPressed,
		whenMouseClicked,
	}
}

const bouncingDotsInitial = `
// bouncing dots

// create dot as an object, instead of a bunch of separate variables
var dot = {
	x: Math.floor(Math.random() * 400),
	y: Math.floor(Math.random() * 400),
	xDir: Math.ceil(Math.random() * 5),
	yDir: Math.ceil(Math.random() * 5),
	color: randomColor(),
}

canvas.animate(mainLoop)

function mainLoop() {
	// update position
	dot.x += dot.xDir
	dot.y += dot.yDir

	// bounce horizontally
	if (dot.x >= 400 || dot.x <= 0) {
		dot.xDir = -dot.xDir;
	}
	// bounce vertically
	if (dot.y >= 400 || dot.y <= 0) {
		dot.yDir = -dot.yDir;
	}

	// draw
	canvas.background('skyblue');
	canvas.fill(dot.color);
	canvas.ellipse(dot.x, dot.y, 50);
}

// You don't need to understand this part yet :)
// Just know that it will give you a random color.
function randomColor() {
	return '#' + Math.random().toString(16).slice(2, 8);
}`

export { bouncingDotsDemo, bouncingDotsInitial }
