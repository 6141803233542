import * as React from 'react'
// import loader from 'monaco-loader';
// import monaco from 'monaco-editor'
import { editor, languages } from 'monaco-editor/esm/vs/editor/editor.api'
import firebase from 'firebase';
import { Prompt } from 'react-router'

import { getUserDocRef, UserDoc } from '../model/userDoc';

// const frameSrc = 'http://localhost:5000/monaco-frame-2?origin=' + encodeURIComponent(location.origin);
// const frameOrigin = 'http://localhost:5000';
const frameSrc = 'https://monaco-frame.mortensoncreative.com/monaco-frame-2?origin=' + encodeURIComponent(location.origin);
const frameOrigin = 'https://monaco-frame.mortensoncreative.com';
const COURSE_ID = 'js-intro';

// type MonacoNS = typeof monaco;

interface MonacoConfig {
	// initialValue: string
	compilerOptions: languages.typescript.CompilerOptions
	editorOptions: editor.IEditorConstructionOptions
	extraLibs: string[] | string
}

interface MonacoFrameInterface {
	updateCode(code: string, run?: boolean): void
	runCode(): void
	// insertAt
}

interface MonacoFrame2Props {
	initialCode: string
	frameProps?: React.IframeHTMLAttributes<HTMLIFrameElement>
	// frameSrc?: string
	focus?: boolean
	resize?: boolean
	extraLibs?: string | string[]
	storageKey?: string
	docName?: string
	docGroup?: string
	docType?: string
	noReset?: boolean
	// frameOrigin?: string
	// showReset?: boolean
	height?: number | string
	width?: number | string
	// resize?: string
	beforeInit?(/* monaco: MonacoNS,  */options: MonacoConfig): MonacoConfig
	afterInit?(api: MonacoFrameInterface): void
	beforeRun?(data: string): boolean | void
	afterRun?(details: {success: boolean, error?: any, stack?: string, value?: any}): void
	autorun?: boolean
	unsavedChangesWarning?: boolean

	// TODO: implement this runner
	// onRun?(console: any): boolean // return false to cancel the run
	// editorDidMount?(editor: monaco.editor.IStandaloneCodeEditor, container: HTMLElement): void
}

// const auth = firebase.auth();

class MonacoFrame2 extends React.Component<MonacoFrame2Props, {dirty: boolean}> {
	editor: editor.IStandaloneCodeEditor
	// resizer: any
	frame: React.RefObject<HTMLIFrameElement>
	frameId: string
	docRef: firebase.firestore.DocumentReference
	auth: firebase.auth.Auth
	lastSavedContent: string
	// dirty: false;

	// frameReady = false
	// frameReadyPromise: Promise<null>
	// setFrameReady: () => void

	// editorReady = false
	// editorReadyPromise: Promise<null>
	editorReady: Promise<null>
	setEditorReady: () => void

	consoleProxy: {
		log: (...args: any[]) => void
		error: (...args: any[]) => void
	}

	constructor(props) {
		super(props);
		this.state = {dirty: false};

		this.frame = React.createRef();
		this.frameId = randomString(10);

		// this.frameReadyPromise = new Promise((res) => {
		// 	this.setFrameReady = () => {
		// 		this.frameReady = true;
		// 		res();
		// 	}
		// });

		this.editorReady = new Promise((res) => {
			this.setEditorReady = res;
			// this.setEditorReady = () => {
			// 	this.editorReady = true;
			// 	res();
			// }
		});

		if (this.props.docName) {
			this.auth = firebase.auth();
			let cu = this.auth.currentUser;
			if (cu) {
				this.initDocRef(cu);
			}
			else {
				// console.log("waiting for auth state");
				let unsubscribe = this.auth.onAuthStateChanged(user => {
					// console.log('got auth state')
					this.initDocRef(user);
					// console.log('unsubscribing')
					unsubscribe();
				});
			}
		}
	}

	initDocRef = async (user: firebase.User) => {
		// console.log('initDocRef');
		this.docRef = getUserDocRef(COURSE_ID, user.uid, this.props.docName);
		try {
			let docSnapshot = await this.docRef.get({source: 'default'});
			// console.log('docSnapshot', docSnapshot);

			let doc = docSnapshot.data() as UserDoc;
			// console.log('doc', doc);

			if (doc && doc.content) {
				this.lastSavedContent = doc.content;
				this.updateCode(doc.content);
			}
		}
		catch (e) {
			console.error(e);
		}
	}

	componentDidMount() {
		// console.log('componentDidMount')
		window.addEventListener('message', this.handleMessage);
		if (this.props.unsavedChangesWarning) {
			window.addEventListener('beforeunload', this.handlePageUnload);
		}
	}

	componentWillUnmount() {
		// console.log('componentWillUnmount')
		window.removeEventListener('message', this.handleMessage);
		if (this.props.unsavedChangesWarning) {
			window.removeEventListener('beforeunload', this.handlePageUnload);
		}
	}

	initFrame(data: MonacoConfig) {
		let { beforeInit, storageKey, initialCode, extraLibs, docName, noReset } = this.props;
		let frame = this.frame.current


		// let editorOptions = e.data.editorOptions as
		let config: MonacoConfig = {
			// initialValue: data.initialValue,
			compilerOptions: data.compilerOptions,
			editorOptions: data.editorOptions,
			extraLibs: data.extraLibs,
		};

		// data.config;
		if (beforeInit) {
			config = beforeInit(/* null,  */config);
		}

		let resetCode = initialCode;

		if (storageKey) {
			let auth = this.auth || (this.auth = firebase.auth());
			let userPrefix = (auth.currentUser ? auth.currentUser.uid.slice(0, 4) : 'anon') + '-';
			let userStorageKey = userPrefix + storageKey;
			let storedValue = sessionStorage.getItem(userStorageKey);
			if (storedValue) {
				// resetCode = initialCode;
				initialCode = storedValue;
			}
		}
		// config.editorOptions.value = initialCode;

		// editorOptions.fontFamily = '"Rubik Mono One", arial'
		this.sendMessage({
			msg: 'init',
			id: this.frameId,
			...config,
			code: initialCode,
			resetCode,
			extraLibs,
			canSave: !!docName || !!storageKey,
			noReset: noReset,
			// runRemote,
		})
	}

	requestRun() {
		this.sendMessage({
			msg: 'requestRun',
		});
	}

	runCode(source: string) {
		let { storageKey, beforeRun } = this.props;

		if (beforeRun) {
			beforeRun(source);
		}

		if (storageKey) {
			let userPrefix = (this.auth.currentUser ? this.auth.currentUser.uid.slice(0, 4) : 'anon') + '-';
			let userStorageKey = userPrefix + storageKey;
			sessionStorage.setItem(userStorageKey, source);
		}

		if (this.docRef && this.lastSavedContent != source) {
			// console.log('updating doc')
			let content = source;
			let userId = this.auth.currentUser.uid;
			let docName = this.props.docName;
			let now = Date.now();
			let updateObj: Partial<UserDoc> = {
				content: content,
				course_id: COURSE_ID,
				user_id: userId,
				doc_name: docName,
				// if we needed more security, firebase.firestore.FieldValue.serverTimestamp() would work,
				// but firebase.firestore.Timestamp is an object w/ seconds and nanoseconds.
				// a single number representing millis is much more convenient
				updated: now,
			}

			if (this.props.docGroup) updateObj.group = this.props.docGroup;
			if (this.props.docType) updateObj.type = this.props.docType as 'console' | 'canvas' | 'custom';

			// console.log('updating doc', updateObj);
			this.docRef.set(updateObj, {merge: true});
			this.lastSavedContent = source;

			// save a version backup. user projects only.
			if (updateObj.group == 'user') {
				// shorten the key just a little. If you save twice in the same second,
				// the second save will overwrite the first, and that's a-ok.
				var ts = Math.floor(now / 1000);
				var versionDocRef = firebase.firestore().doc(`user_doc_versions/${userId}_${docName}_${ts}`);
				versionDocRef.set(updateObj, {merge: true});
			}
		}
	}

	handleMessage = (e: MessageEvent) => {
		let { afterInit, afterRun } = this.props;


		// console.log('window message received', e.data);

		// debugger
		// if (e.origin == location.origin) {
			let data = e.data, event = data.msg;

			if (data.frameId !== this.frameId) {
				// console.log(this.frameId, 'ignoring from', data.frameId)
				return;
			}

			if (event == 'ready') {
				this.initFrame(data);
			}
			else if (event == 'postInit') {
				// updateCode could be called here. do we want that before or after afterInit?
				this.setEditorReady();
				if (afterInit) {
					afterInit({
						updateCode: (code, run) => this.updateCode(code, run),
						runCode: () => this.requestRun(),
					});
				}
				if (this.props.autorun) {
					this.requestRun();
				}
			}
			else if (event == 'beforeRun') {
				this.runCode(data.source);
				this.setState({dirty: false});
			}
			else if (event == 'afterRun') {
				if (afterRun) {
					afterRun(data);
				}
			}
			else if (event == 'dirty') {
				this.setState({dirty: data.value});
			}
		// }
	}

	handlePageUnload = (e) => {
		if (this.state.dirty) {
			// this message will be ignored in most or all browsers, but a generic message will be used instead
			let message = 'Are you sure you want to leave? You have unsaved changes. Hit Save & Run to save.';
			e.returnValue = message;
			return message;
		}
	}

	sendMessage(message: any) {
		let frame = this.frame.current
		try {
			// console.log('posting message', frame.src, frameOrigin, message);
			frame.contentWindow.postMessage(message, frameOrigin);
			// console.log('posted');
		}
		catch (e) {
			console.error('failed to post message', message, e);
		}
	}

	async updateCode(code: string, run?: boolean) {
		// console.log('updateCode called', code.slice(0, 25))
		await this.editorReady;

		// console.log('MonacoFrame2 posting message: updateCode', code.slice(0, 25));
		this.sendMessage({ msg: 'updateCode', code, run})
	}

	render() {
		// console.log('rendering MonacoFrame')
		let frameProps = this.props.frameProps;

		if (!frameProps) {
			frameProps = {};
		}
		if (!frameProps.style) {
			frameProps.style = {width: this.props.width || '100%', height: this.props.height || '100%'};
			if (this.props.resize) frameProps.style.resize = 'vertical';
		}

		// let src = this.props.frameSrc || defaultSrc
		let src = frameSrc;
		src += (frameSrc.indexOf('?') > 0 ? '&' : '?') + 'frameId=' + this.frameId;

		frameProps.src = src;

		return <>
			<Prompt when={this.state.dirty} message='Are you sure you want to leave? You have unsaved changes.' />
			<iframe id={this.frameId} ref={this.frame} frameBorder="none" {...frameProps} />
		</>
	}
}

function randomString(len: number) {
	let str = ''
	while (str.length < len) {
	 str += (Math.random() * 36 | 0).toString(36)
	}
	return str
}

export { MonacoFrame2, MonacoFrame2Props, MonacoFrameInterface }
